import tw from 'twin.macro';

export enum FontType {
  SUB = 'main_normal',
  MAIN = 'large_bold',
  SMALL = 'small_bold',
  SMALL_NORMAL = 'small_normal',
  TITLE5 = 'smalltitle',
  TITLE4 = 'littletitle',
  TITLE3 = 'subtitle',
  TITLE2s = 'title_s',
  TITLE2 = 'title',
  TITLE1 = 'exlarge_bold',
  TAG = 'tag',
}

export const FontStyles = {
  exsmall_normal: tw`font-normal text-xs`, //400 0.75rem
  small_normal: tw`font-normal md:text-sm text-[12px]`, //400 0.75rem
  small_bold: tw`font-bold text-sm`, //700 0.75rem
  main_normal: tw`font-medium text-base`, //500 1rem
  large_normal: tw`font-medium text-xl`, //500 1.25rem
  large_bold: tw`font-medium text-2xl`, //700 1.25rem
  exlarge_bold: tw`font-bold md:text-5xl text-3xl`,
  title_s: tw`font-bold md:text-2xl text-[14px]`,
  title: tw`font-bold md:text-3xl text-[20px]`,
  subtitle: tw`font-bold md:text-[14px] text-[12px]`,
  littletitle: tw`font-bold md:text-base text-[14px]`,
  smalltitle: tw`font-bold text-[14px]`,
  tag: tw`font-semibold text-xs`,
};

// eslint-disable-next-line
export const applyFont = (fontType: FontType) => FontStyles[fontType];
