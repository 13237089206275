//eslint-disable
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Text } from '../../atoms/Text';
import { UserTag } from '../../atoms/UserTag';
import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import dayjs from 'dayjs';
import { API_URL, CHAIN_NETWORK } from '../../../config';
import { useNavigate } from 'react-router-dom';
import { RiShareBoxLine } from 'react-icons/ri';
import { createScanText } from '../ArtDetail';
import { CreateDipositUrlPopUp } from '../../CreateDepositUrlPopUp';
import {
  CreateUrl,
  ButtonContainer,
  DepositButton,
  HistoryWrapper,
  NoteContainer,
  NoteContent,
} from './style';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { Title } from '../Title';

interface Column {
  id:
    | 'artName'
    | 'price'
    | 'benefit'
    | 'royality'
    | 'seller'
    | 'buyer'
    | 'buyAt'
    | 'url'
    | 'depositUrl'
    | 'authCode'
    | 'depositStatus'
    | 'deadline';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const useStyles = makeStyles({
  topContainer: {
    padding: '4rem',
    '@media screen and (max-width:600px)': {
      padding: 0,
    },
  },
  paper: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {},
  headerRow: {},
  headerCell: {
    backgroundColor: '#1A1A1A',
    color: '#fff',
    fontWeight: 'bold',
    borderRight: '1px solid #fff',
    fontSize: '12px',
  },
  artName: {
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '12px',
  },
  bodyCell: {
    fontSize: '12px',
  },
});

type Props = {
  history: any[];
  type: 'purchase' | 'sale' | 'deposit' | 'royalty';
};

interface UpdatePurchase {
  purchaseId: number;
  authCode: string;
  linkUrl: string;
}

export const HistoryTable: React.FC<Props> = ({ history, type }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const authContext: AuthContextType = useAuthProvider();

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState<
    number | undefined
  >(undefined);
  const [depositType, setDepositType] = useState(''); //sell or royalty
  const [purchases, setPurchases] = useState<UpdatePurchase[] | undefined>();

  const updatePurchase = (id: number, authCode: string, linkUrl: string) => {
    setPurchases(
      purchases?.map((purchase) =>
        purchase.purchaseId === id
          ? { ...purchase, authCode, linkUrl }
          : purchase,
      ),
    );
  };

  const createScanURL = (txHash: string) => {
    if (CHAIN_NETWORK === 'PolygonMainnet') {
      return `https://polygonscan.com/tx/${txHash}`;
    } else if (CHAIN_NETWORK === 'GoerliTest') {
      return `https://goerli.etherscan.io/tx/${txHash}`;
    }
  };

  const columns: Column[] = [
    { id: 'artName', label: '作品名', minWidth: 80 },
    type === 'purchase'
      ? {
          id: 'seller',
          label: '出品者',
          minWidth: 80,
          format: (value: number) => value.toLocaleString('en-US'),
        }
      : {
          id: 'buyer',
          label: '購入者',
          minWidth: 80,
          format: (value: number) => value.toLocaleString('en-US'),
        },
  ];

  if (type === 'purchase' || type === 'sale') {
    columns.push(
      {
        id: 'price',
        label: '価格',
        minWidth: 80,
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'buyAt',
        label: '購入日',
        minWidth: 80,
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'url',
        label: 'URL',
        minWidth: 80,
        format: (value: number) => value.toLocaleString('en-US'),
      },
    );
  }

  if (type === 'deposit' || type === 'royalty') {
    columns.push(
      {
        id: 'benefit',
        label: '販売利益',
        minWidth: 80,
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'depositStatus',
        label: '振込申請履歴',
        minWidth: 80,
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'deadline',
        label: '振込申請期限',
        minWidth: 80,
        format: (value: number) => value.toLocaleString('en-US'),
      },
      {
        id: 'depositUrl',
        label: '個別振込申請',
        minWidth: 80,
        format: (value: number) => value.toLocaleString('en-US'),
      },
    );
  }

  return (
    <HistoryWrapper>
      <CreateDipositUrlPopUp
        close={() => setIsPopUpOpen(false)}
        isDisplayed={isPopUpOpen}
        purchaseId={selectedPurchaseId}
        updatePurchase={updatePurchase}
        depositType={depositType}
      />
      <Box className={classes.topContainer}>
        {type === 'purchase' && (
          <Box padding="12px" display="flex" alignItems="end">
            <Title title="購入履歴" subTitle="Purchase history" />
          </Box>
        )}
        {type === 'sale' && (
          <Box padding="12px" display="flex" alignItems="end">
            <Title title="販売履歴" subTitle="SalesHistroy" />
          </Box>
        )}
        {type === 'deposit' && (
          <>
            <Box padding="12px" display="flex" alignItems="end">
              <Title
                title="個別振込申請"
                subTitle="Individual Transfer Application"
              />
            </Box>
            <NoteContent>
              各作品ごとに振込申請を行うことができます。（振込手数料がかかります）
            </NoteContent>
          </>
        )}
        {type === 'royalty' && (
          <>
            <Box padding="12px" display="flex" alignItems="end">
              <Title title="ロイヤリティ履歴" subTitle="Roylaty history" />
            </Box>
            <NoteContent>
              各作品のロイヤリティごとに振込申請を行うことができます。（振込手数料がかかります）
            </NoteContent>
          </>
        )}
        {history && history.length > 0 ? (
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className={classes.table}
              >
                <TableHead className={classes.table}>
                  <TableRow className={classes.headerRow}>
                    {columns.map((column) => (
                      <>
                        <TableCell
                          className={classes.headerCell}
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          <Box justifyContent="center" display="flex">
                            {column.label}
                          </Box>
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history &&
                    history.map((row) => {
                      let depositStatus = '';
                      if (type === 'deposit' || type === 'royalty') {
                        switch (row.gmopgDepositLink.depositStatus) {
                          case 'WAITING':
                            depositStatus = '振込情報入力待ち';
                            break;
                          case 'DEPOSIT_REQUEST_CREATED':
                            depositStatus = '振込申請中';
                            break;
                          case 'DEPOSIT_DATA_CREATED':
                            depositStatus = '送金データ作成中';
                            break;
                          case 'DEPOSIT_DATA_FAILED':
                            depositStatus = '送金データ作成失敗';
                            break;
                          case 'DEPOSITED':
                            depositStatus = '振込済み';
                            break;
                          case 'DEPOSIT_FAILED':
                            depositStatus = '振込失敗';
                            break;
                          case 'DEPOSIT_CANCEL':
                            depositStatus = '振込キャンセル';
                            break;
                          default:
                            depositStatus = '未申請';
                        }
                      }
                      return (
                        <>
                          <TableRow hover tabIndex={-1} key={row.purchaseId}>
                            {/* art name */}
                            <TableCell
                              className={classes.artName}
                              key={`artname-${row.purchaseId}`}
                              onClick={() =>
                                navigate(`/items/${row.art.artId}`)
                              }
                            >
                              {row.art.name}
                            </TableCell>
                            {/* seller or buyer info */}
                            {type === 'purchase' ? (
                              <TableCell
                                className={classes.bodyCell}
                                key={`seller-${row.purchaseId}`}
                              >
                                <UserTag
                                  userTagImageUri={
                                    API_URL + `/users/${row.seller.userId}/icon`
                                  }
                                  userName={row.seller.userName}
                                  onclick={() =>
                                    navigate(`/userpage/${row.seller.userId}`)
                                  }
                                />
                              </TableCell>
                            ) : (
                              <TableCell
                                className={classes.bodyCell}
                                key={`buyer-${row.purchaseId}`}
                              >
                                <UserTag
                                  userTagImageUri={
                                    API_URL + `/users/${row.buyer.userId}/icon`
                                  }
                                  userName={row.buyer.userName}
                                  onclick={() =>
                                    navigate(`/userpage/${row.buyer.userId}`)
                                  }
                                />
                              </TableCell>
                            )}
                            {/* price or banefit */}
                            {type === 'purchase' && (
                              <TableCell
                                className={classes.bodyCell}
                                key={`value-${row.purchaseId}`}
                              >
                                {row.value + '円'}
                              </TableCell>
                            )}
                            {type === 'sale' && (
                              <TableCell
                                className={classes.bodyCell}
                                key={`value-${row.purchaseId}`}
                              >
                                {row.value + '円'}
                              </TableCell>
                            )}
                            {type === 'deposit' && (
                              <TableCell
                                className={classes.bodyCell}
                                key={`price-${row.purchaseId}`}
                              >
                                {row.sellerDepositValue.toLocaleString() + '円'}
                              </TableCell>
                            )}
                            {type === 'royalty' && (
                              <TableCell
                                className={classes.bodyCell}
                                key={`price-${row.purchaseId}`}
                              >
                                {row.royaltyDepositValue.toLocaleString() +
                                  '円'}
                              </TableCell>
                            )}
                            {/* purchase or sale date */}
                            {type === 'purchase' && (
                              <>
                                <TableCell
                                  className={classes.bodyCell}
                                  key={`createdAt-${row.purchaseId}`}
                                >
                                  {dayjs(row.createdAt).format(
                                    'YYYY/MM/DD HH:mm',
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.bodyCell}
                                  key={`url-${row.purchaseId}`}
                                >
                                  <a
                                    href={createScanURL(row.transactionHash)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      textDecoration: 'none',
                                      color: '#808080',
                                      fontWeight: '600',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <RiShareBoxLine
                                      style={{ marginRight: '2%' }}
                                    />
                                    {createScanText()}
                                  </a>
                                </TableCell>
                              </>
                            )}
                            {type === 'sale' && (
                              <>
                                <TableCell
                                  className={classes.bodyCell}
                                  key={`createdAt-${row.purchaseId}`}
                                >
                                  {dayjs(row.createdAt).format(
                                    'YYYY/MM/DD HH:mm',
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.bodyCell}
                                  key={`url-${row.purchaseId}`}
                                >
                                  <a
                                    href={createScanURL(row.transactionHash)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      textDecoration: 'none',
                                      color: '#808080',
                                      fontWeight: '600',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <RiShareBoxLine
                                      style={{ marginRight: '2%' }}
                                    />
                                    {createScanText()}
                                  </a>
                                </TableCell>
                              </>
                            )}
                            {/* deposit status */}
                            {type === 'deposit' && (
                              <TableCell
                                key={`depositStatus-${row.purchaseId}`}
                              >
                                {depositStatus}
                              </TableCell>
                            )}
                            {type === 'royalty' && (
                              <TableCell
                                key={`depositStatus-${row.purchaseId}`}
                              >
                                {depositStatus}
                              </TableCell>
                            )}
                            {type === 'deposit' && (
                              <>
                                <TableCell key={`deadline-${row.purchaseId}`}>
                                  {dayjs(row.createdAt)
                                    .add(120, 'd')
                                    .format('YYYY/MM/DD')}
                                </TableCell>
                              </>
                            )}
                            {type === 'royalty' && (
                              <>
                                <TableCell key={`deadline-${row.purchaseId}`}>
                                  {dayjs(row.createdAt)
                                    .add(120, 'd')
                                    .format('YYYY/MM/DD')}
                                </TableCell>
                              </>
                            )}
                            {/* link url */}
                            {type === 'deposit' &&
                              !row.gmopgDepositLink.DepositID &&
                              (depositStatus == '未申請' ||
                                depositStatus == 'WAITING') &&
                              row.sellerDepositValue > 300 && (
                                <>
                                  <TableCell
                                    onClick={() => {
                                      setIsPopUpOpen(true);
                                      setSelectedPurchaseId(row.purchaseId);
                                      setDepositType('sell');
                                    }}
                                    key={`createUrl-${row.purchaseId}`}
                                  >
                                    <CreateUrl>個別振込申請</CreateUrl>
                                  </TableCell>
                                </>
                              )}
                            {type === 'royalty' &&
                              !row.gmopgDepositLink.DepositID &&
                              (depositStatus == '未申請' ||
                                depositStatus == 'WAITING') &&
                              row.royaltyDepositValue > 300 && (
                                <>
                                  <TableCell
                                    onClick={() => {
                                      setIsPopUpOpen(true);
                                      setSelectedPurchaseId(row.purchaseId);
                                      setDepositType('royalty');
                                    }}
                                    key={`createUrl-${row.purchaseId}`}
                                  >
                                    <CreateUrl>個別振込申請</CreateUrl>
                                  </TableCell>
                                </>
                              )}
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            margin="2rem"
          >
            <SearchIcon style={{ fontSize: '32px' }} color="disabled" />
            <Text fontType={FontType.SMALL} color={Color.BORDER20}>
              表示する履歴がありません
            </Text>
          </Box>
        )}
        {history && (
          <>
            {type === 'purchase' && (
              <>
                <NoteContent>
                  ※
                  決済完了後に購入履歴に反映されない場合がございます。その場合、時間をあけてもう一度ご確認下さい
                </NoteContent>
                <NoteContent>
                  ※
                  作品の移転(Transfer)が失敗した場合は通知が送信されます。通知内容をご確認のうえ、作品詳細画面からもう一度作品移転のリクエストを行って下さい
                </NoteContent>
              </>
            )}
            {type === 'sale' && (
              <>
                <NoteContent>
                  ※
                  販売完了後に購入履歴に反映されない場合がございます。その場合、時間をあけてもう一度ご確認下さい
                </NoteContent>
                <NoteContent>
                  ※売上金額を確認するには、以下のリンクもしくは、マイページメニューの「送金リンク生成」から送金リンクを生成し、送金リンクをクリックして下さい
                </NoteContent>
                <ButtonContainer>
                  <DepositButton
                    onClick={() =>
                      navigate(
                        `/userpage/${authContext.authData.userId}/deposit`,
                      )
                    }
                  >
                    売上金の振込申請ページはこちら
                  </DepositButton>
                </ButtonContainer>
              </>
            )}
            {type === 'deposit' && (
              <NoteContainer>
                <NoteContent>
                  ※ 振込申請期限は120日間です。詳細は
                  <a href="/TermsOfUse">利用規約</a>をご覧下さい
                </NoteContent>
                <NoteContent>
                  ※
                  振込申請完了後に作品販売履歴に反映されない場合がございます。その場合、時間をあけてもう一度ご確認下さい
                </NoteContent>
                <NoteContent>
                  ※
                  口座情報の誤入力等によって口座振込が失敗した場合は別途手数料が発生します。
                </NoteContent>
              </NoteContainer>
            )}
            {type === 'royalty' && (
              <NoteContainer>
                <NoteContent>
                  ※ 振込申請期限は120日間です。詳細は
                  <a href="/TermsOfUse">利用規約</a>をご覧下さい
                </NoteContent>
                <NoteContent>
                  ※
                  振込申請後完了後にロイヤリティ履歴に反映されない場合がございます。その場合、時間をあけてもう一度ご確認下さい
                </NoteContent>
                <NoteContent>
                  ※
                  口座情報の誤入力等によって口座振込が失敗した場合は別途手数料が発生します。
                </NoteContent>
              </NoteContainer>
            )}
          </>
        )}
      </Box>
    </HistoryWrapper>
  );
};
