import styled from '@emotion/styled';
import tw from 'twin.macro';

export const AwardItemShowCaseWrapper = styled.div`
  ${tw`md:mb-16`}
`;

export const StyledShowCaseContainer = styled.div`
  ${tw`flex-grow mb-8`}
`;

export const StyledTextContainer = styled.div`
  ${tw`mx-auto text-center`}
`;

export const StyledText = styled.p`
  ${tw`text-sm md:text-base font-bold block`}
`;

export const StyledLink = styled.a`
  ${tw`text-xs md:text-base font-bold block`}
`;
