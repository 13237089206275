import { Box } from '@material-ui/core';
import React, { FC } from 'react';

type Props = {
  width: number;
};

const SpacerX: FC<Props> = ({ width }: Props) => {
  return <Box width={width} />;
};

export default SpacerX;
