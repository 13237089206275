import styled from '@emotion/styled';
import tw from 'twin.macro';

export const ContestManagementWrapper = styled.div`
  ${tw`my-12 flex flex-col gap-[40px] w-[80%] max-w-[1440px] mx-auto`}
`;

export const TitleWrapper = styled.div`
  ${tw`flex flex-row items-start justify-start w-full `}
`;

export const CreateEventBanner = styled.button`
  ${tw`w-full h-[320px] py-3 rounded-[32px] bg-transparent outline-dashed outline-2 border-none cursor-pointer`}
`;

export const CreateEventBannerItemContainer = styled.div`
  ${tw`flex flex-col justify-center items-center gap-[48px] w-full`}
`;

export const BannerContainer = styled.div`
  ${tw`mx-auto flex justify-center w-full`}
`;

export const BannerItemWrapper = styled.div`
  ${tw`flex flex-col items-start justify-center gap-[24px] w-[80%] h-max mx-auto`}
`;

export const BannerItemContainer = styled.div`
  ${tw`flex flex-col justify-center items-center gap-[48px] w-full`}
`;

export const CurrentBanner = styled.button`
  ${tw`w-full h-[320px] py-3 rounded-[32px] border-none cursor-pointer`}
  background: linear-gradient(90deg, rgba(225, 52, 134, 0.25) 0%, rgba(63, 146, 224, 0.25) 100%);
`;

export const SuspendedBanner = styled.button`
  ${tw`w-full h-[320px] py-3 rounded-[32px] border-none cursor-pointer`}
  background-color: rgba(253, 208, 0, 0.25);
`;

export const NipBannerContainer = styled.div`
  ${tw`mx-auto flex justify-center w-full`}
`;

export const NipBanner = styled.button`
  ${tw`w-full h-[320px] py-3 rounded-[32px] border-none cursor-pointer`}
  background-color: rgba(217, 217, 217, 0.75);
`;
