import styled from '@emotion/styled';
import tw from 'twin.macro';

export const ItemDetailContainer = styled.div`
  ${tw`bg-[#FDFDFD] m-5 rounded-xl`}
  ${tw`grid grid-cols-2 md:grid-cols-4`}
  filter: drop-shodow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -webkit-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -moz-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -ms-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
`;

export const PictureContainer = styled.div`
  ${tw`col-span-2 flex flex-col my-10`}
  cursor: pointer;
`;

export const StyledItemImage = styled.img`
  ${tw`w-full max-h-[24rem]`}
  object-fit: contain;
`;

export const StyledLike = styled.div`
  ${tw`flex justify-end items-center`}
`;

export const ItemInfoContainer = styled.div`
  ${tw`col-span-2 p-10 flex flex-col`}
`;

export const TagsContainer = styled.div`
  ${tw`flex flex-row flex-wrap gap-x-2 gap-y-2 pb-4`}
`;

export const StyledDescription = styled.div`
  ${tw`mt-4 mb-8`}
`;

export const StyledButtonContainer = styled.div`
  ${tw`flex flex-row justify-evenly py-10`}
`;

export const StyledPriceContainer = styled.div`
  ${tw`flex flex-col space-y-1 mt-3`}
`;

export const StyledPriceHStack = styled.div`
  ${tw`flex flex-row items-end`}
`;

export const UserTagVStack = styled.div`
  ${tw`flex flex-col pt-4 space-y-2`}
`;

export const ArtUserInfoContainer = styled.div`
  ${tw`flex flex-row space-x-4`}
`;

export const SalesforceUserInfoContainer = styled.div`
  ${tw`flex flex-col space-y-4 p-3 mt-6 mb-2`}
  background-color: #F5F5F7;
  border-radius: 12px;
`;

export const SalesforceUserInfoCol = styled.div`
  ${tw`flex flex-row `}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex flex-col md:flex-row w-full justify-around my-4`}
`;

export const WideButtonContainer = styled.div`
  ${tw`flex flex-row my-4`}
`;

export const ArtValueContainer = styled.div`
  ${tw`mt-4`}
`;

export const ArtValue = styled.p`
  ${tw`text-2xl font-bold my-0`}
`;
