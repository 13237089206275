import { useState } from 'react';
import { useAuth } from '../../../api/AuthService';
import { useNavigate } from 'react-router-dom';
import { useAlertContext } from '../../../Providers/AlertProvider';

export const useChangePasswordPage = () => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const { ChangePassword } = useAuth();
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();
  const [newPasswordValidation, setNewPasswordValidation] =
    useState<string>('');

  const ChangingPassword = async () => {
    if (newPassword != newPasswordValidation) {
      addAlert('新しいパスワードが一致していません', 'error');
      return;
    }
    await ChangePassword(oldPassword, newPassword)
      .then((res) => {
        if (res == undefined) {
          throw new Error();
        } else if (!res.ok) {
          throw new Error();
        } else {
          addAlert('パスワードを変更しました', 'success');
          navigate('/');
        }
      })
      .catch(() => addAlert('パスワードの変更に失敗しました', 'error'));
  };

  return {
    ChangingPassword,
    setNewPassword,
    setNewPasswordValidation,
    setOldPassword,
  };
};
