import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  StyledButton,
  StyledButtonContainer,
  DeliveryCompletedWrapper,
  DeliveryCompletedTextContainer,
  DeliveryCompletedText,
} from './style';
import { Title } from '../../organisms/Title';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';

export const DeliveryCompleted = () => {
  const navigate = useNavigate();

  return (
    <DeliveryCompletedWrapper>
      <Title title="出庫完了" subTitle="Delivery Completed" />
      <DeliveryCompletedTextContainer>
        <DeliveryCompletedText>出庫が完了しました。</DeliveryCompletedText>
      </DeliveryCompletedTextContainer>
      <StyledButtonContainer>
        <StyledButton onClick={() => navigate('/')}>
          <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
            ホームに戻る
          </Text>
        </StyledButton>
      </StyledButtonContainer>
    </DeliveryCompletedWrapper>
  );
};
