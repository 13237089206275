/* eslint-disable */
import { TermsOfUseWrapper, TermsOfUseContainer } from './style';
import { Title } from '../../organisms/Title';
import React from 'react';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';

export const TermsOfUse: React.FC = () => {
  //第1条//
  const listItems01 = [
    '本規約は、本サービスの利用に関する条件及び当社と会員（次条第2号において定義します。本条において利用希望者（第3条第1項において定義します。）を含むものとします。）との間の権利義務関係を定めることを目的としています。',
    '本規約に同意いただくことにより、当社と会員の間には、本規約を内容とする契約（以下「本利用契約」といいます。）が成立するものとします。',
    '当社が本ウェブサイト（当社が本サービスに関連して運営するウェブサイト（URL：https://nft.yoani.co.jp/。URL又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）のことをいいます。以下同様です。）に掲載される「ガイドライン（URL：https://nft.yoani.co.jp/guideline）」（以下「本ガイドライン」といいます。）その他本サービスに関する一切のルール及び説明として本ウェブサイト上に随時掲載されるもの（名称を問いません。以下「個別規約等」と総称します。）は、本規約の一部を構成するものとします。個別規約等の定めが本規約の定めと矛盾又は抵触する場合、当該個別規約等の定めが本規約に優先して適用されるものとします。',
    '法人その他の団体に所属する個人が、当該団体を代表して第3条第1項に基づき本サービスの会員登録を行った場合、当該個人は、当該団体を代表して、本規約に同意のうえ当社との間で本利用契約を締結するための代理権、代表権その他の正当な権限を有するとみなされます。',
    '会員が未成年者である場合、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。また、本規約への同意時に未成年であった会員が成年に達した後に本サービスを利用した場合、当該会員が未成年であった間の本サービスに関する一切の法律行為及び利用行為を追認したものとみなします。',
    '未成年者の会員が、法定代理人の同意がないにもかかわらず同意があると偽り又は年齢について成年と偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為及び利用行為を取り消すことはできません。',
  ];

  //第2条//
  const listItems02 = [
    '「NFT」とは、Non-Fungible Tokenの略称であり、ブロックチェーン技術を用いてブロックチェーン上で発行される非代替性トークンをいいます。',
    '「会員」とは、第3条第1項に基づき会員登録を行った者をいいます。',
    'NFTの「発行」とは、本サービスを通じてデジタルデータをNFTとしてブロックチェーン上で取引することができるようにデータ処理することをいいます。',
    '「権利者」とは、デジタルデータに係る知的財産権（第7号において定義します。）、利用許諾契約に基づく利用権、肖像権、プライバシー権その他の当該デジタルデータを用いてNFTを発行し、出品（本サービスを通じてNFTを購入可能な状態に置くことをいいます。以下同様です。）し、販売するために必要な権利を有する者をいいます。',
    '「出品者」とは、本サービスを通じてNFTを出品した権利者又は会員をいいます。',
    '「NFT保有者」とは、本サービスを通じてNFTを購入し、本サービス上で現にNFTを保有している会員をいいます。',
    '「知的財産権」とは、全世界における、特許権、実用新案権、意匠権、商標権、著作権（翻訳・翻案権及び二次的著作物の利用に関する原著作者の権利を含みます。）その他一切の知的財産権、及びそれらの登録、更新又は延長等の手続を行う権利をいいます。',
    '「反社会的勢力等」とは、暴力団、暴力団員、暴力団員でなくなってから5年を経過しない者、暴力団準構成員、暴力団関係企業・団体、総会屋、社会運動・政治活動標ぼうゴロ、特殊知能暴力集団、右翼集団その他の反社会的活動を行う団体又はその構成員及びこれに準ずる者をいいます。',
    '「法令等」とは、法律、政令、通達、規則、条例、裁判所の判決、決定、命令又は強制力のある行政処分、ガイドライン、業界団体等における自主規制規則その他の規制の総称をいいます。',
  ];

  //第3条//
  const listItems03_innerListItems = [
    '当社に提供された登録情報の全部又は一部に虚偽、誤記又は記入漏れがある場合',
    '自ら又はその役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者を含みます。）が反社会的勢力等である場合、又は資金提供その他の行為を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与をしている場合',
    '過去に第15条柱書本文に定める措置を受け、本サービスその他の当社のサービスにおいて会員登録を拒否され若しくはアカウントの取消等の措置を受け、又は当社との間での契約その他の合意に違反した場合',
    '本サービスその他の当社の営む事業と競合し、又は競合する可能性がある事業を行っている場合',
    '当社所定の手順を経ずに会員登録を行った場合',
    '当社が別途定める会員登録要件を満たさない場合',
    '本サービスを違法若しくは不当な目的で利用し、又は本サービスの利用を認めることで当社の社会的評価を低下させるおそれがある場合',
    'その他当社が当該利用希望者に本サービスを利用させることが不適当であると判断した場合',
  ];
  const listItems03 = [
    '本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約及び本プライバシーポリシーに同意し、かつ当社が別途定める一定の情報（以下「登録情報」といいます。）を当社所定の方法により当社に提供し、自身のアカウントを作成して、会員登録を完了する必要があります。当社は、次項その他の当社の基準に従い利用希望者による会員登録の当否を判断し、その結果を利用希望者に通知します。当社が会員登録を認める旨の通知をした時点で、会員登録が完了するとともに、当社と利用希望者の間で本規約に従った本利用契約が成立するものとします。',
    [
      '当社は、利用希望者が次の各号に定めるいずれかの事由に該当し又は該当するおそれがある場合には、事前の通知又は催告等を経ることなく、会員登録を拒否することができます。なお、当社が会員登録を拒否した場合においても、当社はその理由を開示する義務を負いません。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems03_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    '会員は、登録情報に誤り又は変更がある場合には、当社が別途定める方式に従い、当該誤り又は変更に係る内容を当社に通知し、かつ本ウェブサイト上で自ら修正又は変更するものとします。',
  ];

  //第4条//
  const listItems04 = [
    '会員は、ID、パスワードその他自身のアカウントに関する情報を、自己の責任において安全に管理・保管し、第三者による不正使用を防止するために必要な措置を講じるものとします。',
    '会員は、貸与、共有、譲渡、名義変更その他方法を問わず、第三者に自身のアカウントを使用させてはなりません。',
    '当社は、IDとパスワードの一致を確認した場合、当該ID及びパスワードの保有者として登録された会員が本サービスを利用したものとみなすことができます。',
    '会員は、本サービスのアカウントが権限を有しない第三者により不正にログインされ、その他不正に使用された場合、ただちにその旨を当社に通知するものとし、その後の対応は当社からの指示に従うものとします。',
  ];

  //第5条//
  const listItems05 = [
    '本サービスの利用には、当社が別途定める手数料が発生します。',
    'ガス代（ブロックチェーンのネットワーク手数料をいいます。以下同じです。）については、当社が別途指定する金額を当社が別途指定する方法により会員が負担するものとします。',
    '前項の規定に拘わらず、会員が代々木アニメーション学院の生徒である場合には、ガス代は当社が負担するものとします。',
  ];

  //第6条//
  const listItems06_innerListItems = [
    '法令等に違反し若しくは第三者の権利を侵害するもの、又は法令等に違反する行為若しくは又は第三者の権利を侵害することにより取得したもの',
    'その発行等又は保有が法令等に違反し又は第三者の権利を侵害するもの',
    'その発行等又は保有が本規約に違反するもの',
    '当社が本ガイドライン上で定めた「投稿できない作品」、「NFT化できない作品」その他の当社が発行等を禁止したもの',
    'その他当社が不適切であると認めたもの',
  ];
  const listItems06 = [
    '権利者は、当社に対して申請をし、承認を受けることで、本サービスを通じて、NFTを発行し、出品し、又は販売（以下「発行等」といいます。）することができます。なお、権利者以外の者（会員を含みます。）は、第8条第1項に定める二次出品を行う場合を除き、本サービスを通じてNFTの発行等をすることができません。',
    '当社は、本ガイドラインその他の当社が定める基準に従い、前項の承認の可否を決定します。なお、当社が承認しなかった場合においても、当社はその理由を開示する義務を負わず、承認がされなかったことにより会員に生じた損害その他の不利益について、一切の責任を負いません。',
    '権利者は、NFTの販売方法を、通常の販売方式、オークション方式、抽選販売方式その他当社が定める方法の中から任意に選ぶことができます。',
    [
      '権利者は、次の各号に定めるNFTを発行等することはできません。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems06_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    '権利者が前項各号に該当し又はそのおそれのあるNFTを発行等した場合、当社は、当社の判断により当該発行等を停止、取消又は無効とすることができます。当社は、当該発行等の停止、取消又は無効により権利者に生じる損害について、当社に故意又は重大な過失がある場合を除き、責任を負わないものとします。',
    '権利者は、自身が本サービスを通じてNFTを発行等するために必要な知的財産権その他の一切の権限を有し、これらの行為が第三者の権利を侵害しないことを表明し、保証するものとします。',
    '権利者によるNFTの発行等に関して、第三者による訴訟の提起その他権利者が権利を有しないことを理由とした紛争が発生した場合、権利者は、その責任と負担においてこれに対応するものとし、当社に一切の負担を生じさせないものとします。権利者は、当社に対し、当該紛争に起因して当社に発生する損害（合理的な弁護士費用を含みます。）を賠償するものとします。',
    '当社は、第1項第1文に基づきNFTの発行等を行った権利者（以下「発行者」といいます。）のみを当該NFTに係る本規約上の権利者として取り扱えば足り、発行者以外に当該NFTに係る権利者が存する場合（発行者が当該NFTの発行等に必要な権利を有しない場合を含みます。）であっても、当社は、当該権利者に対して何らの義務（第8条第3項に基づく支払義務その他の本規約に定める権利者に対する義務を含みますが、これらに限られません。）も負うものではありません。',
  ];

  //第7条//
  const listItems07 = [
    '会員は、本サービスを通じて、権利者又はNFT保有者が出品したNFTを購入することができます（以下、当該会員を「購入者」といいます。）。',
    '会員が、本ウェブサイト上のNFTの販売ページにおいて、確定的にNFTを購入することを内容とする操作をした時点で、出品者と購入者の間で当該NFTについての売買契約が成立するものとします。出品者及び購入者による当該売買契約の履行は、第5項及び第6項に定める方法によるものとします。',
    '当社は、NFTの購入金額、購入可能な回数その他NFTの売買に関連する事項について一定の制限を設ける場合があり、会員はこれに従うものとします。',
    '出品者及び購入者は、第2項の規定に基づき売買契約が成立した後は、民法、消費者契約法その他の法律により認められる場合を除き、当該契約の取消しやキャンセル等を行うことはできません。',
    '第2項の規定に基づき売買契約が成立した場合、購入者は、当社に対し、当社が定める方法に従いNFTの代金を支払うものとします。なお、出品者は、当該NFTの販売代金を、当社又は当社が指定する者が出品者に代わり、購入者から代理受領することについて同意します。',
    '当社は、前項の定めに基づく販売代金の受領後、出品者から出金申請がなされた場合、当社が別途定めるサービス手数料、送金手数料その他手数料を差し引いた金額を、当社が別途定める方法により、当社が当該販売代金を受領してから120日以内に出品者に対して支払うものとします。なお、出品者は、出金申請額が当社の別途定める送金手数料を下回る場合、出金申請を行うことはできません',
    '当社は、出品者が、前項の出金申請を行わなかったこと又は当社に対して前項に定める支払金額を受領するための正確な情報を登録していなかったことにより、当社が出品者に対して正確な情報の提供を求めたにもかかわらず、出品者が当該求めに応じて情報を提供しなかった場合、出品者は支払金額の受領権限を放棄したものとみなします。',
    '第5項の規定による支払が完了した場合、当社は、購入者に対し、当社が定める方式に従いNFTを引き渡すものとします。',
    'プログラムの利用、本サービスのシステムエラー又はバグその他不正な方法によりNFTの出品、販売、購入又は代金の支払い（以下、本項において「出品等」といいます。）が行われたと当社が判断した場合には、当社は、当該出品等を無効なものとして扱うとともに、当該出品等に係るデータを当該出品等が行われる前の状態に復元することができるものとします。当社は、当該復元により権利者又は会員に損害が生じた場合であっても、当社に故意又は重大な過失があるときを除き、責任を負わないものとします。',
  ];

  //第8条//
  const listItems08 = [
    '会員は、本サービス上で権利者又は他の会員から購入したNFTを、本サービスを通じて出品し、販売すること（以下「二次出品」といいます。）ができます。',
    '会員は、二次出品によるNFTの販売方法を、通常の販売方式、オークション方式その他当社が定める方法の中から任意に選ぶことができます。',
    '当社は、会員に対し、二次出品によるNFTの販売代金から当社が別途定めるサービス手数料、送金手数料その他手数料を差し引いた金額を、当社が別途定める時期及び方法により支払うものとします。なお、二次出品によるNFTの出品者は、当該NFTの販売代金を、当社又は当社が指定する者が出品者に代わり、二次出品によるNFTの購入者から代理受領することについて同意します。',
    '二次出品によるNFTの売買契約が成立する都度、当該NFTの販売価格に対して当社が定めるロイヤリティ率に基づき算出されたロイヤリティが発生するものとし、当社は、当該ロイヤリティを発行者に支払うものとします。なお、当該ロイヤリティは二次出品によるNFTの購入者が負担するものとします。',
    '前項に定めるロイヤリティは、当社指定の方法により送金手数料を差し引いた上で当社から発行者に対して支払われるものとします。なお、何らかの原因により当社と発行者との間の本利用契約が終了した場合、当社は発行者に対するロイヤリティの支払義務を負わないものとします。',
    '前条の各規定は、二次出品に準用するものとします。',
  ];

  //第9条//
  const listItems09 = [
    '権利者及びNFT保有者は、当社が指定する方法に基づき、NFTを本サービス以外のNFTマーケットプレイス、ウォレットその他のプラットフォームに転送（以下「外部移転」といいます。）することができます。',
    '前項に基づき権利者又はNFT保有者がNFTを外部移転した場合には、NFTの移転の失敗その他外部移転により生じた損害について、当社は一切の責任を負いません。',
  ];

  //第10条//
  const listItems10 = [
    '購入者は、本サービスを通じてNFTを保有し、二次出品することができます。',
    '権利者がNFTを発行等した場合であっても、当該NFTに係るデジタルデータの著作権その他の知的財産権は権利者に留保され、購入者には移転しないものとします。',
    '前項の規定に拘らず、権利者は、購入者に対し、本サービスを通じてNFTを保有し又は二次出品するために必要な範囲で、当該NFTに係るデジタルデータの利用を無償で許諾するものとし、当該購入者が二次出品をしたNFTをさらに購入した者に対しても無償で許諾させる（段階は問わない。）ものとします。',
    '権利者は、当社に対し、当社が本サービスを提供するために必要な範囲で、NFTに係るデジタルデータの利用を無償で許諾するものとします。',
    '権利者が発行したNFTに係るデジタルデータが著作権等管理事業者がその著作権を管理する著作物を含むこと等により、その利用に関して当該著作権等管理事業者との間の契約や著作権使用料の支払を要する場合、当該契約及び支払その他一切の手続は、当該権利者の責任と負担において対応するものとし、当社に一切の負担を生じさせないものとします。',
  ];

  //第11条//
  const listItems11 = [
    '権利者は、当社に対して申請をし、承認を受けることで、本サービスを通じて、デジタルイラストを公開（以下「イラスト公開」といいます。）することができます。なお、権利者以外の者（会員を含みます。）は本サービスを通じてイラスト公開をすることができません。',
    '当社は、本ガイドラインその他の当社が定める基準に従い、前項の承認の可否を決定します。なお、当社が承認しなかった場合においても、当社はその理由を開示する義務を負わず、承認がされなかったことにより会員に生じた損害その他の不利益について、一切の責任を負いません。',
    '権利者は、第6条第4項各号に定めるデジタルイラストについて、イラスト公開することはできません。',
    '第6条第5項から同条第8項の規定は、イラスト公開について準用します。',
  ];

  //第12条//
  const listItems12_innerListItems = [
    '本サービスに新規機能を追加し、又は既存機能若しくは画面レイアウトの変更、停止、非公開化若しくは削除等をする場合',
    '本サービスに係るハードウェア、ソフトウェア、通信機器その他関連する機器若しくはシステムの点検又は保守作業を行う場合',
    'コンピューター又は通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキングその他予期せぬ要因により本サービスの提供が困難となった場合',
    '本サービスに関するセキュリティ上の問題が生じた場合',
    '天災地変、戦争若しくはそのおそれ、封鎖、通商停止、革命、暴動、伝染病その他の疫病、物資若しくは施設の破壊若しくは損傷、火災、台風、地震又は洪水その他の不可抗力（以下「不可抗力」といいます。）',
    '法令等若しくはこれらに基づく措置、本サービスに適用される法令や自主規制規則等の新設若しくはこれらに基づく措置により、本サービスの運営の継続が困難となった場合',
    'その他前各号に準じる事由により本サービスの変更等が必要であると当社が判断した場合',
  ];
  const listItems12 = [
    [
      '当社は、以下のいずれかの事由が生じた場合には、本サービスの内容の全部若しくは一部を変更、修正若しくは追加（以下「変更等」といいます。以下本条において同様です。）し、又は本サービスの全部若しくは一部の提供を一時的に停止若しくは中断することができるものとします。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems12_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    '前項の規定に拘わらず、当社は、当社がやむを得ないと判断した場合その他当社の都合により、本サービスの内容の全部若しくは一部を変更等し又は本サービスを終了することができます。',
    '当社は、前2項に定める措置を行った場合には、会員に対してその旨を通知するものとします。当該通知は、当該措置により会員に重大な影響がある場合その他当社が必要と認めた場合には事前になされるものとしますが、緊急の場合にはこの限りではありません。',
    '当社は、前項の通知を、変更の内容及び時期を本ウェブサイトに掲示し、又は会員登録に含まれる連絡先に直接通知する方法により行います。',
    '当社は、第1項又は第2項に定める措置により会員に生じた損害について、当該損害の発生が当社の故意又は重大な過失に起因する場合を除き、責任を負いません。',
    '第2項の規定により本サービスが終了する場合、権利者又は会員は、NFTに係るデジタルデータ閲覧、NFTの出品、二次出品、販売、購入その他本サービスを通じたNFTの利用が一切できなくなることについて、予めこれに同意するものとします。当社はこれらに起因する損害について一切責任を負いません。',
  ];

  //第13条//
  const listItems13_innerListItems_innerListItems = [
    '当社又は第三者の名誉又は信用を毀損する表現を含む情報',
    '暴力的又は残虐な表現を含む情報',
    'わいせつな表現を含む情報',
    '差別を助長する表現を含む情報',
    '自殺、自傷行為等又は薬物の不適切な利用を助長する表現を含む情報',
    '違法な勧誘、宣伝等を含む情報',
    '他人に不快感を与える表現を含む情報',
    '本サービスへのアップロード又は当社若しくは第三者への送信行為につき適法かつ正当な権利又は許諾を得ていない個人情報その他の情報',
    '違法な方法で入手した情報',
    'コンピュータ・ウィルスその他の有害なコンピュータ・プログラムを含む情報',
  ];
  const listItems13_innerListItems = [
    '本規約又は法令等に違反する行為',
    '公序良俗に反する行為又はそのおそれのある行為',
    '反社会的勢力等に対する利益供与その他反社会的勢力等に関与する行為',
    '法令等に違反する行為又はこれらの行為を援助若しくは助長する行為',
    '当社若しくは第三者の知的財産権、肖像権、プライバシーの権利、名誉、財産、その他の権利若しくは利益を侵害し、又は侵害するおそれのある行為',
    '当社又は第三者に対する嫌がらせ、誹謗中傷その他これらに類似する行為',
    '会員登録に際して虚偽の情報を提供し又は故意に不正確な情報を提供する行為、又は第三者に成りすます行為',
    '他の会員又は第三者になりすまして本サービスを利用する行為',
    '前各号の他、当社、他の会員又は第三者の権利又は利益を侵害する行為',
    '支払期限を過ぎてもNFTの売買代金を支払わない行為',
    '情報収集ボット、ロボット、スクレーパーその他の自動化された手段を使用して本サービスを利用し若しくは本サービスにアクセスし、又は情報収集、情報入力及び会員登録その他の情報登録を行う行為',
    '当社又は他の会員に係る情報その他の本ウェブサイトに掲載されている情報を改ざん若しくは消去する行為',
    [
      '次のいずれかに該当し、又は該当すると当社が判断する情報を本サービスにアップロードし、又は当社若しくは第三者に送信し、若しくは受信可能な状態に置く行為',
      <ul style={{ listStyle: 'lower-alpha' }}>
        {listItems13_innerListItems_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    '本サービス又は当社サイトに関連するプログラムを変更、削除、逆コンパイル、逆アセンブル若しくはリバースエンジニアリングする行為、又はネットワーク監視若しくは検出ソフトウェアを使用して当社サイトのサイトアーキテクチャを決定する行為',
    '本サービスのシステムの一貫性や安全性を妨害し若しくは損なう行為又は本サービスを実行しているサーバーへの発信若しくは当該サーバーからの発信を解読することを試みる行為',
    '本サービスのネットワーク又はシステムに過度な負荷をかける行為、不正アクセス又はその他これらに準ずる行為',
    '本サービスに類似若しくは競合するサービス又は製品を提供する行為、その準備のために本サービスを利用する行為、又はその他本サービスの目的以外のために本サービスを利用する行為',
    'その他当社が不適切と判断する行為であって、会員に対して相当期間を定めて事前に通知して解消を求めた行為',
  ];
  const listItems13 = [
    [
      '会員は、本サービスの利用に関して、自己又は第三者をして、次の各号に定める行為を行ってはならないものとします。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems13_innerListItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>,
    ],
    '当社は、会員が前項に定める行為を行ったと当社が判断した場合には、当社は、第15条柱書本文その他の本規約の他の条項に基づく措置の他、違反行為のあった会員に係る情報（会員の氏名若しくは名称を含みます。）の公表、警告措置又は違反行為に関連する本サービス上の情報・データ（設定されたタグ、投稿されたコメント、マイページに掲載された情報を含みますが、これらに限られません。）の削除措置を講じることができるものとします。',
    '当社は、前項に定める措置により会員に生じた損害について責任を負いません。',
  ];

  //第14条//
  const listItems14 = [
    '会員は、退会手続その他の当社所定の方法により、本利用契約を解約することができます。この場合、会員は、当社の定める解約の効力発生時点から本サービスを利用できなくなるものとします。',
    '前項の規定に拘わらず、NFTの代金の支払義務、NFT保有権の移転義務その他本規約に基づき本サービスを通じて発生した義務の履行が完了していない場合には、会員は、本利用契約を解約することができません。',
    '会員は、第1項に基づく解約又は次条に基づく措置により本利用契約が終了した場合であっても、本規約に基づき既に発生している一切の義務及び責任から解放されるものではありません。',
  ];

  //第15条//
  const listItems15 = [
    '本規約のいずれかの条項に違反した場合',
    '第13条第1項各号に定める禁止行為をしたと当社が判断した場合',
    '第3条第2項各号に該当すると当社が判断した場合',
    '当社からの問い合わせその他回答を求める連絡に対して、30日間以上応答がない場合',
    '会員が支払停止若しくは支払不能となった場合',
    '破産手続開始、会社更生手続開始、民事再生手続開始若しくは特別清算開始の申立があった場合その他これらに類する手続の開始の申立があった場合',
    '支払停止若しくは支払不能となり、又は手形の不渡り、手形交換所の取引停止処分若しくは銀行取引停止処分、株式会社全銀電子債権ネットワークによる取引停止処分その他の電子債権記録機関によるこれと同等の措置を受けた場合',
    '仮差押、差押、仮処分若しくは競売手続の開始があった場合又は租税公課を滞納して保全差押を受けた場合',
    '事業の廃止、解散その他営業活動を休止した場合',
    '会員の責めに帰すべき事由により、当社において会員の所在が不明となった場合',
    '個人である会員が死亡した場合',
  ];

  //第16条はリスト書きの必要がないので、スキップ//

  //第17条//
  const listItems17_innerListItems = [
    '暴力的な要求行為',
    '法的な責任を超えた不当な要求行為',
    '脅迫的な言動をし、又は暴力を用いる行為',
    '風説の流布、偽計若しくは威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為',
    '方法及び態様の如何を問わず反社会的勢力等と関与する行為',
  ];
  const listItems17 = [
    '会員（法人等の団体にあっては、自らの役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者を含みます。）を含みます。）は、自身が反社会的勢力等に該当しないことを表明し、かつ将来にわたっても該当しないことを保証するものとします。',
    [
      '会員は、自ら又は第三者をして次の各号に定める行為を行わないことを確約するものとします。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems17_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    '会員が反社会的勢力等に該当することが判明した場合、当社は、当該会員に通知することにより、ただちに本利用契約を将来に向かって解除できるものとします。',
    '当社は、前項の規定による解除により会員に生じた損害を賠償する責任を負わないものとします。',
  ];

  //第18条//
  const listItems18_innerListItems = [
    '不可抗力に起因する損害',
    '本サービスの利用のためのインターネット接続サービスの不具合又は性能等の不足その他権利者又は会員の接続環境の障害に起因する損害',
    'コンピュータウィルスが本サービスの提供に用いられる設備に侵入したことに起因して損害が発生した場合において、当社が導入しているコンピューターウィルス対策ソフトに当該コンピュータウィルスに係るウィルスパターン、ウィルス定義ファイル等が提供されていなかったときにおける当該損害',
    '善良な管理者の注意をもってしても防御が困難である、本サービスの提供に用いられる設備への第三者による不正アクセス又は通信経路上での傍受等に起因する損害',
    '当社が定める手順、セキュリティ手段等を権利者又は会員が遵守しないことに起因する損害',
    '本サービスの提供に用いられる設備のうち第三者が提供するソフトウェア（OS、ミドルウェア及びDMBSを含みます。）、ハードウェア又はデータベースに起因する損害',
    '電気通信事業者の提供する電気通信役務の不具合に起因する損害',
    '刑事訴訟法、犯罪捜査のための通信傍受に関する法律その他の法令等に基づく捜索、差押え、検証その他の強制処分に起因する損害',
    '法令等の新設、改廃、解釈の変更等（その効果が過去に遡及する場合を含みます。）に起因する損害',
    '本ウェブサイトとのリンクの有無を問わず、第三者のウェブサイト又はNFT若しくはサービスに起因する損害',
    'ブロックチェーンのネットワーク手数料（ガス代等）の高騰、ハードフォークの発生等による損失',
    '前各号に定めるもののほか、当社の責めに帰することができない事由に起因する損害',
  ];
  const listItems18 = [
    '当社は、当社が別途明示的に表明する場合を除き、本サービスが会員の特定の目的に適合すること、会員が期待する機能・商品的価値・正確性・有用性・完全性を有すること、会員による本サービスの利用が会員に適用のある法令等に適合すること、継続的に利用できること、セキュリティ上の欠陥、エラー、バグその他の不具合がないこと、本サービスが第三者の権利を侵害しないことを保証しません。',
    '当社は、資金決済法、金融商品取引法その他の法令等により、本サービスの提供又は利用等につき、登録、許認可又は届出等が必要とならないことを保証しません。',
    '当社は、当社が別途明示的に表明する場合を除き、会員から提供された登録情報その他のいかなる情報も保存する義務を負いません。会員は、これらの情報を自身の責任において保存するものとします。',
    '当社は、本サービスと連携する外部サービスその他の第三者が提供するサービスの法令等への適合性を保証しません。',
    '本サービスは、NFTのマーケットプレイス機能を提供するものであり、権利者、会員間又は権利者間でのNFTの取引に係る契約については、当社は何らの責任も負わず、権利者又は会員が責任を負うものとします。',
    '本サービス又は本ウェブサイトに関連して会員と他の会員又は第三者との間において生じた紛争については、会員が自己の責任によって解決するものとします。当社は、中立的な立場で当該紛争の解決に努めますが、当該紛争の解決について何らかの法的な義務を負うものではありません。当社は、当該紛争を解決し、本サービスの健全性を確保するために必要と判断した場合、当該紛争の当事者である会員に事実関係の確認をすることができ、当該会員は当社の事実確認に協力するものとします。',
    '当社は、NFTを規制対象とする税制関連法令等の制定又は改正がなされ、会員に何らかの損害が発生した場合であっても、責任を負いません。',
    '本サービスを通じて権利者又は会員に課される公租公課は、権利者又は会員がこれを負担するものとし、当社はその種類や金額を確認する義務を負いません。',
    [
      '当社は、権利者又は会員に生じた次の各号に定める損害について、債務不履行、不法行為その他原因の如何を問わず、責任を負いません。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems18_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    '前各項を含む本規約の規定に拘わらず、当社が何らかの理由により会員に対する責任を負う場合であっても、その責任の範囲は、通常かつ直接発生する損害の範囲に限定され、当該損害が発生した日の属する月における会員のNFT購入額又は10万円のうちいずれか金額が高い方をその上限とし、付随的損害、間接損害、特別損害、将来の損害及び逸失利益に係る損害については、賠償する責任を負わないものとします。ただし、当社の故意又は重大な過失による損害については、この限りではありません。',
  ];

  //第19条//
  const listItems19 = [
    '当社は、会員に関する個人情報を、本プライバシーポリシーに従って取り扱います。会員は、当社が本プライバシーポリシーに従って個人情報を取り扱うことに同意するものとします。',
    '当社は、会員が当社に対して提供した情報、データ等を、個人が特定できない形での統計的情報に加工し当社の裁量で利用及び公開できるものとし、会員は予めこれに承諾するものとします。',
  ];

  //第20条//
  const listItems20 = [
    '会員は、本サービス又は本規約に関連して当社が秘密と指定して開示した一切の情報（以下、総称して「秘密情報」といいます。）を秘密として保持するものとします。',
    '会員は、秘密情報を本サービスの利用又は本利用契約上の義務の履行のみを目的として利用できるものとし、当社の事前の書面による承諾なく第三者に対し開示、漏洩、公表等してはなりません。',
  ];

  //第21条//
  const listItems21_innerListItems = [
    '会員が当社に提供した登録情報の虚偽、誤り、脱落又は変更漏れ',
    '会員によるID又はパスワードの管理不十分、使用上の過誤、第三者による使用等',
    '会員登録、削除、権限の範囲の設定その他会員情報に関する設定の管理又は設定が不十分又は不適切であること',
    '本サービス又は本ウェブサイトにおいて当社又は第三者から提供又はアップロードされたコンテンツの内容',
    '本サービスのセキュリティ上の欠陥、エラー、バグ、その他の欠陥、又はウイルス',
    '本規約に基づく本サービス等の提供の一時停止又は中断',
    '利用停止その他の本規約に基づく措置（本利用契約の解除を含みます。）を講じたこと又は講じなかったこと',
    '登録情報、ユーザーコンテンツその他本サービス並びにアップロードされ又は提供された情報又はデータの毀損又は滅失',
    '本サービスの利用に関連して会員と第三者との間で生じた取引、連絡、紛争等',
    'その他本サービス若しくは本規約に起因し又は関連する事由',
  ];
  const listItems21 = [
    '会員による本規約に違反する行為又は本サービスに関連する会員の故意又は重大な過失に基づく行為により、当社又は第三者に損害又は損失が発生した場合、会員は、当該損害（合理的な弁護士費用を含みます。）又は損失の全額を賠償又は補償するものとします。',
    [
      '当社は、本サービスの提供に関連して、次の各号に定める事由に起因して会員に生じた損害又は損失につき、当社の故意又は重大な過失に基づく場合を除き、責任を負いません。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems21_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
  ];

  //第22条//
  const listItems22 = [
    '当社の会員に対する通知その他の連絡は、本規約に別段の定めがある場合を除き、本ウェブサイトへの掲載、電子メールその他の当社が定める方法により行うものとします。',
    '当社が会員の登録情報に含まれる電子メールアドレスその他の連絡先に対し、通知その他の連絡を行った場合、当該会員は、当該連絡を受領したものとみなします。',
    '本サービスに関する問い合わせその他会員の当社に対する通知その他の連絡は、本規約に別段の定めがある場合を除き、当社が別途指定する電子メールアドレスに対し電子メールを送付する方法により行うものとします。',
  ];

  //第23条//
  const listItems23_innerListItems = [
    '変更の内容が会員の利益に適合する場合',
    '変更の内容が本規約に係る取引の目的に反せず、変更の必要性、変更後の内容の相当性その他の変更に係る事情等に照らし、合理的なものである場合',
  ];
  const listItems23 = [
    [
      '当社は、次の各号のいずれかに該当する場合には、本規約の変更の効力発生時期を定め、かつ、本規約を変更する旨、変更後の規約の内容及びその効力発生時期を本サービス若しくは当社の別途指定するウェブサイト等に掲載することによる公表その他適切な方法で周知することにより、本規約を変更することができるものとします。',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems23_innerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    '前項による本規約の変更は、前項の効力発生時期から効力を生じるものとし、会員には変更後の規約が適用されます。',
    '会員は、本規約の変更の効力の発生後も本サービスの利用を継続することで、かかる変更に同意したものとみなされます。',
  ];

  //第24条//
  const listItems24 = [
    '会員は、当社の事前の書面による承諾なく、本利用契約上の地位又は本利用契約に基づく権利若しくは義務を、第三者に譲渡し、移転し（合併若しくは会社分割による場合を含みます。）、担保に供し、又はその他の処分をしてはなりません。',
    '当社が本サービスに係る事業を第三者に譲渡（合併、会社分割その他事業が移転するあらゆる場合を含みます。）した場合、当社は、当該譲渡に伴い、本規約に基づく契約上の地位、権利、義務及び会員の登録情報その他本サービスを通じて当社が取得した会員に関する一切の情報を当該譲渡の譲受人に譲渡することができるものとし、会員は係る譲渡に予め同意するものとします。',
  ];

  //第25条はリスト書きの必要がないので、スキップ//

  //第26条//
  const listItems26 = [
    '本利用契約は、日本法に準拠し、かつ、これに従って解釈されるものとします。',
    '本利用契約又は本利用契約に基づく本サービスの利用に起因又は関連して生じた一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。',
  ];

  return (
    <TermsOfUseWrapper>
      <TermsOfUseContainer>
        <Title title={'利用規約'} subTitle={'TermsOfUse'} />
        <Text fontType={FontType.TITLE3}>
          <br />
          本マーケットプレイスを使用する際は、以下を遵守して下さい。
        </Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          {`\nこの規約（以下「本規約」といいます。）は、株式会社代々木アニメーション学院（以下「当社」といいます。）が提供するNFTマーケットプレイス「代アニマーケットプレイス」（以下「本サービス」といいます。）の利用に関する条件を定めるものです。本サービスの登録及びご利用前に、本規約及び当社が別途定めるプライバシーポリシー（以下「本プライバシーポリシー」といいます。）の内容をよくお読みください。本規約及び本プライバシーポリシーにご同意いただけない場合には、本サービスをご利用いただくことはできません。\nなお、会員が本サービスを利用することによって、本規約及び本プライバシーポリシーに同意したものとみなします。\n\n`}
        </Text>
        <Text fontType={FontType.TITLE4}>第1条　（目的及び適用範囲）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems01.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第2条　（定義）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          {`\n本規約上で使用する用語の定義は、次に掲げる通りとします。`}
          <ul style={{ listStyle: 'decimal' }}>
            {listItems02.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第3条　（会員登録等）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems03.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第4条　（アカウントの管理）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems04.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第5条　（手数料）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems05.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>
          第6条　（権利者によるNFTの発行、出品等）
        </Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems06.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>
          第7条　（会員によるNFTの購入、代金の支払等）
        </Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems07.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第8条　（会員による二次出品等）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems08.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第9条　（NFTの外部移転）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems09.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第10条　（NFTを巡る権利関係）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems10.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>
          第11条　（権利者によるイラストの公開）
        </Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems11.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>
          第12条　（本サービスの変更、一時停止、中断又は終了）
        </Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems12.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>
          第13条　（禁止行為及び禁止行為に違反した場合の措置）
        </Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems13.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第14条　（会員による解約）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems14.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第15条　（契約解除等）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          {`\n会員が以下の各号のいずれかの事由に該当する場合、当社は、当該会員への通知により、事前の通知又は催告なく、当該会員との間の本利用契約を解除し、本サービスの利用の全部又は一部を制限し、又はアカウントの一時若しくは無期限の利用停止若しくは削除をすることができます。ただし、第1号又は第2号の事由に基づく場合については、具体的な事実関係に照らし悪質なものであると当社が判断した場合を除き、会員に事前に通知の上、合理的な期間内に是正されない場合に限ります。`}
          <ul style={{ listStyle: 'decimal' }}>
            {listItems15.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第16条　（知的財産権等）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          {`\n本規約に別段の定めがある場合を除き、本サービス及び本利用契約に関連して当社が会員に提供した一切のコンテンツに関する知的財産権その他の権利は、当社又は当社に対して当該コンテンツの利用を許諾した者に帰属するものとします。\n\n`}
        </Text>
        <Text fontType={FontType.TITLE4}>第17条　（反社会的勢力等の排除）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems17.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第18条　（免責等）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems18.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第19条　（個人情報の取り扱い）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems19.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第20条　（秘密保持）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems20.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>
          第21条　（会員及び当社の損害賠償責任等）
        </Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems21.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第22条　（通知）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems22.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第23条　（本規約の改訂）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems23.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第24条　（権利義務等の譲渡等）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems24.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>第25条　（分離可能性）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          {`\n本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規約及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。この場合において、当社及び会員は、当該無効又は執行不能と判断された条項又はその一部を適法とし、執行力をもたせるために必要な範囲で修正し、当該無効又は執行不能とされた条項又はその一部の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。\n\n`}
        </Text>
        <Text fontType={FontType.TITLE4}>第26条　（準拠法及び裁判管轄）</Text>
        <Text fontType={FontType.SMALL_NORMAL}>
          <ul style={{ listStyle: 'decimal' }}>
            {listItems26.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
      </TermsOfUseContainer>
    </TermsOfUseWrapper>
  );
};
