import styled from '@emotion/styled';
import { Button } from '../../atoms/Button';
import tw from 'twin.macro';

export const LoginButton = styled(Button)`
  margin-top: 5%;
  display: block;
`;

export const TextContainer = styled.div`
  width: 100%;
  margin: 4% 0% 4% 0%;
`;

export const TextButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  margin: 2% 0% 2% 0%;
  cursor: pointer;
`;

export const WholeWrapper = styled.div`
  ${tw`flex flex-col mt-12 w-[90%] md:w-[60%] mx-auto`}
`;
