import React from 'react';
import {
  LeftContainer,
  RightContainer,
  BannerImage,
  BannerTitle,
  BannerText,
  StyledBanner,
  LinkedBanner,
} from './style';

export const NftManualBanner: React.FC = () => {
  return (
    <LinkedBanner href={'/nftManual'} target="_blank">
      <StyledBanner>
        <LeftContainer>
          <BannerTitle>NFTマーケットプレイスとは？？？</BannerTitle>
          <BannerText>NFTとは？</BannerText>
          <BannerText>ロイヤリティとは？</BannerText>
          <BannerText>登録方法は？</BannerText>
        </LeftContainer>
        <RightContainer>
          <BannerImage src="yotama_and_gear.png" alt="img" />
        </RightContainer>
      </StyledBanner>
    </LinkedBanner>
  );
};
