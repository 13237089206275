/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, ReactNode, useContext } from 'react';
import { toast } from 'react-toastify';

export type Alert = {
  message: string;
  status: 'error' | 'info' | 'warning' | 'success';
};

type AlertContextProps = {
  addAlert: (
    message: string,
    status: 'error' | 'info' | 'warning' | 'success',
  ) => void;
};

type ProviderProps = {
  children: ReactNode;
};

const AlertContext = createContext<AlertContextProps>({
  addAlert: () => {},
});

export const AlertProvider: React.FC<ProviderProps> = ({ children }) => {
  const addAlert = (
    message: string,
    status: 'error' | 'info' | 'warning' | 'success',
  ) => {
    switch (status) {
      case 'error':
        toast.error(message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        break;
      case 'success':
        toast.success(message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        break;
      case 'info':
        toast.info(message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        break;
      case 'warning':
        toast.warn(message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        break;
    }
  };

  return (
    <AlertContext.Provider
      value={{
        addAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => useContext(AlertContext);
