/* eslint-disable */
import React, { useContext, useLayoutEffect, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, TextFieldProps, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  ArtEditContainer,
  ArtInfoContainer,
  ButtonContainer,
  FormContainer,
  TagsContainer,
  SwitchContainer1,
  SwitchContainer2,
  SwitchContainer3,
  TextContainer,
  SwitchesContainer,
  ExhibitTextContainer,
  RowWrapper,
  AnnotatedTextContainer,
  TitleText,
  DetailPriceContainer,
} from './style';
import { useArtEdit } from './hook';

import { NotFound } from '../NotFound';

import { StyledText, SubmitButtonWrapper } from '../../FileUpload/style';
import { useRegisterArt } from '../../FileUpload/hooks';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { SwitchButton } from '../../atoms/SwitchButton';
import { RequiredText } from '../../atoms/required';
import TextInput from '../../atoms/TextInput';
import SpacerY from '../../atoms/Spacer/SpacerY';
import ToggleSwitch from '../../atoms/ToggleSwitch';

import {
  PictureContainer,
  StyledItemImage,
} from '../../organisms/ArtDetail/style';
import { Title } from '../../organisms/Title';

import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import { ButtonType } from '../../../constants/Button';

import {
  BUSINESS_TRANSACTION,
  FIRST_PAY_BACK_RATE,
  SECOND_PAY_BACK_RATE,
  ROYALTY,
} from '../../../config';

import { Tag } from '../../../types/domain/Tag';

import { AuthContextType, AuthContext } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';

import {
  registerTag,
  taggingToArt,
  untaggingToArt,
  getContestItems,
  editContestId,
} from '../../../api/ArtsService';
import {
  sendRequest,
  RequestBody,
  CheckIsRequested,
} from '../../../api/RequestService';

export const ArtEdit: React.FC = () => {
  const { itemId } = useParams();
  const { tags } = useRegisterArt();
  const authContext: AuthContextType = useContext(AuthContext);
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();
  const [tagName, setTagName] = useState('');
  const [options, setOptions] = useState<Tag[]>(tags as Tag[]);
  const [inputValue, setInputValue] = useState('');
  const [artInputValue, setArtInputValue] = useState<number | undefined>();
  const [isRequested, setIsRequested] = useState<boolean>(false);
  const [valueError, setValueError] = useState('');

  if (!itemId) return <></>;

  const {
    setItemName,
    setAbout,
    artValue,
    setArtValue,
    onSubmit,
    currentArtDetail,
    state,
    isArtExhibited,
    setIsArtExibited,
    usePrevious,
  } = useArtEdit(itemId);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [artState, setArtState] = useState<'NFT' | 'Portfolio' | 'Picture'>(
    state,
  );
  const [isPending, setIsPending] = useState(false);
  const [requestType, setRequestType] = useState('');
  const disabled =
    currentArtDetail?.creatorInfo.userId != currentArtDetail?.ownerInfo.userId;

  useEffect(() => {
    (async () => {
      const { pending, type } = await CheckIsRequested(
        Number(currentArtDetail?.id),
        authContext.authData.token as string,
      );
      setIsPending(pending);
      setRequestType(type);
      if (currentArtDetail && !isRequested) {
        if (pending) {
          setIsRequested(true);
        }
      }
    })();
  }, [currentArtDetail?.id]);

  const handleRegisterTag = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    if (!tagName.match(/\S+/gi)) return;
    const revisedTagName = tagName.replaceAll('&', '＆');
    registerTag(revisedTagName, authContext.authData.token as string).then(
      async (res) => {
        if (res.ok) {
          const json = await res.json();
          setOptions([...options, json]);
          setSelectedTags([...selectedTags, json]);
          setTagName('');
          setInputValue('');
        } else {
          addAlert('タグの登録に失敗しました', 'error');
          return;
        }
      },
    );
  };

  const handleUpdateTagsOnArt = () => {
    const addingTags = selectedTags.filter(
      (selectedTag) => currentArtDetail?.tags?.indexOf(selectedTag) == -1,
    );
    const removingTags = currentArtDetail?.tags?.filter(
      (currentTag) => selectedTags.indexOf(currentTag) == -1,
    );
    if (addingTags?.length) {
      taggingToArt(
        itemId,
        addingTags as Tag[],
        authContext.authData.token as string,
      );
    }
    if (removingTags?.length) {
      untaggingToArt(
        itemId,
        removingTags as Tag[],
        authContext.authData.token as string,
      );
    }
  };

  const handleRequest = () => {
    const Req: RequestBody = {
      type: artState == 'NFT' ? 'Mint' : 'Portfolio',
      targetId: Number(currentArtDetail?.id),
      requestedBy: Number(authContext.authData.userId),
    };
    if (
      (artState == 'NFT' && !currentArtDetail?.isNFT) ||
      (artState == 'Portfolio' && !currentArtDetail?.isPortfolio)
    ) {
      sendRequest(Req, authContext.authData.token as string).then(
        async (response) => {
          if (response.ok) {
            if (artState == 'NFT') {
              addAlert('NFTの登録申請が完了しました', 'success');
            } else {
              addAlert('ポートフォリオの登録申請が完了しました', 'success');
            }
          } else {
            if (artState == 'NFT') {
              addAlert('NFTの登録申請に失敗しました', 'error');
            } else {
              addAlert('ポートフォリオの登録申請に失敗しました', 'error');
            }
          }
        },
      );
    }
  };

  const handleSubmit = () => {
    handleUpdateTagsOnArt();
    onSubmit();
    handleEditContestId(heldContestId);
    if (artState == 'NFT' || artState == 'Portfolio') {
      handleRequest();
      navigate('/');
    }
  };

  const handleKeyDown = (e: any) => {
    if (!tagName.match(/\S+/gi)) return;
    if (options?.filter((opt) => opt.name === tagName).length) return;
    if (e.keyCode == '13' || e.keyCode === 32) {
      e.preventDefault();
      handleRegisterTag(e);
    }
  };

  // 全てのタグとすでに作品に紐付いているタグを比較し、重複しているものを削除した配列を返却
  const getArraysDiff = (array1: Tag[], array2: Tag[]) => {
    // 引数の各々の配列から id のみの配列を生成
    const arrayIds1 = array1.map((itm: Tag) => {
      return itm.id;
    });
    const arrayIds2 = array2.map((itm) => {
      return itm.id;
    });
    // id のみの配列で比較
    const arr1 = [...new Set(arrayIds1)];
    const arr2 = [...new Set(arrayIds2)];
    const filterdArray = [...arr1, ...arr2].filter((val) => {
      return !arr1.includes(val) || !arr2.includes(val);
    });
    const enferChateuDiff = tags?.filter((item) => {
      return filterdArray.includes(item.id);
    });
    return enferChateuDiff;
  };

  const handleArtState = (x: 'Picture' | 'NFT' | 'Portfolio') => {
    if (isRequested) {
      addAlert('申請中は作品の状態を変えることはできません', 'error');
    } else if (state == 'NFT' || state == 'Portfolio') {
      addAlert('現在作品の状態を変えることはできません', 'error');
    } else {
      setArtState(x);
    }
  };

  useLayoutEffect(() => {
    if (tags && currentArtDetail?.tags) {
      const filteredTags = getArraysDiff(tags, currentArtDetail?.tags);
      setOptions(filteredTags as Tag[]);
      setSelectedTags(currentArtDetail?.tags);
    }
  }, [tags, currentArtDetail?.tags]);

  useEffect(() => {
    setArtState(state);
  }, [state]);

  useEffect(() => {
    if (!disabled) {
      setArtInputValue(Number(artValue));
    }
  }, [artValue]);

  const commonUsersCommission = 200;

  const RATE =
    currentArtDetail?.creatorInfo.userId == currentArtDetail?.ownerInfo.userId
      ? FIRST_PAY_BACK_RATE
      : SECOND_PAY_BACK_RATE;

  const artValueWithCardCommission =
    Number(artInputValue) - Number(artInputValue) * BUSINESS_TRANSACTION;

  const totalSalesAmount =
    authContext.authData.role === 'common'
      ? artValueWithCardCommission * RATE - commonUsersCommission
      : artValueWithCardCommission * RATE;

  const creatorRoyalty =
    currentArtDetail?.creatorInfo.userId != currentArtDetail?.ownerInfo.userId
      ? Number(artInputValue) * ROYALTY
      : 0;

  const exhibitCommission =
    Number(artInputValue) - totalSalesAmount - creatorRoyalty;

  const handleArtValueChange = (value: string) => {
    setArtInputValue(Number(value));
    setArtValue(value);
    if (Number(value) < 350) {
      setValueError('販売可能な価格は350円以上です');
    } else if (Number(value) >= 10000000) {
      setValueError('販売可能な価格は9,999,999円以下です');
    } else {
      setValueError('');
    }
  };

  const [isContestHeld, setIsContestHeld] = useState(false);
  const [heldContestId, setHeldContestId] = useState<number | null>(null);
  const [heldContestName, setHeldContestName] = useState<string | null>(null);
  const [heldContestTags, setHeldContestTags] = useState<string[]>([]);
  const [isCreatedInContestTerm, setIsCreatedInContestTerm] = useState(false);
  useEffect(() => {
    getContestItems()
      .then((contestData) => {
        const heldContest = contestData.find(
          (contest: any) => contest.is_held === true,
        );
        if (heldContest) {
          setIsContestHeld(true);
          setHeldContestId(heldContest.id);
          setHeldContestName(heldContest.name);
          setHeldContestTags(heldContest.tags);
          if (
            currentArtDetail?.createdAt &&
            new Date(currentArtDetail?.createdAt).getTime() / 1000 >=
              heldContest.start_date &&
            new Date(currentArtDetail?.createdAt).getTime() / 1000 <=
              heldContest.end_date
          ) {
            setIsCreatedInContestTerm(true);
          } else {
            setIsCreatedInContestTerm(false);
          }
          if (
            currentArtDetail?.contestId &&
            currentArtDetail?.contestId === heldContest.id
          ) {
            setIsContestApply(true);
          } else {
            setIsContestApply(false);
          }
        } else {
          setIsContestHeld(false);
          setHeldContestId(null);
          setHeldContestName(null);
          setHeldContestTags([]);
        }
      })
      .catch((error) => {
        console.error('コンテストデータの取得に失敗しました', error);
      });
  }, []);

  const [isContestApply, setIsContestApply] = useState(false);
  useEffect(() => {
    if (isContestHeld && isContestApply) {
      setArtState('NFT');
    }
  }, [isContestApply]);

  const [heldContestTagOptions, setHeldContestTagOptions] = useState<Tag[]>([]);
  const [selectedContestTags, setSelectedContestTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (tags && heldContestTags.length > 0)
      setHeldContestTagOptions(
        tags.filter((tag) => heldContestTags.includes(tag.name)),
      );
    if (selectedTags && heldContestTags.length > 0)
      setSelectedContestTags(
        selectedTags.filter((tag) => heldContestTags.includes(tag.name)),
      );
  }, [tags, selectedContestTags, heldContestTags]);

  const isSelectedOneContestTag = selectedContestTags.length === 1;

  const prevIsContestApply = usePrevious(isContestApply);
  useEffect(() => {
    if (prevIsContestApply) {
      if (!isContestApply) {
        const selectedTagsWithoutContestTag = selectedTags.filter(
          (tag) => !heldContestTags.includes(tag.name),
        );
        setSelectedTags(selectedTagsWithoutContestTag);
      } else {
        setIsArtExibited(false);
        setIsContestApply(true);
      }
    }
  }, [isContestApply, prevIsContestApply]);

  const handleEditContestId = async (heldContestId: number | null) => {
    const artId = currentArtDetail?.id;
    const token = authContext.authData.token;
    if (artId === undefined) return;
    if (typeof token !== 'string') {
      console.error('Token is undefined');
      return;
    }

    const contestId = isContestApply ? heldContestId : null;
    editContestId({
      updateArtInfo: {
        id: artId,
        contestId: contestId,
      },
      token: token,
    })
      .then((response) => {
        if (response) {
          addAlert('コンテストの更新が完了しました', 'success');
        } else {
          addAlert('コンテストの更新に失敗しました', 'error');
        }
      })
      .catch((error) => {
        console.error('コンテストの更新に失敗しました', error);
        addAlert('コンテストの更新に失敗しました', 'error');
      });
  };

  if (authContext.authData.userId != currentArtDetail?.ownerInfo.userId)
    return <NotFound />;

  return (
    <ArtEditContainer>
      {disabled ? (
        <Title title={'出品設定の編集'} subTitle={'Edit Item'} />
      ) : (
        <Title title={'作品情報の編集'} subTitle={'Edit Item'} />
      )}
      <ArtInfoContainer>
        <PictureContainer>
          <StyledItemImage src={currentArtDetail?.entityUrl} />
        </PictureContainer>
        <FormContainer>
          <TextContainer>
            {disabled ? (
              <TitleText>
                <Text fontType={FontType.TITLE3}>作品名</Text>
                <Text fontType={FontType.SUB} color={Color.GRAYSCALE64}>
                  ※作品名の変更はできません
                </Text>
              </TitleText>
            ) : (
              <Text fontType={FontType.TITLE3}>作品名変更</Text>
            )}
          </TextContainer>
          <TextInput
            setText={setItemName}
            placeholder={'作品名'}
            defaultValue={currentArtDetail?.name}
            disabled={disabled}
          />
          <TextContainer>
            {disabled ? (
              <TitleText>
                <Text fontType={FontType.TITLE3}>作品説明</Text>
                <Text fontType={FontType.SUB} color={Color.GRAYSCALE64}>
                  ※作品説明の変更はできません
                </Text>
              </TitleText>
            ) : (
              <Text fontType={FontType.TITLE3}>作品説明変更</Text>
            )}
          </TextContainer>
          <TextInput
            setText={setAbout}
            placeholder={'作品説明'}
            defaultValue={currentArtDetail?.about}
            disabled={disabled}
          />
        </FormContainer>
        {isContestHeld && isCreatedInContestTerm && currentArtDetail?.isNFT && (
          <RowWrapper>
            <TextContainer>
              <StyledText fontType={FontType.TITLE3}>
                {heldContestName} に応募する
              </StyledText>
            </TextContainer>
            <ToggleSwitch
              id="isContestApply"
              className="isContestApply"
              handleChange={() => setIsContestApply(!isContestApply)}
              checked={isContestApply}
            />
          </RowWrapper>
        )}
        <FormContainer>
          {isContestHeld && isContestApply ? (
            <TextContainer>
              <StyledText fontType={FontType.TITLE3}>
                作品状態(イラストコンテストに応募した場合、NFTに固定されます)
              </StyledText>
            </TextContainer>
          ) : authContext.authData.role !== 'common' ? (
            <TextContainer>
              <StyledText fontType={FontType.TITLE3}>
                作品状態(NFTまたはポートフォリオの場合は変更不可)
              </StyledText>
              <RequiredText />
            </TextContainer>
          ) : (
            <TextContainer>
              <StyledText fontType={FontType.SUB}>
                作品状態(NFTの場合は変更不可)
              </StyledText>
              <RequiredText />
            </TextContainer>
          )}
        </FormContainer>
        <RowWrapper>
          {artState == 'NFT' && (
            <>
              <div style={{ marginBottom: '0.5rem', width: '1px' }} />
              <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                ※NFT申請した作品は作品状態を変更することができなくなります。
              </Text>
              {authContext.authData.role == 'student' && (
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※NFT申請の承認には数日ほどかかる場合があります。
                </Text>
              )}
              {authContext.authData.role == 'common' && (
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※NFT申請の承認には時間がかかる場合があります。
                </Text>
              )}
              {isContestApply && (
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※イラストコンテストに応募した場合、出品できなくなります。
                </Text>
              )}
            </>
          )}
          {artState == 'Portfolio' && (
            <>
              <div style={{ marginBottom: '0.5rem', width: '1px' }} />
              <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                ※ポートフォリオ申請した作品は作品状態を変更することができなくなります。
                <br />
                ※ポートフォリオ申請の承認には数日ほどかかる場合があります。
              </Text>
            </>
          )}
        </RowWrapper>
        <SwitchesContainer>
          {state == 'Picture' ? (
            <>
              <SwitchContainer1>
                <SwitchButton
                  onClick={() => handleArtState('Picture')}
                  isOn={artState == 'Picture'}
                >
                  掲載のみ
                </SwitchButton>
              </SwitchContainer1>
              {authContext.authData.mintRequestable && (
                <SwitchContainer2>
                  <SwitchButton
                    onClick={() => handleArtState('NFT')}
                    isOn={artState == 'NFT'}
                  >
                    NFT
                  </SwitchButton>
                </SwitchContainer2>
              )}
              {authContext.authData.role != 'common' && (
                <SwitchContainer3>
                  <SwitchButton
                    onClick={() => handleArtState('Portfolio')}
                    isOn={artState == 'Portfolio'}
                  >
                    ポートフォリオ
                  </SwitchButton>
                </SwitchContainer3>
              )}
            </>
          ) : (
            <>
              {state == 'NFT' && (
                <SwitchContainer2>
                  <SwitchButton
                    onClick={() => handleArtState('NFT')}
                    isOn={artState == 'NFT'}
                  >
                    NFT
                  </SwitchButton>
                </SwitchContainer2>
              )}
              {state == 'Portfolio' && (
                <SwitchContainer3>
                  <SwitchButton
                    onClick={() => handleArtState('Portfolio')}
                    isOn={artState == 'Portfolio'}
                  >
                    ポートフォリオ
                  </SwitchButton>
                </SwitchContainer3>
              )}
            </>
          )}
        </SwitchesContainer>
        {artState == 'NFT' && !isContestApply ? (
          <RowWrapper>
            <ExhibitTextContainer>
              <StyledText fontType={FontType.TITLE3}>出品有無</StyledText>
              <RequiredText />
            </ExhibitTextContainer>
            {isArtExhibited && (
              <div style={{ marginBottom: '4px' }}>
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※出品有にすると、NFT作品がハックツ上で販売されます。
                </Text>
              </div>
            )}
            <ToggleSwitch
              id="isExibited"
              className="isExibited"
              handleChange={() => setIsArtExibited(!isArtExhibited)}
              checked={isArtExhibited}
            />
          </RowWrapper>
        ) : (
          <></>
        )}
        <FormContainer>
          {artState == 'NFT' && isArtExhibited ? (
            <div style={{ marginBottom: '24px' }}>
              <AnnotatedTextContainer>
                <Text fontType={FontType.TITLE3}>販売価格</Text>
                <Text
                  fontType={FontType.SMALL_NORMAL}
                  color={Color.GRAYSCALE64}
                >
                  ￥350 ~ ￥9,999,999
                </Text>
                <RequiredText />
              </AnnotatedTextContainer>
              <TextInput
                setText={handleArtValueChange}
                placeholder={
                  artValue
                    ? `現在の価格：￥${Number(artValue).toLocaleString()}`
                    : '作品価格を入力してください。'
                }
              />
              <DetailPriceContainer>
                <Text
                  fontType={FontType.SMALL_NORMAL}
                  color={Color.GRAYSCALE64}
                >
                  ※ 販売利益：¥
                  {artInputValue == undefined ||
                  Number(artInputValue) < 350 ||
                  isNaN(artInputValue)
                    ? '-'
                    : Math.round(totalSalesAmount).toLocaleString()}
                </Text>
                <Text
                  fontType={FontType.SMALL_NORMAL}
                  color={Color.GRAYSCALE64}
                >
                  ※ システム利用料：¥
                  {artInputValue == undefined ||
                  Number(artInputValue) < 350 ||
                  isNaN(artInputValue)
                    ? '-'
                    : Math.round(exhibitCommission).toLocaleString()}
                </Text>
                {creatorRoyalty != 0 && (
                  <Text
                    fontType={FontType.SMALL_NORMAL}
                    color={Color.GRAYSCALE64}
                  >
                    ※ クリエイターロイヤリティ：¥
                    {artInputValue == undefined ||
                    Number(artInputValue) < 350 ||
                    isNaN(artInputValue)
                      ? '-'
                      : Math.round(creatorRoyalty).toLocaleString()}
                  </Text>
                )}
                {valueError && (
                  <Text fontType={FontType.TITLE4} color={Color.RED}>
                    {valueError}
                  </Text>
                )}
              </DetailPriceContainer>
            </div>
          ) : (
            <></>
          )}
        </FormContainer>
        {isPending && requestType == 'Mint' && (
          <>
            <FormContainer>
              <TextContainer>
                <StyledText fontType={FontType.MAIN} color={Color.BRANDMAIN}>
                  NFT申請中
                </StyledText>
              </TextContainer>
            </FormContainer>
          </>
        )}
        {isPending && requestType == 'Portfolio' && (
          <>
            <FormContainer>
              <TextContainer>
                <StyledText fontType={FontType.MAIN} color={Color.BRANDMAIN}>
                  ポートフォリオ申請中
                </StyledText>
              </TextContainer>
            </FormContainer>
          </>
        )}
        <TagsContainer>
          <Text fontType={FontType.TITLE3}>タグを登録</Text>
          <div style={{ marginBottom: '12px' }}>
            {isContestHeld && isContestApply && (
              <>
                <div style={{ marginBottom: '0.5rem', width: '1px' }} />
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※イラストコンテストに応募する場合、以下のタグの中から必ず一つを選択してください。
                </Text>
              </>
            )}
          </div>
          <Autocomplete
            disabled={disabled}
            value={selectedTags}
            multiple
            id="size-small-outlined"
            size="small"
            options={
              isContestHeld && isContestApply
                ? heldContestTagOptions || []
                : options || []
            }
            disableCloseOnSelect
            disablePortal
            disableListWrap
            debug
            onChange={(_, value) => {
              setSelectedTags(value);
            }}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue.replace(/\s+/gi, ''));
            }}
            renderOption={(opt) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Text fontType={FontType.SMALL_NORMAL}>{opt.name}</Text>
                {/* タグ削除機能が必要になったときに追加 */}
                {/* <IconButton
                  size="small"
                  component="span"
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    handleDeleteTag(e, opt.id)
                  }
                >
                  <GrFormClose />
                </IconButton> */}
              </Box>
            )}
            noOptionsText={
              <Box
                onClick={handleRegisterTag}
                bgcolor="#eee"
                style={{ cursor: 'pointer' }}
                padding={1}
                borderRadius={5}
                display="flex"
                justifyContent="space-between"
              >
                <Text
                  fontType={FontType.SMALL_NORMAL}
                >{`"${tagName}"タグを登録`}</Text>
              </Box>
            }
            getOptionLabel={(opt: Tag) => opt.name || ''}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                onKeyDown={handleKeyDown}
                variant="outlined"
                onChange={(e) =>
                  setTagName(e.target.value.replace(/\s+/gi, ''))
                }
              />
            )}
          />
          <SpacerY height={16} />
          <ButtonContainer>
            <SubmitButtonWrapper>
              <Button
                buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                onClick={() => navigate(`/items/${itemId}`)}
              >
                {/* <Text fontType={FontType.TITLE4} color={Color.WHITETEXT}> */}
                作品画面に戻る
                {/* </Text> */}
              </Button>
            </SubmitButtonWrapper>
            <SubmitButtonWrapper>
              <Button
                disabled={
                  isContestHeld && isContestApply && !isSelectedOneContestTag
                }
                buttonType={ButtonType.ROUNDEDIMPORTANT}
                onClick={handleSubmit}
              >
                <Text fontType={FontType.TITLE5} color={Color.WHITETEXT}>
                  保存
                </Text>
              </Button>
            </SubmitButtonWrapper>
          </ButtonContainer>
        </TagsContainer>
      </ArtInfoContainer>
    </ArtEditContainer>
  );
};
