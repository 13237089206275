import { Box } from '@material-ui/core';

import React, { FC } from 'react';

type Props = {
  height: number;
};

const SpacerY: FC<Props> = ({ height }: Props) => {
  return <Box height={height} />;
};

export default SpacerY;
