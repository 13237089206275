import React from 'react';

import { AwardArtItemCard } from '../../AwardArtItemCard';
import {
  AwardItemShowCaseWrapper,
  StyledLink,
  StyledShowCaseContainer,
  StyledText,
  StyledTextContainer,
} from './style';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { useAwardArtDetail } from './hooks';
import { awardArtItemsList } from './awardItemsList';

import Slider from 'react-slick';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick-theme.css';
import './style.css';

const AwardItemBox = () => {
  const authContext: AuthContextType = useAuthProvider();

  const awardArtItems = awardArtItemsList.map((item) => {
    const eachData = useAwardArtDetail(item.artId, authContext.authData?.token);
    const artData = eachData?.awardArtDetailList?.artData;
    return { ...item, artData };
  });

  const settings = {
    centerMode: true,
    centerPadding: '10%',
    dots: true,
    arrow: true,
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    slidesToShow: 3,
    slideToScroll: 10,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slideToShow: 3,
          dots: false,
        },
      },
    ],
  };
  return (
    <AwardItemShowCaseWrapper>
      <StyledTextContainer>
        <StyledText>
          才能発掘イラストコンテスト第１弾の受賞作品をご紹介！
        </StyledText>
        <StyledLink
          href="https://illustrationcontest.yoani.co.jp/"
          target="_blank"
        >
          ＞＞＞詳細はこちら！
        </StyledLink>
      </StyledTextContainer>
      <StyledShowCaseContainer>
        <Slider {...settings}>
          {awardArtItems.map((item, i) => (
            <AwardArtItemCard awardArt={item} key={i} kind="award" />
          ))}
        </Slider>
      </StyledShowCaseContainer>
    </AwardItemShowCaseWrapper>
  );
};

export default AwardItemBox;
