export enum ButtonType {
  NORMAL,
  IMPORTANT,
  ROUNDED,
  ROUNDEDIMPORTANT,
  ROUNDEDOU,
  WIDEROUNDED,
  CIRCLEWIDE,
  WIDEROUNDED_OUTLINED,
  CIRCLE,
  SMALL_OUTLINED,
  SMALL,
  GRADIENT,
  XLSX_GREEN,
  DISABLE,
}
