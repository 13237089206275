import React, { ReactNode } from 'react';
import { StyledButton } from './style';
import { ButtonType } from '../../../constants/Button';
import { CircularProgress } from '@material-ui/core';

export interface ButtonProps {
  buttonType?: ButtonType;
  children: ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  buttonType = ButtonType.NORMAL,
  children,
  disabled,
  onClick,
  isLoading,
}) => {
  return isLoading ? (
    <StyledButton
      buttonType={buttonType}
      disabled={disabled as boolean}
      onClick={onClick}
    >
      <CircularProgress
        size={16}
        color={'secondary'}
        style={{ margin: '0px 80px 0px 80px' }}
      />
    </StyledButton>
  ) : (
    <StyledButton
      buttonType={buttonType}
      disabled={disabled as boolean}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};
