import styled from '@emotion/styled';
import tw from 'twin.macro';

export const UserTagContainer = styled.div`
  ${tw`flex border-2 border-gray-100 border-solid rounded-full p-1 items-center hover:cursor-pointer hover:shadow-inner`}
`;

export const UserProfileImage = styled.img`
  ${tw`rounded-full w-7 h-7`}
`;

export const TagNameContainer = styled.div`
  ${tw`mx-2`}
`;
