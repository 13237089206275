import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  StyledButton,
  StyledButtonContainer,
  RemittanceCompletedWrapper,
  RemittanceCompletedTextContainer,
  RemittanceCompletedText,
} from './style';
import { Title } from '../../organisms/Title';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';

export const RemittanceCompleted = () => {
  const navigate = useNavigate();

  return (
    <RemittanceCompletedWrapper>
      <Title title="出金完了" subTitle="Remittance Completed" />
      <RemittanceCompletedTextContainer>
        <RemittanceCompletedText>
          MATICの出金が完了しました。
        </RemittanceCompletedText>
      </RemittanceCompletedTextContainer>
      <StyledButtonContainer>
        <StyledButton onClick={() => navigate('/')}>
          <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
            ホームに戻る
          </Text>
        </StyledButton>
      </StyledButtonContainer>
    </RemittanceCompletedWrapper>
  );
};
