import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Text } from '../../atoms/Text';
import { UserTag } from '../../atoms/UserTag';
import { Box } from '@material-ui/core';
import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import SpacerX from '../../atoms/Spacer/SpacerX';
import dayjs from 'dayjs';
import { API_URL, CHAIN_NETWORK } from '../../../config';
import { useNavigate } from 'react-router-dom';
import { RiShareBoxLine } from 'react-icons/ri';
import { createScanText } from '../ArtDetail';

interface Column {
  id: 'artName' | 'price' | 'seller' | 'buyer' | 'buyAt' | 'url';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'artName', label: '作品名', minWidth: 170 },
  { id: 'price', label: '価格', minWidth: 170 },
  {
    id: 'seller',
    label: '出品者',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'buyer',
    label: '購入者',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'buyAt',
    label: '購入日',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'url',
    label: 'URL',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
];

const useStyles = makeStyles({
  topContainer: {
    padding: '4rem',
    '@media screen and (max-width:600px)': {
      padding: 0,
    },
  },
  paper: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {},
  headerRow: {},
  headerCell: {
    backgroundColor: '#1a1a1a',
    color: '#fff',
    fontWeight: 'bold',
    borderRight: '1px solid #fff',
  },
  artName: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  bodyCell: {},
});

type Props = {
  history: any[];
};

export const AdminHistoryTable: React.FC<Props> = ({ history }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const createScanURL = (txHash: string) => {
    if (CHAIN_NETWORK === 'PolygonMainnet') {
      return `https://polygonscan.com/tx/${txHash}`;
    } else if (CHAIN_NETWORK === 'GoerliTest') {
      return `https://goerli.etherscan.io/tx/${txHash}`;
    }
  };

  return (
    <Box className={classes.topContainer}>
      <Box padding="12px" display="flex" alignItems="end">
        <Text fontType={FontType.TITLE2}>取引履歴</Text>
        <SpacerX width={12} />
        <Text color={Color.BRANDMAIN}>Trade history</Text>
      </Box>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead className={classes.table}>
              <TableRow className={classes.headerRow}>
                {columns.map((column) => (
                  <>
                    <TableCell
                      className={classes.headerCell}
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      }}
                    >
                      <Box justifyContent="center" display="flex">
                        {column.label}
                      </Box>
                    </TableCell>
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {history &&
                history.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.purchaseId}>
                      <TableCell
                        className={classes.artName}
                        key={`artname-${row.purchaseId}`}
                        onClick={() => navigate(`/items/${row.art.artId}`)}
                      >
                        {row.art.name}
                      </TableCell>
                      <TableCell
                        className={classes.bodyCell}
                        key={`value-${row.purchaseId}`}
                      >
                        {row.value}
                      </TableCell>
                      <TableCell
                        className={classes.bodyCell}
                        key={`seller-${row.purchaseId}`}
                      >
                        <UserTag
                          userTagImageUri={
                            API_URL + `/users/${row.seller.userId}/icon`
                          }
                          userName={row.seller.userName}
                          onclick={() =>
                            navigate(`/userpage/${row.seller.userId}`)
                          }
                        />
                      </TableCell>
                      <TableCell
                        className={classes.bodyCell}
                        key={`buyer-${row.purchaseId}`}
                      >
                        <UserTag
                          userTagImageUri={
                            API_URL + `/users/${row.buyer.userId}/icon`
                          }
                          userName={row.buyer.userName}
                          onclick={() =>
                            navigate(`/userpage/${row.buyer.userId}`)
                          }
                        />
                      </TableCell>

                      <TableCell
                        className={classes.bodyCell}
                        key={`createdAt-${row.purchaseId}`}
                      >
                        {dayjs(row.createdAt).format('YYYY/MM/DD HH:mm')}
                      </TableCell>
                      <TableCell
                        className={classes.bodyCell}
                        key={`url-${row.purchaseId}`}
                      >
                        <a
                          href={createScanURL(row.transactionHash)}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: '#808080',
                            fontWeight: '600',
                            cursor: 'pointer',
                          }}
                        >
                          <RiShareBoxLine style={{ marginRight: '2%' }} />
                          {createScanText()}
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
