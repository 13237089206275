import {
  YoaniERC721HWithMetaTx__factory,
  MinimalForwarder__factory,
  YoaniCWalletFactory__factory,
} from '../typechain-types';
import { ethers } from 'ethers';
import {
  CHAIN_NETWORK,
  ALCHEMY_APIKEY,
  CONTRACT_WALLET_ADDRESS,
} from '../config';
declare global {
  interface Window {
    ethereum: any;
  }
}

export type Network = 'Hardhat' | 'Goerli' | 'PolygonMainnet';

export const networkParams = {
  Hardhat: {
    chainId: '0x7A69',
    chainName: 'Hardhat',
    nativeCurrency: { symbol: 'GO', decimals: 18 },
    rpcUrls: ['http://127.0.0.1:8545/'],
  },
  Goerli: {
    chainId: '0x5',
    chainName: 'Goerli',
    nativeCurrency: { symbol: 'ETH', decimals: 18 },
    rpcUrls: [`https://eth-goerli.g.alchemy.com/v2/${ALCHEMY_APIKEY}`],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
  PolygonMainnet: {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
    rpcUrls: [
      'https://polygon-mainnet.g.alchemy.com/v2/JaTivzlAs9qALgKBmHVIXR5ES1NdthmK',
    ],
    blockExplorerUrls: ['https://polygonscan.com'],
  },
};

// walletに接続されているかを確認
export const checkWalletIsConnected = async () => {
  const { ethereum } = window;
  if (!ethereum) {
    console.log('Make sure you have MetaMask installed');
  } else {
    // console.log('Wallet exists');
  }

  const accounts = await ethereum.request({ method: 'eth_accounts' });

  if (accounts && accounts.length !== 0) {
    const account = accounts[0];
    // console.log('Found an authorized account: ', account);
    return account;
  } else {
    console.log('No authorized account found');
    return null;
  }
};

// webページをwalletに接続(不使用)
export const connectWalletHandler = async () => {
  if (typeof window.ethereum !== 'undefined') {
    console.log('MetaMask is installed!');
  }
  const { ethereum } = window;
  console.log(ethereum);
  if (!ethereum) {
    alert('Please install MetaMask');
  }

  try {
    await ethereum?.request({
      method: 'eth_requestAccounts',
    });
  } catch (err) {
    console.log(err);
  }
};

//webページをwalletに接続;
export async function getAccount() {
  // const { updateUserInfo, getCurrentUser } = useAuth();
  try {
    const account = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    if (account.length > 0) {
      return account[0];
    } else {
      return;
    }
  } catch (error: any) {
    if (error.code === 4001) {
      // EIP-1193 userRejectedRequest error
      // If this happens, the user rejected the connection request.
      console.log('Please connect to MetaMask.');
    } else if (error.code === -32002) {
      console.log('Please restart Metamask from Chrome Extension.');
    } else {
      console.error(error);
    }
    return;
  }
}

export const changeNetwork = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [networkParams[CHAIN_NETWORK as Network]],
    });
  } catch (err) {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networkParams[CHAIN_NETWORK as Network].chainId }],
    });
  }
};

type Message = {
  from: string;
  to: string;
  value: number;
  gas: number;
  nonce: number;
  data: string;
};

/*
  utils
*/
const EIP712DomainType = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
];

const ForwardRequestType = [
  { name: 'from', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'gas', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'data', type: 'bytes' },
];

function createTypedDataV4(
  chainId: number,
  ForwarderAddress: string,
  message: Message,
) {
  const TypedData = {
    primaryType: 'ForwardRequest' as const,
    types: {
      EIP712Domain: EIP712DomainType,
      ForwardRequest: ForwardRequestType,
    },
    domain: {
      // https://github.com/OpenZeppelin/openzeppelin-contracts/blob/master/contracts/metatx/MinimalForwarder.sol
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract: ForwarderAddress,
    },
    message,
  };
  return TypedData;
}

/*
  main
*/
export async function setApproveForAll(
  provider: ethers.BrowserProvider,
  contractAddr: string,
  forwarderAddr: string,
  operatorAddr: string,
  chainId: number,
) {
  console.log(contractAddr);
  console.log(forwarderAddr);
  console.log(operatorAddr);
  // const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const signer = await provider.getSigner();
  try {
    console.debug('Account:', await signer.getAddress());
  } catch (e) {
    console.error(e);
  }

  const contract = YoaniERC721HWithMetaTx__factory.connect(
    contractAddr,
    signer,
  );
  const forwarder = MinimalForwarder__factory.connect(forwarderAddr, signer);

  const fdata: any = [operatorAddr, true];
  const data = contract.interface.encodeFunctionData(
    'setApprovalForAll',
    fdata,
  );
  const from = await signer.getAddress();
  const nonceBN = await forwarder.getNonce(from);

  const message: Message = {
    from,
    to: contractAddr,
    value: 0,
    gas: 1e6,
    nonce: Number.parseInt(nonceBN),
    data,
  };

  const typedData = createTypedDataV4(chainId, forwarderAddr, message);

  let signature;
  try {
    signature = await provider.send('eth_signTypedData_v4', [
      from,
      JSON.stringify(typedData),
    ]);
    console.debug('signature', signature);
  } catch (e) {
    console.error(e);
  }

  return { message, signature };
}

export async function isApproveForAll(
  provider: ethers.BrowserProvider,
  contractAddr: string,
  operatorAddr: string,
) {
  const signer = await provider.getSigner();
  const contract = YoaniERC721HWithMetaTx__factory.connect(
    contractAddr,
    signer,
  );
  const isApproved = await contract.isApprovedForAll(
    await signer.getAddress(),
    operatorAddr,
  );
  console.debug('isApproved', isApproved);
  return isApproved;
}

// isApproveForAllは接続中のウォレットで検証しようとする。isApproveForAllByWalletIdはwalletAddressを指定して検証する
export async function isApproveForAllByWalletId(
  provider: ethers.BrowserProvider,
  contractAddr: string,
  operatorAddr: string,
  walletId: string,
) {
  const signer = await provider.getSigner();
  const contract = YoaniERC721HWithMetaTx__factory.connect(
    contractAddr,
    signer,
  );

  const isApproved = await contract.isApprovedForAll(walletId, operatorAddr);
  console.debug('isApproved', isApproved);
  return isApproved;
}

/*
  transferFrom
*/
//generate comment
/**
 *
 * @param provider
 * @param contractAddr 環境変数から取得、YoaniMP大元のwalletAddress
 * @param fromAddr UserInfo.walletAddress、NFT所有者のwalletAddress
 * @param toAddr MetaMask wallet address、出庫先のwalletAddress
 * @param tokenId Art.TokenID、NFTのID
 * @returns
 */
export async function transferFrom(
  provider: ethers.BrowserProvider,
  contractAddr: string,
  fromAddr: string,
  toAddr: string,
  tokenId: string,
) {
  console.log(contractAddr);
  console.log(fromAddr);
  console.log(toAddr);
  console.log(tokenId);

  const signer = await provider.getSigner();
  try {
    console.debug('Account:', await signer.getAddress());
  } catch (e) {
    console.error(e);
  }

  const erc721 = YoaniERC721HWithMetaTx__factory.connect(contractAddr, signer);

  try {
    const tx = await erc721.transferFrom(fromAddr, toAddr, tokenId);

    await tx.wait(1);

    return tx;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/*
  get transferFrom estmate
*/
//generate comment
/**
 *
 * @param provider
 * @param contractAddr 環境変数から取得
 * @param fromAddr UserInfo.walletAddress
 * @param toAddr MetaMask wallet address
 * @param tokenId Art.TokenID
 * @returns
 */
export async function transferFromEstimateGas(
  provider: ethers.BrowserProvider,
  contractAddr: string,
  fromAddr: string,
  toAddr: string,
  tokenId: string,
): Promise<string> {
  console.log(contractAddr);
  console.log(fromAddr);
  console.log(toAddr);
  console.log(tokenId);

  const rpc = new ethers.JsonRpcProvider(
    networkParams[CHAIN_NETWORK as Network].rpcUrls[0],
  );
  const signer = await provider.getSigner();
  try {
    console.log('Account:', await signer.getAddress());
  } catch (e) {
    console.error(e);
  }

  const erc721 = YoaniERC721HWithMetaTx__factory.connect(contractAddr, rpc);

  try {
    const estimateGasLimit: bigint = await erc721.transferFrom.estimateGas(
      fromAddr,
      toAddr,
      tokenId,
    );
    console.log(estimateGasLimit);
    const estimateGasPrice = (await signer.provider.getFeeData())?.gasPrice;
    if (!estimateGasPrice) {
      return 'error [transferFromestimateGas]: estimateGasPrice is nill';
    }
    console.debug('estimateGasLimit', estimateGasLimit);
    console.debug('estimateGasPrice', estimateGasPrice);
    return ethers.formatEther(estimateGasPrice * estimateGasLimit);
  } catch (e) {
    console.error(e);
    return '';
  }
}

export async function isContract(
  address: string,
  provider: ethers.BrowserProvider,
): Promise<boolean> {
  const code = await provider.getCode(address);
  return code.length > 2;
}

export async function getBalance(
  address: string,
  provider: ethers.BrowserProvider,
) {
  const balance = await provider.getBalance(address);
  return ethers.formatEther(balance);
}

// export async function withdrawEstimateGas(
//   from: string,
//   to: string,
//   amount: string,
// ) {
//   const rpc = new ethers.JsonRpcProvider(
//     networkParams[CHAIN_NETWORK as Network].rpcUrls[0],
//   );

//   const contract = await YoaniCWalletFactory__factory.connect(
//     CONTRACT_WALLET_ADDRESS,
//     rpc,
//   );

//   console.log(from);
//   console.log(to);
//   console.log(amount);
//   console.log(contract);
//   console.log('get fee data', await rpc.provider.getFeeData());

//   let estimateGasLimit: bigint;
//   if (amount === '') {
//     estimateGasLimit = await contract.sendAllMatic.estimateGas(from, to);
//   } else {
//     estimateGasLimit = await contract.sendMatic.estimateGas(
//       from,
//       to,
//       ethers.parseEther(amount),
//     );
//   }
//   console.log(estimateGasLimit);
//   const estimateGasPrice = (await rpc.provider.getFeeData())?.gasPrice;
//   if (!estimateGasPrice) {
//     return 'error [transferFromestimateGas]: estimateGasPrice is nill';
//   }

//   return ethers.formatEther(estimateGasPrice * estimateGasLimit);
// }
/**
 *
 * @param provider
 * @param fromAddr ログインしているユーザーのウォレットアドレス
 * @param toAddr
 * @param amount
 * @returns
 */
export async function withdraw(
  provider: ethers.BrowserProvider,
  fromAddr: string,
  amount?: string,
) {
  console.log(fromAddr);

  const signer = await provider.getSigner();
  const toAddr = await signer.getAddress();
  try {
    console.debug('Account:', await signer.getAddress());
  } catch (e) {
    console.error(e);
  }

  const contract = YoaniCWalletFactory__factory.connect(
    CONTRACT_WALLET_ADDRESS,
    signer,
  );

  try {
    let tx;
    if (amount) {
      tx = await contract.sendMatic(
        fromAddr,
        toAddr,
        ethers.parseEther(amount),
      );
    } else {
      tx = await contract.sendAllMatic(fromAddr, toAddr);
    }

    await tx.wait(1);

    return tx;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function withdrawEstimateGas(
  provider: ethers.BrowserProvider,
  fromAddr: string,
  amount?: string,
) {
  console.log('withdrawEstimate', fromAddr);
  console.log('withdrawEstimate, contract wallet', CONTRACT_WALLET_ADDRESS);

  console.log(
    'isContract',
    await isContract(CONTRACT_WALLET_ADDRESS, provider),
  );

  const signer = await provider.getSigner();
  const toAddr = await signer.getAddress();
  try {
    console.debug('Account:', await signer.getAddress());
  } catch (e) {
    console.error(e);
  }

  const contract = YoaniCWalletFactory__factory.connect(
    CONTRACT_WALLET_ADDRESS,
    signer,
  );

  try {
    let estimateGasLimit: bigint;

    if (amount && !isNaN(parseFloat(amount))) {
      estimateGasLimit = await contract.sendMatic.estimateGas(
        fromAddr,
        toAddr,
        ethers.parseEther(amount),
      );
    } else {
      estimateGasLimit = await contract.sendAllMatic.estimateGas(
        fromAddr,
        toAddr,
      );
    }
    console.log(estimateGasLimit);
    const estimateGasPrice = (await signer.provider.getFeeData())?.gasPrice;
    if (!estimateGasPrice) {
      return 'error [transferFromestimateGas]: estimateGasPrice is nill';
    }
    console.debug('estimateGasLimit', estimateGasLimit);
    console.debug('estimateGasPrice', estimateGasPrice);
    return ethers.formatEther(estimateGasPrice * estimateGasLimit);
  } catch (e) {
    console.error(e);
    return '';
  }
}
