import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import { HttpStatusCode } from 'axios';

import { useNotification } from './hooks';
import {
  NotificationsWrapper,
  StyledText,
  TextContainer,
  ButtonWrapper,
} from './style';

import { NotFound } from '../NotFound';

import { Button } from '../../atoms/Button';
import { Text } from '../../atoms/Text';
import TextArea from '../../atoms/TextArea';
import TextInput from '../../atoms/TextInput';

import { Title } from '../../organisms/Title';
import Notifications from '../../organisms/Notifications';

import { ButtonType } from '../../../constants/Button';
import { FontType } from '../../../constants/Fonts';

import {
  createNotification,
  updateNotifications,
} from '../../../api/NotificationsService';

import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';

const NotificationsPage: React.FC = () => {
  const authContext: AuthContextType = useAuthProvider();
  const { notifications, unreadNotifications, totalCount, reload } =
    useNotification(authContext.authData.userId as string);

  const [notificationContent, setNotificationContent] = useState('');
  const [notificationUsername, setNotificationUsername] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const { addAlert } = useAlertContext();
  const { userId } = useParams();

  useEffect(() => {
    if (unreadNotifications?.length) {
      const ids = unreadNotifications.map(
        (notification) => notification.Notification.id,
      );
      updateNotifications(ids, authContext.authData.token as string).then(
        (res) => {
          if (res.ok) {
            reload();
          }
        },
      );
    }
  }, [unreadNotifications]);

  async function handlePostNotification() {
    setIsPosting(true);

    if (!notificationUsername) {
      addAlert('ユーザー名またはユーザーメールを入力してください。', 'error');
      setIsPosting(false);
      return;
    }
    if (!notificationContent) {
      addAlert('通知内容を入力してください。', 'error');
      setIsPosting(false);
      return;
    }
    createNotification(
      notificationUsername,
      notificationContent,
      authContext.authData.token as string,
    )
      .then(async (res: any) => {
        if (res.ok) {
          addAlert('通知を送信しました。', 'success');
        } else if (res.status == HttpStatusCode.NotFound) {
          addAlert(
            'ユーザー名またはメールアドレスが存在していません。',
            'error',
          );
        } else {
          addAlert('通知の送信に失敗しました。', 'error');
        }
      })
      .finally(() => setIsPosting(false));
  }
  const [displayType, setDisplayType] = useState('unread');
  const [isAll, setIsAll] = useState(displayType === 'all');
  const [isUnread, setIsUnread] = useState(displayType === 'unread');
  const [isAdmin, setIsAdmin] = useState(displayType === 'admin');

  useEffect(() => {
    setIsAll(displayType === 'all');
    setIsUnread(displayType === 'unread');
    setIsAdmin(displayType === 'admin');
  }, [displayType]);

  if (userId != authContext.authData.userId) return <NotFound />;

  return (
    <NotificationsWrapper>
      <Title title={'お知らせ'} subTitle={'Notifications'} />
      <ButtonWrapper>
        <Button
          buttonType={isAll ? ButtonType.IMPORTANT : ButtonType.SMALL_OUTLINED}
          onClick={() => setDisplayType('all')}
        >
          すべて
        </Button>
        <Button
          buttonType={
            isUnread ? ButtonType.IMPORTANT : ButtonType.SMALL_OUTLINED
          }
          onClick={() => setDisplayType('unread')}
        >
          未読
        </Button>
        {authContext.authData.role === 'admin' && (
          <Button
            buttonType={
              isAdmin ? ButtonType.IMPORTANT : ButtonType.SMALL_OUTLINED
            }
            onClick={() => setDisplayType('admin')}
          >
            運営通知
          </Button>
        )}
      </ButtonWrapper>
      {displayType === 'all' && (
        <Notifications
          notifications={notifications}
          totalCount={totalCount as number}
        />
      )}
      {displayType === 'unread' && (
        <>
          {unreadNotifications?.length === undefined ||
            (unreadNotifications?.length === 0 && (
              <TextContainer>
                <StyledText>未読の通知はありません。</StyledText>
              </TextContainer>
            ))}
          {unreadNotifications?.length != undefined &&
            unreadNotifications?.length > 0 && (
              <Notifications
                notifications={unreadNotifications}
                totalCount={totalCount as number}
              />
            )}
        </>
      )}
      {displayType === 'admin' && (
        <Box>
          <Text fontType={FontType.TITLE2}>運営通知作成</Text>
          <TextContainer>
            <StyledText>宛先ユーザー名またはメールアドレス</StyledText>
          </TextContainer>
          <TextInput
            setText={setNotificationUsername}
            placeholder={
              '通知の宛先ユーザー名またはメールアドレスを入力してください。'
            }
          />
          <TextContainer>
            <StyledText>通知内容</StyledText>
          </TextContainer>
          <TextArea
            setText={setNotificationContent}
            placeholder={'通知内容を入力してください。'}
          />
          <ButtonWrapper>
            <Button
              onClick={handlePostNotification}
              buttonType={ButtonType.GRADIENT}
            >
              {!isPosting ? (
                <p
                  style={{
                    color: '#fff',
                    whiteSpace: 'pre-wrap',
                    fontWeight: 'bold',
                    minWidth: 'max-content',
                    margin: 0,
                    cursor: 'pointer',
                  }}
                >
                  送信する
                </p>
              ) : (
                <CircularProgress
                  size={16}
                  color={'secondary'}
                  style={{ margin: '0px 50px 0px 50px' }}
                />
              )}
            </Button>
          </ButtonWrapper>
        </Box>
      )}
    </NotificationsWrapper>
  );
};

export default NotificationsPage;
