/* eslint-disable @typescript-eslint/ban-types */
import { Tab, Box, Tabs } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Color } from '../../../constants/Color';
import SpacerY from '../Spacer/SpacerY';

interface TabPanelProps {
  label: string;
  children?: ReactNode;
  value: number;
  index: number;
}

interface TabData {
  label: string;
  children?: ReactNode;
}

type Props = {
  tabData: TabData[];
  func?: (index: number | undefined) => void;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CommonTabs: React.FC<Props> = ({ tabData, func }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (func) {
      func(newValue);
    }
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#101010',
            height: '2px',
          },
        }}
      >
        {tabData.map((tab: TabData, index: number) => {
          return (
            <Tab
              color={Color.MAIN}
              key={tab.label}
              label={tab.label}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
      <SpacerY height={24} />
      {tabData.map((tab: TabData, index: number) => {
        return (
          <TabPanel
            value={value}
            index={index}
            key={`tab-pannel-${tab.label}`}
            label={''}
          >
            {tab.children}
          </TabPanel>
        );
      })}
    </>
  );
};
export default CommonTabs;
