import React from 'react';
import { AuthPage } from '../../organisms/AuthPage';

export const SignIn: React.FC = () => {
  return (
    <>
      <AuthPage />
    </>
  );
};
