import React from 'react';
import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { StyledShowCaseContainer } from './style';

import { ArtCard } from '../ArtCard';

import { Text } from '../../atoms/Text';

import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import { CardType } from '../../../constants/CardType';

import { Art } from '../../../types/domain/Art';

interface ContestItemShowCaseProps {
  items: Art[] | undefined;
}

export const ContestItemShowCase: React.FC<ContestItemShowCaseProps> = ({
  items,
}) => {
  return (
    <>
      {!items?.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          margin="2rem"
        >
          <SearchIcon style={{ fontSize: '32px' }} color="disabled" />
          <Text fontType={FontType.SMALL} color={Color.BORDER20}>
            表示する作品がありません
          </Text>
        </Box>
      ) : (
        <StyledShowCaseContainer>
          {items.map((item, index) => {
            return (
              <ArtCard
                _art={item}
                key={index}
                cardType={CardType.NORMAL}
                rank={index + 1}
                kind="display"
              />
            );
          })}
        </StyledShowCaseContainer>
      )}
    </>
  );
};
