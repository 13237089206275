import tw from 'twin.macro';
import styled from '@emotion/styled';

export const TermsOfUseContainer = styled.div`
  ${tw`mt-4 bg-white max-w-[1440px] w-[100%] px-2 md:px-8 mx-auto`}
`;

export const TextContainer = styled.div`
  ${tw`w-[90%] mx-auto mt-8 md:mt-16`}
`;

export const TableWrapper = styled.div`
  ${tw`w-[90%] mx-auto mt-8`}
`;

export const Table = styled.table`
  ${tw`w-full text-left border-collapse`}
`;

export const TableRow = styled.tr`
  ${tw`border border-gray-300`}
  border: 1px solid #000;
`;

export const TableHeader = styled.th`
  ${tw`border border-black p-2 w-[30%] text-xs sm:text-sm md:text-base`}
  border: 1px solid #000;
`;

export const TableData = styled.td`
  ${tw`border border-black p-2 text-xs sm:text-sm md:text-base`}
  border: 1px solid #000;
`;
