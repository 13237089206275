import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';

export const MyPageContainer = styled.div`
  ${tw`max-w-[1440px] mx-auto w-[95%]`}
`;

export const BulkTransferContainer = styled.div`
  ${tw`bg-white mt-4 mb-8 rounded-xl w-[90%] mx-auto py-[20px] px-4 md:px-[30px]`}
  filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -moz-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
`;

export const AmountContainer = styled.div`
  ${tw`md:flex justify-between items-center`}
`;

export const WithdrawButton = styled.button`
  ${tw`w-[90%] md:w-[60%] block bg-[#E95098] border-none text-white md:text-xl font-bold py-3 mx-auto rounded-full cursor-pointer mt-4 md:mt-8`}
  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease;
    opacity: 0.8;
  }
`;

export const AmoutDetailContainer = styled.div`
  ${tw`rounded-xl mx-auto w-[90%] md:w-[60%] `}
`;

export const DetailContainer = styled.div`
  ${tw`flex justify-between mx-auto py-2 md:py-6`}
`;

export const WithdrawableDetailAmountTitle = styled.div`
  ${tw`text-lg md:text-2xl font-bold text-center py-2 md:py-8`}
`;

export const DetailTitle = styled.div`
  ${tw`md:text-xl font-bold`}
`;

export const DetailContent = styled.div`
  ${tw`md:text-xl font-bold`}
`;
export const DetailTitleSub = styled.div`
  ${tw`text-xs font-bold`}
  color: ${Color.GRAYSCALE64};
`;

export const DetailContentSub = styled.div`
  ${tw`text-xs font-bold text-right`}
  color: ${Color.GRAYSCALE64};
`;

export const NoteContainer = styled.div`
  ${tw`w-[90%] md:w-[60%] mx-auto text-left text-sm md:text-base mt-4`}
`;

export const NoteTitle = styled.p`
  ${tw`md:text-xl font-bold`}
`;

export const NoteContent = styled.p`
  ${tw`text-sm md:text-base mb-1`}
`;
