import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';

export interface Props {
  setFile: (file: File) => void;
}

export const DropUpload = (props: Props) => {
  return (
    <DropzoneArea
      acceptedFiles={['image/*']}
      onChange={(file) => props.setFile(file[0])}
      filesLimit={1}
      dropzoneText={'PNG/JPEG'}
      maxFileSize={100000000}
      showPreviewsInDropzone
      showFileNamesInPreview
      showFileNames
    />
  );
};
