import React from 'react';
import {
  FullOverlay,
  CloseButtonContainer,
  BigClearHeader,
  BigContentContainer,
  Window,
  StyledImage,
} from './style';
import { Button } from '../atoms/Button';
import CancelIcon from '@material-ui/icons/Cancel';

interface Props {
  isDisplayed: boolean;
  src: string | undefined;
  close: () => void;
}

export const ArtPopUp: React.FC<Props> = ({ isDisplayed, close, src }) => {
  if (!isDisplayed) {
    return null;
  } else {
    return (
      <FullOverlay>
        <Window>
          <BigClearHeader>
            <CloseButtonContainer>
              <Button onClick={close}>
                <CancelIcon fontSize="large" />
              </Button>
            </CloseButtonContainer>
          </BigClearHeader>
          <BigContentContainer>
            <StyledImage src={src} />
          </BigContentContainer>
        </Window>
      </FullOverlay>
    );
  }
};
