import React from 'react';
import {
  FooterContainer,
  LinkContainer,
  LogoContainer,
  DoubleGridContainer,
  YoaniLogo,
  SocialContainer,
  SocialLogo,
  SocialLogoContainer,
  SocialGridContainer,
  SingleGridContainer,
  ColTitle,
  FooterBorder,
  FooterBg,
} from './style';
import { Text } from '../atoms/Text';
import { Color } from '../../constants/Color';
import { FontType } from '../../constants/Fonts';
import { useNavigate } from 'react-router-dom';
import { AuthContextType, useAuthProvider } from '../../Providers/AuthProvider';
import { useAlertContext } from '../../Providers/AlertProvider';

export default function Footer() {
  const navigate = useNavigate();
  const authContext: AuthContextType = useAuthProvider();
  const { addAlert } = useAlertContext();

  const handleVerify = (path: string) => {
    if (!authContext.authData.emailVerified) {
      navigate('resendAuthEmail');
    } else {
      navigate(`/${path}`);
    }
  };

  return (
    <div style={{ width: '100%', backgroundColor: 'black' }}>
      <FooterBg>
        <FooterBorder />
      </FooterBg>
      <FooterContainer>
        <DoubleGridContainer>
          <LogoContainer>
            <YoaniLogo
              src={`${process.env.PUBLIC_URL}/hakkutsu_logo_white.png`}
            />
          </LogoContainer>
          <Text fontType={FontType.SMALL_NORMAL} color={Color.WHITETEXT}>
            &copy;
            {new Date().getFullYear()} Yoyogi Animation academy. All rights
            reserved.
          </Text>
          <SocialGridContainer>
            <SocialContainer>
              <SocialLogoContainer>
                <SocialLogo
                  onClick={() =>
                    window.open(
                      'https://twitter.com/YAG_PR',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                  src={`${process.env.PUBLIC_URL}/x_logo_white.png`}
                />
                <SocialLogo
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/channel/UCeee1ZxJH1cOX_hZqR8jUVQ',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                  src={`${process.env.PUBLIC_URL}/youtube2.png`}
                />
                <SocialLogo
                  onClick={() =>
                    window.open(
                      'https://page.line.me/xxo4567g',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                  src={`${process.env.PUBLIC_URL}/line.png`}
                />
                <SocialLogo
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/yoani.official/',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                  src={`${process.env.PUBLIC_URL}/facebook2.png`}
                />
                <SocialLogo
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/yoani_official/',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                  src={`${process.env.PUBLIC_URL}/instagram.png`}
                />
              </SocialLogoContainer>
            </SocialContainer>
          </SocialGridContainer>
        </DoubleGridContainer>
        <SingleGridContainer>
          <ColTitle>
            <Text color={Color.WHITETEXT} fontType={FontType.TITLE4}>
              Yoani NFT MPについて
            </Text>
          </ColTitle>
          <LinkContainer>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => navigate('/nftManual')}
            >
              NFTとは？
            </Text>
          </LinkContainer>
        </SingleGridContainer>
        <SingleGridContainer>
          <ColTitle>
            <Text color={Color.WHITETEXT} fontType={FontType.TITLE4}>
              マイアカウント
            </Text>
          </ColTitle>
          <LinkContainer>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                if (!authContext.authData.token) {
                  addAlert(
                    'ログインまたはアカウント作成した上で、もう一度お試し下さい',
                    'warning',
                  );
                } else {
                  navigate(`/userpage/${authContext.authData.userId}`);
                }
              }}
            >
              マイページ
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                if (!authContext.authData.token) {
                  addAlert(
                    'ログインまたはアカウント作成した上で、もう一度お試し下さい',
                    'warning',
                  );
                } else {
                  handleVerify('regist');
                }
              }}
            >
              作品を登録する
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                if (!authContext.authData.token) {
                  addAlert(
                    'ログインまたはアカウント作成した上で、もう一度お試し下さい',
                    'warning',
                  );
                } else {
                  navigate(`/userpage/${authContext.authData.userId}/settings`);
                }
              }}
            >
              プロフィール編集
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                if (!authContext.authData.token) {
                  addAlert(
                    'ログインまたはアカウント作成した上で、もう一度お試し下さい',
                    'warning',
                  );
                } else {
                  navigate(
                    `/userpage/${authContext.authData.userId}/notifications`,
                  );
                }
              }}
            >
              お知らせ
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                if (!authContext.authData.token) {
                  addAlert(
                    'ログインまたはアカウント作成した上で、もう一度お試し下さい',
                    'warning',
                  );
                } else {
                  navigate(
                    `/userpage/${authContext.authData.userId}/likedlist`,
                  );
                }
              }}
            >
              いいねした作品
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                if (!authContext.authData.token) {
                  addAlert(
                    'ログインまたはアカウント作成した上で、もう一度お試し下さい',
                    'warning',
                  );
                } else {
                  navigate(`/userpage/${authContext.authData.userId}/purchase`);
                }
              }}
            >
              購入・販売履歴
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                if (!authContext.authData.token) {
                  addAlert(
                    'ログインまたはアカウント作成した上で、もう一度お試し下さい',
                    'warning',
                  );
                } else {
                  navigate(`/userpage/${authContext.authData.userId}/deposit`);
                }
              }}
            >
              振込申請
            </Text>
          </LinkContainer>
        </SingleGridContainer>
        <SingleGridContainer>
          <ColTitle>
            <Text color={Color.WHITETEXT} fontType={FontType.TITLE4}>
              規約ポリシー
            </Text>
          </ColTitle>
          <LinkContainer>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                navigate('/guideline');
              }}
            >
              ガイドライン
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                navigate('/TermsOfUse');
              }}
            >
              サービス利用規約
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                navigate('/CommercialTransactionLaw');
              }}
            >
              特定商取引法に基づく表記
            </Text>
          </LinkContainer>
        </SingleGridContainer>
        <SingleGridContainer>
          <ColTitle>
            <Text color={Color.WHITETEXT} fontType={FontType.TITLE4}>
              会社情報
            </Text>
          </ColTitle>
          <LinkContainer>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                window.open(
                  'https://www.yoani.co.jp/',
                  '_blank',
                  'noopener noreferrer',
                );
              }}
            >
              運営会社
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                window.open(
                  'https://recruit.yoani.co.jp/',
                  '_blank',
                  'noopener noreferrer',
                );
              }}
            >
              採用情報
            </Text>
            <Text
              color={Color.WHITETEXT}
              fontType={FontType.SMALL_NORMAL}
              onClick={() => {
                navigate(`/inquiry`);
              }}
            >
              お問い合わせ
            </Text>
          </LinkContainer>
        </SingleGridContainer>
      </FooterContainer>
    </div>
  );
}
