import React, { ReactNode } from 'react';
import {
  FullOverlay,
  ClearHeader,
  CloseButtonContainer,
  ContentContainer,
  BigClearHeader,
  BigContentContainer,
  Window,
} from './style';
import { Button } from '../Button';
import CancelIcon from '@material-ui/icons/Cancel';

interface Props {
  isDisplayed: boolean;
  children: ReactNode;
  close: () => void;
  isLarge?: boolean;
}

export const PopUpWindow: React.FC<Props> = ({
  children,
  isDisplayed,
  close,
  isLarge,
}) => {
  if (!isDisplayed) {
    return null;
  } else if (isLarge == true) {
    return (
      <FullOverlay>
        <Window>
          <BigClearHeader>
            <CloseButtonContainer>
              <Button onClick={close}>
                <CancelIcon fontSize="large" />
              </Button>
            </CloseButtonContainer>
          </BigClearHeader>
          <BigContentContainer>{children}</BigContentContainer>
        </Window>
      </FullOverlay>
    );
  } else {
    return (
      <FullOverlay>
        <Window>
          <ClearHeader>
            <CloseButtonContainer>
              <Button onClick={close}>
                <CancelIcon fontSize="large" />
              </Button>
            </CloseButtonContainer>
          </ClearHeader>
          <ContentContainer>{children}</ContentContainer>
        </Window>
      </FullOverlay>
    );
  }
};
