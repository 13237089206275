import React, { useEffect, useState } from 'react';
import {
  ButtonContainer,
  ButtonRowContainer,
  ButtonText,
  ButtonWrapper,
  HeaderPFPContainer,
  MailCertifyButtonWrapper,
  MyPageBioContainer,
  MyPageButtonsContainer,
  MyPageHeaderContainer,
  MyPageHeaderImg,
  MyPageNameContainer,
  MyPagePFP,
  MyPagePFPBase,
  MyPageProfileContainer,
  MyPageSubContainer,
} from './style';
import { useNotification } from '../../pages/NotificationsPage/hooks';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { resendVerifyEmail } from '../../../api/UsersService';
import { UserRes } from '../../../api/AdminService';
import { useAuth } from '../../../api/AuthService';
import { User } from '../../../types/domain/User';
import { useMediaSize } from '../../../lib/useMediaSize';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { FontType } from '../../../constants/Fonts';
import { ButtonType } from '../../../constants/Button';
import { Color } from '../../../constants/Color';

import { FaCog, FaRegBell, FaBrush } from 'react-icons/fa';
import { RiMoneyCnyCircleLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import { MdOutlineCreditScore } from 'react-icons/md';
import { BsPerson } from 'react-icons/bs';
// import { IoWalletOutline } from 'react-icons/io5';
import { IoColorPaletteOutline } from 'react-icons/io5';
import { SiEthereum } from 'react-icons/si';
import { Badge } from '@material-ui/core';

interface ProfileProps {
  displayname?: string;
  pfpUri?: string;
  headerUri?: string;
  username?: string;
  bio?: string;
  userId?: string;
  userRole?: string;
  isPortfolio: boolean;
  SFData?: UserRes;
}

export const MyPageProfile: React.FC<ProfileProps> = ({
  displayname,
  pfpUri,
  headerUri,
  username,
  bio,
  userId,
  // userRole,
  // isPortfolio,
  SFData,
}) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const { addAlert } = useAlertContext();
  const { isSm } = useMediaSize();
  const navigate = useNavigate();
  const authContext: AuthContextType = useAuthProvider();
  const { unreadNotifications } = useNotification(
    authContext.authData.userId as string,
  );
  const isViewable = authContext.authData.role === 'admin';
  const { getCurrentUser } = useAuth();

  useEffect(() => {
    getCurrentUser().then((res) => setCurrentUser(res));
  }, []);

  const email = currentUser?.email;

  const handleResend = async () => {
    try {
      const data = await resendVerifyEmail(email);
      if (data.code === 200) {
        addAlert('メールアドレスの認証メールを再送しました。', 'success');
      } else {
        addAlert(data.mesasge, 'error');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <MyPageProfileContainer>
      <HeaderPFPContainer>
        <MyPageHeaderContainer>
          <MyPageHeaderImg src={headerUri} />
        </MyPageHeaderContainer>
        <MyPagePFPBase>
          <MyPagePFP src={pfpUri} />
        </MyPagePFPBase>
      </HeaderPFPContainer>
      <MyPageNameContainer>
        <Text fontType={FontType.TITLE2}>{displayname || username}</Text>
        <Text fontType={FontType.TITLE2s}>{username}</Text>
      </MyPageNameContainer>
      {!authContext.authData.emailVerified &&
        authContext.authData.userId == userId && (
          <MailCertifyButtonWrapper>
            <Button
              buttonType={ButtonType.ROUNDEDIMPORTANT}
              onClick={handleResend}
            >
              認証メールを送信する
            </Button>
          </MailCertifyButtonWrapper>
        )}
      {isViewable && (
        <>
          {SFData && (
            <>
              <MyPageSubContainer>
                <Text color={Color.BRAND30} fontType={FontType.SUB}>
                  {SFData.salesforceUser?.schoolName}
                </Text>
                <Text color={Color.BRAND30} fontType={FontType.SUB}>
                  {SFData.salesforceUser?.classFaculty}
                </Text>
              </MyPageSubContainer>
              <MyPageSubContainer>
                <Text color={Color.BRAND30} fontType={FontType.SUB}>
                  {SFData.salesforceUser?.classDepartment}
                </Text>
                <Text color={Color.BRAND30} fontType={FontType.SUB}>
                  {SFData.salesforceUser?.classNo}
                </Text>
              </MyPageSubContainer>
              <MyPageSubContainer>
                <Text color={Color.BRAND30} fontType={FontType.SUB}>
                  {SFData.salesforceUser?.studentNo}
                </Text>
                <Text color={Color.BRAND30} fontType={FontType.SUB}>
                  {SFData.salesforceUser?.lastname}{' '}
                  {SFData.salesforceUser?.firstname}
                </Text>
              </MyPageSubContainer>
            </>
          )}
        </>
      )}
      {/* 現時点では不必要なためコメントアウト 8/7 */}
      {/* <MyPageIDContainer>
        <Text fontType={FontType.TITLE2} color={Color.BRAND30}>
          {username}
        </Text>
      </MyPageIDContainer> */}
      {bio && (
        <MyPageBioContainer>
          <Text fontType={FontType.SUB}>{bio}</Text>
        </MyPageBioContainer>
      )}
      <MyPageButtonsContainer>
        {authContext.authData.userId == userId && (
          <>
            {/* １列目 */}
            <ButtonRowContainer>
              <ButtonWrapper>
                <ButtonContainer
                  onClick={() => navigate(`/userpage/${userId}`)}
                >
                  {isSm ? (
                    <BsPerson size={'2em'} color={Color.MAIN} />
                  ) : (
                    <BsPerson size={'1.5em'} color={Color.MAIN} />
                  )}
                  <ButtonText>マイページ</ButtonText>
                </ButtonContainer>
              </ButtonWrapper>
            </ButtonRowContainer>

            {/* ２列目 */}
            <ButtonRowContainer>
              <ButtonWrapper>
                <ButtonContainer
                  onClick={() => navigate(`/userpage/${userId}/likedlist`)}
                >
                  {isSm ? (
                    <MdOutlineFavoriteBorder size={'2em'} color={Color.MAIN} />
                  ) : (
                    <MdOutlineFavoriteBorder
                      size={'1.5em'}
                      color={Color.MAIN}
                    />
                  )}
                  <ButtonText>いいねした作品</ButtonText>
                </ButtonContainer>
              </ButtonWrapper>
              <ButtonWrapper
                onClick={() => navigate(`/userpage/${userId}/notifications`)}
              >
                <ButtonContainer>
                  {isSm ? (
                    <FaRegBell size={'2em'} color={Color.MAIN} />
                  ) : (
                    <FaRegBell size={'1.5em'} color={Color.MAIN} />
                  )}
                  <Badge
                    badgeContent={unreadNotifications?.length}
                    color="secondary"
                  />
                  <ButtonText>お知らせ一覧</ButtonText>
                </ButtonContainer>
              </ButtonWrapper>
            </ButtonRowContainer>

            {/* ３列目 */}
            <ButtonRowContainer>
              <ButtonWrapper
                onClick={() => navigate(`/userpage/${userId}/purchase`)}
              >
                <ButtonContainer>
                  {isSm ? (
                    <MdOutlineCreditScore size={'2em'} color={Color.MAIN} />
                  ) : (
                    <MdOutlineCreditScore size={'1.5em'} color={Color.MAIN} />
                  )}
                  <ButtonText>購入・販売履歴</ButtonText>
                </ButtonContainer>
              </ButtonWrapper>
              <ButtonWrapper>
                <ButtonContainer
                  onClick={() => navigate(`/userpage/${userId}/deposit`)}
                >
                  {isSm ? (
                    <RiMoneyCnyCircleLine size={'2em'} color={Color.MAIN} />
                  ) : (
                    <RiMoneyCnyCircleLine size={'1.5em'} color={Color.MAIN} />
                  )}
                  <ButtonText>振込申請</ButtonText>
                </ButtonContainer>
              </ButtonWrapper>
            </ButtonRowContainer>

            {/* ４列目 */}
            <ButtonRowContainer>
              <ButtonWrapper>
                <ButtonContainer
                  onClick={() => navigate(`/userpage/${userId}/remittance`)}
                >
                  {isSm ? (
                    <SiEthereum size={'2em'} color={Color.MAIN} />
                  ) : (
                    <SiEthereum size={'1.5em'} color={Color.MAIN} />
                  )}
                  <ButtonText>MATIC出金申請</ButtonText>
                </ButtonContainer>
              </ButtonWrapper>
              {authContext.authData.role == 'student' && (
                <ButtonWrapper
                  onClick={() => navigate(`/userpage/${userId}/portfolio`)}
                >
                  <ButtonContainer>
                    {isSm ? (
                      <FaBrush size={'2em'} color={Color.MAIN} />
                    ) : (
                      <FaBrush size={'1.5em'} color={Color.MAIN} />
                    )}
                    <ButtonText>ポートフォリオ</ButtonText>
                  </ButtonContainer>
                </ButtonWrapper>
              )}
            </ButtonRowContainer>

            {/* ５列目 */}
            <ButtonRowContainer>
              {authContext.authData.role == 'admin' && (
                <ButtonWrapper onClick={() => navigate(`/contestmanagement`)}>
                  <ButtonContainer>
                    {isSm ? (
                      <IoColorPaletteOutline size={'2em'} color={Color.MAIN} />
                    ) : (
                      <IoColorPaletteOutline
                        size={'1.5em'}
                        color={Color.MAIN}
                      />
                    )}
                    <ButtonText>イラストコンテスト管理</ButtonText>
                  </ButtonContainer>
                </ButtonWrapper>
              )}
              <ButtonWrapper>
                <ButtonContainer
                  onClick={() => navigate(`/userpage/${userId}/settings`)}
                >
                  {isSm ? (
                    <FaCog size={'2em'} color={Color.MAIN} />
                  ) : (
                    <FaCog size={'1.5em'} color={Color.MAIN} />
                  )}
                  <ButtonText>設定</ButtonText>
                </ButtonContainer>
              </ButtonWrapper>
            </ButtonRowContainer>
          </>
        )}
      </MyPageButtonsContainer>
    </MyPageProfileContainer>
  );
};
