import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';

export const ItemDetailContainer = styled.div`
  ${tw`m-5`}
  ${tw`grid grid-cols-2 md:grid-cols-4`}
`;

export const PictureContainer = styled.div`
  ${tw`col-span-2 flex flex-col my-10`}
`;

export const StyledItemImage = styled.img`
  ${tw`w-full max-h-[40rem]`}
  object-fit: contain;
  :hover {
    opacity: 0.6;
  }
`;

export const ItemInfoContainer = styled.div`
  ${tw`col-span-2 p-1 md:p-10 flex flex-col`}
`;

export const TagsContainer = styled.div`
  ${tw`flex flex-row flex-wrap gap-x-2 gap-y-2 pb-4`}
`;

export const StyledDescription = styled.div`
  ${tw`mt-4 mb-8 w-[100%] break-words`}
`;

export const DateDiscriptionContainer = styled.div`
  ${tw`mt-4 mb-8 w-[100%] break-words flex flex-col`}
`;

export const StyledDateDescription = styled.div`
  ${tw`my-2 w-[100%] break-words flex flex-row`}
`;

export const StyledButtonContainer = styled.div`
  ${tw`flex flex-row justify-evenly py-10`}
`;

export const UserTagVStack = styled.div`
  ${tw`flex flex-col pt-4 space-y-2`}
`;

export const ArtUserInfoContainer = styled.div`
  ${tw`flex flex-row space-x-4 mb-4`}
`;

export const NFTInfoWrapper = styled.div`
  ${tw`mb-4 md:mb-8 bg-white px-5 pb-2 pt-4 rounded-lg`}
  border: 1px solid ${Color.BORDER10};
`;

export const NFTInfoTextContainer = styled.div`
  ${tw`mb-4 break-words`}
`;

export const NFTScanPath = styled.a`
  ${tw`flex items-center  text-[#808080] font-bold cursor-pointer break-words`}
`;

export const BorderBox = styled.div`
  margin: 2% 5% 2% 5%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${Color.BORDER10};
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 6%;
  margin-bottom: 5%;
`;
