import React, { useContext, useState } from 'react';
import { Art } from '../../../types/domain/Art';
import { Text } from '../../atoms/Text';
import { TagsContainer } from './style';
import SearchIcon from '@material-ui/icons/Search';
import { CardType } from '../../../constants/CardType';
import { Title } from '../Title';
import { Tag } from '../../atoms/Tag';
import { useNavigate } from 'react-router-dom';
import { Tag as TagType } from '../../../types/domain/Tag';
import { Color } from '../../../constants/Color';
import { useLocation } from 'react-router-dom';
import SortableItems from '../../pages/PortfolioPage/SortableItems';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';
import { ArtCard } from '../ArtCard';
import { StyledPortfolioContainer } from '../ItemShowCase/style';
import { Box } from '@material-ui/core';
import SpacerX from '../../atoms/Spacer/SpacerX';
import SpacerY from '../../atoms/Spacer/SpacerY';
import { sortPortfolioItems } from '../../../api/ArtsService';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { FontType } from '../../../constants/Fonts';

interface ItemShowCaseProps {
  userId?: string;
  title?: string;
  subTitle?: string;
  items: Art[] | undefined;
  cardType?: CardType;
  buttonAction?: any; // TODO Fix this
  tags?: TagType[];
  reload: () => void;
  kind: string;
}

export const PortfolioItems: React.FC<ItemShowCaseProps> = ({
  userId,
  title,
  subTitle,
  items,
  tags,
  reload,
  kind,
}) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [selectedTag, setSelectedTag] = useState(
    decodeURI(search.replace('?tag=', '')),
  );
  const [isEditing, setIseEditing] = useState(false);
  const [itemIds, setItemIds] = useState<any[]>([]);

  const handleSearchWithTag = (tag: string) => {
    setSelectedTag(tag);
    navigate(`/search?tag=${tag}`, { replace: true });
  };

  const handleSave = () => {
    sortPortfolioItems(
      authContext.authData.userId as string,
      itemIds,
      authContext.authData.token as string,
    ).then(async (res) => {
      if (res.ok) {
        await reload();
        addAlert('並び替えを保存しました', 'success');
        setIseEditing(false);
        setItemIds([]);
      } else {
        addAlert('並び替えに失敗しました。もう一度お試しください', 'error');
        setIseEditing(false);
        setItemIds([]);
      }
    });
  };

  return (
    <>
      {title && <Title title={title} subTitle={subTitle} />}
      <TagsContainer>
        {tags && tags.length > 0 && (
          <Tag
            title="すべて"
            key={`tag-all`}
            func={() => {
              setSelectedTag('');
              navigate('/');
            }}
            color={!selectedTag ? Color.WHITETEXT : Color.BRAND20}
            bgColor={!selectedTag ? Color.BRANDMAIN : Color.BRAND40}
          />
        )}
        {tags?.map((tag, index) => {
          return (
            <Tag
              title={tag.name}
              key={`tag-${index}`}
              func={() => handleSearchWithTag(tag.name)}
              color={selectedTag === tag.name ? Color.WHITETEXT : Color.BRAND20}
              bgColor={
                selectedTag === tag.name ? Color.BRANDMAIN : Color.BRAND40
              }
            />
          );
        })}
      </TagsContainer>

      {!items || !items.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          margin="2rem"
        >
          <SearchIcon style={{ fontSize: '50px' }} color="disabled" />
          <Text color={Color.BORDER20}>表示する作品がありません</Text>
          {userId == authContext.authData.userId && (
            <Button
              buttonType={ButtonType.IMPORTANT}
              onClick={() => navigate('/regist')}
            >
              <Text fontType={FontType.TAG} color={Color.WHITETEXT}>
                作品を登録
              </Text>
            </Button>
          )}
        </Box>
      ) : (
        <>
          {isEditing ? (
            <>
              <Box display="flex" justifyContent="right">
                <Button
                  buttonType={ButtonType.SMALL_OUTLINED}
                  onClick={() => {
                    setIseEditing(false);
                    setItemIds([]);
                  }}
                >
                  キャンセル
                </Button>
                <SpacerX width={12} />
                <Button
                  disabled={!itemIds.length}
                  buttonType={ButtonType.SMALL}
                  onClick={handleSave}
                >
                  保存
                </Button>
              </Box>
              <SpacerY height={32} />
              <SortableItems
                arts={items}
                isEditing={isEditing}
                setItemIds={setItemIds}
              />
            </>
          ) : (
            <>
              {userId == authContext.authData.userId && (
                <Box display="flex" justifyContent="right">
                  <Button
                    buttonType={ButtonType.SMALL}
                    onClick={() => setIseEditing(true)}
                  >
                    順番を並び替える
                  </Button>
                </Box>
              )}
              <SpacerY height={32} />
              <StyledPortfolioContainer cardType={CardType.NORMAL}>
                {items.map((item, index) => (
                  <Box
                    key={`portfolio-${index}`}
                    justifyContent="center"
                    padding="10%"
                  >
                    <ArtCard
                      _art={item}
                      key={index}
                      cardType={CardType.NORMAL}
                      rank={index + 1}
                      kind={kind}
                    />
                  </Box>
                ))}
              </StyledPortfolioContainer>
            </>
          )}
        </>
      )}
    </>
  );
};
