import React, { useEffect, useState } from 'react';
import {
  WholeWrapper,
  CommentBodyContainer,
  CommentModuleContainer,
  HistoryAvatar,
  HistoryAvatarImg,
  HistoryBackground,
  HistoryRowContainer,
  MyAvatar,
  TitleContainer,
} from './style';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { UserInfo } from '../../../types/domain/User';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';
import { Color } from '../../../constants/Color';
import { Comment } from '../../../types/domain/Comment';
import TextInput from '../../atoms/TextInput';
import { postComments } from '../../../api/CommentService';
import { useAuthProvider } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { useSWRConfig } from 'swr';
import { API_URL } from '../../../config';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

interface Props {
  comments: Comment[] | undefined;
  myInfo: UserInfo | undefined;
  artId: string;
}

interface ContentProps {
  comments: Comment[] | undefined;
}

const CommentsContents = ({ comments }: ContentProps) => {
  const navigate = useNavigate();

  return (
    <>
      {comments ? (
        comments.map((item) => (
          <HistoryRowContainer key={comments.indexOf(item)}>
            <HistoryAvatar
              onClick={() => navigate(`/userpage/${item.author.userId}`)}
            >
              {item.author.profileImage ? (
                <HistoryAvatarImg src={item.author.profileImage} />
              ) : (
                <HistoryAvatarImg
                  src={`${process.env.PUBLIC_URL}/default-icon.png`}
                />
              )}
            </HistoryAvatar>
            <CommentBodyContainer>
              <Text fontType={FontType.TITLE4} color={Color.GRAYSCALE64}>
                {item.author.displayname
                  ? item.author.displayname
                  : 'deleted user'}
              </Text>
              <Text fontType={FontType.TITLE3} color={Color.GRAYSCALE64}>
                {item.body}
              </Text>
              <Text fontType={FontType.TITLE4} color={Color.GRAYSCALE64}>
                {dayjs(item.createdAt).format('YYYY/MM/DD HH:mm')}
              </Text>
              <Text fontType={FontType.SUB} color={Color.GRAYSCALE64}></Text>
            </CommentBodyContainer>
          </HistoryRowContainer>
        ))
      ) : (
        <>
          <Text fontType={FontType.TITLE3}>現在コメントはありません。</Text>
        </>
      )}
    </>
  );
};

export const Comments: React.FC<Props> = ({ artId, comments, myInfo }) => {
  const [comment, setComment] = useState<string>();
  const { authData } = useAuthProvider();
  const { addAlert } = useAlertContext();
  const { mutate } = useSWRConfig();

  const commentSubmitHandler = () => {
    if (!myInfo?.userId || !comment || !authData.token) return;
    postComments({
      author: myInfo?.userId,
      artId: artId,
      content: comment,
      token: authData.token,
    })
      .then(() => addAlert('コメントが投稿されました', 'success'))
      .then(() => {
        setComment(undefined);
      })
      .then(() => mutate(API_URL + `/arts/${artId}/comments`))
      .catch(() => addAlert('コメント投稿に失敗しました', 'error'));
  };

  useEffect(() => {}, [comments]);

  return (
    <WholeWrapper>
      <TitleContainer>
        <Text fontType={FontType.TITLE2s}>コメント</Text>
      </TitleContainer>
      <HistoryBackground>
        <CommentsContents comments={comments} />
      </HistoryBackground>
      <CommentModuleContainer>
        {myInfo ? (
          <>
            <MyAvatar src={myInfo?.profileImage} />
            <TextInput setText={setComment} placeholder={'コメントする'} />
            <Button
              buttonType={ButtonType.IMPORTANT}
              onClick={commentSubmitHandler}
            >
              <Text fontType={FontType.TITLE4} color={Color.WHITETEXT}>
                投稿
              </Text>
            </Button>
          </>
        ) : (
          <>
            <Text fontType={FontType.TITLE3}>
              コメントするには、<a href={'/MailSignIn'}>ログイン</a>
              してください。
            </Text>
          </>
        )}
      </CommentModuleContainer>
    </WholeWrapper>
  );
};
