import styled from '@emotion/styled';
import tw from 'twin.macro';

export const GrayContainer = styled.div`
  ${tw`col-start-1 col-span-2 flex flex-col justify-center p-2 md:p-8 mb-20`}
  background-color: rgba(126, 134, 158, 0.08);
  border-radius: 10px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 100px;
  display: flex;
  align-items: flex-end;
`;

export const SubTitleContainer = styled.div`
  ${tw`flex flex-row justify-between items-center pb-2 mb-4`}
  border-bottom: 1px solid rgba(126, 134, 158, 0.24);
`;

export const SubTitle = styled.div`
  ${tw`text-[16px] font-bold`}
`;

export const WholeContainer = styled.div`
  ${tw`w-[94%] md:w-[90%] max-w-[1440px] mx-auto`}
`;

export const UpperContainer = styled.div`
  ${tw`flex flex-row flex-wrap gap-x-2 mb-4 mt-4`}
  align-items: center;
`;

export const SelectContainer = styled.div`
  ${tw`flex flex-col md:flex-row md:items-center gap-x-4 mb-4`}
`;

export const LowerContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  ${tw`text-center px-8 py-1 md:py-2 rounded-full font-bold text-[14px] md:text-[16px] text-white bg-[#E95098] border-none cursor-pointer hover:opacity-80 transition-all duration-300`}
`;

export const StyledInput = styled.input`
  ${tw`flex-grow w-full md:w-[80%] mb-2 md:mb-0 h-[36px] md:h-[48px] rounded-lg py-2 px-4 border-none text-[14px]`}
  background-color: rgba(126, 134, 158, 0.16);
  box-sizing: border-box;
  &:read-only::placeholder {
    color: black;
  }
`;
export const UpdateInput = styled.input`
  ${tw`flex-grow w-[60%] mb-0 md:mb-2 md:mb-0 h-[36px] md:h-[48px] rounded-lg py-2 px-4 border-none text-[14px]`}
  background-color: rgba(126, 134, 158, 0.16);
  box-sizing: border-box;
  &:read-only::placeholder {
    color: black;
  }
`;

export const InputContainer = styled.div`
  ${tw`md:flex justify-between items-center gap-4`}
`;

export const WordListContainer = styled.div`
  ${tw`md:grid md:grid-cols-2 gap-4`}
`;

export const WordContainer = styled.div`
  ${tw`md:flex flex-row gap-2 items-center mb-4 md:mb-2`}
`;

export const UpdateWordContainer = styled.div`
  ${tw`flex items-center gap-2 h-[36px] md:h-auto mb-2 md:mb-0 md:w-[60%]`}
`;

export const Word = styled.div`
  ${tw`text-[16px] md:text-[18px] font-bold w-[80%] md:w-auto h-[36px] md:h-auto flex items-center`}
`;

export const PendingWord = styled.div`
  ${tw`text-[16px] md:text-[18px] font-bold w-[20%] text-[#909090] w-[40%] md:w-auto`}
`;

export const ButtonContainer = styled.div`
  ${tw`flex flex-row justify-center items-center gap-4 md:block md:w-[40%]`}
`;

export const DeleteButton = styled.button`
  ${tw`text-center px-4 py-1 md:py-2 rounded-full font-bold text-[14px] md:text-[16px] text-[#E95098] bg-[#F6F6F6] w-[100%] md:w-auto block hover:bg-[#E95098] hover:text-[#F6F6F6] transition-all duration-300`}
  border: 2px solid #e95098;
`;
export const UpdateButton = styled.button`
  ${tw`text-center px-4 py-1 md:py-2 rounded-full font-bold text-[14px] md:text-[16px] text-[#1a1a1a] bg-[#F6F6F6] w-[100%] md:w-auto md:mb-2 block hover:bg-[#1a1a1a] hover:text-[#F6F6F6] transition-all duration-300`}
  border: 2px solid #1a1a1a;
`;

export const DeleteInvertButton = styled.button`
  ${tw`text-center px-4 py-1 md:py-2 rounded-full font-bold text-[14px] md:text-[16px] text-white bg-[#E95098] w-[100%] md:w-auto block hover:bg-[#F6F6F6] hover:text-[#E95098] hover:border-[#E95098] transition-all duration-300`}
  border: 2px solid #F6F6F6;
`;
export const UpdateInvertButton = styled.button`
  ${tw`text-center px-4 py-1 md:py-2 rounded-full font-bold text-[14px] md:text-[16px] text-white bg-[#656565] w-[100%] md:w-auto md:mb-2 block hover:bg-[#F6F6F6] hover:text-[#656565] hover:border-[#656565] transition-all duration-300`}
  border: 2px solid #F6F6F6;
`;
