import React, { useEffect, useState } from 'react';
import { MyPageProfile } from '../../organisms/MyPageProfile';
import {
  MyPageContainer,
  BulkTransferContainer,
  WithdrawButton,
  AmoutDetailContainer,
  DetailContainer,
  DetailTitle,
  DetailContent,
  DetailTitleSub,
  DetailContentSub,
  WithdrawableDetailAmountTitle,
  AmountContainer,
  NoteContainer,
  NoteTitle,
  NoteContent,
} from './style';
import { useParams } from 'react-router-dom';
import { fetchPurchaseHistory } from '../../../api/PurchaseService';
import { HistoryTable } from '../../organisms/HistoryTable';
import { ArtInfo } from '../../../types/domain/Art';
// import { makeStyles } from '@material-ui/core';
// import { TotalDisplay } from '../../organisms/TotalDisplay';
import { NotFound } from '../NotFound';
import { useUserPage } from './hooks';
import { CreateDipositUrlPopUp } from '../../CreateDepositUrlPopUp';
// import { getDepositUrl } from '../../../api/PurchaseService';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { Title } from '../../organisms/Title';
import dayjs from 'dayjs';

interface GmopgDepositLink {
  OrderId: string;
  DepositID: string;
  depositStatus: string;
}
export interface HistoryType {
  purchaseId: number;
  value: number;
  art: ArtInfo;
  buyer: any;
  seller: any;
  status: 'processing' | 'completed' | 'failed';
  createdAt: string;
  gmopgDepositLink: GmopgDepositLink;
  sellerDepositValue: number;
  royaltyDepositValue: number;
  transactionHash: string;
}

export const UserDepositPage: React.FC = () => {
  const { userId } = useParams();
  const { addAlert } = useAlertContext();
  const [saleHistory, setSaleHistory] = useState<HistoryType[]>([]);
  const [royaltyHistory, setRoyaltyHistory] = useState<HistoryType[]>([]);
  if (!userId) return <></>;
  const { userData, SFData, authContext } = useUserPage(userId);

  const saleTotal = saleHistory
    ?.filter(
      (item) =>
        !item.gmopgDepositLink.depositStatus ||
        item.gmopgDepositLink.depositStatus === 'WAITING',
    )
    .reduce((acc, cur) => {
      return acc + cur.sellerDepositValue;
    }, 0);

  const royaltyTotal = royaltyHistory
    ?.filter(
      (item) =>
        !item.gmopgDepositLink.depositStatus ||
        item.gmopgDepositLink.depositStatus === 'WAITING',
    )
    .reduce((acc, cur) => {
      return acc + cur.royaltyDepositValue;
    }, 0);

  const transferFee = saleTotal + royaltyTotal < 30000 ? 300 : 500;

  const withdrawableAmount: number = saleTotal + royaltyTotal - transferFee;

  const saleTotalString: string = saleTotal?.toLocaleString();
  const royaltyTotalString: string = royaltyTotal?.toLocaleString();
  const withdrawableAmountString: string = withdrawableAmount.toLocaleString();
  const totalFeeString: string = transferFee.toLocaleString();

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isAll, setIsAll] = useState<string>('');

  useEffect(() => {
    fetchPurchaseHistory(userId, authContext.authData.token as string).then(
      async (res) => {
        if (res.ok) {
          const json = await res.json();
          setSaleHistory(json.sellHistory);
          setRoyaltyHistory(json.royaltyHistory);
        }
      },
    );
  }, []);

  const saleHistoryDate = saleHistory?.map((history) => history.createdAt);
  const royaltyHistoryDate = royaltyHistory?.map(
    (history) => history.createdAt,
  );

  let oldestSaleHistoryDate: Date | undefined;
  let oldestRoyaltyHistoryDate: Date | undefined;

  if (saleHistoryDate && saleHistoryDate.length > 0) {
    oldestSaleHistoryDate = saleHistoryDate
      .map((dateStr) => new Date(dateStr))
      .reduce((a: Date, b: Date) => (a < b ? a : b));
  }
  if (royaltyHistoryDate && royaltyHistoryDate.length > 0) {
    oldestRoyaltyHistoryDate = royaltyHistoryDate
      .map((dateStr) => new Date(dateStr))
      .reduce((a: Date, b: Date) => (a < b ? a : b));
  }

  let oldestHistoryDate: Date | undefined;
  if (oldestSaleHistoryDate && oldestRoyaltyHistoryDate) {
    oldestHistoryDate =
      oldestSaleHistoryDate < oldestRoyaltyHistoryDate
        ? oldestSaleHistoryDate
        : oldestRoyaltyHistoryDate;
  }

  let deadline: string | undefined;
  if (oldestHistoryDate) {
    deadline = dayjs(oldestHistoryDate).add(120, 'day').format('YYYY/MM/DD');
  }

  const handleClick = () => {
    if (saleTotal + royaltyTotal <= 300 || isNaN(saleTotal + royaltyTotal)) {
      addAlert('振込可能額が300円以下の場合は出金できません', 'error');
    } else {
      setIsPopUpOpen(true);
      setIsAll('all');
    }
  };

  if (userId != authContext.authData.userId) return <NotFound />;

  return (
    <MyPageContainer>
      <CreateDipositUrlPopUp
        close={() => setIsPopUpOpen(false)}
        isDisplayed={isPopUpOpen}
        depositType={isAll}
      />
      <MyPageProfile
        displayname={userData?.displayname}
        pfpUri={userData?.profileImage}
        headerUri={userData?.headerImage}
        username={userData?.username}
        bio={userData?.normalBio}
        userRole={userData?.role}
        userId={userId}
        isPortfolio={false}
        SFData={SFData}
      />
      <div style={{ width: '90%', margin: 'auto' }}>
        <Title title="一括振込申請" subTitle="Bulk Transfer Application" />
      </div>
      <BulkTransferContainer>
        <AmountContainer>
          <AmoutDetailContainer>
            <WithdrawableDetailAmountTitle>
              振込可能額詳細
            </WithdrawableDetailAmountTitle>
            <DetailContainer>
              <DetailTitle>作品販売利益：</DetailTitle>
              {!saleTotal ? (
                <DetailContent>0 円</DetailContent>
              ) : (
                <DetailContent>{saleTotalString}円</DetailContent>
              )}
            </DetailContainer>
            <DetailContainer>
              <DetailTitle>ロイヤリティ：</DetailTitle>
              {!royaltyTotal ? (
                <DetailContent>0 円</DetailContent>
              ) : (
                <DetailContent>{royaltyTotalString}円</DetailContent>
              )}
            </DetailContainer>
            <DetailContainer>
              <DetailTitle>振込手数料：</DetailTitle>
              {saleTotal + royaltyTotal == 0 || !withdrawableAmount ? (
                <DetailContent>- 円</DetailContent>
              ) : (
                <DetailContent>-{totalFeeString}円</DetailContent>
              )}
            </DetailContainer>
            <hr />
            <DetailContainer>
              <DetailTitle>振込可能額合計：</DetailTitle>
              <DetailContent>
                {withdrawableAmount < 0 || !withdrawableAmount
                  ? '- 円'
                  : withdrawableAmountString + '円'}
              </DetailContent>
            </DetailContainer>
            <DetailContainer>
              <DetailTitleSub>振込申請期限：</DetailTitleSub>
              {deadline ? (
                <DetailContentSub>
                  {deadline}
                  <br /> （※最も早い振込申請期限）
                </DetailContentSub>
              ) : (
                <DetailContentSub>-</DetailContentSub>
              )}
            </DetailContainer>
          </AmoutDetailContainer>
        </AmountContainer>
        <WithdrawButton onClick={handleClick}>
          一括振込申請をする
        </WithdrawButton>
        <NoteContainer>
          <NoteTitle>ご注意事項（※必ずお読みください）</NoteTitle>
          <NoteContent>
            ※振込期限は作品が購入されてから120日間です。詳細は
            <a href="/TermsOfUse">利用規約</a>をご確認ください。
          </NoteContent>
          <NoteContent>
            ※各作品の振込申請可能な期間が過ぎるとその作品の振込申請はできません。
          </NoteContent>
          <NoteContent>
            ※一括振込は作品販売利益合計とロイヤリティ合計が300円以下の場合は申請できません。
          </NoteContent>
          <NoteContent>
            ※振込手数料は作品販売利益とロイヤリティの合計が301円〜29,999円の場合は
            <span style={{ fontWeight: '700' }}>300円</span>、
            30,000円以上の場合は
            <span style={{ fontWeight: '700' }}>500円</span>です。
          </NoteContent>
        </NoteContainer>
      </BulkTransferContainer>
      <HistoryTable history={saleHistory} type="deposit" />
      <HistoryTable history={royaltyHistory} type="royalty" />
    </MyPageContainer>
  );
};
