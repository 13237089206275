import React, { useState } from 'react';
import {
  StyledButton,
  IconContainer,
  TextContainer,
  ButtonContainer,
} from './style';

export const CopyButton = () => {
  const [isCopied, setIsCopied] = useState(false);

  const isBrowser = typeof window !== 'undefined';
  const urlCopyHandler = async (url: string) => {
    if (!isBrowser) return;

    try {
      await navigator.clipboard.writeText(url);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } catch {
      console.error('');
    }
  };

  return (
    <ButtonContainer>
      <StyledButton onClick={() => urlCopyHandler(location.href)}>
        {isCopied ? (
          <>
            <>
              <IconContainer src={`${process.env.PUBLIC_URL}/copied.png`} />
              <TextContainer>
                <p style={{ color: '#E95098' }}>コピーしました！</p>
              </TextContainer>
            </>
          </>
        ) : (
          <>
            <IconContainer src={`${process.env.PUBLIC_URL}/copy.png`} />
            <TextContainer>
              <p>リンクをコピー</p>
            </TextContainer>
          </>
        )}
      </StyledButton>
    </ButtonContainer>
  );
};
