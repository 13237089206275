import React from 'react';

import {
  CharacterContainer,
  CharacterImage,
  CharacterName,
  DialogContainer,
  DialogText,
  DialogWrapper,
  ImageContainer,
  ImageWrapper,
  ManualPageTitleText,
  ManualPageTitleWrapper,
  NftManualPageWrapper,
  ImageSpContainer,
} from './style';

import { characters, dialogs1, dialogs2, dialogs3 } from './constant';
import { CharacterProps, DialogProps } from './constant';

type MaualPageTitleProps = {
  title: string;
};

const ManualPageTitle: React.FC<MaualPageTitleProps> = ({ title }) => {
  return (
    <ManualPageTitleWrapper>
      <ManualPageTitleText>{title}</ManualPageTitleText>
    </ManualPageTitleWrapper>
  );
};

const Character: React.FC<CharacterProps> = ({ name, image, borderColor }) => {
  return (
    <CharacterContainer>
      <CharacterImage
        src={`${process.env.PUBLIC_URL}/${image}`}
        borderColor={borderColor}
      />
      <CharacterName>
        <ruby>{name}</ruby>
      </CharacterName>
    </CharacterContainer>
  );
};

const Dialog: React.FC<{
  character: CharacterProps;
  dialog?: string;
  url?: string;
}> = ({ character, dialog, url }) => {
  return (
    <DialogWrapper>
      <Character {...character} />
      <DialogContainer borderColor={character.borderColor}>
        <DialogText>
          {dialog}
          {url && (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          )}
        </DialogText>
      </DialogContainer>
    </DialogWrapper>
  );
};

export const NftManualPage = () => {
  return (
    <NftManualPageWrapper>
      <ManualPageTitle title="1. NFTとは？" />
      {dialogs1.map((dialog1: DialogProps, index: number) => {
        const character = characters.find(
          (char) => char.name === dialog1.speaker,
        );

        if (character && dialog1.type === 'text') {
          return (
            <Dialog key={index} character={character} dialog={dialog1.text} />
          );
        }
        if (!character && dialog1.type === 'image') {
          return (
            <ImageWrapper key={index}>
              <ImageContainer
                src={`${process.env.PUBLIC_URL}/${dialog1.url}`}
              />
            </ImageWrapper>
          );
        }
      })}
      <ManualPageTitle title="2. ロイヤリティとは？" />
      {dialogs2.map((dialog2: DialogProps, index: number) => {
        const character = characters.find(
          (char) => char.name === dialog2.speaker,
        );
        if (character && dialog2.type === 'text') {
          return (
            <Dialog key={index} character={character} dialog={dialog2.text} />
          );
        }
        if (!character && dialog2.type === 'image') {
          return (
            <ImageWrapper key={index}>
              <ImageContainer
                src={`${process.env.PUBLIC_URL}/${dialog2.url}`}
              />
            </ImageWrapper>
          );
        }
      })}
      <ManualPageTitle title="3. NFTの登録方法" />
      {dialogs3.map((dialog3: DialogProps, index: number) => {
        const character = characters.find(
          (char) => char.name === dialog3.speaker,
        );
        if (character && dialog3.type === 'text') {
          return (
            <Dialog
              key={index}
              character={character}
              dialog={dialog3.text}
              url={dialog3.url && dialog3.url}
            />
          );
        }
        if (!character && dialog3.type === 'image') {
          return (
            <ImageWrapper key={index}>
              {dialog3.url === 'manualImg/3-4.png' ||
              dialog3.url == 'manualImg/3-5.png' ? (
                <ImageSpContainer
                  src={`${process.env.PUBLIC_URL}/${dialog3.url}`}
                />
              ) : (
                <ImageContainer
                  src={`${process.env.PUBLIC_URL}/${dialog3.url}`}
                />
              )}
            </ImageWrapper>
          );
        }
      })}
    </NftManualPageWrapper>
  );
};
