import React, { useContext, useState } from 'react';
import { Color } from '../../../constants/Color';
import { Tag } from '../../atoms/Tag';
import { Text } from '../../atoms/Text';
import { HomeContainer, LoadingButtonContainer } from './style';
import { useRequests } from './hooks';
import SpacerY from '../../atoms/Spacer/SpacerY';
import { RequestedArt } from '../../organisms/RequestedArt';
import { RequestData } from '../../../api/AdminService';
import { Title } from '../../organisms/Title';
import { NotFound } from '../NotFound';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
import {
  FormControl,
  InputLabel,
  Select,
  // CircularProgress,
  Box,
  // TablePagination,
} from '@material-ui/core';
import { ButtonType } from '../../../constants/Button';
import { FontType } from '../../../constants/Fonts';
import {
  Partition,
  Search,
  StyledInput,
  SearchIconWrapper,
} from '../../atoms/SearchBar/style';
import {
  GrayContainer,
  UpperContainer,
  SelectContainer,
  LowerContainer,
  ButtonContainer,
} from '../AdminUser/style';
import { useAdminUsers } from '../AdminUser/hooks';
import { Button } from '../../atoms/Button';
import SearchIcon from '@material-ui/icons/Search';
// import { RequestPaginationWrapper } from '../../organisms/Notifications/style';
import { useEffect } from 'react';
import queryString from 'query-string';

export const RequestsPage: React.FC = () => {
  const authContext: AuthContextType = useContext(AuthContext);
  const { schools, faculties, departments } = useAdminUsers();
  const [selectedTag, setSelectedTag] = useState('pending');
  const [searchWord, setSearchWord] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [school, setSchool] = useState('');
  const [faculty, setFacukty] = useState('');
  const [department, setDepartment] = useState('');
  const [type, setType] = useState('');

  const handleSchoolChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      setSchool(event.target.value as string);
    } else {
      setSchool('');
    }
  };

  const handleFacultyChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    if (event.target.value) {
      setFacukty(event.target.value as string);
    } else {
      setFacukty('');
    }
  };

  const handleDepartmentChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    if (event.target.value) {
      setDepartment(event.target.value as string);
    } else {
      setDepartment('');
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      setType(event.target.value as string);
    } else {
      setType('');
    }
  };

  function handleSearch() {
    setQuery('q=' + searchWord.replace(/\s/g, '+'));
  }

  const { requestsData } = useRequests(
    authContext.authData.token as string,
    selectedTag,
    query +
      `${school ? `&school=${school}` : ''}` +
      `${faculty ? `&faculty=${faculty}` : ''}` +
      `${department ? `&department=${department}` : ''}` +
      `${type ? `&type=${type}` : ''}`,
  );

  const handleSearchWithTag = (tag: string) => {
    setSelectedTag(tag);
  };
  useEffect(() => {
    const values = queryString.parse(window.location.search);
    const queryParam = values.school;
    setSchool(queryParam as string);
  }, []);

  if (authContext.authData.role !== 'admin') return <NotFound />;

  return (
    <HomeContainer>
      <SpacerY height={60} />
      <Title title="申請待ち一覧" subTitle="List of waiting applications" />
      <GrayContainer>
        <Text fontType={FontType.TITLE3} color={Color.BRANDMAIN}>
          ステータス
        </Text>
        <UpperContainer>
          <Tag
            title="申請待ち"
            key={`pending`}
            func={() => handleSearchWithTag(`pending`)}
            color={selectedTag === `pending` ? Color.WHITETEXT : Color.BRAND20}
            bgColor={
              selectedTag === `pending` ? Color.BRANDMAIN : Color.BRAND40
            }
          />
          <Tag
            title="承認済み"
            key={`accepted`}
            func={() => handleSearchWithTag(`accepted`)}
            color={selectedTag === `accepted` ? Color.WHITETEXT : Color.BRAND20}
            bgColor={
              selectedTag === `accepted` ? Color.BRANDMAIN : Color.BRAND40
            }
          />
          <Tag
            title="拒否済み"
            key={`rejected`}
            func={() => handleSearchWithTag(`rejected`)}
            color={selectedTag === `rejected` ? Color.WHITETEXT : Color.BRAND20}
            bgColor={
              selectedTag === `rejected` ? Color.BRANDMAIN : Color.BRAND40
            }
          />
        </UpperContainer>
        <SpacerY height={24} />
        <Text fontType={FontType.TITLE3} color={Color.BRANDMAIN}>
          ユーザー検索
        </Text>
        <UpperContainer>
          <Search active>
            <StyledInput
              placeholder="ユーザーを探す（ユーザ名・表示名・氏名）"
              onChange={(event) => setSearchWord(event.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  e.preventDefault();
                  handleSearch();
                }
              }}
            />
            <Partition />
            <SearchIconWrapper onClick={handleSearch}>
              <SearchIcon style={{ color: 'white' }} />
            </SearchIconWrapper>
          </Search>
        </UpperContainer>
        <SpacerY height={24} />

        <Text fontType={FontType.TITLE3} color={Color.BRANDMAIN}>
          フィルター
        </Text>
        <SelectContainer>
          <FormControl>
            <InputLabel htmlFor="outlined-age-native-simple">
              作品タイプ
            </InputLabel>
            <Select
              native
              value={type}
              onChange={handleTypeChange}
              label="作品タイプ"
              inputProps={{
                name: 'type',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option key="mint" value="Mint">
                NFT
              </option>
              <option key="portfolio" value="Portfolio">
                ポートフォリオ
              </option>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel shrink={!!school} htmlFor="outlined-age-native-simple">
              学校
            </InputLabel>
            <Select
              native
              value={school}
              onChange={handleSchoolChange}
              label="学校"
              inputProps={{
                name: 'school',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {schools &&
                schools.map((schoolValue: string) => {
                  return (
                    <option key={schoolValue} value={schoolValue}>
                      {schoolValue}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel shrink={!!faculty} htmlFor="outlined-age-native-simple">
              学部
            </InputLabel>
            <Select
              native
              value={faculty}
              onChange={handleFacultyChange}
              label="学部"
              inputProps={{
                name: 'faculty',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {faculties &&
                faculties.map((facultyValue: string) => {
                  return (
                    <option key={facultyValue} value={facultyValue}>
                      {facultyValue}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel
              shrink={!!department}
              htmlFor="outlined-age-native-simple"
            >
              学科
            </InputLabel>
            <Select
              native
              value={department}
              onChange={handleDepartmentChange}
              label="学科"
              inputProps={{
                name: 'department',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {departments &&
                departments.map((departmentValue: string) => {
                  return (
                    <option key={departmentValue} value={departmentValue}>
                      {departmentValue}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </SelectContainer>
        <LowerContainer>
          <ButtonContainer>
            <Button
              onClick={handleSearch}
              buttonType={ButtonType.ROUNDEDIMPORTANT}
            >
              検索
            </Button>
          </ButtonContainer>
        </LowerContainer>
      </GrayContainer>

      {(() => {
        if (
          selectedTag === `pending` &&
          (!requestsData.requestsDataArray ||
            !requestsData.requestsDataArray.length)
        ) {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              margin="2rem"
              minHeight="300px"
            >
              <SpacerY height={16} />
              <SearchIcon style={{ fontSize: '50px' }} color="disabled" />
              <Text color={Color.BORDER20}>
                表示する申請待ちの作品がありません
              </Text>
              <SpacerY height={12} />
            </Box>
          );
        } else if (
          selectedTag === `accepted` &&
          (!requestsData.requestsDataArray ||
            !requestsData.requestsDataArray.length)
        ) {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              margin="2rem"
              minHeight="300px"
            >
              <SpacerY height={16} />
              <SearchIcon style={{ fontSize: '50px' }} color="disabled" />
              <Text color={Color.BORDER20}>
                表示する承認済みの作品がありません
              </Text>
              <SpacerY height={12} />
            </Box>
          );
        } else if (
          selectedTag === `rejected` &&
          (!requestsData.requestsDataArray ||
            !requestsData.requestsDataArray.length)
        ) {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              margin="2rem"
              minHeight="300px"
            >
              <SpacerY height={16} />
              <SearchIcon style={{ fontSize: '50px' }} color="disabled" />
              <Text color={Color.BORDER20}>
                表示する拒否済みの作品がありません
              </Text>
              <SpacerY height={12} />
            </Box>
          );
        } else {
          return (
            <>
              {requestsData.requestsDataArray &&
                requestsData.requestsDataArray.map(
                  (r: RequestData, index: number) => {
                    return (
                      <RequestedArt
                        key={index}
                        artDetail={r.art}
                        request={r.request}
                        user={r.user}
                        status={selectedTag}
                        reload={requestsData.reload}
                        value={r.art.value}
                      />
                    );
                  },
                )}
              {!requestsData.isLast &&
                requestsData.size * 5 <= requestsData.total - 5 && (
                  <LoadingButtonContainer>
                    <Button
                      buttonType={ButtonType.CIRCLEWIDE}
                      onClick={requestsData.loadMore}
                    >
                      もっと見る
                    </Button>
                  </LoadingButtonContainer>
                )}
            </>
          );
        }
      })()}
    </HomeContainer>
  );
};
