import { getArts } from '../../../api/ArtsService';
// import { getDepositUrl } from '../../../api/PurchaseService';
import { getSFUserInfo, getUser } from '../../../api/UsersService';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';

export const useUserPage = (userId: string) => {
  const authContext: AuthContextType = useAuthProvider();
  const { userData } = getUser(userId);
  const { SFData } = getSFUserInfo(
    userData?.role as string,
    userData?.studentNo as string,
    authContext.authData.token as string,
  );

  const createdItemsData = getArts(`createdBy=${userId}`);
  const myItemsData = getArts(`ownedBy=${userId}`);

  return { userData, SFData, myItemsData, createdItemsData, authContext };
};
