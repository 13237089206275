/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiPlusSquare } from 'react-icons/fi';
import { Box } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

import {
  ContestManagementWrapper,
  TitleWrapper,
  CreateEventBanner,
  CreateEventBannerItemContainer,
  BannerContainer,
  BannerItemWrapper,
  BannerItemContainer,
  CurrentBanner,
  SuspendedBanner,
  NipBannerContainer,
  NipBanner,
} from './style';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';

import { Title } from '../../organisms/Title';

import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { ButtonType } from '../../../constants/Button';

import { getContestItems } from '../../../api/ArtsService';

interface EventProps {
  id?: number;
  name: string;
  start_date: number;
  end_date: number;
  is_held?: boolean;
  is_deleted?: boolean;
  tags: string[];
  is_current?: boolean;
  is_scheduled?: boolean;
}

export const ContestManagement = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<EventProps[]>([]);
  const [currentDate, setCurrentDate] = useState<number>(
    Math.floor(Date.now() / 1000),
  );

  useEffect(() => {
    const fetchContestItems = async () => {
      try {
        const contestData = await getContestItems();
        const newEvents = contestData.map((event: any) => ({
          ...event,

          //開催期間内
          is_current:
            currentDate >= event.start_date && currentDate <= event.end_date,
          //開催期間前
          is_scheduled: currentDate < event.start_date,
        }));

        //開催中を最優先表示、かつ開催期間内のものを優先表示、かつ開催期間外のものは開催予定のものを優先して表示
        newEvents.sort((a: any, b: any) => {
          if (a.is_held !== b.is_held) {
            return b.is_held ? 1 : -1;
          }
          if (a.is_current !== b.is_current) {
            return b.is_current ? 1 : -1;
          }
          if (!a.is_current && a.is_scheduled !== b.is_scheduled) {
            return b.is_scheduled ? 1 : -1;
          }
          return 0;
        });

        setEvents(newEvents);
      } catch (error) {
        console.error('コンテストデータの取得に失敗しました', error);
      }
    };

    fetchContestItems();
  }, [currentDate]);

  useEffect(() => {
    setInterval(
      () => {
        const newDate = Math.floor(Date.now() / 1000);
        setCurrentDate(newDate);
      },
      //1分ごとに更新
      60000,
    );
  }, []);

  return (
    <ContestManagementWrapper>
      <TitleWrapper>
        <Box style={{ marginTop: '2rem' }}>
          <IconButton
            onClick={() => navigate(-1)}
            color="secondary"
            aria-label="back"
            style={{ padding: '6px', marginRight: '48px' }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
        <Title title="イラストコンテスト管理" subTitle="Contest Management" />
      </TitleWrapper>
      <CreateEventBanner onClick={() => navigate('/eventedit')}>
        <CreateEventBannerItemContainer>
          <Text fontType={FontType.TITLE2} color={Color.MAIN}>
            新規作成
          </Text>
          <FiPlusSquare size={32} color={Color.MAIN} />
        </CreateEventBannerItemContainer>
      </CreateEventBanner>
      {events.map((event, id) => (
        <BannerContainer key={id}>
          {event.is_current && event.is_held && (
            <CurrentBanner
              onClick={() => navigate(`/eventedit?id=${event.id}`)}
            >
              <BannerItemWrapper>
                <Text fontType={FontType.TITLE2s} color={Color.BRAND_NEW_PINK}>
                  開催中
                </Text>
                <BannerItemContainer>
                  <Text fontType={FontType.TITLE2} color={Color.MAIN}>
                    {event.name}
                  </Text>
                  <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
                    {moment.unix(event.start_date).format('YYYY/MM/DD HH:mm')} ~{' '}
                    {moment.unix(event.end_date).format('YYYY/MM/DD HH:mm')}
                  </Text>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/contestreview?id=${event.id}`);
                    }}
                    buttonType={ButtonType.IMPORTANT}
                  >
                    <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
                      審査用画面へ
                    </Text>
                  </Button>
                </BannerItemContainer>
              </BannerItemWrapper>
            </CurrentBanner>
          )}
          {event.is_current && !event.is_held && (
            <SuspendedBanner
              onClick={() => navigate(`/eventedit?id=${event.id}`)}
            >
              <BannerItemWrapper>
                <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
                  停止中
                </Text>
                <BannerItemContainer>
                  <Text fontType={FontType.TITLE2} color={Color.MAIN}>
                    {event.name}
                  </Text>
                  <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
                    {moment.unix(event.start_date).format('YYYY/MM/DD HH:mm')} ~{' '}
                    {moment.unix(event.end_date).format('YYYY/MM/DD HH:mm')}
                  </Text>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/contestreview?id=${event.id}`);
                    }}
                    buttonType={ButtonType.IMPORTANT}
                  >
                    <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
                      審査用画面へ
                    </Text>
                  </Button>
                </BannerItemContainer>
              </BannerItemWrapper>
            </SuspendedBanner>
          )}
          {!event.is_current && (
            <NipBannerContainer>
              {event.is_scheduled && (
                <NipBanner
                  onClick={() => navigate(`/eventedit?id=${event.id}`)}
                >
                  <BannerItemWrapper>
                    <Text
                      fontType={FontType.TITLE2s}
                      color={Color.BRAND_NEW_BLUE}
                    >
                      開催予定
                    </Text>
                    <BannerItemContainer>
                      <Text fontType={FontType.TITLE2} color={Color.MAIN}>
                        {event.name}
                      </Text>
                      <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
                        {moment
                          .unix(event.start_date)
                          .format('YYYY/MM/DD HH:mm')}{' '}
                        ~{' '}
                        {moment.unix(event.end_date).format('YYYY/MM/DD HH:mm')}
                      </Text>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/contestreview?id=${event.id}`);
                        }}
                        buttonType={ButtonType.IMPORTANT}
                      >
                        <Text
                          fontType={FontType.TITLE3}
                          color={Color.WHITETEXT}
                        >
                          審査用画面へ
                        </Text>
                      </Button>
                    </BannerItemContainer>
                  </BannerItemWrapper>
                </NipBanner>
              )}
              {!event.is_scheduled && (
                <NipBanner
                  onClick={() => navigate(`/eventedit?id=${event.id}`)}
                >
                  <BannerItemWrapper>
                    <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
                      終了
                    </Text>
                    <BannerItemContainer>
                      <Text fontType={FontType.TITLE2} color={Color.MAIN}>
                        {event.name}
                      </Text>
                      <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
                        {moment
                          .unix(event.start_date)
                          .format('YYYY/MM/DD HH:mm')}{' '}
                        ~{' '}
                        {moment.unix(event.end_date).format('YYYY/MM/DD HH:mm')}
                      </Text>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/contestreview?id=${event.id}`);
                        }}
                        buttonType={ButtonType.IMPORTANT}
                      >
                        <Text
                          fontType={FontType.TITLE3}
                          color={Color.WHITETEXT}
                        >
                          審査用画面へ
                        </Text>
                      </Button>
                    </BannerItemContainer>
                  </BannerItemWrapper>
                </NipBanner>
              )}
            </NipBannerContainer>
          )}
        </BannerContainer>
      ))}
    </ContestManagementWrapper>
  );
};
