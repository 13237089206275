import 'react-image-crop/dist/ReactCrop.css';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from 'react';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop,
} from 'react-image-crop';

import { canvasPreview } from './CanvasPreview';
import { FlexBox, CropperBox } from './styled';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  aspect: { height: number; width: number };
  src: string;
  previewCanvasRef: React.RefObject<HTMLCanvasElement>;
  setCroppedFile: (value: File) => void;
};

const ImageCropper: VoidFunctionComponent<Props> = ({
  open,
  setOpen,
  aspect,
  src,
  previewCanvasRef,
  setCroppedFile,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const handleChange = useCallback((_: PixelCrop, percentCrop: PercentCrop) => {
    setCrop(percentCrop);
  }, []);

  const handleComplete = useCallback((c: PixelCrop) => {
    setCompletedCrop(c);
  }, []);

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect.width / aspect.height));
    },
    [aspect],
  );

  const handleConfirm = useCallback(() => {
    previewCanvasRef.current?.toBlob((blob) => {
      if (blob) {
        setCroppedFile(
          new File([blob], 'croppedImage.png', {
            type: 'image/png',
          }),
        );
      }
    });
    setOpen(false);
  }, [previewCanvasRef, setCroppedFile, setOpen]);

  useEffect(() => {
    if (imgRef.current && previewCanvasRef?.current && completedCrop) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        1,
        0,
      );
    }
  }, [completedCrop]);

  return (
    <>
      {open && (
        <FlexBox>
          <CropperBox>
            <ReactCrop
              crop={crop}
              onChange={handleChange}
              onComplete={handleComplete}
              aspect={aspect.width / aspect.height}
            >
              <img
                ref={imgRef}
                alt="cropped-img"
                src={src}
                onLoad={handleImageLoad}
                style={{ maxHeight: '200px' }}
                crossOrigin="anonymous"
              />
            </ReactCrop>
          </CropperBox>
          <button onClick={handleConfirm}>確定</button>
        </FlexBox>
      )}
    </>
  );
};

export default ImageCropper;
