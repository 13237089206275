import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';

export const ArtDeliveryContainer = styled.div`
  ${tw`flex flex-col sm:p-20 mx-1 sm:mx-40 flex-wrap items-center justify-center`}
`;

export const TextContainer = styled.div`
  ${tw`md:flex gap-1 items-center justify-start mt-2 mb-2 w-full max-w-[1440px]`}
`;

export const WideButtonContainer = styled.div`
  ${tw`flex flex-row my-4 w-full max-w-[1440px]`}
`;

export const DeliveryFeeContainer = styled.div`
  ${tw`flex mb-4 bg-white px-8 py-4 rounded-lg w-full max-w-[1374px]`}
  border: 1px solid ${Color.BORDER10};
`;

export const DeliveryFeeWrapper = styled.div`
  ${tw`flex flex-col justify-start gap-2 mt-8 w-full max-w-[1440px]`}
`;

export const TitleContainer = styled.div`
  ${tw`flex items-start mb-2 w-full max-w-[1440px]`}
`;
