import React from 'react';
import { useUserPage } from './hooks';
import { ItemShowCase } from '../../organisms/ItemShowCase';
import { MyPageProfile } from '../../organisms/MyPageProfile';
import { MyPageContainer } from './style';
import { useParams } from 'react-router-dom';
import { NotFound } from '../NotFound';

export const UserPage: React.FC = () => {
  const { userId } = useParams();
  if (!userId) return <NotFound />;
  const { createdItemsData, userData, myItemsData, SFData } =
    useUserPage(userId);

  return (
    <MyPageContainer>
      <MyPageProfile
        displayname={userData?.displayname}
        pfpUri={userData?.profileImage}
        headerUri={userData?.headerImage}
        username={userData?.username}
        bio={userData?.normalBio}
        userRole={userData?.role}
        userId={userId}
        isPortfolio={false}
        SFData={SFData}
      />
      <ItemShowCase
        items={createdItemsData.artsData}
        title="作成した作品一覧"
        subTitle="List of created works"
        buttonAction={createdItemsData.loadMore}
        type="created"
        total={createdItemsData.total}
        isLast={createdItemsData.isLast}
        kind="create"
      />
      <ItemShowCase
        items={myItemsData.artsData}
        title="所有している作品一覧"
        subTitle="List of works I have"
        buttonAction={myItemsData.loadMore}
        type="owned"
        total={myItemsData.total}
        isLast={myItemsData.isLast}
        kind="own"
      />
    </MyPageContainer>
  );
};
