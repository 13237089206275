import styled from '@emotion/styled';
import tw from 'twin.macro';

export const PageWrapper = styled.div`
  ${tw`w-full flex flex-col justify-center items-center`}
`;

export const RequestPaginationWrapper = styled.div`
  ${tw`w-full p-3 flex flex-row justify-end`}
`;

export const PaginationWrapper = styled.div`
  ${tw`w-full bg-[#f5f5f8] flex flex-row justify-end px-3`}
`;

export const NotificationWrapper = styled.div`
  ${tw`w-full bg-[#f5f5f8] flex flex-col sm:flex-row px-3 py-2`}
`;
export const NotificationTitle = styled.div`
  ${tw`w-[20%] bg-[#f5f5f8] flex flex-row`}
`;
export const NotificationDescription = styled.div`
  overflow-wrap: anywhere;
  ${tw`flex flex-row w-full sm:w-2/3 bg-[#f5f5f8] cursor-pointer hover:underline transition-all duration-300`};
`;
export const NotificationDescriptionArtIdNull = styled.div`
  overflow-wrap: anywhere;
  ${tw`flex flex-row w-full sm:w-2/3 bg-[#f5f5f8]`};
`;
export const NotificationData = styled.div`
  ${tw`flex w-full sm:w-1/6 justify-end bg-[#f5f5f8]`}
`;

export const TabsWrapper = styled.div`
  ${tw`flex flex-col justify-center`}
`;

export const Divider = styled.div`
  height: 1px;
`;

export const Description = styled.p`
  ${tw`font-bold text-xs md:text-base my-1`}
`;

export const UserProfileImageWrapper = styled.div`
  ${tw`flex justify-center items-center cursor-pointer gap-2`}
`;
