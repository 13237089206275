import styled from '@emotion/styled';
import tw from 'twin.macro';

export const SettignContainer = styled.div`
  ${tw`max-w-[1440px] mx-auto w-[95%]`}
`;

export const StyledWrapper = styled.div`
  ${tw`mb-8`}
`;

export const StyledContainer = styled.div`
  ${tw`mb-2`}
`;

export const StyledTextAreaContainer = styled.div`
  width: 100%;
  height: 250px;
`;

export const StyledSmallContainer = styled.div`
  ${tw`flex justify-between items-center`}
`;

export const ChangeButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const SubContainer = styled.div`
  display: flex;
  width: 40%;
  height: 50px;
  justify-content: center;
  align-items: center;
`;
export const MyPageHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  aspect-ratio: 15 / 4;
`;

export const HeaderInput = styled.input`
  cursor: pointer;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
`;

export const PFPInput = styled.input`
  cursor: pointer;
  position: absolute;
  display: flex;
  ${tw`h-48 w-48 rounded-full`}
  z-index: 5;
  opacity: 0;
`;

export const HeaderInputContainer = styled.div`
  height: 100%;
  aspect-ratio: 15 / 4;
`;

export const MyPageHeaderImg = styled.img`
  ${tw`w-full rounded-2xl`}
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: contain;
`;

export const MyPagePFPBase = styled.div`
  ${tw`flex`}
  margin-bottom: 24px;
`;

export const MyPagePFPContainer = styled.div`
  margin: -6rem auto 0;
  ${tw`p-2 bg-white flex rounded-full w-20 sm:w-48`}
  z-index: 3;
`;

export const MyPagePFP = styled.img`
  ${tw`rounded-full w-20 sm:w-48`}
  z-index: 4;
  object-fit: cover;
`;

export const TopPartContainer = styled.div`
  padding-top: 5vw;
`;

export const MiddlePartContainer = styled.div`
  ${tw`mx-auto w-[90%] md:w-[60%] mb-8`}
`;

export const FullOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  z-index: 65535;
`;

export const HeaderOverlay = styled.div`
  ${tw`flex justify-center items-center rounded-2xl`}
  position: absolute;
  width: 100%;
  aspect-ratio: 15 / 4;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const PFPoverlay = styled.div`
  ${tw`flex justify-center items-center rounded-full w-20 sm:w-48 h-20 sm:h-48`}
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  z-index: 6;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  cursor: pointer;
`;

export const TextButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  margin: 2% 0% 2% 0%;
  cursor: pointer;
`;

export const WalletContainer = styled.button`
  background: transparent;
  border: none;
`;
