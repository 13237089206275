import { useParams } from 'react-router-dom';
import { ArtDetail } from '../../organisms/ArtDetail';
import React from 'react';
import { useItems } from './hooks';

export const Items: React.FC = () => {
  const { itemId } = useParams();
  const { artData, currentUser, comments, reload } = useItems(itemId);
  return (
    <>
      <ArtDetail
        artDetail={artData}
        currentUser={currentUser}
        comments={comments}
        reload={reload}
      />
    </>
  );
};
