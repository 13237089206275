import React, { useEffect } from 'react';
import { StyledInput } from './styled';

type Props = {
  setNum: (num: number) => void;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isAvailable?: boolean;
  disabled?: boolean;
  defaultValue?: number | undefined;
  type?: string;
  step?: number;
};

export default function NumberInput({
  disabled,
  onKeyDown,
  placeholder,
  setNum,
  defaultValue,
}: Props): JSX.Element {
  const ref = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
    };

    input?.addEventListener('wheel', handleWheel);
    return () => {
      input?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setNum(value);
  };
  return (
    <>
      <StyledInput
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleInput}
        onKeyDown={onKeyDown}
        ref={ref}
        defaultValue={defaultValue}
        type="number"
        step="0.001"
      />
    </>
  );
}
