import { getUserArts, getArts } from '../../../api/ArtsService';
import { getSFUserInfo, getUser } from '../../../api/UsersService';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';

export const useUserPage = (userId: string) => {
  const authContext: AuthContextType = useAuthProvider();

  const { userData } = getUser(userId);
  const { SFData } = getSFUserInfo(
    userData?.role as string,
    userData?.studentNo as string,
    authContext.authData.token as string,
  );

  const createdItemsData =
    authContext.authData.userId == userId
      ? getUserArts(authContext.authData.token as string, `isCreated=true`)
      : getArts(`createdBy=${userId}`);
  const myItemsData =
    authContext.authData.userId == userId
      ? getUserArts(authContext.authData.token as string, `isOwned=true`)
      : getArts(`ownedBy=${userId}`);

  return { userData, SFData, myItemsData, createdItemsData };
};
