import { useState } from 'react';
// import { useAuth } from '../../../api/AuthService';
import { useNavigate } from 'react-router-dom';
import { useAlertContext } from '../../../Providers/AlertProvider';
import {
  checkPasswordResetHash,
  resetPassword,
  sendPasswordResetEmail,
} from '../../../api/CommentService';

export const useChangePasswordPage = () => {
  const [email, setEmail] = useState<string>('');
  const [hash, setHash] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();
  const [newPasswordValidation, setNewPasswordValidation] =
    useState<string>('');

  const sendResetEmail = async () => {
    await sendPasswordResetEmail(email).then((res) => {
      if (res.ok) {
        setSuccess(true);
        addAlert('パスワード再設定用メールを送信しました', 'success');
      } else {
        addAlert('入力されたメールアドレスは登録されていません', 'error');
      }
    });
  };

  const checkHash = async () => {
    await checkPasswordResetHash(hash).then((res) => {
      if (res.valid) {
        setIsValid(true);
      } else {
        setIsValid(false);
        navigate('/resetPassword', { replace: true });
        addAlert(
          'パスワード再設定用リンクの期限が切れています。もう一度お試し下さい',
          'error',
        );
      }
    });
  };

  const handleChangePassword = async () => {
    if (newPassword != newPasswordValidation) {
      addAlert('新しいパスワードが一致していません', 'error');
      return;
    }
    await resetPassword(newPassword, hash).then((res) => {
      if (res.ok) {
        addAlert('パスワードを変更しました', 'success');
        navigate('/MailSignIn', { replace: true });
      } else {
        addAlert('パスワードの変更に失敗しました', 'error');
      }
    });
  };

  return {
    handleChangePassword,
    sendResetEmail,
    newPassword,
    newPasswordValidation,
    setNewPassword,
    setNewPasswordValidation,
    hash,
    email,
    setEmail,
    setHash,
    success,
    setSuccess,
    isValid,
    setIsValid,
    checkHash,
  };
};
