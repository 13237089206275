import { LoginButton, TextButton, TextContainer, WholeWrapper } from './style';
import { Text } from '../../atoms/Text';
import { Color } from '../../../constants/Color';
import TextInput from '../../atoms/TextInput';
import PasswordInput from '../../atoms/PasswordInput';
import { ButtonType } from '../../../constants/Button';
import React from 'react';
import { useAuthPage } from './hooks';
import { Title } from '../Title';
import { FontType } from '../../../constants/Fonts';

export const AuthPage = () => {
  const {
    password,
    identifier,
    setPassword,
    setIdentifier,
    login,
    navigate,
    isInputValid,
  } = useAuthPage();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && password && identifier) {
      login();
    }
  };

  return (
    <WholeWrapper>
      <Title title={'ログイン'} subTitle={'Log in'} />
      <>
        <TextContainer>
          <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
            メールアドレスまたはユーザー名
          </Text>
        </TextContainer>
        <TextInput
          onKeyDown={handleKeyDown}
          setText={setIdentifier}
          placeholder="メールアドレスまたはユーザー名を入力してください"
        />
      </>
      <TextContainer>
        <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
          パスワード
        </Text>
      </TextContainer>
      <PasswordInput
        onKeyDown={handleKeyDown}
        placeholder="パスワードを入力する"
        setPassword={setPassword}
      />
      <TextButton onClick={() => navigate('/resetPassword')}>
        パスワードを忘れた方はこちら
      </TextButton>
      {!isInputValid ? (
        <LoginButton buttonType={ButtonType.DISABLE}>
          <p
            style={{
              color: '#909090',
              whiteSpace: 'pre-wrap',
              fontWeight: 'bold',
              minWidth: 'max-content',
              margin: 0,
              cursor: 'pointer',
            }}
          >
            ログイン
          </p>
        </LoginButton>
      ) : (
        <LoginButton buttonType={ButtonType.GRADIENT} onClick={() => login()}>
          <p
            style={{
              color: '#fff',
              whiteSpace: 'pre-wrap',
              fontWeight: 'bold',
              minWidth: 'max-content',
              margin: 0,
              cursor: 'pointer',
            }}
          >
            ログイン
          </p>
        </LoginButton>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }} />
        <TextButton onClick={() => navigate('/StudentSignUp')}>
          新規学生アカウント登録はこちら
        </TextButton>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }} />
        <TextButton onClick={() => navigate('/SignUp')}>
          新規一般アカウント登録はこちら
        </TextButton>
        <div style={{ flex: 1 }} />
      </div>
    </WholeWrapper>
  );
};
