import styled from '@emotion/styled';

import { ButtonType } from '../../../constants/Button';
import { Color } from '../../../constants/Color';
import tw from 'twin.macro';

const getButtonStyle = (buttonType: ButtonType) => {
  if (buttonType === ButtonType.NORMAL) {
    return `
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-style: none;
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
        display: flex;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.1s;
        align-items: center;
        &:hover {
          color: ${Color.BRANDMAIN}
        }
    `;
  } else if (buttonType === ButtonType.IMPORTANT) {
    return `
        background-color: ${Color.BRANDMAIN};
        transition: opacity 0.2s;
        color: ${Color.BG};
        border-radius: 9999px;
        padding: 0.75rem 2.0rem;
        width: 150px;
        &:hover {
          opacity: 0.6;
        }
    `;
  } else if (buttonType === ButtonType.ROUNDEDIMPORTANT) {
    return `
        width: 100%;
        height: 40px;
        justify-content: center;
        align-items: center;
        border: 0.1rem solid;
        border-radius: 25px;
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
        display: flex;
        cursor: pointer;
        background-color: ${Color.BRANDMAIN};
        transition: background-color 0.1s;
        color: ${Color.BG};
        border: 1px solid ${Color.BRANDMAIN};
        border-radius: 9999px;
        padding: 0 1.5vw;
        &:hover {
          opacity: 0.6;
        }
    `;
  } else if (buttonType === ButtonType.ROUNDED) {
    return `
        background-color: ${Color.BRAND40};
        transition: background-color 0.1s;
        color: ${Color.MAIN};
        border-radius: 9999px;
        height: 3vw;
        width: 3vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:hover {
          background-color: transparent;
          color: ${Color.BRANDMAIN}
        }
    `;
  } else if (buttonType === ButtonType.WIDEROUNDED) {
    return `
        width: 100%;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
        display: flex;
        cursor: pointer;
        background-color: ${Color.BRAND40};
        transition: background-color 0.1s;
        color: ${Color.MAIN};
        border-radius: 9999px;
        padding: 0 1.5vw;
        &:hover {
          background-color: transparent;
          color: ${Color.BRANDMAIN}
        }
    `;
  } else if (buttonType === ButtonType.CIRCLEWIDE) {
    return `
        display: flex;
        flex: 1;
        font-weight: bold;
        justify-content: center;
        background-color: ${Color.BRANDMAIN};
        transition: opacity 0.2s;
        color: #fff;
        border-radius: 9999px;
        border-style: solid;
        border-width: 1px;
        border-color: ${Color.BRANDMAIN};
        &:hover {
         opacity:0.6;
        }
    `;
  } else if (buttonType === ButtonType.WIDEROUNDED_OUTLINED) {
    return `
        width: 100%;
        display: flex;
        flex: 1;
        font-weight: bold;
        justify-content: center;
        background-color: #fff;
        transition: opacity 0.2s;
        color: ${Color.BRANDMAIN};
        border-radius: 9999px;
        border-style: solid;
        border-width: 1px;
        border-color: ${Color.BRANDMAIN};
        &:hover {
         background-color: ${Color.BG};
        }
    `;
  } else if (buttonType === ButtonType.SMALL) {
    return `
        display: flex;
        flex: 1;
        max-width: 150px;
        font-weight: bold;
        justify-content: center;
        background-color: ${Color.BRANDMAIN};
        transition: opacity 0.2s;
        color: #fff;
        border-radius: 9999px;
        border-style: solid;
        border-width: 1px;
        border-color: ${Color.BRANDMAIN};
        &:hover {
         background-color: transparent;
         color: ${Color.BRANDMAIN};
        }
    `;
  } else if (buttonType === ButtonType.SMALL_OUTLINED) {
    return `
        display: flex;
        flex: 1;
        max-width: 150px;
        font-weight: bold;
        justify-content: center;
        background-color: #fff;
        transition: opacity 0.2s;
        color: ${Color.BRANDMAIN};
        border-radius: 9999px;
        border-style: solid;
        border-width: 1px;
        border-color: ${Color.BRANDMAIN};
        &:hover {
         background-color: ${Color.BG};
        }
    `;
  } else if (buttonType === ButtonType.CIRCLE) {
    return `
        background-color: ${Color.BRAND40};
        transition: background-color 0.1s;
        color: ${Color.MAIN};
        border-radius: 9999px;
        height: 60px;
        width: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:hover {
          background-color: transparent;
          color: ${Color.BRANDMAIN}
        }
    `;
  } else if (buttonType === ButtonType.GRADIENT) {
    return `
        background: linear-gradient(97.55deg, #E95098 19.83%, #00A1E9 83.48%);
        border-radius: 100px;
        color: ${Color.WHITETEXT};
    `;
  } else if (buttonType === ButtonType.XLSX_GREEN) {
    return `
        background: #107A40;
        border-radius: 100px;
        color: ${Color.WHITETEXT};
    `;
  } else if (buttonType === ButtonType.DISABLE) {
    return `
        background: #BCBCBC;
        border-radius: 100px;
        color: #909090;
    `;
  } else {
    console.error('incorrect button type');
  }
};

interface Props {
  buttonType: ButtonType;
  disabled: boolean;
}

export const StyledButton = styled.button<Props>`
  ${tw`px-5 py-3 border-none ease-out items-center cursor-pointer`}
  ${({ buttonType }) => getButtonStyle(buttonType)}
  ${({ disabled }) =>
    disabled &&
    `border: solid 1px ${Color.BOX_BG}; background-color: ${Color.BORDER10}; color: ${Color.MAIN}; &:hover {
      border: solid 1px ${Color.BOX_BG}; background-color: ${Color.BORDER10}; color: ${Color.MAIN};
    }; cursor: default;`}
`;
