import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './pages/App';
import { BrowserRouter } from 'react-router-dom';
import HeaderWithSearch from './components/HeaderWithSearch';
import Footer from './components/Footer';
import { AuthProvider } from './Providers/AuthProvider';
import { SWRConfig } from 'swr';
import { AlertProvider } from './Providers/AlertProvider';
import { ToastContainer, Slide } from 'react-toastify';
import ScrollToTop from './lib/ScrollToTop';

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SWRConfig value={{ errorRetryInterval: 20000 }}>
        <AlertProvider>
          <AuthProvider>
            <HeaderWithSearch />
            <ScrollToTop />
            <App />
            <Footer />
          </AuthProvider>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="colored"
            transition={Slide}
          />
        </AlertProvider>
      </SWRConfig>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
