import { getArtDetail } from '../../../api/ArtsService';
import { useAuth } from '../../../api/AuthService';
import { useEffect, useState, useContext } from 'react';
import { User } from '../../../types/domain/User';
import { getComments } from '../../../api/CommentService';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

export const useItems = (id: string | undefined) => {
  // if (!id) return { artData: undefined };
  const authContext: AuthContextType = useContext(AuthContext);
  const [currentUser, setCurrentUser] = useState<User>();
  const token: string | undefined = authContext.authData?.token || undefined;

  const { artData, reload } = getArtDetail(id as string, token);
  const { getCurrentUser } = useAuth();
  const { comments } = getComments(id as string);

  useEffect(() => {
    getCurrentUser().then((user) => setCurrentUser(user));
  }, []);

  return { artData, currentUser, comments, reload };
};
