import { useState } from 'react';
import _ from 'lodash';

import { Art } from '../../../types/domain/Art';

import { prePurchaseCheck } from '../../../api/PurchaseService';
import { issueInvoiceId } from '../../../api/PurchaseService';

import { useAlertContext } from '../../../Providers/AlertProvider';
import { AuthDataType } from '../../../Providers/AuthProvider';

export const useHandlePurchase = (options: {
  artDetail?: Art | undefined;
  authData?: AuthDataType | undefined;
  setIsPurchasing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { artDetail, authData, setIsPurchasing } = options;

  const { addAlert } = useAlertContext();
  const [invoiceId] = useState('');

  const PrePurchase = async () => {
    return prePurchaseCheck(artDetail?.id as string, authData?.token as string)
      .then((res) => {
        const json = res.json();
        return Promise.resolve(json);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  const handleIssueInvoiceId = async () => {
    if (invoiceId) return;
    return issueInvoiceId(
      parseInt(authData?.userId as string),
      parseInt(artDetail?.id as string),
      parseInt(artDetail?.ownerInfo.userId as string),
      authData?.token as string,
    );
  };

  const purchase = async () => {
    setIsPurchasing(true);

    const url = await PrePurchase()
      .then(async (res) => {
        console.log(res);
        return handleIssueInvoiceId();
      })
      .then(async (res) => {
        if (!res.ok) {
          return Promise.reject(
            '購入手続きが失敗しました。管理者にお問い合わせください。',
          );
        }

        const json = await res.json();

        return Promise.resolve(json.data.LinkUrl);
      })
      .catch((e) => {
        addAlert(e, 'error');
      })
      .finally(() => {
        setIsPurchasing(false);
      });

    if (!_.isEmpty(url)) {
      window.open(url, '_blank', 'noopener noreferrer');
    }
  };

  return {
    purchase,
  };
};
