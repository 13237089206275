/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImCross } from 'react-icons/im';
import {
  Box,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  StyledContainer,
  RowWrapper,
  ColumnWrapper,
  StyledText,
  TextContainer,
  DropUploadContainer,
  StyledFormContainer,
  HeaderContainer,
  ButtonWrapper,
  SwitchContainer,
  AnnotatedTextContainer,
  DetailPriceContainer,
  UploadedImageWrapper,
  UploadedImageContainer,
  DeleteButtonWrapper,
} from './style';

import { useRegisterArt } from './hooks';

import { BUSINESS_TRANSACTION, FIRST_PAY_BACK_RATE } from '../../config';

import { RequiredText } from '../atoms/required';
import { Text } from '../atoms/Text';
import TextInput from '../atoms/TextInput';
import TextArea from '../atoms/TextArea';
import { Button } from '../atoms/Button';
import { DropUpload } from '../atoms/DropUpload';
import { SwitchButton } from '../atoms/SwitchButton';
import { ToggleSwitch } from '../atoms/ToggleSwitch';
import { CheckBox } from '../atoms/CheckBox';

import { FontType } from '../../constants/Fonts';
import { Color } from '../../constants/Color';
import { ButtonType } from '../../constants/Button';

import { TermsText } from '../pages/SignUp/style';
import { ResendAuthEmail } from '../pages/ResendAuthEmail';

import { Art, ArtForRegistAPI } from '../../types/domain/Art';
import { Tag } from '../../types/domain/Tag';

import {
  registerArt,
  registerTag,
  taggingToArt,
  getContestItems,
} from '../../api/ArtsService';
import { sendRequest, RequestBody } from '../../api/RequestService';

import { useAlertContext } from '../../Providers/AlertProvider';
import { AuthContext, AuthContextType } from '../../Providers/AuthProvider';

interface RegistDataResponse {
  code: number;
  data?: Art;
  message: string;
  status: boolean;
}

export function FileUpload() {
  const { tags, reload, usePrevious } = useRegisterArt();
  const authContext: AuthContextType = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  const [itemName, setItemName] = useState('');
  const [explanation, setExplanation] = useState('');
  const [artValue, setArtValue] = useState('');
  const [valueError, setValueError] = useState('');
  const [file, setFile] = useState<File>();
  const [tagName, setTagName] = useState('');
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [options, setOptions] = useState<Tag[]>(tags as Tag[]);
  const [inputValue, setInputValue] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  //作品状態
  const [artState, setArtState] = useState<'NFT' | 'Portfolio' | 'Picture'>(
    'Picture' || 'NFT',
  );

  //掲載有無
  const [isArtExhibited, setIsArtExibited] = useState(false);
  const commonUsersCommission = 200;

  const artValueWithCardCommission =
    Number(artValue) - Number(artValue) * BUSINESS_TRANSACTION;

  const totalSalesAmount =
    authContext.authData.role === 'common'
      ? artValueWithCardCommission * FIRST_PAY_BACK_RATE - commonUsersCommission
      : artValueWithCardCommission * FIRST_PAY_BACK_RATE;

  const exhibitCommission = Number(artValue) - totalSalesAmount;

  const formValidate = () => {
    if (itemName.length == 0) return false;
    if (!file || file.size === 0) return false;
    if (!checked) return false;
    if (valueError) return false;

    return true;
  };

  const isValidButton = formValidate();

  const handleRegisterArt = async () => {
    setIsPosting(true);
    const art: ArtForRegistAPI = {
      name: itemName,
      createdBy: authContext.authData.userId as string,
      ownedBy: authContext.authData.userId as string,
      about: explanation,
      isDisplay: isArtExhibited,
      isNFT: false,
      isPortfolio: false,
      isHide: false,
      tags: selectedTags,
      value: Number(artValue),
    };

    if (Number.isNaN(Number(artValue))) {
      addAlert('価格には半角数字を入力してください', 'error');
      setIsPosting(false);
      return;
    }

    if (isArtExhibited && Number(artValue) < 350 && artState == 'NFT') {
      addAlert('価格には350円以上を入力してください', 'error');
      setIsPosting(false);
      return;
    } else if (
      isArtExhibited &&
      Number(artValue) >= 10000000 &&
      artState == 'NFT'
    ) {
      addAlert('価格には9,999,999円以下を入力してください', 'error');
      setIsPosting(false);
      return;
    }

    if (isContestApply && heldContestId) {
      art.contestId = heldContestId.toString();
    }

    registerArt(art, file as File, authContext.authData.token as string).then(
      async (res: RegistDataResponse) => {
        if (res.status) {
          const json = res;
          setIsPosting(false);
          reload();
          navigate('/');
          if (artState === 'Picture') {
            addAlert('作品を登録しました', 'success');
          }
          const Req: RequestBody = {
            type: artState == 'NFT' ? 'Mint' : 'Portfolio',
            targetId: Number(json?.data?.id),
            requestedBy: Number(authContext.authData.userId),
          };
          if (artState == 'NFT' || artState == 'Portfolio') {
            sendRequest(Req, authContext.authData.token as string).then(
              async (response) => {
                if (response.ok) {
                  if (artState == 'NFT') {
                    addAlert('NFTの登録申請が完了しました', 'success');
                  } else {
                    addAlert(
                      'ポートフォリオの登録申請が完了しました',
                      'success',
                    );
                  }
                } else {
                  if (artState == 'NFT') {
                    addAlert('NFTの登録申請に失敗しました', 'error');
                  } else {
                    // error handling足りないのでは？
                    addAlert('ポートフォリオの登録申請に失敗しました', 'error');
                  }
                }
              },
            );
          }

          if (selectedTags.length && json?.data?.id) {
            await taggingToArt(
              json.data.id,
              selectedTags,
              authContext.authData.token as string,
            );
          } else {
            addAlert('タグが設定されていません', 'warning');
            setIsPosting(false);
            setSelectedTags([]);
          }
        }
      },
    );
    console.log(art);
  };

  const handleRegisterTag = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    if (!tagName.match(/\S+/gi)) return;
    const revisedTagName = tagName.replaceAll('&', '＆');
    registerTag(revisedTagName, authContext.authData.token as string).then(
      async (res) => {
        if (res.ok) {
          const json = await res.json();
          setOptions([...options, json]);
          setSelectedTags([...selectedTags, json]);
          setTagName('');
          setInputValue('');
        } else {
          addAlert('タグの登録に失敗しました', 'error');
          setIsPosting(false);
          return;
        }
      },
    );
  };

  const handleArtState = (x: 'Picture' | 'NFT' | 'Portfolio') => {
    setArtState(x);
  };

  const handleKeyDown = (e: any) => {
    if (!tagName.match(/\S+/gi)) return;
    if (options?.filter((opt) => opt.name === tagName).length) return;
    if (heldContestTagOptions?.filter((opt) => opt.name === tagName).length)
      return;

    if (e.keyCode == '13' || e.keyCode === 32) {
      e.preventDefault();
      handleRegisterTag(e);
    }
  };

  const handleArtValueChange = (value: string) => {
    setArtValue(value);
    if (Number(value) < 350) {
      setValueError('販売可能な価格は350円以上です');
    } else if (Number(value) >= 10000000) {
      setValueError('販売可能な価格は9,999,999円以下です');
    } else {
      setValueError('');
    }
  };

  useEffect(() => {
    setOptions(tags as Tag[]);
  }, [tags]);

  if (!authContext.authData.emailVerified) return <ResendAuthEmail />;

  const [isUploaded, setIsUploaded] = useState(false);

  useEffect(() => {
    if (file) {
      console.log(file);
      setIsUploaded(true);
    }
  }, [file]);

  const [isContestHeld, setIsContestHeld] = useState(false);
  const [heldContestId, setHeldContestId] = useState<number | null>(null);
  const [heldContestName, setHeldContestName] = useState<string | null>(null);
  const [heldContestTags, setHeldContestTags] = useState<string[]>([]);
  useEffect(() => {
    const fetchIsContestData = async () => {
      try {
        const contestData = await getContestItems();
        const heldContest = contestData.find(
          (contest: any) => contest.is_held === true,
        );
        if (heldContest) {
          setIsContestHeld(true);
          setHeldContestId(heldContest.id);
          setHeldContestName(heldContest.name);
          setHeldContestTags(heldContest.tags);
        } else {
          setIsContestHeld(false);
          setHeldContestId(null);
          setHeldContestName(null);
          setHeldContestTags([]);
        }
      } catch (error) {
        addAlert(
          'コンテストデータの取得に失敗しました。ページをリロードしてください',
          'error',
        );
        console.error('コンテストデータの取得に失敗しました', error);
      }
    };
    fetchIsContestData();
  }, []);

  const [isContestApply, setIsContestApply] = useState(false);
  useEffect(() => {
    if (isContestHeld && isContestApply) {
      setArtState('NFT');
    } else {
      setArtState('Picture');
    }
  }, [isContestApply]);

  const [heldContestTagOptions, setHeldContestTagOptions] = useState<Tag[]>([]);
  const [selectedContestTags, setSelectedContestTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (tags && heldContestTags.length > 0)
      setHeldContestTagOptions(
        tags.filter((tag) => heldContestTags.includes(tag.name)),
      );
    if (selectedTags && heldContestTags.length > 0)
      setSelectedContestTags(
        selectedTags.filter((tag) => heldContestTags.includes(tag.name)),
      );
  }, [tags, selectedTags, heldContestTags]);

  const isSelectedOneContestTag = selectedContestTags.length === 1;

  const prevIsContestApply = usePrevious(isContestApply);
  useEffect(() => {
    if (prevIsContestApply) {
      if (!isContestApply) {
        const selectedTagsWithoutContestTag = selectedTags.filter(
          (tag) => !heldContestTags.includes(tag.name),
        );
        setSelectedTags(selectedTagsWithoutContestTag);
      } else {
        setIsArtExibited(false);
        setIsContestApply(true);
      }
    }
  }, [isContestApply, prevIsContestApply]);

  return (
    <ColumnWrapper>
      <HeaderContainer>
        <Text fontType={FontType.TITLE2}>作品登録</Text>
        <Text color={Color.BRANDMAIN}>Work registration</Text>
      </HeaderContainer>
      <RowWrapper>
        <StyledContainer>
          <TextContainer>
            <StyledText fontType={FontType.TITLE3}>
              作品データアップロード
            </StyledText>
            <RequiredText />
          </TextContainer>
          {isUploaded && file ? (
            <UploadedImageWrapper>
              <DeleteButtonWrapper>
                <ImCross
                  size={16}
                  color={'#ffffff'}
                  onClick={() => setIsUploaded(false)}
                />
              </DeleteButtonWrapper>
              <UploadedImageContainer src={URL.createObjectURL(file)} />
            </UploadedImageWrapper>
          ) : (
            <DropUploadContainer>
              <DropUpload setFile={setFile} />
            </DropUploadContainer>
          )}
        </StyledContainer>
        <StyledFormContainer>
          <TextContainer>
            <StyledText fontType={FontType.TITLE3}>作品タイトル</StyledText>
            <RequiredText />
          </TextContainer>
          <TextInput setText={setItemName} placeholder={'題名'} />
          <TextContainer>
            <StyledText fontType={FontType.TITLE3}>作品説明</StyledText>
          </TextContainer>
          <TextArea
            setText={setExplanation}
            placeholder={'あなたの作品に関して説明してください'}
          />
          {isContestHeld && (
            <>
              <TextContainer>
                <StyledText fontType={FontType.TITLE3}>
                  {heldContestName} に応募する
                </StyledText>
              </TextContainer>
              <ToggleSwitch
                id="isContestApply"
                className="isContestApply"
                handleChange={() => setIsContestApply(!isContestApply)}
                checked={isContestApply}
              />
            </>
          )}
          {isContestHeld && isContestApply ? (
            <TextContainer>
              <StyledText fontType={FontType.TITLE3}>
                作品状態(イラストコンテストに応募した場合、NFTに固定されます)
              </StyledText>
            </TextContainer>
          ) : authContext.authData.role !== 'common' ? (
            <TextContainer>
              <StyledText fontType={FontType.TITLE3}>
                作品状態(NFTまたはポートフォリオの場合は変更不可)
              </StyledText>
              <RequiredText />
            </TextContainer>
          ) : (
            <TextContainer>
              <StyledText fontType={FontType.SUB}>
                作品状態(NFTの場合は変更不可)
              </StyledText>
              <RequiredText />
            </TextContainer>
          )}
          <div style={{ marginBottom: '12px' }}>
            {artState == 'NFT' && (
              <>
                <div style={{ marginBottom: '0.5rem', width: '1px' }} />
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※NFT申請した作品は作品状態を変更することができなくなります。
                </Text>
                {authContext.authData.role == 'student' && (
                  <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                    ※NFT申請の承認には数日ほどかかる場合があります。
                  </Text>
                )}
                {authContext.authData.role == 'common' && (
                  <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                    ※NFT申請の承認には時間がかかる場合があります。
                  </Text>
                )}
                {isContestApply && (
                  <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                    ※イラストコンテストに応募した場合、出品できなくなります。
                  </Text>
                )}
              </>
            )}
            {artState == 'Portfolio' && (
              <>
                <div style={{ marginBottom: '0.5rem', width: '1px' }} />
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※ポートフォリオ申請した作品は作品状態を変更することができなくなります。
                  <br />
                  ※ポートフォリオ申請の承認には数日ほどかかる場合があります。
                </Text>
              </>
            )}
          </div>
          {isContestHeld && isContestApply ? (
            <SwitchContainer>
              <SwitchButton
                onClick={() => handleArtState('NFT')}
                isOn={artState == 'NFT'}
              >
                NFT
              </SwitchButton>
            </SwitchContainer>
          ) : (
            <SwitchContainer>
              <SwitchButton
                onClick={() => handleArtState('Picture')}
                isOn={artState == 'Picture'}
              >
                掲載のみ
              </SwitchButton>
              {authContext.authData.mintRequestable && (
                <SwitchButton
                  onClick={() => handleArtState('NFT')}
                  isOn={artState == 'NFT'}
                >
                  NFT
                </SwitchButton>
              )}
              {authContext.authData.role != 'common' && (
                <SwitchButton
                  onClick={() => handleArtState('Portfolio')}
                  isOn={artState == 'Portfolio'}
                >
                  ポートフォリオ
                </SwitchButton>
              )}
            </SwitchContainer>
          )}
          {artState == 'NFT' && !isContestApply ? (
            <>
              <TextContainer>
                <StyledText fontType={FontType.TITLE3}>出品有無</StyledText>
              </TextContainer>
              {isArtExhibited && (
                <div style={{ marginBottom: '12px' }}>
                  <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                    出品有にすると、NFT作品がハックツ上で販売されます。
                  </Text>
                </div>
              )}
              <ToggleSwitch
                id="isExibited"
                className="isExibited"
                handleChange={() => setIsArtExibited(!isArtExhibited)}
                checked={isArtExhibited}
              />
              {isArtExhibited ? (
                <>
                  <AnnotatedTextContainer>
                    <Text fontType={FontType.TITLE3}>販売価格</Text>
                    <Text
                      fontType={FontType.SMALL_NORMAL}
                      color={Color.GRAYSCALE64}
                    >
                      ￥350 ~ ￥9,999,999
                    </Text>
                    <RequiredText />
                  </AnnotatedTextContainer>
                  <TextInput
                    setText={handleArtValueChange}
                    placeholder={'作品の価格'}
                  />
                  <DetailPriceContainer>
                    <Text
                      fontType={FontType.SMALL_NORMAL}
                      color={Color.GRAYSCALE64}
                    >
                      ※ 販売利益：¥
                      {artValue == '' || Number(artValue) < 350
                        ? '-'
                        : Math.round(totalSalesAmount).toLocaleString()}
                    </Text>
                    <Text
                      fontType={FontType.SMALL_NORMAL}
                      color={Color.GRAYSCALE64}
                    >
                      ※ システム利用料：¥
                      {artValue == '' || Number(artValue) < 350
                        ? '-'
                        : Math.round(exhibitCommission).toLocaleString()}
                    </Text>
                    {valueError && artValue && (
                      <Text fontType={FontType.TITLE4} color={Color.RED}>
                        {valueError}
                      </Text>
                    )}
                  </DetailPriceContainer>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <TextContainer>
            <StyledText fontType={FontType.TITLE3}>タグ</StyledText>
          </TextContainer>
          <div style={{ marginBottom: '12px' }}>
            {isContestHeld && isContestApply && (
              <>
                <div style={{ marginBottom: '0.5rem', width: '1px' }} />
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  ※イラストコンテストに応募する場合、以下のタグの中から必ず一つを選択してください。
                </Text>
              </>
            )}
          </div>
          <Autocomplete
            value={selectedTags}
            multiple
            id="size-small-outlined"
            size="small"
            options={
              isContestHeld && isContestApply
                ? heldContestTagOptions || []
                : options || []
            }
            disableCloseOnSelect
            disablePortal
            disableListWrap
            debug
            onChange={(_, value) => {
              setSelectedTags(value);
            }}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue.replace(/\s+/gi, ''));
            }}
            renderOption={(opt) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Text fontType={FontType.SMALL_NORMAL}>{opt.name}</Text>
                {/* タグ削除機能が必要になったときに追加 */}
                {/* <IconButton
                  size="small"
                  component="span"
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    handleDeleteTag(e, opt.id)
                  }
                >
                  <GrFormClose />
                </IconButton> */}
              </Box>
            )}
            noOptionsText={
              <Box
                onClick={handleRegisterTag}
                bgcolor="#eee"
                style={{ cursor: 'pointer' }}
                padding={1}
                borderRadius={5}
                display="flex"
                justifyContent="space-between"
              >
                <Text
                  fontType={FontType.SMALL_NORMAL}
                >{`"${tagName}"タグを登録`}</Text>
              </Box>
            }
            getOptionLabel={(opt: Tag) => opt.name || ''}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                onKeyDown={handleKeyDown}
                variant="outlined"
                onChange={(e) => {
                  setTagName(e.target.value.replace(/\s+/gi, ''));
                }}
              />
            )}
          />
          <TextContainer>
            <CheckBox onClick={() => setChecked(!checked)} />
            <TermsText target="_blank" href="/TermsOfUse">
              利用規約
            </TermsText>
            <TermsText>と</TermsText>
            <TermsText target="_blank" href="/guideline">
              ガイドライン
            </TermsText>
            <TermsText>に同意する</TermsText>
            <RequiredText />
          </TextContainer>
          {!isValidButton || (isContestApply && !isSelectedOneContestTag) ? (
            <ButtonWrapper>
              <Button buttonType={ButtonType.DISABLE} disabled={true}>
                <p
                  style={{
                    color: '#909090',
                    whiteSpace: 'pre-wrap',
                    fontWeight: 'bold',
                    minWidth: 'max-content',
                    margin: 0,
                    cursor: 'pointer',
                  }}
                >
                  登録する
                </p>
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper>
              <Button
                onClick={handleRegisterArt}
                buttonType={ButtonType.GRADIENT}
              >
                {!isPosting ? (
                  <p
                    style={{
                      color: '#fff',
                      whiteSpace: 'pre-wrap',
                      fontWeight: 'bold',
                      minWidth: 'max-content',
                      margin: 0,
                      cursor: 'pointer',
                    }}
                  >
                    登録する
                  </p>
                ) : (
                  <CircularProgress
                    size={16}
                    color={'secondary'}
                    style={{ margin: '0px 50px 0px 50px' }}
                  />
                )}
              </Button>
            </ButtonWrapper>
          )}
        </StyledFormContainer>
      </RowWrapper>
    </ColumnWrapper>
  );
}
