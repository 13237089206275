// API Error status 一覧
export const EXIST_PENDING_REQUEST =
  'NFT申請が未承認の作品があります' as string;
export const TRANSFER_FAILED_PURCHASE_SELLER =
  '購入されたNFTの購入者への転送が完了するまで、ウォレットアドレスは更新できません' as string;
export const INVALID_EMAIL_ADDRESS =
  '存在するメールアドレスを指定してください' as string;
export const INVALID_EMAIL_ADDRESS_OWNER =
  '出品者のメールアドレスが無効になっているため購入できません。お問い合わせフォームへご連絡ください。' as string;
export const INVALID_EMAIL_ADDRESS_CREATOR =
  '作成者のメールアドレスが無効になっているため購入できません。お問い合わせフォームへご連絡ください。' as string;
export const WALLET_ADDRESS_CANNOT_BE_CHANGED =
  'ウォレットアドレスの変更はできません' as string;
