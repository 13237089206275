import { Network } from '../Providers/WalletProvider';

export const API_URL = process.env.REACT_APP_API_URL as string;
export const METAMASK_URL = process.env.REACT_APP_METAMASK_URL as Network;
export const CHAIN_NETWORK = process.env.REACT_APP_CHAIN_NETWORK as string;
export const PAYPAL_CLIENT_ID = process.env
  .REACT_APP_PAYPAL_CLIENT_ID as string;
export const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_WALLET as string;
export const ALCHEMY_APIKEY = process.env.REACT_APP_ALCHEMY_APIKEY as string;
export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID as string);
export const CONTRACT_ADDRESS = process.env
  .REACT_APP_CONTRACT_ADDRESS as string;
export const FORWARDER_ADDRESS = process.env
  .REACT_APP_FORWARDER_ADDRESS as string;
export const CONTRACT_WALLET_ADDRESS = process.env
  .REACT_APP_CONTRACT_WALLET_ADDRESS as string;
export const BUSINESS_FIXED_FEE = parseFloat(
  process.env.REACT_APP_BUSINESS_FIXED_FEE as string,
);
export const BUSINESS_TRANSACTION = parseFloat(
  process.env.REACT_APP_BUSINESS_TRANSACTION as string,
);
export const FIRST_PAY_BACK_RATE = parseFloat(
  process.env.REACT_APP_FIRST_PAY_BACK_RATE as string,
);
export const SECOND_PAY_BACK_RATE = parseFloat(
  process.env.REACT_APP_SECOND_PAY_BACK_RATE as string,
);
export const ROYALTY = parseFloat(process.env.REACT_APP_ROYALTY as string);
export const ENV = process.env.REACT_APP_ENV || ('dev' as string);
