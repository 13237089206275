/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import { CircularProgress } from '@material-ui/core';

import {
  ArtDeliveryContainer,
  TextContainer,
  WideButtonContainer,
  DeliveryFeeContainer,
  DeliveryFeeWrapper,
  TitleContainer,
} from './style';
import { useItems } from './hook';

import { Button } from '../../atoms/Button';
import { RequiredText } from '../../atoms/required';
import { CheckBox } from '../../atoms/CheckBox';
import { Text } from '../../atoms/Text';

import { Title } from '../../organisms/Title';
import { DeliveryDetail } from '../../organisms/DeliveryDetail';
import { ConfirmationModal } from '../../organisms/ConfirmationModal';

import { TermsText } from '../../pages/SignUp/style';

import { FontType } from '../../../constants/Fonts';
import { ButtonType } from '../../../constants/Button';

import { setApproveERC721 } from '../../../api/Approve';
import { itemTransferedOutside } from '../../../api/ArtsService';

import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import {
  getAccount,
  isContract,
  transferFrom,
  transferFromEstimateGas,
} from '../../../Providers/ethers';

export const ArtDelivery: React.FC = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [isMetamaskSigned, setIsMetamaskSigned] = useState(false);
  const { artData, currentUser, reload } = useItems(itemId);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const { addAlert } = useAlertContext();
  const authContext: AuthContextType = useContext(AuthContext);
  const handleDelivery = async () => {
    setIsProcessing(true);
    try {
      const provider = new ethers.BrowserProvider(window.ethereum, 'any');
      const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS;
      const fromAddr = currentUser?.walletAddr;
      const toAddr = await getAccount();
      const tokenId = artData?.Token?.contractTokenId as string;

      if (contractAddr && fromAddr && toAddr && tokenId) {
        if (!(await isContract(fromAddr, provider))) {
          throw new Error('fromAddr is not smart contract address');
        }
        if (await isContract(toAddr, provider)) {
          throw new Error('toAddr is not EOA wallet address');
        }

        const res = await setApproveERC721(
          toAddr,
          fromAddr,
          String(tokenId),
          authContext.authData.token,
        );
        console.log(res);
        if (!res || res.status !== 200) {
          addAlert(
            'コネクションエラーが発生しました。時間をおいて再度出庫してください。',
            'error',
          );
          throw new Error('setApproveERC721 failed');
        }
        if (res?.status == 200) {
          await transferFrom(provider, contractAddr, fromAddr, toAddr, tokenId)
            .then(async (transaction: ethers.ContractTransactionResponse) => {
              if (transaction && transaction.hash) {
                await itemTransferedOutside(
                  contractAddr,
                  Number(tokenId),
                  toAddr,
                  transaction.hash,
                  authContext.authData.token,
                );
              }
              return transaction;
            })
            .then(() => {
              console.log('delivery completed');
              navigate('/deliveryCompleted');
              addAlert('出庫に成功しました。', 'success');
            })
            .catch((error) => {
              console.error(error);
              addAlert(
                '出庫が拒否されたか、Matic残高が不足しています。',
                'error',
              );
              throw new Error(
                'MetaMaskのトランザクションがユーザーによって拒否されました。',
              );
            });
        } else {
          addAlert('予期せぬエラーが発生しました。', 'error');
          throw new Error('setApproveERC721 failed');
        }
      } else {
        addAlert(
          'ページをリロードして再度 MetaMask で署名を行なってください。',
          'warning',
        );
      }
    } catch (error) {
      addAlert('出庫に失敗しました。', 'error');
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const [deliveryFee, setDeliveryFee] = useState<string | null>(
    'MetaMaskの署名を行なってください。',
  );
  useEffect(() => {
    if (isMetamaskSigned) {
      (async () => {
        setIsConnecting(true);
        try {
          const provider = new ethers.BrowserProvider(window.ethereum, 'any');
          const contractAddr = process.env.REACT_APP_CONTRACT_ADDRESS;
          const fromAddr = currentUser?.walletAddr;
          const toAddr = await getAccount();
          const tokenId = artData?.Token?.contractTokenId as string;

          if (contractAddr && fromAddr && toAddr && tokenId) {
            if (!(await isContract(fromAddr, provider))) {
              throw new Error('fromAddr is not smart contract address');
            }
            if (await isContract(toAddr, provider)) {
              throw new Error('toAddr is not EOA wallet address');
            }

            const deliveryFeeCalc = await transferFromEstimateGas(
              provider,
              contractAddr,
              fromAddr,
              toAddr,
              tokenId,
            );
            setDeliveryFee(deliveryFeeCalc);
          }
        } catch (error) {
          setDeliveryFee('手数料が取得できません。');
          console.error('手数料の取得に失敗しました。');
        } finally {
          setIsConnecting(false);
        }
      })();
    }
  }, [isMetamaskSigned]);

  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);

  return (
    <>
      {/* モーダル */}
      <ConfirmationModal
        title="本当に出庫してもよろしいですか？"
        description="出庫したNFTは署名した MetaMask のウォレットに転送されます。"
        buttonTitle="出庫する"
        subButtonTitle="キャンセル"
        close={() => setIsDeliveryModalOpen(false)}
        isDisplayed={isDeliveryModalOpen}
        func={handleDelivery}
        isLoading={isProcessing}
      />
      {/* 本体 */}
      <ArtDeliveryContainer>
        <TitleContainer>
          <Title title="作品の出庫" subTitle="NFT Delivery" />
        </TitleContainer>
        <DeliveryDetail
          artDetail={artData}
          currentUser={currentUser}
          reload={reload || (() => Promise.resolve())}
          setIsMetamaskSigned={setIsMetamaskSigned}
        />
        <DeliveryFeeWrapper>
          <Text fontType={FontType.TITLE2s}>手数料（目安）</Text>
          <Text fontType={FontType.SMALL_NORMAL}>
            ※手数料は、出庫時に署名した MetaMask
            のウォレットから自動的に支払われます。残高が不足している場合は、出庫できません。
            <br />
            ※この手数料は目安です。時間によって変動するため、出庫時には異なる場合があります。
          </Text>
          <DeliveryFeeContainer>
            {!isConnecting ? (
              deliveryFee !== null && (
                <>
                  {deliveryFee === '手数料が取得できません。' ||
                  deliveryFee === 'MetaMaskの署名を行なってください。' ? (
                    <Text fontType={FontType.TITLE3}>{deliveryFee}</Text>
                  ) : (
                    <Text fontType={FontType.TITLE3}>
                      転送手数料: {deliveryFee.toString()} MATIC
                    </Text>
                  )}
                </>
              )
            ) : (
              <>
                <Text fontType={FontType.TITLE3}>手数料を計算中・・・</Text>
                <CircularProgress
                  size={16}
                  color={'secondary'}
                  style={{ margin: '0px 0px 0px 20px' }}
                />
              </>
            )}
          </DeliveryFeeContainer>
          <TextContainer>
            <CheckBox onClick={() => setChecked(!checked)} />
            <TermsText target="_blank" href="/TermsOfUse">
              利用規約
            </TermsText>
            <TermsText>と</TermsText>
            <TermsText target="_blank" href="/guideline">
              ガイドライン
            </TermsText>
            <TermsText>に同意する</TermsText>
            <RequiredText />
          </TextContainer>
        </DeliveryFeeWrapper>
        <WideButtonContainer>
          <Button
            buttonType={ButtonType.ROUNDEDIMPORTANT}
            onClick={() => setIsDeliveryModalOpen(true)}
            disabled={!checked || !isMetamaskSigned || isProcessing}
          >
            {isProcessing ? '処理中...' : '出庫する'}
          </Button>
        </WideButtonContainer>
      </ArtDeliveryContainer>
    </>
  );
};
