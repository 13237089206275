import styled from '@emotion/styled';
import tw from 'twin.macro';

export const FullOverlay = styled.div`
  ${tw`fixed flex items-center justify-center left-0 top-0 w-full h-full bg-[rgba(100,100,100,0.8)]`}
  z-index: 100;
`;

export const Window = styled.div`
  ${tw`w-[95%] md:w-[60%] bg-white mx-auto rounded-xl relative py-8`}
`;

export const CloseButtonContainer = styled.div`
  ${tw`absolute right-2 flex items-center justify-center bg-transparent w-[3em] h-[3em]`}
`;

export const TitleContainer = styled.div`
  ${tw`w-[90%] mx-auto mt-12 flex items-center gap-4`}
`;

export const PasswordInputContainer = styled.div`
  ${tw`w-[90%] md:w-[60%] mx-auto mt-8`}
`;

export const TextContainer = styled.div`
  ${tw`w-full my-4`}
`;

export const BigClearHeader = styled.div`
  ${tw`w-[min(60em,100%)] h-[2.5em] flex items-center justify-end`}
`;

export const TextButton = styled.button`
  ${tw`border-none outline-none bg-transparent text-[#E95098] text-sm cursor-pointer mt-4`}
`;

export const URLButton = styled.button`
  ${tw`w-[90%] mx-auto block mt-8 text-white text-xl py-2 rounded-full cursor-pointer hover:opacity-80 transition-all ease-in-out duration-300`}
  background: linear-gradient(97.55deg, #E95098 19.83%, #00A1E9 83.48%);
`;

export const ButtonContainer = styled.div`
  ${tw`w-[90%] md:w-[60%] mx-auto mt-8`}
`;

export const DisabledURLButton = styled.button`
  ${tw`w-[90%] mx-auto block bg-[#bcbcbc] mt-8 text-[#909090] text-xl py-2 rounded-full`}
`;

export const DipositDataContainer = styled.div`
  ${tw`w-[80%] mx-auto mt-8`}
`;

export const AuthCodeContainer = styled.div`
  ${tw`w-[90%] md:w-[60%] mx-auto mt-8`}
`;

export const AuthCode = styled.div`
  ${tw`text-center flex-row items-center justify-center pt-2 md:pt-0 px-4 rounded-lg`}
  border: 1px solid #8A8A8A;
`;

export const CopyButton = styled.button`
  ${tw`mx-auto block flex items-center border-none outline-none bg-transparent text-sm cursor-pointer transition-all ease-in-out duration-300`}
  &:hover {
    transform: scale(1.02);
  }
`;

export const IconContainer = styled.img`
  ${tw`w-[22px] h-[22px] mr-0 lg:mr-1`}
`;

export const LinkUrlContainer = styled.div`
  ${tw`w-full mx-auto mt-8`}
`;

export const LinkUrl = styled.a`
  ${tw`block text-[#E95098] md:text-xl pt-2 text-white font-bold rounded-full
  cursor-pointer hover:opacity-80 hover:scale-105 transition-all ease-in-out duration-300
  text-center py-2 no-underline`}
  background: linear-gradient(97.55deg, #E95098 19.83%, #00A1E9 83.48%)
`;

export const NoteContainer = styled.div`
  ${tw`mx-auto mt-4`}
`;

export const Note = styled.p`
  ${tw`text-sm py-0`}
`;
