import styled from '@emotion/styled';
import tw from 'twin.macro';

export const PurchaseResultWrapper = styled.div`
  ${tw`mt-12 flex flex-col w-[80%] max-w-[1440px] mx-auto`}
`;

export const PurchaseResultTextContainer = styled.div`
  ${tw`mt-5`}
`;

export const PurchaseResultText = styled.p`
  ${tw`text-lg font-bold text-center`}
`;

export const StyledButtonContainer = styled.div`
  ${tw`my-5 mx-auto flex justify-center w-[90%] md:w-[50%]`}
`;

export const StyledButton = styled.button`
  ${tw`w-full py-3 text-lg font-bold text-white rounded-full bg-[#E95098]  outline-none`}
`;
