import React, { useContext, useEffect } from 'react';
import { StyledContainer, ChangeButtonContainer, SubContainer } from './style';
import { useState } from 'react';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { NotFound } from '../NotFound';
import { useBanners } from './hooks';
import { BannerRes, deleteBanner } from '../../../api/AdminService';
import { BannerInput } from '../../organisms/BnnerInput/BannerInput';
import { API_URL } from '../../../config';

export const EditBanner: React.FC = () => {
  const authContext: AuthContextType = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  const { banners, reload } = useBanners();
  const [currentBanners, setCurrentBanners] = useState<any>([]);

  const addBanner = () => {
    // append({ url: '', src: undefined });
    const emptyBanners = currentBanners.filter((b: any) => b.isEmpty);
    if (emptyBanners.length) {
      addAlert('保存が完了していないバナーがあります', 'warning');
    } else {
      const emptyBanner: any = {
        index: currentBanners.length,
        id: '',
        createdAt: '',
        ownedAt: '',
        url: '',
        isEmpty: true,
      };
      setCurrentBanners((prev: any) => [...prev, emptyBanner]);
    }
  };

  const removeBanner = (index: number, id: string, isEmpty: boolean) => {
    setCurrentBanners((prev: any) =>
      prev.filter((value: any) => value.index !== index),
    );
    if (isEmpty) {
      reload();
      addAlert('バナーを削除しました', 'success');
    } else {
      deleteBanner(id, authContext.authData.token as string).then((res) => {
        if (res.ok) {
          reload();
          addAlert('バナーを削除しました', 'success');
        } else {
          reload();
          addAlert('バナーの削除に失敗しました', 'error');
        }
      });
    }
  };

  useEffect(() => {
    if (banners) {
      const newBanners = banners.map((b: BannerRes, index: number) => {
        const newBanner = {
          index,
          image: API_URL + `/banners/${b.id}/entity`,
          ...b,
        };
        return newBanner;
      });
      setCurrentBanners(newBanners);
    }
  }, [banners]);

  if (authContext.authData.role !== 'admin') return <NotFound />;

  return (
    <StyledContainer>
      {currentBanners &&
        currentBanners.length > 0 &&
        currentBanners.map((banner: any, index: number) => {
          return (
            <BannerInput
              key={banner.id}
              index={index}
              banner={banner}
              authContext={authContext}
              removeFunc={removeBanner}
              reload={reload}
            />
          );
        })}

      <ChangeButtonContainer>
        <SubContainer>
          <Button buttonType={ButtonType.NORMAL} onClick={addBanner}>
            バナーを追加する ＋
          </Button>
        </SubContainer>
      </ChangeButtonContainer>
    </StyledContainer>
  );
};
