import React from 'react';

import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  FaqPageWrapper,
  FaqPageTitleWrapper,
  FaqPageTitleText,
  QAWrapper,
  QASubTitle,
  LinkContainer,
} from './style';

import { QAs1, QAs2, QAs3, QAs4, QAProps } from './constant';

const useStyles = makeStyles(
  (theme: {
    typography: { pxToRem: (arg0: number) => any; fontWeightRegular: any };
  }) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

type FaqPageTitleProps = {
  title: string;
};

const FaqPageTitle: React.FC<FaqPageTitleProps> = ({ title }) => {
  return (
    <FaqPageTitleWrapper>
      <FaqPageTitleText>{title}</FaqPageTitleText>
    </FaqPageTitleWrapper>
  );
};

const QA: React.FC<QAProps> = (qa: QAProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{qa.question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{qa.answer}</Typography>
      </AccordionDetails>
      {qa.link && (
        <AccordionDetails>
          <Typography>
            <LinkContainer onClick={() => navigate(`/${qa.link}`)}>
              {qa.linkText}
            </LinkContainer>
          </Typography>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export const FaqPage = () => {
  return (
    <FaqPageWrapper>
      <FaqPageTitle title="よくあるご質問" />
      <QAWrapper>
        <QASubTitle>出品について</QASubTitle>
        {QAs1.map((qa: QAProps, index: number) => (
          <QA key={index} {...qa} />
        ))}
        <QASubTitle>購入手続きについて</QASubTitle>
        {QAs2.map((qa: QAProps, index: number) => (
          <QA key={index} {...qa} />
        ))}
        <QASubTitle>アカウントについて</QASubTitle>
        {QAs3.map((qa: QAProps, index: number) => (
          <QA key={index} {...qa} />
        ))}
        <QASubTitle>ログインについて</QASubTitle>
        {QAs4.map((qa: QAProps, index: number) => (
          <QA key={index} {...qa} />
        ))}
      </QAWrapper>
    </FaqPageWrapper>
  );
};
