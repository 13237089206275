import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TablePagination } from '@material-ui/core';
import dayjs from 'dayjs';

import { Text } from '../../atoms/Text';
import { UserProfileImage } from '../../atoms/UserTag/style';

import { FontType } from '../../../constants/Fonts';

import { API_URL } from '../../../config';

import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';

import {
  getNotificationsByPage,
  NotificationData,
} from '../../../api/NotificationsService';

import {
  Divider,
  NotificationData as NotificatioDiv,
  NotificationDescription,
  NotificationTitle,
  NotificationWrapper,
  PageWrapper,
  PaginationWrapper,
  Description,
  UserProfileImageWrapper,
  NotificationDescriptionArtIdNull,
} from './style';

export type Props = {
  notifications: NotificationData[] | undefined;
  totalCount: number;
};

const Notifications: React.FC<Props> = (props: Props) => {
  const { totalCount } = props;
  const [page, setPage] = React.useState(0);
  const authContext: AuthContextType = useAuthProvider();
  const { notificationsByPage } = getNotificationsByPage(
    authContext.authData.userId as string,
    page,
    authContext.authData.token as string,
  );
  const navigate = useNavigate();

  const handleUserIcon = (notification: any) => {
    switch (notification.Notification.type) {
      case 'PAYOUT':
      case 'TRANSFER_FAILED':
      case 'TRANSFER_SUCCESS':
      case 'DEPOSITED':
      case 'DEPOSIT_REQUEST_CREATED':
      case 'DEPOSIT_DATA_CREATED':
      case 'DEPOSIT_FAILED':
      case 'REQUEST':
      case 'REQUEST_CREATED':
      case 'OPERATION':
      case 'OS_ROYALTY_SENT':
        return `${process.env.PUBLIC_URL}/yoani-logo192.png`;
      default:
        return notification.User.userId !== 0
          ? API_URL + `/users/${notification?.User?.userId}/icon`
          : `${process.env.PUBLIC_URL}/default-icon.png`;
    }
  };

  const handleDisplayName = (notification: any) => {
    switch (notification.Notification.type) {
      case 'PAYOUT':
      case 'TRANSFER_FAILED':
      case 'TRANSFER_SUCCESS':
      case 'DEPOSITED':
      case 'DEPOSIT_REQUEST_CREATED':
      case 'DEPOSIT_DATA_CREATED':
      case 'DEPOSIT_FAILED':
      case 'REQUEST':
      case 'REQUEST_CREATED':
      case 'OPERATION':
      case 'OS_ROYALTY_SENT':
        return 'YOANI';
      default:
        return notification?.User?.displayname
          ? notification?.User?.displayname.slice(0, 20) + '...'
          : 'deleted user';
    }
  };

  const handleCommentType = (notification: any) => {
    switch (notification.Notification.type) {
      case 'BUY':
        return `【作品が購入されました】"${notification.Art.name}" が${
          notification?.User?.displayname
            ? notification?.User?.displayname
            : 'deleted user'
        }に購入されました`;

      case 'REQUEST':
        if (notification.Request.type === 'Mint') {
          if (notification.Request.status === 'Accepted') {
            return `【NFT申請】"${notification.Art.name}" のNFT申請が承認されました`;
          } else {
            return `【NFT申請】"${notification.Art.name}" のNFT申請が${
              notification?.User?.displayname
                ? notification?.User?.displayname
                : 'deleted user'
            }に拒否されました`;
          }
        } else {
          if (notification.Request.status === 'Accepted') {
            return `【ポートフォリオ申請】"${
              notification.Art.name
            }" のポートフォリオ申請が${
              notification?.User?.displayname
                ? notification?.User?.displayname
                : 'deleted user'
            }に承認されました`;
          } else {
            return `【ポートフォリオ申請】"${
              notification.Art.name
            }" のポートフォリオ申請が${
              notification?.User?.displayname
                ? notification?.User?.displayname
                : 'deleted user'
            }に拒否されました`;
          }
        }

      case 'REQUEST_CREATED':
        return `【${
          notification?.Request?.type == 'Portfolio' ? 'ポートフォリオ' : 'NFT'
        }申請】${
          notification?.Request?.type == 'Portfolio' ? 'ポートフォリオ' : 'NFT'
        }申請を受け付けました。${
          notification?.Request?.type == 'Portfolio' ? 'ポートフォリオ' : 'NFT'
        }申請の承認が完了するまでお待ちください。`;

      case 'COMMENT':
        return `【コメント】"${notification.Art.name}" に${
          notification?.User?.displayname
            ? notification?.User?.displayname
            : 'deleted user'
        }がコメントしました`;

      case 'LIKE':
        return `【いいね！】"${notification.Art.name}" がいいねされました`;

      case 'PAYOUT':
        return `【売上明細】"${notification.User.email}" に売上明細メールが送信されました。メールに記載されている売上内容をご確認ください。`;

      case 'OS_ROYALTY_SENT':
        return (
          <div>
            【売上分配】{`"${notification.Art.name}" `}
            がOpenSea上で取引され、ロイヤリティが分配されました。
            <a href={`/userpage/${authContext.authData.userId}/remittance`}>
              こちら
            </a>
            からご確認下さい。
          </div>
        );

      case 'TRANSFER_SUCCESS':
        return (
          <div>
            【NFT移転】
            作品の取得(Transfer)に成功しました。取引内容をご確認の場合は、
            <a href={`/userpage/${authContext.authData.userId}/purchase`}>
              こちら
            </a>
            からご確認下さい。
          </div>
        );

      case 'TRANSFER_FAILED':
        return (
          <div>
            【NFT移転】 作品の取得(Transfer)に失敗しました。購入した作品の
            <a href={'/items/' + notification.Art.ID}>詳細ページ</a>
            から再度Transferを実行してください
          </div>
        );

      case 'OPERATION':
        return `【運営からのお知らせ】${notification.Notification.content}`;

      case 'DEPOSIT_REQUEST_CREATED':
        return '【振込申請受付】振込申請を受け付けました。振込が完了するまでお待ちください。';

      case 'DEPOSIT_DATA_CREATED':
        return '【振込処理中】振込処理中です。振込が完了するまでお待ちください。';

      case 'DEPOSIT_DATA_FAILED':
        return '【振込失敗】振込情報の作成に失敗しました。';

      case 'DEPOSIT_FAILED':
        return '【振込失敗】振込処理に失敗しました。';

      case 'DEPOSIT_CANCELLED':
        return '【振込キャンセル】振込がキャンセルされました。';

      case 'DEPOSITED':
        return '【振込完了】振込処理が完了しました。';
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  return (
    <PageWrapper>
      <>
        <PaginationWrapper>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={30}
            labelRowsPerPage={''}
            SelectProps={{ style: { display: 'none' } }}
            nextIconButtonProps={{ color: 'secondary' }}
            backIconButtonProps={{ color: 'secondary' }}
          />
        </PaginationWrapper>
        <Divider />
        {notificationsByPage &&
          notificationsByPage.map((notification: any) => {
            return (
              <>
                <NotificationWrapper>
                  <NotificationTitle>
                    {notification.User ? (
                      <UserProfileImageWrapper
                        onClick={() => {
                          if (
                            notification.User.userId != 0 &&
                            notification.Notification.type != 'OPERATION'
                          ) {
                            navigate(`/userpage/${notification.User.userId}`);
                          }
                        }}
                      >
                        <UserProfileImage src={handleUserIcon(notification)} />
                        <Text fontType={FontType.TITLE4}>
                          {handleDisplayName(notification)}
                        </Text>
                      </UserProfileImageWrapper>
                    ) : (
                      <>
                        <UserProfileImage
                          src={`${process.env.PUBLIC_URL}/default-icon.png`}
                        />
                        <Text fontType={FontType.TITLE4}>匿名ユーザー</Text>
                      </>
                    )}
                  </NotificationTitle>
                  {notification.Art.ID != 0 ? (
                    <NotificationDescription
                      onClick={() => navigate(`/items/${notification.Art.ID}`)}
                    >
                      <Description>
                        {handleCommentType(notification)}
                      </Description>
                    </NotificationDescription>
                  ) : (
                    <NotificationDescriptionArtIdNull>
                      <Description>
                        {handleCommentType(notification)}
                      </Description>
                    </NotificationDescriptionArtIdNull>
                  )}
                  <NotificatioDiv>
                    <Text fontType={FontType.SMALL_NORMAL}>
                      {dayjs(notification?.Notification?.created_at).format(
                        'YYYY/MM/DD HH:mm',
                      )}
                    </Text>
                  </NotificatioDiv>
                </NotificationWrapper>
                <Divider />
              </>
            );
          })}
      </>
    </PageWrapper>
  );
};

export default Notifications;
