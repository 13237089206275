/* eslint-disable */
import React from 'react';
import { ethers } from 'ethers';

import { User } from '../../../types/domain/User';

import { relay } from '../../../api/Approve';

import { useAlertContext } from '../../../Providers/AlertProvider';
import { AuthDataType } from '../../../Providers/AuthProvider';
import {
  checkWalletIsConnected,
  changeNetwork,
  isApproveForAllByWalletId,
  setApproveForAll,
} from '../../../Providers/ethers';

import {
  METAMASK_URL,
  ADMIN_ADDRESS,
  CHAIN_ID,
  CONTRACT_ADDRESS,
  CHAIN_NETWORK,
  FORWARDER_ADDRESS,
} from '../../../config';

export const useMetamaskConnection = (options: {
  authData?: AuthDataType | undefined;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSigning: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { authData, setIsApproved, setIsSigning } = options;

  const isApproved = async (walletId: string) => {
    const provider =
      window.ethereum != null
        ? new ethers.BrowserProvider(window.ethereum, 'any')
        : ethers.getDefaultProvider(CHAIN_NETWORK);

    if (provider instanceof ethers.BrowserProvider) {
      const boolean = await isApproveForAllByWalletId(
        provider,
        CONTRACT_ADDRESS,
        ADMIN_ADDRESS,
        walletId,
      );
      return boolean;
    } else {
      return false;
    }
  };

  const checkApproval = async (user: User | undefined) => {
    if (!user) return Promise.resolve(true);
    const walletId = await checkWalletIsConnected();

    if (!walletId) {
      return Promise.resolve(false);
    }

    if (await isApproved(walletId)) {
      setIsApproved(true);
      console.log('useEffect update user wallet'); // ここでの更新が必要か微妙
    }

    return Promise.resolve(false);
  };

  const { addAlert } = useAlertContext();
  const setApproval = async () => {
    await changeNetwork();
    const provider =
      window.ethereum != null
        ? new ethers.BrowserProvider(window.ethereum, 'any')
        : ethers.getDefaultProvider(CHAIN_NETWORK);

    if (provider instanceof ethers.BrowserProvider) {
      setIsSigning(true);
      const { message, signature } = await setApproveForAll(
        provider,
        CONTRACT_ADDRESS,
        FORWARDER_ADDRESS,
        ADMIN_ADDRESS,
        CHAIN_ID,
      );
      const res = await relay(authData?.token, message, signature);
      if (res?.status == 202) {
        setIsApproved(true);
        setIsSigning(false);
      } else {
        setIsSigning(false);
        addAlert('署名に失敗しました', 'error');
      }
    } else {
      addAlert(
        'MetaMaskの拡張機能をブラウザにインストールしてください',
        'error',
      );
    }
  };

  const signAndUpdateWallet = async (user: User | undefined) => {
    if (!user) return Promise.resolve();
    await setApproval();
    addAlert('署名が完了しました', 'success');
    return Promise.resolve();
  };

  const handleMetamaskConnection = async () => {
    if (!window.ethereum) {
      window.open(`${METAMASK_URL}`, '_blank', 'noopener noreferrer');
      return Promise.resolve();
    }
    await changeNetwork();
    try {
      const account = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      if (account.length > 0) {
        return account[0];
      } else {
        return;
      }
    } catch (error: any) {
      console.log(error);
      if (error.code === -32002) {
        addAlert(
          'すでに一度MetaMaskが起動しています。\r\n接続するには直接拡張機能からMetaMaskを起動してください。',
          'warning',
        );
      }
    }

    return Promise.resolve();
  };

  return {
    signAndUpdateWallet,
    checkApproval,
    handleMetamaskConnection,
  };
};
