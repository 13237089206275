import React, { useState } from 'react';
import {
  InputContainer,
  StyledInput,
  PasswordPassButton,
  EyeIcon,
} from './styled';

/*親コンポーネントでは
const [password, setPassword] = useState('');
を宣言し、
<PasswordInput setPassword={setPassword} />
のようにして用いる*/

type Props = {
  setPassword: (password: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  ref?: React.Ref<HTMLInputElement>;
};

export default function PasswordInput({
  setPassword,
  placeholder,
  onKeyDown,
  ref,
}: Props): JSX.Element {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  return (
    <InputContainer>
      <StyledInput
        type={isPasswordVisible ? 'text' : 'password'}
        required
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onChange={(e) => setPassword(e.target.value)}
        ref={ref}
      />
      <PasswordPassButton onClick={togglePasswordVisibility}>
        {!isPasswordVisible ? (
          <EyeIcon src="/eye.png" />
        ) : (
          <EyeIcon src="/eye-off.png" />
        )}
      </PasswordPassButton>
    </InputContainer>
  );
}
