/*eslint-disable*/
import { useContext } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { API_URL } from '../../../config';

import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

import { getSelectedContestArts } from '../../../api/ArtsService';

export const useContestReview = (contestId: number) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const token: string | undefined = authContext.authData?.token || undefined;

  const selectedContestData = getSelectedContestArts(contestId, true, token);

  return {
    selectedContestData,
  };
};

export const useExportAsXlsx = (
  artIdArray: number[],
  contestId: string,
  token: string | undefined,
) => {
  axios({
    method: 'post',
    url: `${API_URL}/restricted/arts/create/excel-file`,
    data: artIdArray,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
    .then((response) => {
      saveAs(response.data, `illust-contest_${contestId}_review.xlsx`);
    })
    .catch((error) => {
      console.log(error);
    });
};
