import { PaymentButton } from '../../atoms/PaymentButton';
import styled from '@emotion/styled';
import { Color } from '../../../constants/Color';

export const StyledPaymentButton = styled(PaymentButton)`
  margin-top: 10%;
  margin-bottom: 2%;
`;
export const ButtonContainer = styled.div`
  padding: 2% 5% 4% 5%;
`;
export const HeadingContainer = styled.div`
  padding-left: 10%;
  padding-top: 2%;
  width: 100%;
`;
export const BorderBox = styled.div`
  margin: 2% 5% 2% 5%;
  width: 90%;
  height: 70%;
  border-radius: 10px;
  border: 1px solid ${Color.BORDER10};
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 6%;
  margin-bottom: 5%;
`;
