import React, { FC } from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

type GridProps = {
  children: any;
};

export const SortableGrid = styled.div`
  ${tw`grid grid-cols-2 md:grid-cols-3`}
  grid-gap: 16px;
  margin-bottom: 1rem;
`;

const Grid: FC<GridProps> = ({ children }) => {
  return <SortableGrid>{children}</SortableGrid>;
};

export default Grid;
