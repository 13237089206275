import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { postInquiry } from '../../../api/CommentService';

export const useAuthPage = () => {
  const [body, setBody] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [mailAddress, setMailAddress] = useState<string>('');
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();

  const login = async () => {
    await postInquiry(username, mailAddress, body).then((res) => {
      if (res.ok) {
        addAlert('お問い合わせを送信しました', 'success');
        navigate('/', { replace: true });
      } else {
        addAlert(
          'お問い合わせの送信に失敗しました。入力項目を確認し、もう一度お試し下さい',
          'error',
        );
      }
    });
  };

  const switchCheck = () => {
    setChecked(!checked);
  };

  return {
    body,
    username,
    mailAddress,
    setBody,
    setUsername,
    setMailAddress,
    checked,
    setChecked,
    login,
    switchCheck,
    navigate,
  };
};
