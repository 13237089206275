import { Box } from '@material-ui/core';
import React, { FC } from 'react';

type Props = {
  width: number;
  height: number;
};

const SpacerX: FC<Props> = ({ width, height }: Props) => {
  return <Box width={width} height={height} />;
};

export default SpacerX;
