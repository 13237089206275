import { UserForSignUp, UserForSignIn, User } from '../types/domain/User';
import { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from '../Providers/AuthProvider';
import { API_URL } from '../config';

export interface UpdateInfo {
  username?: string;
  email?: string;
  normalBio?: string;
  portfolioBio?: string;
  displayname?: string;
  walletAddr?: string;
}

// TODO ロジックが混雑しているので整理
export const useAuth = () => {
  const authContext: AuthContextType = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // @notice 基本的にストレージの管理はAuthProviderで行う。

  //
  // ログイン時の処理
  //
  interface LoginResponseType {
    token: string;
  }

  // ログインし、トークンを受け取る
  const getToken = async (user: UserForSignIn) => {
    const res: Response = await fetch(API_URL + `/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: user.username,
        email: user.email,
        password: user.password,
      }),
    });
    if (res.ok) {
      const receivedData = (await res.json()) as LoginResponseType;
      // TODO バリデータの追加
      return receivedData.token;
    } else {
      console.error('invalid auth data');
      return Promise.reject(Error('ログインに失敗しました'));
    }
  };

  //
  // ユーザー情報を取得する処理
  //

  // トークンを使い、ユーザー情報を取得し、保管する
  const getUserInfo = async (
    receivedToken: string | undefined,
  ): Promise<User> => {
    if (!receivedToken)
      return Promise.reject(Error('ログイントークンが見つかりません'));
    const res = await fetch(API_URL + `/restricted/users/token/alive`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${receivedToken}` },
    });
    if (res.ok) {
      const receivedData = (await res.json()) as User;
      authContext.setAuthData({
        token: receivedToken,
        userId: receivedData.userId,
        role: receivedData.role,
        emailVerified: receivedData.emailVerified,
        mintRequestable: receivedData.mintRequestable,
      });

      const iconRes = await fetch(
        API_URL + `/users/${receivedData.userId}/icon`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${receivedToken}`,
          },
        },
      );
      const headerRes = await fetch(
        API_URL + `/users/${receivedData.userId}/header`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${receivedToken}`,
          },
        },
      );
      receivedData.profileImage = iconRes.url;
      receivedData.headerImage = headerRes.url;

      return receivedData;
    } else {
      console.error('invalid token');
      authContext.deleteAuthData();
      return Promise.reject(Error('ユーザー情報の取得に失敗しました'));
    }
  };

  const getCurrentUser = async (): Promise<User> => {
    return getUserInfo(authContext.authData.token);
  };

  interface StudentAuthData {
    studentNo: string;
    passwordTemp: string;
  }

  const studentSignUp = async (student: StudentAuthData): Promise<boolean> => {
    const res: Response = await fetch(API_URL + `/authStudent`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(student),
    });
    if (res.ok) {
      return true;
    } else {
      return Promise.reject('学生アカウント登録情報に誤りがあります');
    }
  };

  // ログイン時に実行される関数
  const loginHandler = async (user: UserForSignIn): Promise<User> => {
    setIsLoading(true);
    return getToken(user)
      .then((receivedToken) => getUserInfo(receivedToken))
      .finally(() => setIsLoading(false));
  };

  const signUpHandler = async (user: UserForSignUp) => {
    setIsLoading(true);

    return fetch(API_URL + `/users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(
            '処理中にエラーが発生しました。システム管理者にお問い合わせください',
          );
        }
        return res.json();
      })
      .then((json) => {
        if (!json.status) {
          return Promise.reject(json.message);
        }
        return json.data;
      })
      .finally(() => setIsLoading(false));
  };

  const studentSignUpHandler = async (
    student: StudentAuthData,
  ): Promise<boolean> => {
    setIsLoading(true);
    return studentSignUp(student)
      .then(() => true)
      .catch((err) => Promise.reject(err))
      .finally(() => setIsLoading(false));
  };

  //
  // ユーザー情報を更新する処理
  //
  const updateUserInfo = async (updateInfo: UpdateInfo) => {
    if (!authContext.authData.token) return;
    const res = await fetch(
      API_URL + `/restricted/users/${authContext.authData.userId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authContext.authData.token}`,
        },
        body: JSON.stringify({
          username: updateInfo.username,
          email: updateInfo.email,
          displayname: updateInfo.displayname,
          portfolioBio: updateInfo.portfolioBio,
          normalBio: updateInfo.normalBio,
          walletAddr: updateInfo.walletAddr,
        }),
      },
    );
    if (res.ok) {
      return res;
    } else {
      return Promise.reject(Error(await res.json()));
    }
  };

  interface UploadImageProps {
    image?: File;
    type: 'icon' | 'header';
  }

  const uploadProfileImage = async ({ image, type }: UploadImageProps) => {
    if (
      !authContext.authData.token ||
      !authContext.authData.userId ||
      !authContext.authData.role ||
      !image
    ) {
      console.error('invalid auth data or icon data');
      return;
    }
    const formData = new FormData();
    formData.append('file', image);
    const res = await fetch(
      API_URL + `/restricted/users/${authContext.authData.userId}/${type}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authContext.authData.token}`,
        },
        body: formData,
      },
    );
    if (res.ok) {
      return;
    } else {
      return Promise.reject(Error('update failed'));
    }
  };

  const ChangePassword = async (oldPassword: string, newPassword: string) => {
    if (!authContext.authData.token) {
      console.error('invalid auth data');
      return;
    }
    const body = JSON.stringify({
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
    const res = await fetch(
      API_URL + `/restricted/users/${authContext.authData.userId}/password`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authContext.authData.token}`,
          'Content-Type': 'application/json',
        },
        body: body,
      },
    );
    return res;
  };

  return {
    isLoading,
    loginHandler,
    updateUserInfo,
    signUpHandler,
    uploadProfileImage,
    getUserInfo,
    studentSignUpHandler,
    getCurrentUser,
    ChangePassword,
  };
};
