import styled from '@emotion/styled';
import tw from 'twin.macro';

export const FaqPageWrapper = styled.div`
  ${tw`w-[90%] md:w-full max-w-[1000px] mx-auto px-4 md:px-20 md:mt-12 pb-12 bg-white bg-opacity-80 rounded-2xl overflow-hidden`}
`;

export const FaqPageTitleWrapper = styled.div`
  ${tw`mt-16 mb-4 w-full`}
  border-left: 4px solid #E95098;
`;

export const FaqPageTitleText = styled.h2`
  ${tw`text-2xl md:text-4xl font-bold ml-2`}
`;

export const QAWrapper = styled.div`
  ${tw`w-full mt-8`}
`;

export const QASubTitle = styled.h3`
  ${tw`text-base md:text-lg font-bold mb-4 mt-8`}
`;

export const LinkContainer = styled.div`
  ${tw`text-sm underline cursor-pointer text-blue-500`}
`;
