import React from 'react';
import { StyledInput } from './styled';

interface CheckBoxProps {
  onClick: (check: boolean) => void;
}

export function CheckBox(props: CheckBoxProps): JSX.Element {
  return (
    <div>
      <StyledInput
        type="checkbox"
        required
        onChange={(e) => {
          props.onClick(e.target.checked);
        }}
      />
    </div>
  );
}
