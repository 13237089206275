import React from 'react';
import {
  LeftContainer,
  RightContainer,
  BannerImage,
  BannerTitle,
  BannerText,
  StyledBanner,
  LinkedBanner,
} from './style';

export const FaqBanner: React.FC = () => {
  return (
    <LinkedBanner href={'/faq'} target="_blank">
      <StyledBanner>
        <LeftContainer>
          <BannerTitle>よくあるご質問</BannerTitle>
          <BannerText>　</BannerText>
          <BannerText>　</BannerText>
          <BannerText>　</BannerText>
        </LeftContainer>
        <RightContainer>
          <BannerImage src="yotama_and_question.png" alt="img" />
        </RightContainer>
      </StyledBanner>
    </LinkedBanner>
  );
};
