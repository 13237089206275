export type ColorType = { __brand__: 'ColorType' } & string;

type ColorObject = {
  MAIN: ColorType;
  BG_NONE: ColorType;
  BG: ColorType;
  BG_BLACK: ColorType;
  BOX_BG: ColorType;
  BORDER20: ColorType;
  BORDER10: ColorType;
  BRANDMAIN: ColorType;
  BRANDACCENT: ColorType;
  BRAND20: ColorType;
  BRAND30: ColorType;
  BRAND40: ColorType;
  COLORBG: ColorType;
  WHITETEXT: ColorType;
  DIVIDER: ColorType;
  SKELETON_1: ColorType;
  SKELETON_2: ColorType;
  MAIN64: ColorType;
  MAIN48: ColorType;
  GRAYSCALE64: ColorType;
  MAIN100: ColorType;
  BRAND_YELLOW: ColorType;
  BRAND_GREEN: ColorType;
  BRAND_NEW_PINK: ColorType;
  BRAND_NEW_YELLOW: ColorType;
  BRAND_NEW_BLUE: ColorType;
  RED: ColorType;
};

const Color: ColorObject = {
  MAIN: '#080808' as ColorType,
  BG_NONE: 'rgba(0, 0, 0, 0)' as ColorType,
  BG: '#EEEEEE' as ColorType, // Background
  BG_BLACK: '#1A1A1A' as ColorType,
  BOX_BG: '#EEEEEE' as ColorType,
  BORDER20: '#999999' as ColorType,
  BORDER10: '#DDDDDD' as ColorType, // Frame
  BRANDMAIN: '#E95098' as ColorType,
  BRANDACCENT: '#FD9F1C' as ColorType,
  BRAND20: 'rgba(75, 75, 75, 0.9)' as ColorType,
  BRAND30: 'rgba(58, 58, 58, 0.64)' as ColorType,
  BRAND40: 'rgba(126, 134, 158, 0.16)' as ColorType,
  COLORBG: 'rgba(126, 134, 158, 0.08)' as ColorType,
  WHITETEXT: '#FFFFFF' as ColorType,
  DIVIDER: 'rgba(106, 113, 134, 0.24)' as ColorType,
  SKELETON_1: '#dddddd' as ColorType,
  SKELETON_2: '#cccccc' as ColorType,
  MAIN100: 'rgba(215, 20, 24, 1)' as ColorType,
  MAIN64: 'rgba(215, 20, 24, 0.64)' as ColorType,
  MAIN48: 'rgba(215, 20, 24, 0.48)' as ColorType,
  GRAYSCALE64: 'rgba(24, 26, 44, 0.64)' as ColorType,
  BRAND_YELLOW: '#FD9F1C' as ColorType,
  BRAND_GREEN: '#19D315' as ColorType,
  BRAND_NEW_PINK: '#E95098' as ColorType,
  BRAND_NEW_YELLOW: '#FDD000' as ColorType,
  BRAND_NEW_BLUE: '#00A1E9' as ColorType,
  RED: '#FF0000' as ColorType,
};

export { Color as Color };
