import { LoginButton, TextContainer, WholeWrapper } from './style';
import { Text } from '../../atoms/Text';
import { Color } from '../../../constants/Color';
import TextInput from '../../atoms/TextInput';
import { ButtonType } from '../../../constants/Button';
import React from 'react';
import { useAuthPage } from './hooks';
import { Title } from '../../organisms/Title';
import SpacerY from '../../atoms/Spacer/SpacerY';
import TextArea from '../../atoms/TextArea';

export const InqueryPage: React.FC = () => {
  const {
    body,
    mailAddress,
    username,
    setBody,
    setUsername,
    setMailAddress,
    login,
  } = useAuthPage();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && mailAddress && username && body) {
      login();
    }
  };

  return (
    <WholeWrapper>
      <Title title={'お問い合わせ'} subTitle={'contact'} />
      <>
        <TextContainer>
          <Text color={Color.BORDER20}>名前</Text>
        </TextContainer>
        <TextInput
          onKeyDown={handleKeyDown}
          setText={setUsername}
          placeholder="名前を入力してください"
        />
      </>
      <>
        <TextContainer>
          <Text color={Color.BORDER20}>メールアドレス</Text>
        </TextContainer>
        <TextInput
          setText={setMailAddress}
          onKeyDown={handleKeyDown}
          placeholder="メールアドレスを入力してください"
        />
      </>

      <TextContainer>
        <Text color={Color.BORDER20}>お問い合わせ内容</Text>
      </TextContainer>
      <TextArea
        placeholder="お問い合わせ内容を入力してください"
        setText={setBody}
      />
      <SpacerY height={30} />
      <LoginButton
        buttonType={ButtonType.ROUNDEDIMPORTANT}
        onClick={() => login()}
      >
        送信する
      </LoginButton>
      <SpacerY height={50} />
    </WholeWrapper>
  );
};
