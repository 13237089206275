import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../atoms/Button';
import SearchIcon from '@material-ui/icons/Search';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { useNavigate } from 'react-router-dom';
import {
  MobileHeaderContainer,
  ProfileButtonContainer,
  StyledHeader,
  StyledHeaderLogo,
  StyledHeaderPFP,
  HeaderContainer,
  FixedHeader,
} from './style';
import { ButtonType } from '../../constants/Button';
import { Text } from '../atoms/Text';
import { FontType } from '../../constants/Fonts';
import { Color } from '../../constants/Color';
import { AuthContextType, useAuthProvider } from '../../Providers/AuthProvider';
import { useAuth } from '../../api/AuthService';
import { useMediaSize } from '../../lib/useMediaSize';
// import { useAlertContext } from '../../Providers/AlertProvider';
import { Badge, Box, Menu, MenuItem } from '@material-ui/core';
import { useNotification } from '../pages/NotificationsPage/hooks';
import SpacerX from '../atoms/Spacer/SpacerX';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useRegisterArt } from '../FileUpload/hooks';
import {
  StyledInput,
  Search,
  SearchIconWrapper,
} from '../atoms/SearchBar/style';

export default function HeaderWithSearch() {
  const [role, setRole] = useState<string>();
  const [pfp, setPfp] = useState<string>();
  const { getCurrentUser } = useAuth();
  const navigate = useNavigate();
  const { tags } = useRegisterArt();
  // const { addAlert } = useAlertContext();

  const OPTIONS_LIMIT = 30;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  });

  const authContext: AuthContextType = useAuthProvider();
  const { unreadNotifications } = useNotification(
    authContext.authData.userId as string,
  );
  const logoutNavigate = () => {
    authContext.deleteAuthData();
    setPfp('');
    setRole('');
    navigate('/');
  };
  const { isMd } = useMediaSize();

  useEffect(() => {
    getCurrentUser().then(
      (res) => {
        setPfp(res.profileImage);
        setRole(res.role);
      },
      () => {
        setPfp('');
        setRole('');
      },
    );
  }, [authContext.authData.userId]);

  //検索関数
  const handleSearch = (searchInput: string) => {
    if (!searchInput) return;
    const query = searchInput.replace(/%/g, '％').replaceAll('&', '＆');
    const revisedQuery = query.replace(/\s/g, '+');
    navigate(`search?q=${revisedQuery}`);
  };

  const headerRef = useRef<HTMLDivElement>(null);

  const handleVerify = (path: string) => {
    if (!authContext.authData.emailVerified) {
      navigate('/resendAuthEmail');
    } else {
      navigate(`/${path}`);
    }
  };

  const ProfileButton = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <ProfileButtonContainer onClick={handleClick}>
          {authContext.authData.token ? (
            <Badge badgeContent={unreadNotifications?.length} color="secondary">
              {pfp && <StyledHeaderPFP src={pfp} />}
            </Badge>
          ) : (
            <StyledHeaderPFP
              src={`${process.env.PUBLIC_URL}/default-icon.png`}
            />
          )}
        </ProfileButtonContainer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {authContext.authData.token ? (
            <Box>
              <MenuItem
                onClick={() =>
                  navigate(`/userpage/${authContext.authData.userId}`)
                }
              >
                マイページ
              </MenuItem>
              <MenuItem
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() =>
                  navigate(
                    `/userpage/${authContext.authData.userId}/notifications`,
                  )
                }
              >
                お知らせ
                <Badge
                  overlap="rectangular"
                  badgeContent={unreadNotifications?.length}
                  color="secondary"
                />
              </MenuItem>
              <MenuItem
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() =>
                  navigate(`/userpage/${authContext.authData.userId}/likedlist`)
                }
              >
                いいねした作品
              </MenuItem>
              <MenuItem
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() =>
                  navigate(`/userpage/${authContext.authData.userId}/purchase`)
                }
              >
                購入・販売履歴
              </MenuItem>
              <MenuItem
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() =>
                  navigate(`/userpage/${authContext.authData.userId}/deposit`)
                }
              >
                振込申請
              </MenuItem>
              <MenuItem
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() =>
                  navigate(
                    `/userpage/${authContext.authData.userId}/remittance`,
                  )
                }
              >
                MATIC出金申請
              </MenuItem>
              <MenuItem
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() =>
                  navigate(`/userpage/${authContext.authData.userId}/settings`)
                }
              >
                設定
              </MenuItem>
              <MenuItem onClick={logoutNavigate}>ログアウト</MenuItem>
              {!isMd && (
                <MenuItem>
                  <Button
                    buttonType={ButtonType.IMPORTANT}
                    onClick={() => handleVerify('regist')}
                  >
                    <Text fontType={FontType.TAG} color={Color.WHITETEXT}>
                      作品を登録
                    </Text>
                  </Button>
                </MenuItem>
              )}
              {role === 'admin' && (
                <>
                  <Box display="flex" justifyContent="center">
                    ---管理者---
                  </Box>
                  <MenuItem onClick={() => navigate('AdminUser')}>
                    ユーザー一覧
                  </MenuItem>
                  <MenuItem onClick={() => navigate('approval')}>
                    申請待ち一覧
                  </MenuItem>
                  <MenuItem onClick={() => navigate('banner')}>
                    TOPバナー編集
                  </MenuItem>
                  <MenuItem onClick={() => navigate('AdminTransaction')}>
                    取引履歴一覧
                  </MenuItem>
                  <MenuItem onClick={() => navigate('NgWords')}>
                    NGワード一覧
                  </MenuItem>
                  <MenuItem onClick={() => navigate('contestmanagement')}>
                    イラストコンテスト管理
                  </MenuItem>
                </>
              )}
            </Box>
          ) : (
            <Box>
              <MenuItem onClick={() => navigate(`/SignIn`)}>ログイン</MenuItem>
              {/* <MenuItem onClick={() => navigate(`/IdSignIn`)}>
                ログイン（ユーザー名）
              </MenuItem> */}
              <MenuItem onClick={() => navigate(`/SignUp`)}>
                アカウント登録（一般ユーザー）
              </MenuItem>
              <MenuItem onClick={() => navigate(`/StudentSignUp`)}>
                アカウント登録（学生ユーザー）
              </MenuItem>
            </Box>
          )}
        </Menu>
      </>
    );
  };

  const NormalHeader = () => {
    return (
      <>
        <HeaderContainer>
          <StyledHeaderLogo
            src={`${process.env.PUBLIC_URL}/hakkutsu_logo_white.png`}
            onClick={() => navigate(`/`)}
          />
          <SpacerX width={50} />
          <Autocomplete
            filterOptions={filterOptions}
            style={{ width: '100%' }}
            id="size-small-outlined"
            size="medium"
            clearOnBlur={false}
            options={tags || []}
            getOptionLabel={(opt: any) => opt.name}
            noOptionsText="該当するタグはありません"
            renderOption={(opt: any) => {
              return (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    onClick={() => navigate(`search?q=${opt.name}`)}
                  >
                    <Text fontType={FontType.SMALL_NORMAL}># {opt.name}</Text>
                    <CallMadeIcon fontSize="small" color="disabled" />
                  </Box>
                </>
              );
            }}
            renderInput={(params: any) => {
              return (
                <Search ref={params.InputProps.ref} active>
                  <StyledInput
                    {...params.inputProps}
                    value={params.inputProps.value.replaceAll('&', '＆')}
                    type="text"
                    placeholder={'NFTを探す'}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        e.preventDefault();
                        handleSearch(params.inputProps.value);
                      }
                    }}
                  />
                  <SearchIconWrapper
                    onClick={() => handleSearch(params.inputProps.value)}
                  >
                    <SearchIcon style={{ color: 'white' }} />
                  </SearchIconWrapper>
                </Search>
              );
            }}
          />
          <div style={{ flexGrow: 1 }}></div>
          {!authContext.authData.token ? (
            <>
              <ProfileButton />
              <Button
                buttonType={ButtonType.GRADIENT}
                onClick={() => navigate('/SignIn')}
              >
                <p
                  style={{
                    color: '#fff',
                    whiteSpace: 'pre-wrap',
                    fontWeight: 'bold',
                    minWidth: 'max-content',
                    margin: 0,
                    cursor: 'pointer',
                  }}
                >
                  ログイン
                </p>
              </Button>
            </>
          ) : (
            <>
              <ProfileButton />
              <Button
                buttonType={ButtonType.IMPORTANT}
                onClick={() => handleVerify('regist')}
              >
                <p
                  style={{
                    color: '#fff',
                    whiteSpace: 'pre-wrap',
                    fontWeight: 'bold',
                    minWidth: 'max-content',
                    margin: 0,
                    cursor: 'pointer',
                  }}
                >
                  作品を登録
                </p>
              </Button>
            </>
          )}
        </HeaderContainer>
      </>
    );
  };

  const MobileHeader = () => {
    return (
      <>
        <MobileHeaderContainer>
          <StyledHeaderLogo
            src={`${process.env.PUBLIC_URL}/hakkutsu_logo_white.png`}
            onClick={() => navigate(`/`)}
          />
        </MobileHeaderContainer>
        <>
          <Autocomplete
            filterOptions={filterOptions}
            style={{ width: '60%' }}
            id="size-small-outlined"
            size="medium"
            clearOnBlur={false}
            options={tags || []}
            getOptionLabel={(opt: any) => opt.name}
            noOptionsText="該当するタグはありません"
            renderOption={(opt: any) => {
              return (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    onClick={() => navigate(`search?q=${opt.name}`)}
                  >
                    <Text fontType={FontType.SUB}># {opt.name}</Text>
                    <CallMadeIcon fontSize="small" color="disabled" />
                  </Box>
                </>
              );
            }}
            renderInput={(params: any) => {
              return (
                <Search ref={params.InputProps.ref}>
                  <StyledInput
                    {...params.inputProps}
                    value={params.inputProps.value.replaceAll('&', '＆')}
                    type="text"
                    placeholder={'NFTを探す'}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        e.preventDefault();
                        handleSearch(params.inputProps.value);
                      }
                    }}
                  />
                  <SearchIconWrapper
                    onClick={() => handleSearch(params.inputProps.value)}
                  >
                    <SearchIcon style={{ color: 'white' }} />
                  </SearchIconWrapper>
                </Search>
              );
            }}
          />
        </>
        <>
          <ProfileButton />
        </>
      </>
    );
  };

  return (
    <FixedHeader>
      <StyledHeader ref={headerRef}>
        {isMd ? <NormalHeader /> : <MobileHeader />}
      </StyledHeader>
    </FixedHeader>
  );
}
