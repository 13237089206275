import React, { useContext, useState } from 'react';
import {
  deleteNgWord,
  updateNgWord,
  getAllNgWords,
  registerNgWord,
  getAllOkWords,
  registerOkWord,
  deleteOkWord,
  updateOkWord,
} from '../../../api/NgwordService';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { AuthContextType, AuthContext } from '../../../Providers/AuthProvider';
// import { useWordsServices } from './hooks';

import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import {
  GrayContainer,
  TitleContainer,
  WholeContainer,
  UpperContainer,
  Button,
  StyledInput,
  InputContainer,
  WordContainer,
  SubTitleContainer,
  SubTitle,
  Word,
  UpdateButton,
  DeleteButton,
  PendingWord,
  WordListContainer,
  UpdateInput,
  ButtonContainer,
  UpdateWordContainer,
  UpdateInvertButton,
  DeleteInvertButton,
} from './style';
import { Title } from '../../organisms/Title';
import { NotFound } from '../NotFound';

interface WordsData {
  ID: string;
  CreatedAt: string;
  DeletedAt?: string;
  UpdatedAt?: string;
  Word: string;
}

interface WordsDataResponse {
  status: boolean;
  code: number;
  data?: WordsData[];
  message: string;
}

export const NgWordsPage = () => {
  const [okWord, setOkWord] = useState('');
  const [ngWord, setNgWord] = useState('');
  const authContext: AuthContextType = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  const [ngWords, setNgWords] = useState<WordsDataResponse | undefined>(
    undefined,
  );
  const [okWords, setOkWords] = useState<WordsDataResponse | undefined>(
    undefined,
  );
  const [newNgWords, setNewNgWords] = useState<string[]>([]);
  const [clickedNgWordId, setClickedNgWordId] = useState('');
  const [newOkWords, setNewOkWords] = useState<string[]>([]);
  const [clickedOkWordId, setClickedOkWordId] = useState('');

  const handleNgWordIdClick = (wordId: string) => {
    setClickedNgWordId(wordId);
  };
  const handleNgWordIdClickCancel = () => {
    setClickedNgWordId('');
  };

  const handleOkWordIdClick = (wordId: string) => {
    setClickedOkWordId(wordId);
  };
  const handleOkWordIdClickCancel = () => {
    setClickedOkWordId('');
  };

  // NGワード
  // Get and show all NG words
  const handleGetAllNgwords = async () => {
    try {
      const data: WordsDataResponse = await getAllNgWords(
        authContext.authData.token as string,
      );
      if (data.status) {
        setNgWords(data);
      } else {
        addAlert(data.message, 'error');
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Delete
  const handleDeleteNgWord = async (id: string) => {
    const confirm = window.confirm('本当に削除しますか？');
    if (!confirm) return;
    try {
      const res: WordsDataResponse = await deleteNgWord(
        id,
        authContext.authData.token as string,
      );
      if (res.status) {
        addAlert('NGワードを削除しました。', 'success');
        handleGetAllNgwords();
      } else {
        addAlert(res.message, 'error');
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Register
  const handleNgWordRegistration = async () => {
    try {
      const res: WordsDataResponse = await registerNgWord(
        ngWord,
        authContext.authData.token as string,
      );
      if (res.status) {
        addAlert('NGワードを登録しました。', 'success');
        handleGetAllNgwords();
      } else {
        addAlert(res.message, 'error');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNgWord('');
    }
  };
  // Update
  const handleUpdateNgWord = async (wordId: string, newWord: string) => {
    const confirm = window.confirm('本当に更新しますか？');
    if (!confirm) return;
    try {
      const res: WordsDataResponse = await updateNgWord(
        wordId,
        newWord,
        authContext.authData.token as string,
      );
      if (res.status) {
        addAlert('NGワードを更新しました。', 'success');
        handleGetAllNgwords();
        handleNgWordIdClickCancel();
        setNewNgWords([]);
      } else {
        addAlert(res.message, 'error');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdatedNgWordChange = (newWord: string, index: number) => {
    const updatedNgWords = [...newNgWords];
    updatedNgWords[index] = newWord;
    setNewNgWords(updatedNgWords);
  };

  // OKワード
  //Get and show all OK words
  const handleGetAllOkwords = async () => {
    try {
      const data: WordsDataResponse = await getAllOkWords(
        authContext.authData.token as string,
      );
      if (data.status) {
        setOkWords(data);
      } else {
        addAlert(data.message, 'error');
      }
      setOkWords(data);
    } catch (error) {
      console.error(error);
    }
  };
  // Delete
  const handleDeleteOkWord = async (id: string) => {
    const confirm = window.confirm('本当に削除しますか？');
    if (!confirm) return;
    try {
      const res: WordsDataResponse = await deleteOkWord(
        id,
        authContext.authData.token as string,
      );
      if (res.status) {
        addAlert('OKワードを削除しました。', 'success');
        handleGetAllOkwords();
      } else {
        addAlert(res.message, 'error');
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Register
  const handleOkWordRegistration = async () => {
    try {
      const res: WordsDataResponse = await registerOkWord(
        okWord,
        authContext.authData.token as string,
      );
      if (res.status) {
        addAlert('OKワードを登録しました。', 'success');
        handleGetAllOkwords();
      } else {
        addAlert(res.message, 'error');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setOkWord('');
    }
  };
  // Update
  const handleUpdateOkWord = async (wordId: string, newWord: string) => {
    const confirm = window.confirm('本当に更新しますか？');
    if (!confirm) return;
    try {
      const res: WordsDataResponse = await updateOkWord(
        wordId,
        newWord,
        authContext.authData.token as string,
      );
      if (res.status) {
        addAlert('OKワードを更新しました。', 'success');
        handleGetAllOkwords();
        handleOkWordIdClickCancel();
        setNewOkWords([]);
      } else {
        addAlert(res.message, 'error');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleNewOkWordChange = (newWord: string, index: number) => {
    const newUpdatedOkWords = [...newOkWords];
    newUpdatedOkWords[index] = newWord;
    setNewOkWords(newUpdatedOkWords);
  };

  if (authContext.authData.role !== 'admin') return <NotFound />;

  return (
    <WholeContainer>
      <TitleContainer>
        <Title title={'禁止ワード設定'} subTitle={'NG Words Setting'} />
      </TitleContainer>
      <GrayContainer>
        <UpperContainer>
          <SubTitle>禁止ワード新規登録</SubTitle>
        </UpperContainer>
        <InputContainer>
          <StyledInput
            type="text"
            value={ngWord}
            onChange={(e) => setNgWord(e.target.value)}
            placeholder="新規登録するNGワードを入力してください。"
          />
          <Button onClick={handleNgWordRegistration}>登録</Button>
        </InputContainer>
      </GrayContainer>
      <GrayContainer>
        <SubTitleContainer>
          <SubTitle>禁止ワード一覧</SubTitle>
          <Button onClick={handleGetAllNgwords}>一覧</Button>
        </SubTitleContainer>
        <WordListContainer>
          {ngWords?.data?.map((item, index) => (
            <WordContainer key={index}>
              {clickedNgWordId === item.ID ? (
                <>
                  <UpdateWordContainer>
                    <PendingWord>{item.Word}</PendingWord>
                    <UpdateInput
                      type="text"
                      value={newNgWords[index]}
                      onChange={(e) =>
                        handleUpdatedNgWordChange(e.target.value, index)
                      }
                      placeholder="更新後のワードを入力してください"
                    />
                  </UpdateWordContainer>
                  <ButtonContainer>
                    <UpdateInvertButton onClick={handleNgWordIdClickCancel}>
                      更新取消
                    </UpdateInvertButton>
                    <DeleteInvertButton
                      onClick={() =>
                        handleUpdateNgWord(item.ID, newNgWords[index])
                      }
                    >
                      更新確定
                    </DeleteInvertButton>
                  </ButtonContainer>
                </>
              ) : (
                <>
                  <UpdateWordContainer>
                    <Word>{item.Word}</Word>
                  </UpdateWordContainer>
                  <ButtonContainer>
                    <UpdateButton onClick={() => handleNgWordIdClick(item.ID)}>
                      更新
                    </UpdateButton>
                    <DeleteButton onClick={() => handleDeleteNgWord(item.ID)}>
                      削除
                    </DeleteButton>
                  </ButtonContainer>
                </>
              )}
            </WordContainer>
          ))}
        </WordListContainer>
      </GrayContainer>
      <TitleContainer>
        <Title title={'許可ワード設定'} subTitle={'OK Words Setting'} />
      </TitleContainer>
      <GrayContainer>
        <UpperContainer>
          <Text fontType={FontType.TITLE3}>許可ワード新規登録</Text>
        </UpperContainer>
        <InputContainer>
          <StyledInput
            type="text"
            value={okWord}
            onChange={(e) => setOkWord(e.target.value)}
            placeholder="新規登録するOKワードを入力してください。"
          />
          <Button onClick={handleOkWordRegistration}>登録</Button>
        </InputContainer>
      </GrayContainer>
      <GrayContainer>
        <SubTitleContainer>
          <SubTitle>許可ワード一覧</SubTitle>
          <Button onClick={handleGetAllOkwords}>一覧</Button>
        </SubTitleContainer>
        <WordListContainer>
          {okWords?.data?.map((item, index) => (
            <WordContainer key={index}>
              {clickedOkWordId === item.ID ? (
                <>
                  <UpdateWordContainer>
                    <PendingWord>{item.Word}</PendingWord>
                    <UpdateInput
                      type="text"
                      value={newOkWords[index]}
                      onChange={(e) =>
                        handleNewOkWordChange(e.target.value, index)
                      }
                      placeholder="更新後のワードを入力してください"
                    />
                  </UpdateWordContainer>
                  <ButtonContainer>
                    <UpdateInvertButton onClick={handleOkWordIdClickCancel}>
                      更新取消
                    </UpdateInvertButton>
                    <DeleteInvertButton
                      onClick={() =>
                        handleUpdateOkWord(item.ID, newOkWords[index])
                      }
                    >
                      更新確定
                    </DeleteInvertButton>
                  </ButtonContainer>
                </>
              ) : (
                <>
                  <UpdateWordContainer>
                    <Word>{item.Word}</Word>
                  </UpdateWordContainer>
                  <ButtonContainer>
                    <UpdateButton onClick={() => handleOkWordIdClick(item.ID)}>
                      更新
                    </UpdateButton>
                    <DeleteButton onClick={() => handleDeleteOkWord(item.ID)}>
                      削除
                    </DeleteButton>
                  </ButtonContainer>
                </>
              )}
            </WordContainer>
          ))}
        </WordListContainer>
      </GrayContainer>
    </WholeContainer>
  );
};
