import React, { useContext, useState } from 'react';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import {
  GrayContainer,
  TitleContainer,
  WholeContainer,
  UpperContainer,
  LowerContainer,
  ButtonContainer,
  SelectContainer,
} from './style';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';
import { Title } from '../../organisms/Title';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SpacerY from '../../atoms/Spacer/SpacerY';
import { AuthContextType, AuthContext } from '../../../Providers/AuthProvider';
import { NotFound } from '../NotFound';
import { useAdminUsers } from './hooks';
import {
  getAllUsersWithSearch,
  getUsersWithSearch,
  UserRes,
} from '../../../api/AdminService';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import {
  SearchIconWrapper,
  Search,
  StyledInput,
  Partition,
} from '../../atoms/SearchBar/style';
import { FormControl, InputLabel, Select } from '@material-ui/core';

interface Column {
  id:
    | 'username'
    | 'displayname'
    | 'name'
    | 'schoolName'
    | 'classType'
    | 'classFaculty'
    | 'classDepartment'
    | 'classNo';
  label: string;
  minWidth?: number;
  align?: 'center';
}

const columns: Column[] = [
  { id: 'username', label: 'ユーザー名', minWidth: 150 },
  {
    id: 'displayname',
    label: '表示名',
    minWidth: 150,
  },
  {
    id: 'name',
    label: '氏名',
    minWidth: 150,
  },
  {
    id: 'schoolName',
    label: '学校',
    minWidth: 150,
  },
  {
    id: 'classType',
    label: '課程',
    minWidth: 150,
  },
  {
    id: 'classFaculty',
    label: '学部',
    minWidth: 150,
  },
  {
    id: 'classDepartment',
    label: '学科',
    minWidth: 150,
  },
  {
    id: 'classNo',
    label: 'クラス',
    minWidth: 150,
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const AdminUsersPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const authContext: AuthContextType = useContext(AuthContext);
  const { schools, faculties, departments } = useAdminUsers();
  const [page, setPage] = useState(0);
  const [searchWord, setSearchWord] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [school, setSchool] = useState('');
  const [faculty, setFacukty] = useState('');
  const [department, setDepartment] = useState('');

  const handleSchoolChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      setSchool(event.target.value as string);
    } else {
      setSchool('');
    }
  };

  const handleFacultyChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    if (event.target.value) {
      setFacukty(event.target.value as string);
    } else {
      setFacukty('');
    }
  };

  const handleDepartmentChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    if (event.target.value) {
      setDepartment(event.target.value as string);
    } else {
      setDepartment('');
    }
  };

  const { searchedUsers } = getUsersWithSearch(
    page,
    authContext.authData.token as string,
    query +
      `${school ? `&school=${school}` : ''}` +
      `${faculty ? `&faculty=${faculty}` : ''}` +
      `${department ? `&department=${department}` : ''}`,
  );

  const { searchedAllUsers } = getAllUsersWithSearch(
    authContext.authData.token as string,
    query +
      `${school ? `&school=${school}` : ''}` +
      `${faculty ? `&faculty=${faculty}` : ''}` +
      `${department ? `&department=${department}` : ''}`,
  );

  function handleSearch() {
    setQuery('q=' + searchWord.replace(/\s/g, '+'));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  if (authContext.authData.role !== 'admin') return <NotFound />;

  return (
    <WholeContainer>
      <TitleContainer>
        <Title title={'ユーザー一覧'} subTitle={'User List'} />
      </TitleContainer>
      <GrayContainer>
        <UpperContainer>
          <Text fontType={FontType.TITLE3}>ユーザー検索</Text>
          <Search active>
            <StyledInput
              placeholder="ユーザーを探す（ユーザ名・表示名・氏名）"
              onChange={(event) => setSearchWord(event.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  e.preventDefault();
                  handleSearch();
                }
              }}
            />
            <Partition />
            <SearchIconWrapper onClick={handleSearch}>
              <SearchIcon style={{ color: 'white' }} />
            </SearchIconWrapper>
          </Search>
        </UpperContainer>
        <SelectContainer>
          <FormControl>
            <InputLabel htmlFor="outlined-age-native-simple">学校</InputLabel>
            <Select
              native
              value={school}
              onChange={handleSchoolChange}
              label="学校"
              inputProps={{
                name: 'school',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {schools &&
                schools.map((schoolValue: string) => {
                  return (
                    <option key={schoolValue} value={schoolValue}>
                      {schoolValue}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="outlined-age-native-simple">学部</InputLabel>
            <Select
              native
              value={faculty}
              onChange={handleFacultyChange}
              label="学科"
              inputProps={{
                name: 'faculty',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {faculties &&
                faculties.map((facultyValue: string) => {
                  return (
                    <option key={facultyValue} value={facultyValue}>
                      {facultyValue}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="outlined-age-native-simple">学科</InputLabel>
            <Select
              native
              value={department}
              onChange={handleDepartmentChange}
              label="学科"
              inputProps={{
                name: 'department',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {departments &&
                departments.map((departmentValue: string) => {
                  return (
                    <option key={departmentValue} value={departmentValue}>
                      {departmentValue}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </SelectContainer>
        <LowerContainer>
          <ButtonContainer>
            <Button
              onClick={handleSearch}
              buttonType={ButtonType.ROUNDEDIMPORTANT}
            >
              検索
            </Button>
          </ButtonContainer>
        </LowerContainer>
      </GrayContainer>

      <SpacerY height={50} />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`colmun-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedUsers &&
                searchedUsers.map((row: UserRes) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`row-${row.userId}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/userpage/${row.userId}`)}
                    >
                      <TableCell key={`username-${row.userId}`}>
                        {row.username}
                      </TableCell>
                      <TableCell key={`displayname-${row.userId}`}>
                        {row.displayname}
                      </TableCell>
                      <TableCell
                        key={`name-${row.userId}`}
                      >{`${row.salesforceUser.lastname} ${row.salesforceUser.firstname}`}</TableCell>
                      <TableCell key={`school-${row.userId}`}>
                        {row.salesforceUser.schoolName}
                      </TableCell>
                      <TableCell key={`classType-${row.userId}`}>
                        {row.salesforceUser.classType}
                      </TableCell>
                      <TableCell key={`faculty-${row.userId}`}>
                        {row.salesforceUser.classFaculty}
                      </TableCell>
                      <TableCell key={`department-${row.userId}`}>
                        {row.salesforceUser.classDepartment}
                      </TableCell>
                      <TableCell key={`classNo-${row.userId}`}>
                        {row.salesforceUser.classNo}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={searchedAllUsers?.length as number}
          rowsPerPage={30}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage={''}
          SelectProps={{ style: { display: 'none' } }}
          nextIconButtonProps={{ color: 'secondary' }}
          backIconButtonProps={{ color: 'secondary' }}
        />
      </Paper>
    </WholeContainer>
  );
};
