export interface CharacterProps {
  name: string;
  image: string;
  borderColor: string;
}

export interface DialogProps {
  type: 'text' | 'image';
  speaker?: string;
  text?: string;
  url?: string;
}

export const characters = [
  {
    name: 'ハロちゃん',
    kana: '',
    image: 'halo-chan-icon.png',
    borderColor: '#E95098',
  },
  {
    name: '代たまくん',
    image: 'tamago.png',
    borderColor: '#000',
  },
];

export const dialogs1: DialogProps[] = [
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'よたまくん！代々木アニメーション学院がNFTを売買できるハックツっていうサービスが開始したんだけど、NFTって何〜？',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'いい質問だね。NFTは「Non-Fungible Token」の略で、日本語で言うと「非代替可能なトークン」となるよ。',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: '簡単に言えば、デジタルの世界での一点ものの証明みたいなものだよ。',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: '一点ものの証明って、どういうこと？',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: '例えば、絵画などの芸術作品は1つしかないもので、NFTはそれがデジタルになったイメージだよ。',
  },
  {
    type: 'image',
    url: 'manualImg/1-1.png',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'デジタルデータのコピーはできても、正真正銘のオリジナルは1つしかない、という証明がNFTにはあるんだ。',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'だからデジタル上のデータなのに価値がつくんだね！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'その通り。NFTはオリジナルの価値をデジタルで保持する新しい方法として注目されているよ。',
  },
  {
    type: 'image',
    url: 'manualImg/1-2.png',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'NFTってそんなに凄いものだったんだ！でも、どうやったら私の作った画像がNFTになるの？',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'ハックツに作品を掲載して、NFT登録を申請しよう！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'NFTとして登録しても、出品するかしないかは自分で選択できるよ！自分のNFTを保持し続けても良いし、他の人に譲渡しても良いんだ！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'じゃあ、実際の画面を見ながらNFTにしてみようか！',
  },
];

export const dialogs2: DialogProps[] = [
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'よたまくん！ハックツを使っていたら、ロイヤリティっていう言葉が出てきたんだけど、なんのことなのかな〜',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'ロイヤリティについて解説する前に、ハックツで発生する収益について整理しよう！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'ハックツで発生する収益には2種類あるんだ！１つが販売収益で、もう１つがロイヤリティというものなんだ！',
  },
  {
    type: 'image',
    url: 'manualImg/2-1.png',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: '販売収益は自分が保有するNFTを他の人に販売した時に発生する収益だよ。フリマアプリと似たような仕組みだね。',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'そして、ロイヤリティは、自分が作成したNFTを他のユーザーに売却した後も、他のユーザー間で取引される度に、取引額の一定割合が出品者＝クリエイターに還元される収益のことだよ！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: '少し複雑だから、図を用いて説明するね！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'ハロチャンが作成したNFTを1,000円で販売して、購入したユーザーが2,000円で転売した場合を例に見てみよう！',
  },
  {
    type: 'image',
    url: 'manualImg/2-2.png',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'なるほど！ロイヤリティがあると、クリエイターの創作がより活発になりそうだね！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'ハックツはクリエイターのための場所なんだ！',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: '本当だ！色んな人から欲しがられるNFTを作れるように私も頑張るよ！',
  },
];

export const dialogs3: DialogProps[] = [
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'よたまくん！私も実際にNFTを出してみたい！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'そうだよね！じゃあ実際のハックツの画面を見ながらNFTを登録してみよう！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'まずは作品登録画面にアクセスしよう！',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: '画面の右上にあるピンク色のボタンだね！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: '作品登録ページに移動できたら、作ったデジタルアートの画像データのアップロードと作品名の入力をしてね！作品説明は自由に文章を入力できるよ！',
  },
  {
    type: 'image',
    url: 'manualImg/3-1.png',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: '画像データのアップロードと作品名を入力できたよ！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: `
    それじゃあ、次はいよいよ作品をNFTとして登録していくよ！ 
    `,
  },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: `
  //   それじゃあ、次はいよいよ作品をNFTとして登録していくよ！ とその前に…
  //   ハロちゃんは「Metamask」って知ってる？
  //   `,
  // },
  // {
  //   type: 'text',
  //   speaker: 'ハロちゃん',
  //   text: '「Metamask」…？',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'ハックツにNFTを登録するには、この「Metamask」が必要なんだ！ウォレットと呼ばれているよ。暗号通貨やNFTを管理するための財布のようなものだと思ってね！Metamaskは、NFTを登録するために必要なウォレットだよ！',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'それじゃあ、NFTをハックツに登録する前に「Metamask」を公式サイトからダウンロードしよう！このリンクからダウンロードしてね！',
  //   url: 'https://metamask.io/',
  // },
  // {
  //   type: 'text',
  //   speaker: 'ハロちゃん',
  //   text: '「Metamask」をダウンロードすることができたよ！',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'ハックツに戻ってNFTを登録しよう！',
  // },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'まず初めに、「作品状態」を”NFT”に変えてみよう！',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: '「作品状態」を”NFT”に変えたら、何か新しい項目が表示されたよ！',
  },
  {
    type: 'image',
    url: 'manualImg/3-2.png',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: '新しい項目について説明するね。「出品有無」はNFTを登録したあと、実際にハックツで販売するかどうかを選択できるよ！',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'じゃあ、出品有にしてNFTを販売してみようかな！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: '出品有を選択したんだね！それじゃあ次は販売価格を設定しよう！',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: '販売価格を入力したよ！',
  },
  {
    type: 'image',
    url: 'manualImg/3-3.png',
  },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'そしたら最後は、さっきダウンロードしたMetamaskをハックツに接続するよ。',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: '「ウォレットを接続する」ボタンを押してみて！',
  // },
  // {
  //   type: 'text',
  //   speaker: 'ハロちゃん',
  //   text: 'お！Metamaskのポップアップが表示されたよ！',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'Metamaskのアカウントを選択して、「次へ」を押してみて！',
  // },
  // {
  //   type: 'image',
  //   url: 'manualImg/3-4.png',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'ページが変わったら「接続」を押してね！これでMetamaskとハックツの接続が完了したよ！',
  // },
  // {
  //   type: 'text',
  //   speaker: 'ハロちゃん',
  //   text: 'やったー！でも、次はハックツの作品登録画面に別のボタンが表示されたよ？',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: '「ウォレットで署名する」ボタンだね！NFTを登録するには、ユーザーによる署名という作業が必要なんだ。',
  // },
  // {
  //   type: 'text',
  //   speaker: 'ハロちゃん',
  //   text: 'わかった！じゃあ押してみるね。またMetamaskのポップアップが表示されたよ！',
  // },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'じゃあ、そのポップアップで署名を完了させよう！下にスクロールして「署名」ボタンを押してみよう！',
  // },
  // {
  //   type: 'image',
  //   url: 'manualImg/3-5.png',
  // },
  // {
  //   type: 'text',
  //   speaker: 'ハロちゃん',
  //   text: 'ハックツに戻ったよ！しばらくしたらボタンが消えたよ。',
  // },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'あとは、「利用規約とガイドラインに同意する」にチェックを入れて、「登録する」を押そう！',
  },
  // {
  //   type: 'text',
  //   speaker: '代たまくん',
  //   text: 'これで署名が完了したよ！あとは、「利用規約とガイドラインに同意する」にチェックを入れて、「登録する」を押そう！',
  // },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: 'トップページに戻って、「NFTの登録申請が完了しました」と表示されたよ！',
  },
  {
    type: 'text',
    speaker: '代たまくん',
    text: 'おめでとう！これでNFTの登録申請が完了したよ！',
  },
  {
    type: 'text',
    speaker: 'ハロちゃん',
    text: '代たまくん！NFTの登録方法を教えてくれてありがとう！',
  },
];
