import styled from '@emotion/styled';

export const StyledToggleSwitch = styled.div`
  & input {
    display: none;
    &:checked + label {
      background-color: #e95098;
      &::before {
        left: 2em;
        background-color: #fff;
      }
    }
  }

  & label {
    background-color: rgba(126, 134, 158, 0.16);
    border-radius: 2em;
    border: 2px solid var(--text-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 2em;
    position: relative;
    transition: 0.5s;
    width: 3.75em;

    &::before {
      background: #e95098;
      border-radius: 100%;
      content: '';
      display: inline-block;
      height: 1.5em;
      position: absolute;
      left: 0.25em;
      transition: 0.5s ease-out;
      width: 1.5em;
      z-index: 2;
    }
  }
`;
