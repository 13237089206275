import styled from '@emotion/styled';
import tw from 'twin.macro';

export const HomeContainer = styled.div`
  ${tw`flex flex-col max-w-[1440px] mx-auto`}
`;

export const BannerContainer = styled.div`
  ${tw`flex flex-col my-8 md:my-20`}
`;

export const TotalNumContainer = styled.div`
  ${tw`bg-[#fff] px-4 py-2 rounded-full w-[60px] text-[14px] md:text-[16px] text-center font-bold mt-2 md:mt-0 ml-[5%]`};
  filter: drop-shodow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -webkit-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -moz-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -ms-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
`;

export const LoadingButtonContainer = styled.div`
  ${tw`flex justify-center mx-auto mt-2 mb-16`}
  width: 180px;
`;
