import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { LoadingButton } from '@mui/lab';

interface Props {
  title: string;
  description: string;
  description2?: string;
  description3?: string;
  buttonTitle: string;
  subButtonTitle: string;
  isDisplayed: boolean;
  close: () => void;
  func: () => void;
  isLoading?: boolean;
}

export const ConfirmationModal = (props: Props) => {
  const {
    title,
    description,
    description2,
    description3,
    buttonTitle,
    subButtonTitle,
    isDisplayed,
    close,
    func,
    isLoading,
  } = props;
  return (
    <Dialog
      open={isDisplayed}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
          {/* {`削除された作品はデータの復元ができません。本当によろしいですか？`} */}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {description2}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {description3}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <> </>
        ) : (
          <Button
            onClick={close}
            style={{ color: '#E95098', borderRadius: 50 }}
            color="secondary"
            variant="outlined"
          >
            {subButtonTitle}
          </Button>
        )}
        {isLoading ? (
          <LoadingButton
            onClick={func}
            style={{
              backgroundColor: '#E95098',
              color: '#fff',
              borderRadius: 50,
              width: '90px',
              height: '36px',
            }}
            variant="contained"
            loading
          />
        ) : (
          <Button
            onClick={func}
            style={{
              backgroundColor: '#E95098',
              color: '#fff',
              borderRadius: 50,
              padding: '4px 8px',
            }}
            variant="contained"
          >
            {buttonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
