import { Text } from '../../atoms/Text';
import React from 'react';
import {
  ImgContainer,
  StyledButtonContainer,
  StyledNotFoundContainer,
  StyledNotFoundTextFrame,
  SuccessImg,
} from './style';
import { FontType } from '../../../constants/Fonts';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../../constants/Color';
import SpacerY from '../../atoms/Spacer/SpacerY';

export const SendSuccess: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyledNotFoundContainer>
      <ImgContainer>
        <SuccessImg src={`${process.env.PUBLIC_URL}/success-email.png`} />
      </ImgContainer>
      <StyledNotFoundTextFrame>
        <Text fontType={FontType.TITLE2} color={Color.BRANDMAIN}>
          パスワード再設定用メールを送信しました！
        </Text>
        <SpacerY height={24} />
        <Text fontType={FontType.TITLE3} color={Color.BRAND20}>
          ご登録のアドレス宛にパスワード再設定ページのリンクを送信しました。
        </Text>
        <Text fontType={FontType.TITLE3} color={Color.BRAND20}>
          再設定ページにてパスワードの変更をお願いします。
        </Text>
        <SpacerY height={24} />
        <StyledButtonContainer>
          <Button
            buttonType={ButtonType.IMPORTANT}
            onClick={() => navigate('/')}
          >
            <Text fontType={FontType.TITLE4} color={Color.WHITETEXT}>
              TOPページに戻る
            </Text>
          </Button>
        </StyledButtonContainer>
      </StyledNotFoundTextFrame>
    </StyledNotFoundContainer>
  );
};
