import styled from '@emotion/styled';
import tw from 'twin.macro';

export const FooterContainer = styled.div`
  ${tw`grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 px-8 md:px-20 py-4 md:w-[100vw] max-w-[1440px] mx-auto bg-black`}
`;

export const SocialContainer = styled.div`
  ${tw`flex flex-col justify-start mr-4 max-w-xs`}
`;

export const SocialLogoContainer = styled.div`
  ${tw`flex flex-row mt-4 justify-between`}
`;

export const GridContainer = styled.div`
  ${tw`col-span-1 flex flex-col justify-center pt-3 pl-4`}
`;

export const SocialGridContainer = styled.div`
  ${tw`col-span-1 md:col-span-2 flex flex-col mb-6`}
`;

export const DoubleGridContainer = styled.div`
  ${tw`col-span-2 md:col-span-4 lg:col-span-2 flex flex-col border-2 border-indigo-600`}
`;

export const SingleGridContainer = styled.div`
  ${tw`col-span-1 md:col-span-1 px-2 mb-6`}
`;

export const SearchNFTContainer = styled.div`
  ${tw`flex-grow flex flex-col gap-y-3`}
`;

export const LogoContainer = styled.div`
  ${tw`flex justify-center max-w-xs mb-4 w-[60%] md:w-[100%] mx-auto md:mx-0`}
`;

export const YoaniLogo = styled.img`
  ${tw`px-2 w-[100%]`}
`;

export const ColTitle = styled.div`
  ${tw`col-span-1 mb-6`}
`;
export const LinkContainer = styled.div`
  ${tw`flex flex-col gap-y-3 pr-[4px]`}
  cursor: pointer;
`;

export const SocialLogo = styled.img`
  max-width: 2rem;
  cursor: pointer;
`;

export const FooterBorder = styled.div`
  ${tw`h-[1px] bg-white mx-2 absolute bottom-0 w-[85%] max-w-[1440px]`}
`;

export const FooterBg = styled.div`
  ${tw`h-[40px] bg-black relative flex justify-center`}
`;
