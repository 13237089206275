import React from 'react';
import {
  StyledButton,
  IconContainer,
  TextContainer,
  ButtonContainer,
  IconWrapper,
} from './style';

export const TweetButton = () => {
  const handleTweet = () => {
    const tweetText = `代アニNFTマーケットプレイスで作品をチェックしよう!: ${window.location.href}`;
    const tweetUrl = `
      https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}
    `;
    window.open(tweetUrl, '_blank');
  };

  return (
    <ButtonContainer>
      <StyledButton onClick={handleTweet}>
        <IconWrapper>
          <IconContainer src={`${process.env.PUBLIC_URL}/x_logo_white.png`} />
        </IconWrapper>
        <TextContainer>
          <p>Xで共有</p>
        </TextContainer>
      </StyledButton>
    </ButtonContainer>
  );
};
