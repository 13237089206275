import React from 'react';
import { Required } from './style';

export const RequiredText = () => {
  return (
    <Required>
      <span>※</span>
      <span>必須</span>
    </Required>
  );
};
