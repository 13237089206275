import styled from '@emotion/styled';
import tw from 'twin.macro';

export const RemittanceWrapper = styled.div`
  ${tw`flex flex-col items-center justify-center gap-16 w-full max-w-[84vw] 2xl:max-w-[1440px] mx-auto mb-[2rem]`}
`;

export const TitleWrapper = styled.div`
  ${tw`flex flex-row items-start justify-start w-full `}
`;

export const RemittancableDepositWrapper = styled.div`
  ${tw`flex flex-col items-start justify-center gap-8 w-full `}
`;

export const RemittancableDepositContainer = styled.div`
  ${tw`flex flex-row items-center justify-center gap-8 w-full h-[96px]`}
`;

export const RemittancableDepositBox = styled.div`
  ${tw`flex items-center justify-center w-[80%] lg:w-[40%] h-full bg-white border-solid border-2 border-black`}
`;

export const RemittanceAmountWrapper = styled.div`
  ${tw`flex flex-col items-start justify-center gap-8 w-full `}
`;

export const RemittanceAmountContainer = styled.div`
  ${tw`flex flex-row items-center justify-center gap-8 w-full h-[96px]`}
`;

export const RemittanceFeeWrapper = styled.div`
  ${tw`flex flex-col justify-start gap-2 mt-8 w-full max-w-[1440px]`}
`;

export const RemittanceFeeContainer = styled.div`
  ${tw`flex mb-4 bg-white px-8 py-4 rounded-lg border-solid border-2 border-black`}
  width: calc(100% - 4rem - 4px);
`;

export const CheckBoxWrapper = styled.div`
  ${tw`md:flex gap-1 items-center justify-start mt-8 mb-2 w-full`}
`;

export const WideButtonContainer = styled.div`
  ${tw`flex flex-row my-4 w-full `}
`;
