/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useCallback, useEffect } from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import Grid from './Grid';
import SortableItem from './Sortable';
import Item from './Item';
import { Art } from '../../../types/domain/Art';

type Props = {
  arts: Art[];
  isEditing: boolean;
  setItemIds: React.Dispatch<React.SetStateAction<any[]>>;
};

const SortableItems: React.FC<Props> = (props: Props) => {
  const { arts, setItemIds } = props;
  const [items, setItems] = useState<any[]>(arts);
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as any);
  }, []);
  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex(
          (item) => item.id === (over?.id as string),
        );

        const newItems = arrayMove(items, oldIndex, newIndex);
        const newItemIds = newItems.map((item) => item.id);
        setItemIds(newItemIds);
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }, []);
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  useEffect(() => {
    if (!items.length) {
      setItems(arts);
    }
  }, [arts]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      // autoScroll
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Grid>
          {items.map((item: Art, index) => (
            <SortableItem
              key={`sortableitem-${item.id}`}
              id={item.id}
              index={index}
              item={item}
            />
          ))}
        </Grid>
        <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
          {activeId ? (
            <Item
              id={activeId}
              isDragging
              index={items.indexOf(activeId)}
              item={items.filter((item) => item.id === activeId)[0]}
            />
          ) : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default SortableItems;
