/*import React from 'react';
import Cropper from 'react-cropper';
import { PopUpWindow } from '../atoms/PopUpWindow';
import { useState } from 'react';
import { useAuth } from '../../api/AuthService';*/

/*export interface CropperProps {
  width: number;
  height: number;
  defaultImg: string;
  isDisplayed: boolean;
  setIsDisplayed: (isDisplayed: boolean) => void;
  fileType: 'icon' | 'header';
}

async function dataUrlToFile(dataURL: string, filename: string): Promise<File> {
  const blob = await (await fetch(dataURL)).blob();
  return new File([blob], filename);
}

export const CropperPopUp: React.FC<CropperProps> = (props) => {
  const [cropper, setCropper] = useState<any>();
  const [imageName, setImageName] = useState('');
  const [image, setImage] = useState<string | ArrayBuffer | null>('');
  const [imageString, setImageString] = useState(props.defaultImg);
  const handleClose = () => {
    props.setIsDisplayed(false);
  };

  const getCropData = async (e: any) => {
    e.preventDefault();
    const { uploadProfileImage } = useAuth();
    const type = props.fileType;
    if (typeof cropper !== 'undefined') {
      //デフォルトのPNGはファイルサイズが大きいのでjpegにする
      const imagedata = await cropper
        .getCroppedCanvas()
        .toDataURL('image/jpeg');
      setImageString(imagedata);
      const imageFile = await dataUrlToFile(imagedata, imageName);
      // アップロード処理
      /*console.log('アップロード処理');
      const storageRef = storage.ref("images/test/"); //どのフォルダの配下に入れるかを設定
      const imagesRef = storageRef.child(imageName); //ファイル名

      console.log('ファイルをアップする行為');
      const upLoadTask = imagesRef.putString(imagedata, 'data_url');
      const input = { imageFile, type };
      await uploadProfileImage(input);
    }
    return;
  };

  const handleImage = (e: any) => {
    try {
      const imageFile = e.target.files[0];
      setImageName(imageFile.name); //アップロード時のファイル名で使用
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      props.setIsDisplayed(true);
      e.target.value = null; //ファイル選択された内容をクリアする（クリアしないと同じファイルが編集できない）
    } catch {
      e.target.value = null;
      props.setIsDisplayed(false);
    }
  };

  return (
    <PopUpWindow isDisplayed={props.isDisplayed} close={handleClose}>
      <form>
        <input type="file" onChange={handleImage} />
      </form>
      {image && (
        <div>
          <img width="400px" src={image as any} alt="uploaded" />
        </div>
      )}
      <Cropper
        style={{ height: 400, width: '100%' }}
        initialAspectRatio={1}
        preview=".img-preview"
        src={imageString}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        guides={true}
      />
      <button onClick={getCropData}>a</button>
    </PopUpWindow>
  );
};*/
import { useCallback } from 'react';
import { useState } from 'react';
import React from 'react';
import { PopUpWindow } from '../atoms/PopUpWindow';
import ImageCropper from '../Cropper/index';
import { useRef } from 'react';
import { useAuth } from '../../api/AuthService';
import { Button } from '../atoms/Button';
import { ButtonType } from '../../constants/Button';
import { useAlertContext } from '../../Providers/AlertProvider';
import {
  WideButtonContainer,
  HeaderCropperContainer,
  IconCropperContainer,
} from './style';
interface UploadImageProps {
  image?: File;
  type: 'icon' | 'header';
}
export interface CropperProps {
  width: number;
  height: number;
  defaultImg: string;
  isDisplayed: boolean;
  setIsDisplayed: (isDisplayed: boolean) => void;
  fileType: 'icon' | 'header';
  setImageString: (image: string) => void;
}

/*async function dataUrlToFile(dataURL: string, filename: string): Promise<File> {
  const blob = await (await fetch(dataURL)).blob();
  return new File([blob], filename);
}*/

export function CropperPopUp(props: CropperProps) {
  const { uploadProfileImage } = useAuth();
  const previewCanvasRef = useRef(null);
  const [src, setSrc] = useState('');
  const [croppedFile, setCroppedFile] = useState<File | null>(null);
  const [open, setOpen] = useState(false);
  const { addAlert } = useAlertContext();
  const handleClose = () => {
    const targetFile = document.getElementById('file') as HTMLInputElement;
    targetFile.value = '';
    //alert('a');
    props.setIsDisplayed(false);
  };
  const handleChangeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file =
        e.target.files !== null && e.target.files[0] ? e.target.files[0] : null;
      if (file) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setSrc(reader.result?.toString() || ''),
        );
        reader.readAsDataURL(file);
        setOpen(true);
      }
      const targetFile = document.getElementById('file') as HTMLInputElement;
      targetFile.value = '';
    },
    [setSrc],
  );
  function uploadImage() {
    const type = props.fileType;
    const targetFile = document.getElementById('file') as HTMLInputElement;
    if (targetFile.value == '' && croppedFile == null) {
      addAlert('画像を選択して切り抜きたい位置で確定してください', 'error');
      return;
    }
    if (croppedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(croppedFile);
      reader.onload = (event) => {
        if (event.target != null) {
          props.setImageString(event.target.result as string);
        }
      };
      const input: UploadImageProps = {
        image: croppedFile,
        type: type,
      };
      uploadProfileImage(input).then(handleClose);
      addAlert('画像が更新されました', 'success');
    }
  }
  return (
    <PopUpWindow
      close={handleClose}
      isDisplayed={props.isDisplayed}
      isLarge={true}
    >
      <input
        type="file"
        id="file"
        accept=".png,.jpg,.jpeg"
        onChange={handleChangeFile}
        style={{
          marginTop: '10px',
          marginBottom: '10px',
        }}
      />
      <div style={{ flexGrow: 1 }} />
      {props.fileType == 'header' && (
        <HeaderCropperContainer>
          <canvas
            ref={previewCanvasRef}
            style={{
              objectFit: 'contain',
              width: '600px',
              height: '140px',
            }}
          />
          <ImageCropper
            open={open}
            setOpen={setOpen}
            aspect={{ width: 1200, height: 320 }}
            src={src}
            previewCanvasRef={previewCanvasRef}
            setCroppedFile={setCroppedFile}
          />
        </HeaderCropperContainer>
      )}
      {props.fileType == 'icon' && (
        <IconCropperContainer>
          <canvas
            ref={previewCanvasRef}
            style={{
              objectFit: 'contain',
              width: '380px',
              height: '380px',
            }}
          />
          <ImageCropper
            open={open}
            setOpen={setOpen}
            aspect={{ width: 400, height: 400 }}
            src={src}
            previewCanvasRef={previewCanvasRef}
            setCroppedFile={setCroppedFile}
          />
        </IconCropperContainer>
      )}
      <div style={{ flexGrow: 1 }} />
      <WideButtonContainer>
        <Button onClick={uploadImage} buttonType={ButtonType.ROUNDEDIMPORTANT}>
          登録する
        </Button>
      </WideButtonContainer>
      <WideButtonContainer>
        <Button onClick={handleClose} buttonType={ButtonType.WIDEROUNDED}>
          キャンセル
        </Button>
      </WideButtonContainer>
    </PopUpWindow>
  );
}
