import styled from '@emotion/styled';
import tw from 'twin.macro';

export const GrayContainer = styled.div`
  ${tw`col-start-1 col-span-2 flex flex-col justify-center p-8 mb-4`}
  background-color: rgba(126, 134, 158, 0.08);
  border-radius: 10px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 100px;
  display: flex;
  align-items: flex-end;
`;

export const WholeContainer = styled.div`
  ${tw`w-[90%] max-w-[1440px] mx-auto`}
`;

export const UpperContainer = styled.div`
  ${tw`flex flex-row flex-wrap gap-x-2 mb-4 mt-4`}
  align-items: center;
`;

export const SelectContainer = styled.div`
  ${tw`flex flex-col md:flex-row md:items-center gap-x-4 mb-4`}
`;

export const LowerContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
