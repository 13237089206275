import React, { useState } from 'react';
import { resendVerifyEmail } from '../../../api/UsersService';

import {
  MailCertificationWrapper,
  TextContainer,
  TextDetail,
  StyledButtonContainer,
  StyledButton,
  // StyledButtonDisable,
  // StyledResendButton,
  // StyledTextContainer,
  StyledButtonWh,
} from './style';
import { Title } from '../../organisms/Title';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';

import { useAlertContext } from '../../../Providers/AlertProvider';
import { AuthContextType, AuthContext } from '../../../Providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

import { useUser } from './hooks';

export const ResendAuthEmail = () => {
  const { addAlert } = useAlertContext();
  const authContext: AuthContextType = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const userId = authContext ? authContext.authData.userId : '0';
  const { userData } = useUser(userId);
  const email = userData?.email;

  const handleResend = async () => {
    try {
      const data = await resendVerifyEmail(email);
      if (data.code === 200) {
        addAlert('メールアドレスの認証メールを再送しました。', 'success');
        setIsSuccess(true);
      } else if (data.code === 500) {
        addAlert(
          '一度ログインし、マイページから認証メールを再送してください。',
          'error',
        );
      } else {
        addAlert(data.mesasge, 'error');
        setIsSuccess(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleResetting = () => {
    if (userId === undefined) {
      addAlert(
        '一度ログインしてからマイページの設定にアクセスしてください。',
        'error',
      );
      setIsSuccess(false);
      return;
    } else {
      navigate(`/userpage/${userId}/settings`);
    }
  };

  return (
    <MailCertificationWrapper>
      <Title title="認証メール送信" subTitle="Send Auhtentification Email" />
      {!isSuccess ? (
        <>
          <TextContainer>
            <TextDetail>
              作品の登録・NFTの購入には、メールアドレスの認証が必要です。
              <br />
              以下のボタンを押して、認証メールを送信してください。
            </TextDetail>
          </TextContainer>
          <StyledButtonContainer>
            <StyledButton onClick={handleResend}>
              <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
                認証メールを送信する
              </Text>
            </StyledButton>
          </StyledButtonContainer>
        </>
      ) : (
        <StyledButtonContainer>
          <TextContainer>
            <TextDetail>
              認証メールを送信しました。ご登録しているメールアドレスの受信ボックスをご確認ください。
            </TextDetail>
          </TextContainer>
          <StyledButton onClick={() => navigate('/')}>
            <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
              トップページへ
            </Text>
          </StyledButton>
          <StyledButtonWh onClick={() => navigate(`/userpage/${userId}`)}>
            <Text fontType={FontType.TITLE3} color={Color.BRANDMAIN}>
              マイページへ
            </Text>
          </StyledButtonWh>
        </StyledButtonContainer>
      )}
      {!isSuccess && (
        <>
          <StyledButtonContainer>
            <Button
              buttonType={ButtonType.WIDEROUNDED_OUTLINED}
              onClick={handleResetting}
            >
              <Text fontType={FontType.TITLE3}>メールアドレスを再設定する</Text>
            </Button>
          </StyledButtonContainer>
          <TextContainer>
            <TextDetail>
              ※認証メールが届かない場合は、迷惑メールフォルダもご確認ください。
              <br />
              ※迷惑メールフォルダにもメールが届かない場合は、上記のリンクからメールアドレスを再設定してください。
            </TextDetail>
          </TextContainer>
        </>
      )}
    </MailCertificationWrapper>
  );
};
