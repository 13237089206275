import styled from '@emotion/styled';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInput = styled.input`
  appearance: none;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 10px;
  background-color: rgba(126, 134, 158, 0.16);
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
  font-size: 14px;
  &:read-only::placeholder {
    color: black;
  }
`;

export const PasswordPassButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EyeIcon = styled.img`
  width: 24px;
  height: 24px;
`;
