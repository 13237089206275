import { API_URL } from '../config';

/**
 * Register NG Word
 * POST: /restricted/ngWords
 * @param word NG word
 * @param token JWT token
 */
export const registerNgWord = async (word: string, token: string) => {
  const url = API_URL + '/restricted/ngWords';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const body = JSON.stringify({ word });

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: body,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  const data = await response.json();
  return data;
};

/**
 * Get NG Words
 * GET: /restricted/ngWords
 * @param token JWT token
 * @returns NG words
 */
export const getAllNgWords = async (token: string) => {
  const response = await fetch(API_URL + '/restricted/ngWords', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Error while fetching NG words');
  }
  const data = await response.json();
  return data;
};

/**
 * Delete NG Word
 * DELETE: /restricted/ngWords
 * @param wordId NG word ID
 * @param token JWT token
 */
export const deleteNgWord = async (wordId: string, token: string) => {
  const url = API_URL + '/restricted/ngWords/' + wordId;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const response = await fetch(url, {
    method: 'DELETE',
    headers: headers,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  const data = await response.json();
  return data;
};

/**
 * Update NG Word
 * PUT: /restricted/ngWords
 * @param wordId NG word ID
 * @param newWord New NG word
 * @param token JWT token
 */
export const updateNgWord = async (
  wordId: string,
  newWord: string,
  token: string,
) => {
  const url = API_URL + '/restricted/ngWords/' + wordId;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const body = JSON.stringify({ word: newWord });

  const response = await fetch(url, {
    method: 'PUT',
    headers: headers,
    body: body,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  const data = await response.json();
  return data;
};

/**
 * Register OK Word
 * POST: /restricted/okWords
 * @param word OK word
 * @param token JWT token
 */
export const registerOkWord = async (word: string, token: string) => {
  const url = API_URL + '/restricted/okWords';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const body = JSON.stringify({ word });

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: body,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  const data = await response.json();
  return data;
};

/**
 * Get OK Words
 * GET: /restricted/okWords
 * @param token JWT token
 * @returns OK words
 */
export const getAllOkWords = async (token: string) => {
  const response = await fetch(API_URL + '/restricted/okWords', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Error while fetching OK words');
  }
  const data = await response.json();
  return data;
};

/**
 * Delete OK Word
 * DELETE: /restricted/okWords
 * @param wordId OK word ID
 * @param token JWT token
 */
export const deleteOkWord = async (wordId: string, token: string) => {
  const url = API_URL + '/restricted/okWords/' + wordId;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const response = await fetch(url, {
    method: 'DELETE',
    headers: headers,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  const data = await response.json();
  return data;
};

/**
 * Update OK Word
 * PUT: /restricted/okWords
 * @param wordId OK word ID
 * @param newWord New OK word
 * @param token JWT token
 */
export const updateOkWord = async (
  wordId: string,
  newWord: string,
  token: string,
) => {
  const url = API_URL + '/restricted/okWords/' + wordId;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const body = JSON.stringify({ word: newWord });

  const response = await fetch(url, {
    method: 'PUT',
    headers: headers,
    body: body,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  const data = await response.json();
  return data;
};
