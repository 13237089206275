import styled from '@emotion/styled';
import tw from 'twin.macro';

interface ColorProps {
  color: string;
}

interface ImgProps {
  url: string | undefined;
}

const hexToRGBA = (hex: string, alpha: number) => {
  const [r, g, b] = [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16),
  ];
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getColor = (color: string | undefined) => {
  switch (color) {
    case '#E95098':
      return hexToRGBA('#E95098', 0.2);
    case '#00A1E9':
      return hexToRGBA('#00A1E9', 0.2);
    case '#FDD000':
      return hexToRGBA('#FDD000', 0.2);
    default:
      return 'rgba(0, 0, 0, 0.1)';
  }
};

export const StyledAwardItemContainer = styled.div<ColorProps>`
  ${tw`m-1 md:m-2 flex flex-col h-full bg-white rounded-md`}
  filter: drop-shadow(5px 8px 8px ${(props) => getColor(props.color)});
  -webkit-filter: drop-shadow(5px 8px 8px ${(props) => getColor(props.color)});
  -moz-filter: drop-shadow(5px 8px 8px ${(props) => getColor(props.color)});
  -ms-filter: drop-shadow(5px 8px 8px ${(props) => getColor(props.color)});
  transition: transform 0.2s ease-out;
  &:hover {
    transform: scale(1.02);
  }
`;

export const StyledAwardTextWrapper = styled.div`
  ${tw`bg-white px-1 md:px-2 py-2 rounded-t-lg grow`}
`;

export const StyledAwardTextContainer = styled.div<ColorProps>`
  ${tw``}
  border-left: 4px solid ${(props) => props.color};
`;

export const StyledAwardCategoryContainer = styled.div`
  ${tw`flex items-center mb-2`}
`;

export const StyledAwardText = styled.div`
  ${tw`text-[8px] sm:text-[16px] text-left font-bold block pl-2 items-center`}
`;
export const StyledText = styled.div`
  ${tw`text-[11px] sm:text-[12px] text-left font-bold block pl-2 items-center`}
`;

export const StyledAwardImageContainer = styled.div<ImgProps>`
  ${tw`flex justify-center relative bg-cover rounded-md ease-out duration-300 h-[200px] md:h-[100px] md:pt-[100%] cursor-pointer hover:opacity-90`}
  transform: translateX;
  background-image: url(${({ url }) => {
    return url;
  }});
  background-position: 50% 50%;
`;

export const StyledInfoContainer = styled.div`
  ${tw`w-[90%] absolute bottom-2 bg-black bg-opacity-70 rounded-md px-2 hidden sm:block`}
`;

export const StyledArtTitle = styled.p`
  ${tw`text-[12px] text-white`}
`;

export const StyledItemBottom = styled.div`
  ${tw`flex items-center justify-between mx-2 mb-2 relative`}
`;

export const StyledArtistName = styled.div`
  ${tw`text-[4px] flex flex-row ml-1 hidden md:block`}
`;

export const StyledUserInfoConateiner = styled.div`
  ${tw`flex items-center justify-between`}
`;
export const StyledArtistPFP = styled.img`
  ${tw`h-6 w-6 rounded-full hidden md:block`}
`;

export const StyledArtistInfo = styled.div`
  ${tw`flex items-center`}
`;

export const StyledLike = styled.div`
  ${tw`absolute bottom-0 right-0 flex rounded-full gap-1 h-[32px] md:p-0 bg-white md:bg-none md:justify-end items-center z-10`}
  @media (max-width: 768px) {
    filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
    -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
    -mozfilter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
    -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  }
`;

export const TextContainer = styled.div`
  ${tw`flex justify-center items-center pr-3`}
`;

export const StyledPrice = styled.div`
  ${tw`absolute text-center py-1 rounded-xl bg-white px-4 md:px-6 bottom-[85%] right-2 md:bottom-2 md:right-2 flex md:block md:justify-center md:items-center`}
`;

export const StyledNFTText = styled.p`
  ${tw`text-xs font-bold m-0`}
  background: linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%);
  color: transparent;
  -webkit-background-clip: text;
`;

export const StyledPriceText = styled.p`
  ${tw`text-sm font-bold m-0 hidden md:block`}
  background: linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%);
  color: transparent;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
`;

export const StyledTitle = styled.div`
  ${tw`flex justify-between whitespace-nowrap my-1 md:my-2 mx-2 items-start hidden md:block`}
`;

export const StyledImageContainer = styled.div<ImgProps>`
  ${tw`flex justify-center relative bg-cover ease-out duration-300 h-[200px] md:h-[100px] md:pt-[100%] cursor-pointer hover:opacity-90`}
  transform: translateX;
  background-image: url(${({ url }) => {
    return url;
  }});
  background-position: 50% 50%;
`;
