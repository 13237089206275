// /* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';

import {
  StyledArtistName,
  StyledArtistPFP,
  StyledImageContainer,
  StyledImageSkeleton,
  StyledItemBottom,
  StyledItemCardContainer,
  StyledLike,
  StyledPrice,
  StyledPriceText,
  StyledRank,
  StyledTitle,
  StyledArtistInfo,
  StyledNFTText,
  TextContainer,
} from './style';

import { Text } from '../../atoms/Text';
import { NowrapText } from '../../atoms/NowrapText';

import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import { CardType } from '../../../constants/CardType';

import { Art } from '../../../types/domain/Art';

import { priceRenderer } from '../../../lib/priceRenderer';

import { AuthContext } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';

import { addLikeByUser, removeLikeByUser } from '../../../api/ArtsService';

export interface ArtCardProps {
  _art: Art;
  cardType?: CardType;
  rank?: number;
  isSortable?: boolean;
  kind: string;
  selectedTagId?: string;
  query?: string;
}

// TODO Add loading failed photo
export const ArtCard: React.FC<ArtCardProps> = ({
  _art,
  cardType = CardType.NORMAL,
  rank,
  isSortable,
  kind,
  selectedTagId,
  query,
}) => {
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);

  const ranking = () => {
    if (cardType != CardType.RANKING) return;
    return (
      <StyledRank>
        <Text color={Color.WHITETEXT}>{rank}</Text>
      </StyledRank>
    );
  };

  const artValue = _art?.value == void 0 ? 0 : _art?.value;

  const price = () => {
    if (!_art.value || !(_art.isNFT && _art.isDisplay)) return;
    return (
      <StyledPrice>
        <StyledNFTText>NFT</StyledNFTText>
        {authData.role === 'admin' && (
          <StyledPriceText>{priceRenderer(artValue)}</StyledPriceText>
        )}
      </StyledPrice>
    );
  };

  const nftItem = () => {
    if (_art.isNFT && !_art.isDisplay) {
      return (
        <StyledPrice>
          <StyledNFTText>NFT</StyledNFTText>
        </StyledPrice>
      );
    }
  };

  // いいね機能
  const { addAlert } = useAlertContext();
  const [isLiked, setIsLiked] = useState(_art?.isLiked as boolean);
  const [currentLikes, setCurrentLikes] = useState<number>(0);

  useEffect(() => {
    if (_art != undefined) {
      setCurrentLikes(_art?.like as number);
    }
  }, [_art]);

  useEffect(() => {
    if (_art != undefined) {
      setIsLiked(_art?.isLiked as boolean);
    }
  }, [_art]);

  const handleAddLike = () => {
    if (authData.token) {
      addLikeByUser(
        authData.userId as string,
        _art?.id as string,
        authData.token as string,
      );
      setCurrentLikes(currentLikes + 1);
      setIsLiked(true);
    } else {
      addAlert(
        'ログインまたはアカウント作成した上で、もう一度お試し下さい',
        'warning',
      );
    }
  };

  const handleRemoveLike = () => {
    if (currentLikes <= 0) return; //likesが0以下の場合はキャンセル
    if (authData.token) {
      removeLikeByUser(
        authData.userId as string,
        _art?.id as string,
        authData.token as string,
      );
      setCurrentLikes(currentLikes - 1);
      setIsLiked(false);
    }
  };

  const like = () => {
    return (
      <StyledLike>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={isLiked ? handleRemoveLike : handleAddLike}
          edge="end"
        >
          {isLiked ? (
            <MdOutlineFavorite size="20px" color={Color.BRANDMAIN} />
          ) : (
            <MdOutlineFavoriteBorder size="20px" color={Color.BRANDMAIN} />
          )}
        </IconButton>
        <TextContainer>
          <Text fontType={FontType.SUB} color={Color.BRANDMAIN}>
            {currentLikes}
          </Text>
        </TextContainer>
      </StyledLike>
    );
  };

  // Prettierエラー回避用
  const isNFTAndDisplay = _art?.isNFT && _art?.isDisplay;
  const isPurchasingByUser =
    _art?.artPurchase?.status == 'purchasing' &&
    authData.userId == _art?.artPurchase?.buyerId;
  const notPurchasing = _art?.artPurchase?.status !== 'purchasing';

  return (
    <StyledItemCardContainer cardType={cardType} isSortable={isSortable}>
      {_art ? (
        <>
          <StyledImageContainer
            url={_art.entityUrl}
            isSortable={isSortable}
            onClick={() =>
              navigate(`/items/${_art.id}`, {
                state: {
                  kind: kind,
                  selectedTagId: selectedTagId,
                  query: query,
                },
              })
            }
          >
            {ranking()}
            {isNFTAndDisplay && (isPurchasingByUser || notPurchasing) && (
              <>{price()}</>
            )}
            {nftItem()}
          </StyledImageContainer>
          <StyledTitle>
            <Text fontType={FontType.TITLE4}>
              {_art.name.length > 10
                ? _art.name.slice(0, 10) + '...'
                : _art.name}
            </Text>
          </StyledTitle>
          <StyledItemBottom>
            <StyledArtistInfo>
              {_art.ownerInfo.profileImage ? (
                <StyledArtistPFP src={_art.ownerInfo.profileImage} />
              ) : (
                <StyledArtistPFP
                  src={`${process.env.PUBLIC_URL}/default-icon.png`}
                />
              )}
              <StyledArtistName>
                <NowrapText fontType={FontType.TITLE5} color={Color.BRAND30}>
                  {_art.ownerInfo.displayname
                    ? _art.ownerInfo.displayname.slice(0, 10) + '...'
                    : 'deleted user'}
                </NowrapText>
              </StyledArtistName>
            </StyledArtistInfo>
            {like()}
          </StyledItemBottom>
        </>
      ) : (
        // TODO FIX THEM
        <>
          <StyledImageSkeleton />
          <StyledTitle>
            <Text fontType={FontType.TITLE3}>Loading...</Text>
          </StyledTitle>
        </>
      )}
    </StyledItemCardContainer>
  );
};
