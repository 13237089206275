import styled from '@emotion/styled';
import tw from 'twin.macro';

export const StyledInput = styled.input`
  ${tw`flex-grow`}
  appearance: none;
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: rgba(126, 134, 158, 0.16);
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
  font-size: 14px;
  &:read-only::placeholder {
    color: black;
  }
`;
