import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Button } from '../../atoms/Button';

export const LoginButton = styled(Button)`
  margin-top: 10%;
  display: block;
`;

export const TextContainer = styled.div`
  ${tw`w-full flex items-center justify-start gap-1 my-4`}
`;

export const TextButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  margin: 2% 0% 2% 0%;
  cursor: pointer;
`;

export const WholeWrapper = styled.div`
  ${tw`mt-10 mx-auto flex flex-col w-[90%] md:w-[60%]`}
`;

export const Spacer = styled.div`
  width: 100%;
  height: 30px;
`;
