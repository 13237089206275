import { getArts, getTags } from '../../../api/ArtsService';
import { searchUsersByQuery } from '../../../api/UsersService';
import { useContext } from 'react';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

export const useSearch = (query: string) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const token: string | undefined = authContext.authData?.token || undefined;

  const searchedItemsData = getArts(query, token);
  const { tags } = getTags('popularity=true');
  const searchedUsers = searchUsersByQuery(query);
  return { searchedItemsData, tags, searchedUsers };
};
