import styled from '@emotion/styled';

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 250px;
  border: none;
  border-radius: 10px;
  resize: none;
  background-color: rgba(126, 134, 158, 0.16);
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
`;
