import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Text } from '../../atoms/Text';

export const NotificationsWrapper = styled.div`
  ${tw`mt-12 flex flex-col w-[80%] max-w-[1440px] mx-auto`}
`;

export const TabsWrapper = styled.div`
  ${tw`flex flex-col justify-center mb-20`}
`;

export const TextContainer = styled.div`
  margin: 2% 0% 2% 0%;
  window: 100%;
  height: 5%;
`;

export const AnnotatedTextContainer = styled.div`
  margin: 2% 0% 2% 0%;
  ${tw`flex flex-row flex-wrap`}
  ${tw`mb-8 gap-4 items-end items-center`}
`;

export const StyledText = styled(Text)`
  margin: 2% 0% 2% 0%;
`;

export const ButtonWrapper = styled.div`
  ${tw`flex flex-row justify-start gap-4 mt-4 mb-12`}
`;
