import { useEffect, useRef } from 'react';

import { getArts, getTags } from '../../api/ArtsService';

export const useRegisterArt = () => {
  const { tags } = getTags('');
  const { reload } = getArts();
  function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }
  return { tags, reload, usePrevious };
};
