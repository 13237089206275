import {
  getAllUsers,
  getSFDepartments,
  getSFFaculties,
  getSFSchools,
} from '../../../api/AdminService';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';

export const useAdminUsers = () => {
  const authContext: AuthContextType = useAuthProvider();

  const { users } = getAllUsers(authContext.authData.token as string);
  const { schools } = getSFSchools(authContext.authData.token as string);
  const { faculties } = getSFFaculties(authContext.authData.token as string);
  const { departments } = getSFDepartments(
    authContext.authData.token as string,
  );

  return { users, schools, faculties, departments };
};
