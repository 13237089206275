import styled from '@emotion/styled';
import tw from 'twin.macro';

export const MyPageContainer = styled.div`
  ${tw`max-w-[1440px] mx-auto w-[95%]`}
`;

export const TotalDisplayContainer = styled.div`
  ${tw`w-[90%] md:w-[60%] mx-auto bg-white flex flex-col justify-center items-center p-2 rounded-xl md:mt-12`}
  filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -moz-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
`;
