import styled from '@emotion/styled';
import tw from 'twin.macro';

type Props = {
  status: string;
};

export const HistoryWrapper = styled.div`
  ${tw`mb-20`}
`;

export const CreateUrl = styled.div`
  ${tw`bg-[#E95098] text-white text-xs md:text-sm text-center rounded-full cursor-pointer py-2 font-bold`}
  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease;
    opacity: 0.8;
  }
`;

export const ButtonContainer = styled.div`
  ${tw`mx-auto w-[80%]`}
`;

export const DepositButton = styled.button`
  ${tw`mt-20 w-full bg-[#E95098] text-white text-sm md:text-base text-center rounded-full cursor-pointer border-none py-2 font-bold
  hover:opacity-80 hover:scale-105 transition-all ease-in-out`}
`;

export const DepositStatusContainer = styled.div<Props>`
  ${tw`flex items-center justify-center`}

  ${(props) => {
    switch (props.status) {
      case 'unapplied':
        return tw`bg-[#828181] text-white text-lg text-center rounded-full cursor-pointer`;
      case 'applying':
        return tw`bg-[#F2DF87] text-lg text-center rounded-full cursor-pointer`;
      case 'failed':
        return tw`bg-[#F8B5B5] text-lg text-center rounded-full cursor-pointer`;
      case 'successed':
        return tw`bg-[#B7FDAC] text-lg text-center rounded-full cursor-pointer`;
      default:
        return tw`bg-[#828181] text-white text-lg text-center rounded-full cursor-pointer`;
        break;
    }
  }}
`;

export const NoteContainer = styled.div`
  ${tw`mx-auto mt-4`}
`;

export const NoteContent = styled.p`
  ${tw`text-xs mb-1`}
`;
