import styled from '@emotion/styled';

export const StyledButtonOn = styled.button`
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  min-width: max-content;
  border-style: none;
  display: flex;
  cursor: pointer;
  background: linear-gradient(97.55deg, #e95098 19.83%, #00a1e9 83.48%);
  color: white;
  align-items: center;
  border-radius: 100px;
  &:hover {
    opacity: 0.6;
  }
`;

export const StyledButtonOff = styled.button`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  min-width: max-content;
  border-style: none;
  display: flex;
  cursor: pointer;
  background-color: rgba(126, 134, 158, 0.16);
  align-items: center;
  border-radius: 100px;
  &:hover {
    opacity: 0.6;
  }
`;
