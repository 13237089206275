import styled from '@emotion/styled';
import { Text } from '../atoms/Text';
import tw from 'twin.macro';

//透明化して当たり判定めっちゃデカくしてはみ出た分はoverflow:hiddenでカット
export const FileInput = styled.input`
  display: none;
  font-size: 300px;
  height: 300px;
`;

export const FileButton = styled.label`
  background: #39f;
  color: white;
  font-size: 0.8rem;
  padding: 6px 12px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition: 0.3s ease-out;
  }
`;

export const ContainerDiv = styled.div`
  text-align: center;
`;

export const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledContainer = styled.div`
  ${tw`w-[80%] md:w-[50%] h-[100%] pl-[5%] pr-[5%] mx-0`}
`;

export const StyledFormContainer = styled.div`
  width: 80%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
`;

export const RowWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ColumnWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  width: 80%;
  height: 10%;
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 3% 3% 2% 5%;
`;

export const TextContainer = styled.div`
  ${tw`md:flex gap-1 items-center justify-start mt-8 mb-2 w-full`}
`;

export const AnnotatedTextContainer = styled.div`
  margin: 2% 0% 0% 0%;
  ${tw`flex flex-row flex-wrap`}
  ${tw`mb-2 gap-4 items-end items-center`}
`;

export const StyledText = styled(Text)`
  margin: 2% 0% 2% 0%;
`;

export const StyledSelect = styled.select`
  display: flex;
  align-items: center;
  appearance: none;
  width: 100%;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border: none;
  background-color: rgb(230, 230, 230);
  border-radius: 10px;
`;

export const DropUploadContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  width: 10rem;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: justify-start;
  flex-direction: row;
  gap: 2%;
  width: 340px;
`;

export const WideButtonWrapper = styled.div`
  ${tw`mt-4 mr-2 w-[14rem] sm:flex`}
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  width: 14rem;
`;

export const DetailPriceContainer = styled.div`
  ${tw`mt-2`}
`;

export const UploadedImageWrapper = styled.div`
  ${tw`flex flex-col items-end justify-center w-[100%] h-[100%] relative`}
`;

export const UploadedImageContainer = styled.img`
  ${tw`w-[100%]  h-[100%]`}
`;

export const DeleteButtonWrapper = styled.div`
  ${tw`w-[32px] h-[32px] bg-black/50 absolute top-[-16px] right-[-16px] cursor-pointer rounded-full flex items-center justify-center`}
`;
