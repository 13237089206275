import React, { useContext, useEffect, useState } from 'react';
import { fetchAllPurchaseHistory } from '../../../api/PurchaseService';
import { ArtInfo } from '../../../types/domain/Art';
import { Box } from '@material-ui/core';
import { TotalDisplay } from '../../organisms/TotalDisplay';
import { AdminHistoryTable } from '../../organisms/AdminHistoryTable';
import { AuthContextType, AuthContext } from '../../../Providers/AuthProvider';
import { AdminHistoryTableContainer } from './style';

export interface HistoryType {
  purchaseId: number;
  value: number;
  art: ArtInfo;
  buyer: any;
  seller: any;
  status: 'processing' | 'completed' | 'failed';
  createdAt: string;
}

export const AdminPurchaseHistoryPage: React.FC = () => {
  const [purchaseHistory, setPurchaseHistory] = useState<HistoryType[]>([]);
  const authContext: AuthContextType = useContext(AuthContext);
  const purchaseTotal = purchaseHistory?.reduce((acc, cur) => {
    return acc + cur.value;
  }, 0);

  useEffect(() => {
    fetchAllPurchaseHistory(authContext.authData.token as string).then(
      async (res) => {
        if (res.ok) {
          const json = await res.json();
          setPurchaseHistory(json.purchaseInfos);
        }
      },
    );
  }, []);

  return (
    <AdminHistoryTableContainer>
      <Box marginY="4rem">
        <Box display="flex" justifyContent="center">
          <TotalDisplay value={purchaseTotal} text="総取引額" />
        </Box>
        <AdminHistoryTable history={purchaseHistory} />
      </Box>
    </AdminHistoryTableContainer>
  );
};
