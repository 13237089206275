import React from 'react';
import { Text } from '../../atoms/Text';
import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import { TotalContainer } from './style';

export interface Props {
  value: number;
  text: string;
}

export const TotalDisplay: React.FC<Props> = ({ value, text }) => {
  return (
    <TotalContainer>
      <div>
        <Text fontType={FontType.TITLE2s}>{text}</Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'end',
          gap: '4px',
        }}
      >
        <Text fontType={FontType.TITLE2} color={Color.BRANDMAIN}>
          {value ? value?.toLocaleString() : 0}
        </Text>
        <Text fontType={FontType.TITLE2s}>円</Text>
      </div>
    </TotalContainer>
  );
};
