import React from 'react';
import { StyledTag } from './style';
import { Text } from '../Text';
import { FontType } from '../../../constants/Fonts';
import { ColorType } from '../../../constants/Color';

interface Props {
  title: string;
  func?: () => void;
  color: ColorType;
  bgColor: string;
}

export const Tag: React.FC<Props> = ({ title, func, color, bgColor }) => {
  return (
    <StyledTag onClick={func} bgColor={bgColor}>
      <Text fontType={FontType.TAG} color={color}>
        {title}
      </Text>
    </StyledTag>
  );
};
