import React, { useEffect, useState } from 'react';
import { useUserPage } from './hooks';
import { MyPageProfile } from '../../organisms/MyPageProfile';
import { MyPageContainer, TotalDisplayContainer } from './style';
import { useParams } from 'react-router-dom';
import { fetchPurchaseHistory } from '../../../api/PurchaseService';
import { HistoryTable } from '../../organisms/HistoryTable';
import { ArtInfo } from '../../../types/domain/Art';
import { TotalDisplay } from '../../organisms/TotalDisplay';
import { NotFound } from '../NotFound';

export interface HistoryType {
  purchaseId: number;
  value: number;
  art: ArtInfo;
  buyer: any;
  seller: any;
  status: 'processing' | 'completed' | 'failed';
  createdAt: string;
}

export const UserPurchaseHistoryPage: React.FC = () => {
  // const classes = useStyles();
  const { userId } = useParams();
  const [purchaseHistory, setPurchaseHistory] = useState<HistoryType[]>([]);
  const [saleHistory, setSaleHistory] = useState<HistoryType[]>([]);
  if (!userId) return <></>;
  const { userData, SFData, authContext } = useUserPage(userId);
  const purchaseTotal = purchaseHistory?.reduce((acc, cur) => {
    return acc + cur.value;
  }, 0);
  const saleTotal = saleHistory?.reduce((acc, cur) => {
    return acc + cur.value;
  }, 0);

  useEffect(() => {
    fetchPurchaseHistory(userId, authContext.authData.token as string).then(
      async (res) => {
        if (res.ok) {
          const json = await res.json();
          setPurchaseHistory(json.buyHistory);
          setSaleHistory(json.sellHistory);
        }
      },
    );
  }, []);

  if (userId != authContext.authData.userId) return <NotFound />;

  return (
    <MyPageContainer>
      <MyPageProfile
        displayname={userData?.displayname}
        pfpUri={userData?.profileImage}
        headerUri={userData?.headerImage}
        username={userData?.username}
        bio={userData?.normalBio}
        userRole={userData?.role}
        userId={userId}
        isPortfolio={false}
        SFData={SFData}
      />
      <TotalDisplayContainer>
        <TotalDisplay value={purchaseTotal} text="購入額合計" />
        <TotalDisplay value={saleTotal} text="販売額合計" />
      </TotalDisplayContainer>
      <HistoryTable history={purchaseHistory} type="purchase" />
      <HistoryTable history={saleHistory} type="sale" />
    </MyPageContainer>
  );
};
