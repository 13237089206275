import React, { ReactNode } from 'react';

import { StyledText } from './style';
import { FontType } from '../../../constants/Fonts';
import { Color, ColorType } from '../../../constants/Color';
import { Box } from '@material-ui/core';

export interface TextProps {
  fontType?: FontType;
  color?: ColorType;
  reactive?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

const NowrapText: React.FC<TextProps> = ({
  fontType = FontType.MAIN,
  color = Color.MAIN,
  reactive = false,
  children,
  onClick,
}) => {
  return (
    <Box>
      <StyledText
        fontType={fontType}
        color={color}
        reactive={reactive}
        onClick={onClick}
      >
        {children}
      </StyledText>
    </Box>
  );
};

const MemoizedText: typeof NowrapText = React.memo(NowrapText);

export { MemoizedText as NowrapText };
