import React, { ReactNode } from 'react';
import { StyledButtonOn, StyledButtonOff } from './style';

export interface SwitchProps {
  isOn: boolean | undefined;
  onClick: () => void;
  children: ReactNode;
}

export const SwitchButton: React.FC<SwitchProps> = ({
  isOn,
  onClick,
  children,
}) => {
  return (
    <>
      {isOn ? (
        <StyledButtonOn onClick={onClick}>{children}</StyledButtonOn>
      ) : (
        <StyledButtonOff onClick={onClick}>{children}</StyledButtonOff>
      )}
    </>
  );
};
