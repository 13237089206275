import {
  getArtDetail,
  updateArt,
  UpdateArtInfo,
} from '../../../api/ArtsService';
import { useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
//import { CheckIsRequested } from '../../../api/RequestService';

export const useArtEdit = (itemId: string) => {
  const { artData } = getArtDetail(itemId);
  const [itemName, setItemName] = useState<string | undefined>(artData?.name);
  const [about, setAbout] = useState<string | undefined>(artData?.about);
  const [artValue, setArtValue] = useState<string | undefined>(
    String(artData?.value == 0 ? '1' : artData?.value),
  );
  const [newArtData, setNewArtData] = useState<UpdateArtInfo>();
  const { authData } = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  //let isRequested = false;
  //掲載有無
  const [isArtExhibited, setIsArtExibited] = useState(
    artData?.isDisplay === void 0 ? false : artData?.isDisplay,
  );
  const disable = artData?.creatorInfo.userId != artData?.ownerInfo.userId;

  useEffect(() => {
    if (!artData?.id || !itemName) return;
    setNewArtData({
      id: artData.id,
      name: !disable ? itemName : undefined,
      about: !disable ? about : undefined,
      value: Number(artValue),
      isDisplay: isArtExhibited,
    });
  }, [itemName, about, artValue, isArtExhibited]);

  useEffect(() => {
    if (!itemName) setItemName(artData?.name);
    if (!isArtExhibited) setIsArtExibited(artData?.isDisplay as boolean);
    setArtValue(String(artData?.value));
  }, [artData?.id]);

  /*useEffect(() => {
    (async () => {
      if (artData) {
        if (await CheckIsRequested(Number(artData?.id))) {
          isRequested = true;
        }
      }
    })();
  }, []);*/

  let state: 'NFT' | 'Portfolio' | 'Picture' = 'Picture';
  if (artData?.isNFT) {
    state = 'NFT';
  } else if (artData?.isPortfolio) {
    state = 'Portfolio';
  } else {
    state = 'Picture';
  }

  const onSubmit = () => {
    // get token
    const token = authData.token;
    // set data to save
    if (!itemName || !token || !artData?.id) {
      addAlert('作品名を入力してください', 'error');
      throw new Error('name or token is undefined');
    }
    if (
      !isFinite(Number(artValue)) ||
      (artValue != undefined && /[^\d]/.test(artValue))
    ) {
      addAlert('価格は半角数字を入力してください', 'error');
      throw new Error('artValue is not a valid number');
    }
    if (isArtExhibited && Number(artValue) < 350 && state == 'NFT') {
      addAlert('価格には350円以上を入力してください', 'error');
      throw new Error('art price is undefined');
    }
    if (isArtExhibited && Number(artValue) >= 10000000 && state == 'NFT') {
      addAlert('価格には999万円以下を入力してください', 'error');
      throw new Error('art price is undefined');
    }
    if (isArtExhibited && Number(artValue) < 350 && state == 'Picture') {
      addAlert('価格には350円以上を入力してください', 'error');
      throw new Error('art price is undefined');
    }
    if (isArtExhibited && Number(artValue) >= 10000000 && state == 'Picture') {
      addAlert('価格には999万円以下を入力してください', 'error');
      throw new Error('art price is undefined');
    }

    if (!newArtData) {
      console.error('invalid art data');
      return;
    }
    // send data
    updateArt({
      updateArtInfo: newArtData,
      token: token,
    })
      .then(() => addAlert('作品情報を更新しました', 'success'))
      .catch((e) => console.error(e));
  };

  function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  return {
    //isRequested,
    setItemName,
    setAbout,
    artValue,
    setArtValue,
    onSubmit,
    currentArtDetail: artData,
    state,
    isArtExhibited,
    setIsArtExibited,
    usePrevious,
  };
};
