import {
  Table,
  TableData,
  TableHeader,
  TableRow,
  TableWrapper,
  TermsOfUseContainer,
  TextContainer,
} from './style';
import { Title } from '../../organisms/Title';
import React from 'react';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';

export const CommercialTransaction: React.FC = () => {
  return (
    <TermsOfUseContainer>
      <Title
        title={'特定商取引法に基づく表記'}
        subTitle={'Commercial Transaction Law'}
      />
      <TextContainer>
        <Text fontType={FontType.SMALL_NORMAL}>
          当社の提供するNFTマーケットプレイス「代アニマーケットプレイス」
          （以下「本サービス」といいます。）は、NFTを販売するご利用者
          （二次出品者を含み、以下「出品者」といいます。）と
          これを購入するご利用者（以下「購入者」といいます。）による
          売買の場を提供するものですので、本サービス上で売買され、
          提供されるNFTの提供事業者は当社ではなく、出品者となります。
          <br />
          そのため、以下の表示では、当社が提供する本サービスについてのみ記載しております。
        </Text>
      </TextContainer>
      <TableWrapper>
        <Table>
          <TableRow>
            <TableHeader>事業者の名称</TableHeader>
            <TableData>株式会社代々木アニメーション学院</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>代表者</TableHeader>
            <TableData>代表取締役社長　橋本 大輝</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>所在地</TableHeader>
            <TableData>
              〒101-0061 <br />
              東京都千代田区神田三崎町一丁目3番9号
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>お問い合わせ先</TableHeader>
            <TableData>
              <a href="https://nft.yoani.co.jp/inquiry">お問い合わせページ</a>
              <br />
              TEL: 03-6895-5621
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>役務の対価</TableHeader>
            <TableData>
              出品者：当社所定のサービス手数料（消費税を含みます。）。購入者：料金はかかりません。
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>役務の対価以外の必要料金</TableHeader>
            <TableData>
              ウェブページの閲覧、コンテンツダウンロード等に必要となるインターネット接続料金、
              通信料金等は、ご利用者のご負担となります。それぞれの料金は、
              ご利用者がご利用のインターネットプロバイダ、携帯電話会社等にお問い合わせください。
              <br />
              その他必要となる料金は以下の通りです。
              <br />
              ・出品者への販売代金送金時の所定の送金手数料
              <br />
              ・NFTの発行時・取引時に生じるGAS代（ブロックチェーンのネットワーク手数料をいいます。）
              <br />
              ・消費税
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>役務の対価の支払方法及び支払時期</TableHeader>
            <TableData>
              サービス手数料は、出品者への販売代金支払い時に、販売代金から自動的に差し引いて徴収されます。
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>役務の提供時期</TableHeader>
            <TableData>
              当社所定の手続きの終了後、直ちにご利用いただけます。
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>キャンセルに関する特約</TableHeader>
            <TableData>
              本サービスの特性上、サービス手数料の返金、キャンセルは一切受け付けておりません。
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>動作環境</TableHeader>
            <TableData>
              ●PC
              <br />
              ・推奨OS
              <br />
              Windows 11
              <br />
              MacOS 13.0 以上
              <br />
              <br />
              ・推奨ブラウザ
              <br />
              Internet Explorer（最新版）
              <br />
              Chrome（最新版）
              <br />
              Edge（最新版）
              <br />
              Firefox（最新版）
              <br />
              Brave（最新版）
              <br />
              <br />
              ●スマートフォン・タブレット
              <br />
              ・推奨OS
              <br />
              iOS 12.0 以降
              <br />
              Android OS 6.0 以降
              <br />
              <br />
              ・推奨ブラウザ
              <br />
              Safari （最新版）
              <br />
              Google Chrome （最新版）
              <br />
            </TableData>
          </TableRow>
        </Table>
      </TableWrapper>
    </TermsOfUseContainer>
  );
};
