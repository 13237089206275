import React, { useState } from 'react';
import {
  StyledPaymentButton,
  ButtonContainer,
  HeadingContainer,
  BorderBox,
  PriceContainer,
} from './style';
import { PopUpWindow } from '../../atoms/PopUpWindow';
import { ButtonType } from '../../../constants/Button';
import { Button } from '../../atoms/Button';
import { Text } from '../../atoms/Text';
import { TaxRate } from '../../../constants/Number';
import { useNavigate } from 'react-router-dom';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';

import { Art } from '../../../types/domain/Art';
import {
  completeUserPayment,
  firstTransfer,
  issueInvoiceId,
  payoutToAdmin,
} from '../../../api/PurchaseService';
interface Props {
  artDetail: Art;
  isDisplayed: boolean;
  setIsDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPurchasing: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
  amount: number;
  reload: () => void;
}

export const PaymentPopUp = (props: Props) => {
  const navigate = useNavigate();
  const [invoiceId, setInvoiceId] = useState('');
  const [isLoading, setIsloading] = useState(true);
  const authContext: AuthContextType = useAuthProvider();
  const { addAlert } = useAlertContext();
  const priceTotal =
    authContext.authData.role === 'common'
      ? Math.floor(props.amount * (1 + TaxRate) + 200) //手数料＋税込みの価格
      : Math.floor(props.amount * (1 + TaxRate)); //税込みの価格

  const handleIssueInvoiceId = () => {
    if (invoiceId) return;
    issueInvoiceId(
      parseInt(authContext.authData.userId as string),
      parseInt(props.artDetail.id),
      parseInt(props.artDetail.ownerInfo.userId as string),
      authContext.authData.token as string,
    ).then(async (res) => {
      if (res.ok) {
        const json = await res.json();
        setInvoiceId(json.invoiceId);
      }
    });
  };

  const onClick = async () => {
    await handleIssueInvoiceId();
  };

  const handleTransfer = async (orderId: string) => {
    await firstTransfer(
      invoiceId,
      'completed',
      orderId,
      authContext.authData.token as string,
    ).then((res: any) => {
      if (res.ok) {
        props.reload();
        addAlert('作品の移転(Transfer)が完了しました', 'success');
        props.setIsPurchasing(false);
        props.close();
      } else {
        props.reload();
        addAlert(
          '作品の移転(Transfer)に失敗しました。時間をあけて再リクエストを行ってください',
          'error',
        );
        props.setIsPurchasing(false);
        props.close();
      }
    });
  };

  const handlePayout = async (orderId: string) => {
    await payoutToAdmin(orderId, authContext.authData.token as string);
  };

  const onPayment = async (orderId: string) => {
    // PayPalでの決済完了をDBに登録
    props.setIsPurchasing(true);
    await completeUserPayment(
      orderId,
      authContext.authData.token as string,
    ).then((res) => {
      if (res.ok) {
        handleTransfer(orderId); //Transfer実行
        handlePayout(orderId); //Payout実行
      }
    });
    props.setIsDisplayed(false);
  };

  return (
    <PopUpWindow isDisplayed={props.isDisplayed} close={props.close}>
      <HeadingContainer>
        <Text>購入手続き</Text>
      </HeadingContainer>
      <BorderBox>
        <PriceContainer>
          <Text>価格</Text>
          <div style={{ flexGrow: 1 }}></div>
          <Text>{'¥' + props.amount.toLocaleString()}</Text>
        </PriceContainer>
        <PriceContainer>
          <Text>税</Text>
          <div style={{ flexGrow: 1 }}></div>
          <Text>
            {'¥' + Math.floor(props.amount * TaxRate).toLocaleString()}
          </Text>
        </PriceContainer>
        {authContext.authData.role === 'common' && (
          <PriceContainer>
            <Text>手数料</Text>
            <div style={{ flexGrow: 1 }}></div>
            <Text>{'¥' + 200}</Text>
          </PriceContainer>
        )}
        <hr />
        <PriceContainer>
          <Text>合計</Text>
          <div style={{ flexGrow: 1 }}></div>
          <Text>{'¥' + priceTotal.toLocaleString()}</Text>
        </PriceContainer>
      </BorderBox>
      {authContext.authData.token ? (
        <ButtonContainer>
          <StyledPaymentButton
            amount={priceTotal as number}
            onPayment={onPayment}
            onClick={onClick}
            artDetail={props.artDetail}
            invoiceId={invoiceId}
            setIsDisplayed={props.setIsDisplayed}
            setInvoiceId={setInvoiceId}
            setIsLoading={setIsloading}
          />
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <Button
            buttonType={ButtonType.ROUNDEDIMPORTANT}
            onClick={() => navigate('/MailSignIn')}
            disabled={isLoading}
          >
            ログインして購入
          </Button>
        </ButtonContainer>
      )}
    </PopUpWindow>
  );
};
