import React from 'react';
import { StyledToggleSwitch } from './style';

export interface Props {
  id: string;
  className: string;
  handleChange: () => void;
  checked: boolean;
}

export function ToggleSwitch(props: Props): JSX.Element {
  return (
    <StyledToggleSwitch className={props.className}>
      <input
        id={props.id}
        type="checkbox"
        onChange={props.handleChange}
        checked={props.checked}
      />
      <label htmlFor={props.id} />
    </StyledToggleSwitch>
  );
}

export default ToggleSwitch;
