import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateDepositUrl } from './hooks';

import {
  FullOverlay,
  Window,
  CloseButtonContainer,
  TextContainer,
  TextButton,
  PasswordInputContainer,
  DipositDataContainer,
  AuthCodeContainer,
  LinkUrlContainer,
  LinkUrl,
  AuthCode,
  CopyButton,
  IconContainer,
  ButtonContainer,
  NoteContainer,
  Note,
} from './style';
import CancelIcon from '@material-ui/icons/Cancel';
import { LoadingButton } from '@mui/lab';
import PasswordInput from '../atoms/PasswordInput';
import { Text } from '../atoms/Text';
import { Color } from '../../constants/Color';
import { FontType } from '../../constants/Fonts';
import { Button } from '../atoms/Button';
import { ButtonType } from '../../constants/Button';

import { useAlertContext } from '../../Providers/AlertProvider';

interface Props {
  isDisplayed: boolean;
  close: () => void;
  purchaseId?: number | undefined;
  updatePurchase?: any;
  depositType: string;
}

export const CreateDipositUrlPopUp: React.FC<Props> = ({
  isDisplayed,
  close,
  purchaseId,
  updatePurchase,
  depositType,
}) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [authCode, setAuthCode] = useState<string>('');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { fetchData } = useCreateDepositUrl(password, depositType, purchaseId);
  const { addAlert } = useAlertContext();

  useEffect(() => {
    if (isDisplayed) {
      setPassword('');
      setAuthCode('');
      setLinkUrl('');
      setIsCopied(false);
    }
  }, [purchaseId]);

  const handleClick = async () => {
    if (!password) {
      addAlert('パスワードを入力してください', 'error');
      return;
    }
    setIsLoading(true);
    try {
      const fetchedData = await fetchData();

      if (fetchedData) {
        setAuthCode(fetchedData.AuthCode);
        setLinkUrl(fetchedData.LinkUrl);
        updatePurchase(purchaseId, fetchedData.AuthCode, fetchedData.LinkUrl);
      }
    } catch (error) {
      // addAlert(
      //   '口座振込URLの発行に失敗しました。時間を空けてもう一度お試しください。',
      //   'error',
      // );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyAuthCode = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (err) {
      console.log(err);
    }
  };

  if (!isDisplayed) {
    return null;
  } else {
    return (
      <FullOverlay>
        <Window>
          <CloseButtonContainer>
            <Button onClick={close}>
              <CancelIcon fontSize="large" />
            </Button>
          </CloseButtonContainer>
          {!linkUrl && (
            <PasswordInputContainer>
              <Text fontType={FontType.TITLE3}>
                本人確認のためパスワードを入力してください
              </Text>
              <TextContainer>
                <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
                  パスワード
                </Text>
              </TextContainer>
              <PasswordInput
                placeholder="パスワードを入力する"
                setPassword={setPassword}
              />
              <TextButton onClick={() => navigate('/resetPassword')}>
                パスワードを忘れた方はこちら
              </TextButton>
            </PasswordInputContainer>
          )}
          <DipositDataContainer>
            {authCode ? (
              <AuthCodeContainer>
                <Text fontType={FontType.TITLE4}>認証コード</Text>
                <AuthCode>
                  <Text fontType={FontType.TITLE2}>{authCode}</Text>
                  {!isCopied ? (
                    <CopyButton onClick={() => copyAuthCode(authCode)}>
                      <IconContainer
                        src={`${process.env.PUBLIC_URL}/copy.png`}
                      />
                      <p style={{ fontSize: '10px', fontWeight: 900 }}>
                        認証コードをコピーしてください
                      </p>
                    </CopyButton>
                  ) : (
                    <CopyButton onClick={() => copyAuthCode(authCode)}>
                      <IconContainer
                        src={`${process.env.PUBLIC_URL}/copied.png`}
                      />
                      <p
                        style={{
                          fontSize: '10px',
                          fontWeight: 900,
                          color: '#E95098',
                        }}
                      >
                        認証コードをコピーしました！
                      </p>
                    </CopyButton>
                  )}
                </AuthCode>
                <NoteContainer>
                  <Note>
                    ※遷移先のページで認証コードが必要になります。認証コードをコピーしてください。
                  </Note>
                </NoteContainer>
              </AuthCodeContainer>
            ) : null}
          </DipositDataContainer>
          <ButtonContainer>
            {linkUrl ? (
              <LinkUrlContainer>
                <Text fontType={FontType.TITLE4}>口座振込URL</Text>
                <LinkUrl href={linkUrl} target="_blank">
                  口座振込ページ
                </LinkUrl>
                <NoteContainer>
                  <Note>※上記のボタンから口座振込ページへ進んでください。</Note>
                </NoteContainer>
              </LinkUrlContainer>
            ) : (
              <Button
                onClick={handleClick}
                buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                disabled={isLoading}
              >
                {isLoading ? <LoadingButton loading /> : '本人確認をする'}
              </Button>
            )}
          </ButtonContainer>
        </Window>
      </FullOverlay>
    );
  }
};
