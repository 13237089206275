import { useCallback, useContext } from 'react';
import { getDepositUrl } from '../../api/PurchaseService';
import { AuthContext, AuthContextType } from '../../Providers/AuthProvider';
import { useAlertContext } from '../../Providers/AlertProvider';

export const useCreateDepositUrl = (
  password: string,
  depositType: string,
  purchaseId?: number,
) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const token = authContext.authData.token;
  const { addAlert } = useAlertContext();

  const fetchData = useCallback(async () => {
    const data = await getDepositUrl(
      password,
      depositType,
      purchaseId,
      token,
    ).then((res) => {
      if (res.code === 200) {
        addAlert('URLを発行しました', 'success');
        return res.data;
      } else if (res.code === 500) {
        addAlert('すでにURLが発行されています', 'warning');
      } else {
        addAlert('パスワードが違います', 'error');
        return null;
      }
    });
    return data;
  }, [password, purchaseId, token]);

  return { fetchData };
};
