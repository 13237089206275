import { LoginButton, TextContainer, WholeWrapper, Spacer } from './style';
import { Text } from '../../atoms/Text';
import { Color } from '../../../constants/Color';
import PasswordInput from '../../atoms/PasswordInput';
import { ButtonType } from '../../../constants/Button';
import React, { useEffect } from 'react';
import { useChangePasswordPage } from './hooks';
import { Title } from '../../organisms/Title';
import { useParams } from 'react-router-dom';

export const ResetPassword: React.FC = () => {
  const {
    setNewPassword,
    handleChangePassword,
    newPassword,
    newPasswordValidation,
    setNewPasswordValidation,
    hash,
    setHash,
    checkHash,
  } = useChangePasswordPage();
  const params = useParams();

  useEffect(() => {
    if (params.hash) {
      setHash(params.hash);
    }
    if (hash) {
      checkHash();
    }
  }, [params, hash]);

  return (
    <WholeWrapper>
      <Title title={'パスワード再設定'} subTitle={'Change'} />
      <TextContainer>
        <Text color={Color.BORDER20}>再設定用パスワード</Text>
      </TextContainer>
      <PasswordInput
        placeholder="再設定用パスワードを入力する"
        setPassword={setNewPassword}
      />
      <TextContainer>
        <Text color={Color.BORDER20}>確認用パスワード</Text>
      </TextContainer>
      <PasswordInput
        placeholder="確認用パスワードを入力する"
        setPassword={setNewPasswordValidation}
      />
      <Spacer />
      <LoginButton
        disabled={!newPassword || !newPasswordValidation}
        buttonType={ButtonType.ROUNDEDIMPORTANT}
        onClick={() => handleChangePassword()}
      >
        パスワードを変更する
      </LoginButton>
    </WholeWrapper>
  );
};
