import useSWR, { Fetcher, mutate } from 'swr';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { API_URL } from '../config';
import { User } from '../types/domain/User';
import { UserRes } from './AdminService';
import { useEffect, useState, useRef } from 'react';

export interface UserResponse {
  userId: string;
  username: string;
  displayname: string;
  profileImage?: string;
  studentNo: string;
  salesforceId: string;
  role: string;
  belongs: string;
  normalBio: string;
  email: string;
  walletAddr?: string;
  headerImage?: string;
}

export interface UserDataResponse {
  Total: number;
  Users: UserResponse[];
}

interface LikedByUsersDataResponse {
  status: boolean;
  code: number;
  data: UserDataResponse;
  message: string;
}

export const getUser = (userId?: string) => {
  const fetcher: Fetcher<UserResponse, string> = (url: string) =>
    fetch(url, { mode: 'cors' }).then((res) => res.json());

  const userConverter = (
    userResponse: UserResponse | undefined,
  ): User | undefined => {
    if (!userResponse) return;
    userResponse.profileImage = API_URL + `/users/${userId}/icon`;
    userResponse.headerImage = API_URL + `/users/${userId}/header`;
    const user: User = {
      ...userResponse,
    };

    return user;
  };
  const { data, error } = useSWR(API_URL + `/users/${userId}`, fetcher);

  const userData = userConverter(data);

  return { userData, error };
};

export const getSFUserInfo = (
  role: string,
  studentNo: string,
  token: string,
) => {
  const fetcher: Fetcher<UserRes, string> = (url: string) =>
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const { data, error } = useSWR(
    role === 'student'
      ? API_URL + `/restricted/users/salesforce/${studentNo}`
      : '',
    fetcher,
  );

  return { SFData: data, error };
};

// Intersection
export const useInterSection = (
  ref: React.MutableRefObject<HTMLDivElement>,
) => {
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });
    let observerRefCurrent: HTMLDivElement | null = null;
    if (ref.current) {
      observer.observe(ref.current);
      observerRefCurrent = ref.current;
    }

    if (observerRefCurrent) {
      return () => {
        observer.unobserve(observerRefCurrent as HTMLDivElement);
      };
    }
    return;
  });
  return intersecting;
};

// ユーザー検索API
export const searchUsersByQuery = (query?: string) => {
  const trimmedQuery = query ? query.substring(2) : undefined;
  const fetcher: Fetcher<UserDataResponse, string> = (url: string) =>
    fetch(url).then((res) => res.json());

  const userConverter = (userDataResponse: UserDataResponse) => {
    return userDataResponse.Users.map((userRes: UserResponse) => {
      userRes.profileImage = API_URL + `/users/${userRes.userId}/icon`;
      userRes.headerImage = API_URL + `/users/${userRes.userId}/header`;
      const users: User = {
        ...userRes,
      };
      return users;
    });
  };

  const getKey: SWRInfiniteKeyLoader = (
    pageIndex: number,
    previousPageData: UserDataResponse | null,
  ) => {
    if (previousPageData && !previousPageData.Users.length) return null;
    return `${API_URL}/users/search?query=${trimmedQuery}&page=${
      pageIndex + 1
    }`;
  };

  const { data, error, size, setSize } = useSWRInfinite(getKey, fetcher);

  const usersData = data
    ? data.flatMap((userDataResponse) => userConverter(userDataResponse))
    : [];

  const loadMore = () => setSize(size + 1);

  const total = data?.[0]?.Total;

  const isLastPage = data?.[0]?.Users.length === total;

  const ref = useRef<HTMLDivElement>(
    null,
  ) as React.MutableRefObject<HTMLDivElement>;
  const intersection = useInterSection(ref);

  useEffect(() => {
    if (intersection && !isLastPage) {
      loadMore();
    }
  }, [intersection, isLastPage]);

  return { usersData, error, size, setSize, loadMore, total, isLastPage, ref };
};

export const getLikedByUsersList = (artId: string) => {
  const fetcher: Fetcher<LikedByUsersDataResponse, string> = (url: string) =>
    fetch(url).then((res) => res.json());

  const userConverter = (userDataResponse: LikedByUsersDataResponse) => {
    return userDataResponse.data.Users.map((userRes: UserResponse) => {
      userRes.profileImage = API_URL + `/users/${userRes.userId}/icon`;
      userRes.headerImage = API_URL + `/users/${userRes.userId}/header`;
      const users: User = {
        ...userRes,
      };
      return users;
    });
  };

  const getKey: SWRInfiniteKeyLoader = (
    index: number,
    previousPageData: LikedByUsersDataResponse | null,
  ) => {
    if (previousPageData && !previousPageData.data.Users.length) return null;
    return API_URL + `/arts/${artId}/art-liked-users?page=${index + 1}`;
  };
  const options = {
    revalidateAll: true,
  };

  const { data, error, size, setSize } = useSWRInfinite(
    getKey,
    fetcher,
    options,
  );

  const usersData = data
    ? data.flatMap((userDataResponse) => userConverter(userDataResponse))
    : [];

  const loadMore = () => {
    setSize(size + 1);
  };

  const reload = () =>
    mutate(API_URL + `/arts/${artId}/art-liked-users?page=1`);

  const total = data?.[0]?.data.Total ?? 0;

  const isLast: boolean = usersData.length === total;

  return { usersData, error, size, setSize, loadMore, reload, total, isLast };
};

export const verifyEmail = async (hash?: string) => {
  const response = await fetch(API_URL + `/users/verify-email/${hash}`, {
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error('Failed to verify email');
  }

  const data = response.json();
  return data;
};

export const resendVerifyEmail = async (email?: string) => {
  const response = await fetch(
    API_URL + `/users/resend-verify-email/${email}`,
    {
      method: 'POST',
    },
  );

  if (!response.ok) {
    throw new Error('Failed to resend verify email');
  }

  const data = response.json();
  return data;
};
