/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import {
  ContestReviewWrapper,
  TitleWrapper,
  SubTitleWrapper,
  ButtonItemWrapper,
  ButtonImage,
} from './style';
import { useContestReview, useExportAsXlsx } from './hooks';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';

import { Title } from '../../organisms/Title';
import { ContestItemShowCase } from '../../organisms/ContestItemShowCase';

import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { ButtonType } from '../../../constants/Button';

import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

import { Art } from '../../../types/domain/Art';

interface ItemsProps {
  artsData: Art[];
}

export const ContestReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedContestArts, setSelectedContestArts] = useState<ItemsProps>();
  const params = new URLSearchParams(location.search);
  const contestId = params.get('id');
  const contestIdNum = Number(contestId);

  const { selectedContestData } = useContestReview(contestIdNum);

  useEffect(() => {
    if (contestId !== null) {
      setSelectedContestArts(selectedContestData);
    }
  }, [selectedContestData?.artsData.length]);

  const authContext: AuthContextType = useContext(AuthContext);
  const token: string | undefined = authContext.authData?.token || undefined;
  const handleExportAsXlsx = () => {
    if (!selectedContestArts || !contestId) return;
    const artIdArray = selectedContestArts?.artsData.map((art) => art.id);
    const artIdArrayNum = artIdArray.map((id) => Number(id));
    useExportAsXlsx(artIdArrayNum, contestId, token);
  };

  return (
    <ContestReviewWrapper>
      <TitleWrapper>
        <Box style={{ marginTop: '2rem' }}>
          <IconButton
            onClick={() => navigate(-1)}
            color="secondary"
            aria-label="back"
            style={{ padding: '6px', marginRight: '48px' }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
        <Title title="応募作品審査画面" subTitle="Contest Review" />
      </TitleWrapper>
      <SubTitleWrapper>
        <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
          保存した作品一覧
        </Text>
        <Button buttonType={ButtonType.XLSX_GREEN} onClick={handleExportAsXlsx}>
          <ButtonItemWrapper>
            <ButtonImage src="excel_logo.svg" alt="excel" />
            <Text fontType={FontType.TITLE2s} color={Color.WHITETEXT}>
              xlsx形式で出力する
            </Text>
          </ButtonItemWrapper>
        </Button>
      </SubTitleWrapper>
      {selectedContestArts && (
        <ContestItemShowCase items={selectedContestArts.artsData} />
      )}
    </ContestReviewWrapper>
  );
};
