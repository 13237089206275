import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {
  TotalNumContainer,
  LoadingButtonContainer,
  StyledShowCaseContainer,
  TagsContainer,
} from './style';

import { Title } from '../Title';
import { ArtCard } from '../ArtCard';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { Tag } from '../../atoms/Tag';

import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import { CardType } from '../../../constants/CardType';
import { ButtonType } from '../../../constants/Button';

import { Art } from '../../../types/domain/Art';
import { Tag as TagType } from '../../../types/domain/Tag';

import { AuthContextType, AuthContext } from '../../../Providers/AuthProvider';

interface ItemShowCaseProps {
  title?: string;
  subTitle?: string;
  items: Art[] | undefined;
  cardType?: CardType;
  buttonAction?: any; // TODO Fix this
  tags?: TagType[];
  type: 'top' | 'created' | 'owned';
  total?: number;
  isLast: boolean;
  kind:
    | 'contest'
    | 'new'
    | 'recommend'
    | 'search'
    | 'create'
    | 'own'
    | 'tag'
    | 'portfolio'
    | 'nft'
    | string;
  query?: string;
}

export const ItemShowCase: React.FC<ItemShowCaseProps> = ({
  title,
  items,
  cardType,
  buttonAction,
  tags,
  type,
  total,
  isLast,
  kind,
  query,
}) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [selectedTag, setSelectedTag] = useState(
    decodeURI(search.replace('?tag=', '')),
  );
  const handleSearchWithTag = (tag: string) => {
    setSelectedTag(tag);
    navigate(`/search?tag=${tag}`, { replace: true });
  };

  return (
    <>
      {title && (
        <Title
          title={title}
          subTitle={tags?.find((x) => x.id == selectedTag)?.name}
        />
      )}
      {total ? <TotalNumContainer>{total}件</TotalNumContainer> : <></>}
      <TagsContainer>
        {/* {tags && tags.length > 0 && (
          <Tag
            title="すべて"
            key={`tag-all`}
            func={() => {
              setSelectedTag('');
              navigate('/');
            }}
            color={!selectedTag ? Color.WHITETEXT : Color.BRAND20}
            bgColor={!selectedTag ? Color.BRANDMAIN : Color.BRAND40}
          />
        )} */}
        {tags?.map((tag, index) => {
          return (
            <Tag
              title={tag.name}
              key={`tag-${index}`}
              func={() => handleSearchWithTag(tag.id)}
              color={selectedTag == tag.id ? Color.WHITETEXT : Color.BRAND20}
              bgColor={selectedTag == tag.id ? Color.BRANDMAIN : Color.BRAND40}
            />
          );
        })}
      </TagsContainer>

      {!items || !items.length ? (
        <>
          {type === 'top' ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              margin="2rem"
            >
              <SearchIcon style={{ fontSize: '32px' }} color="disabled" />
              <Text fontType={FontType.SMALL} color={Color.BORDER20}>
                表示する作品がありません
              </Text>
            </Box>
          ) : (
            <>
              {type === 'created' ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  margin="2rem"
                >
                  <SearchIcon style={{ fontSize: '32px' }} color="disabled" />
                  <Text fontType={FontType.SMALL} color={Color.BORDER20}>
                    作成した作品がありません
                  </Text>
                  {userId == authContext.authData.userId && (
                    <Button
                      buttonType={ButtonType.IMPORTANT}
                      onClick={() => navigate('/regist')}
                    >
                      <Text fontType={FontType.TAG} color={Color.WHITETEXT}>
                        作品を登録
                      </Text>
                    </Button>
                  )}
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  margin="2rem"
                >
                  <SearchIcon style={{ fontSize: '32px' }} color="disabled" />
                  <Text fontType={FontType.SMALL} color={Color.BORDER20}>
                    所有している作品がありません
                  </Text>
                  {userId == authContext.authData.userId && (
                    <Button
                      buttonType={ButtonType.IMPORTANT}
                      onClick={() => navigate('/')}
                    >
                      <Text fontType={FontType.TAG} color={Color.WHITETEXT}>
                        TOPページへ
                      </Text>
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <StyledShowCaseContainer cardType={cardType}>
          {cardType === CardType.RANKING ? (
            <>
              {items.map((item, index) => (
                <ArtCard
                  _art={item}
                  key={index}
                  cardType={cardType}
                  rank={index + 1}
                  kind={kind}
                />
              ))}
            </>
          ) : (
            items.map((item, index) => {
              return (
                <ArtCard
                  _art={item}
                  key={index}
                  cardType={cardType}
                  rank={index + 1}
                  kind={kind}
                  selectedTagId={selectedTag}
                  query={query}
                />
              );
            })
          )}
        </StyledShowCaseContainer>
      )}
      {!isLast && (
        <LoadingButtonContainer>
          <Button buttonType={ButtonType.CIRCLEWIDE} onClick={buttonAction}>
            もっと見る
          </Button>
        </LoadingButtonContainer>
      )}
    </>
  );
};
