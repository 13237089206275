/* eslint-disable */
import { GuideLineWrapper, GuideLineContainer } from './style';
import { Title } from '../../organisms/Title';
import React from 'react';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';

export const GuideLine: React.FC = () => {
  //投稿できない作品について//
  const listItems01 = [
    '著作権を侵害するもの',
    '他者に著しい不快感を与えるもの',
    '18歳未満が閲覧不可なエロティックな表現が含まれるもの',
    '18歳未満が閲覧不可なグロテスクな表現が含まれるもの',
    '政治、宗教、ヘイト表現が含まれるもの',
    '公序良俗、差別的思想に結び付くもの',
    '過度なオマージュ作品',
    '二次創作に該当するものをオリジナルとして提出すること(ファンアートの投稿は可)',
    'その他学院が適切ではないと判断した作品',
  ];

  //NFT化できない作品について//
  const listItems02 = [
    '著作権を侵害する作品',
    '二次創作に該当する作品',
    '既に商品化されているもの、また商品化の予定がある作品',
    '著作権が制作者から第三者へ譲渡されている作品',
    '著しくクオリティーの低い作品(NFT化は講師の承認が必要)',
    'その他学院が適切ではないと判断した作品',
  ];

  //情報配信の注意事項//
  const listItems03_innerListItems01_innerListItems01 = [
    '虚偽または誤解を与えるような不確かな情報を発信しないこと',
    '第三者になりすまさないこと',
    'ソーシャルメディア上で特定の第三者(個人・会社など)を誹謗中傷しないこと',
    'たとえ匿名で利用していても、過去の些細な情報から個人情報は容易に特定され、他のユーザーによって拡散されることがあると認識すること',
    '自身の発信は本人の意思とは関係なく本学院に属する学生としての言動として見られ、本学院の評価・自身の評価に大きな悪影響を及ぼす場合もあると自覚すること',
    '一度発信した内容は、即時に伝達・拡散し、完全に削除することはできず、また、その内容によっては、本人の意思とは関係なく、常に「炎上」などの可能性があること',
    '本学院校舎内において発信、投稿、その他の利用をしないこと',
    'トラブル防止のため、見知らぬ相手と DM などの個人的なコミュニケーションは避け、仕事の依頼などがあった場合は、本学院へ相談すること',
  ];
  const listItems03_innerListItems01_innerListItems02 = [
    '第三者や本学院の名誉や社会的信用を傷つけたり、不快な気持ちにさせたりする内容',
    '第三者のプライバシーにかかわる内容',
    '第三者の著作権、肖像権、商標権などの権利を侵害する内容',
    '自身の居場所や行動範囲など、個人が特定されるような内容',
    '違法・不当な内容、違法行為・犯罪行為を誘うような内容',
    '人種、民族、言語、宗教、身体、性、思想、言条などに関する差別的な内容',
    '在学中、または在学時の活動にて知り得たオーディション情報、作品情報、個人情報、企業情報などについて、本学院の許可なく開示、漏洩するような内容',
    '本学院の授業内容や教材など、その他本学院の運営にかかわる内容',
    'その他、公序良俗に反する内容または社会常識に照らして不適切な内容',
  ];
  const listItems03_innerListItems01 = [
    [
      '心得\nソーシャルメディアの利用にあたっては、以下のことに注意しましょう。',
      <ul>
        {listItems03_innerListItems01_innerListItems01.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
    [
      '絶対に発信してはならない内容\nソーシャルメディアの利用にあたっては、例えば以下のような内容は絶対に発信してはなりません。',
      <ul>
        {listItems03_innerListItems01_innerListItems02.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
  ];
  const listItems03_innerListItems02_innnerListItems = [
    '画像、映像、楽曲などを許可なく使用する、他の Web サイトなどに転載する行為',
    '写真へのタグ付けなど、無断で他人の写貞や情報を公開する行為',
    '本学院内で掲示・使用されている作品など、他人の作品を無断で転載・撮影する行為',
    'サービスや商品に付されたロゴや名称などの登録された商標を無断で転載する行為',
    '他人や、他人の作品・活動等を誹謗中傷する行為',
    'その他、第三者の権利・利益を侵害する行為、法令に違反する行為または犯罪となる行為',
  ];
  const listItems03_innerListItems02 = [
    'ソーシャルメディアと法令の関わりについて\nソーシャルメディアの利用においては、プライバシー保護や著作権保護のための法律など、さまざまな法律が問題となります。ソーシャルメディアを利用するにあたっては、第三者のプライバシー、名誉、社会的信用、著作権、肖像権、商標権などの権利を侵害する可能性があることに十分に注意を払いましょう。',
    [
      '法的な問題につながる行為\n例えば以下のような行為は、本人の意思とは関係なく、違法行為・犯罪行為となる場合があるので、絶対に行ってはなりません。',
      <ul>
        {listItems03_innerListItems02_innnerListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>,
    ],
  ];
  const listItems03 = [
    [
      'ソーシャルメディアの利用について',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems03_innerListItems01.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>,
    ],
    [
      '法令順守について',
      <ul style={{ listStyle: 'lower-roman' }}>
        {listItems03_innerListItems02.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>,
    ],
  ];

  return (
    <GuideLineWrapper>
      <GuideLineContainer>
        <Title title={'ガイドライン'} subTitle={'Guideline'} />
        <Text fontType={FontType.TITLE3}>
          <br />
          本マーケットプレイスを使用する際は、以下を遵守して下さい。
        </Text>
        <Text fontType={FontType.TITLE4}>
          <br />
          ■投稿できない作品について
        </Text>
        <Text fontType={FontType.SMALL}>
          {`\n○下記に当てはまる作品は、投稿を禁止します`}
          <ul>
            {listItems01.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>■NFT化できない作品について</Text>
        <Text fontType={FontType.SMALL}>
          {`\n○下記に当てはまる作品は、NFT化を禁止します `}
          <ul>
            {listItems02.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Text>
        <Text fontType={FontType.TITLE4}>■情報配信の注意事項</Text>
        <Text fontType={FontType.SMALL}>
          {`\n○配信する内容・情報、マーケットプレイスでの活動については代々木アニメーション学院「ソーシャルメディアガイドライン」を遵守すること\n\n『ソーシャルメディアガイドライン』`}
          <ul style={{ listStyle: 'decimal' }}>
            {listItems03.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Text>
      </GuideLineContainer>
    </GuideLineWrapper>
  );
};
