import {
  LoginButton,
  TextContainer,
  WholeWrapper,
  Spacer,
  TextButton,
} from './style';
import { Text } from '../../atoms/Text';
import { Color } from '../../../constants/Color';
import { ButtonType } from '../../../constants/Button';
import React, { useEffect } from 'react';
import { useChangePasswordPage } from './hooks';
import { Title } from '../../organisms/Title';
import TextInput from '../../atoms/TextInput';
import { useNavigate } from 'react-router-dom';
import { SendSuccess } from './SendSuccess';

export const SendEmail: React.FC = () => {
  const { sendResetEmail, setEmail, email, success, setSuccess } =
    useChangePasswordPage();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setSuccess(false);
    };
  }, []);

  return (
    <>
      {success ? (
        <SendSuccess />
      ) : (
        <WholeWrapper>
          <Title title={'パスワード再設定'} subTitle={'Password Reset'} />
          <TextContainer>
            <Text color={Color.BORDER20}>ご登録のメールアドレス</Text>
          </TextContainer>
          <TextInput setText={setEmail} placeholder="sample@example.com" />
          <TextButton onClick={() => navigate('/inquiry')}>
            メールアドレスとパスワードを忘れた方はこちら
          </TextButton>
          <Spacer />
          <LoginButton
            disabled={!email}
            buttonType={ButtonType.ROUNDEDIMPORTANT}
            onClick={() => sendResetEmail()}
          >
            メールを受け取る
          </LoginButton>
        </WholeWrapper>
      )}
    </>
  );
};
