import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PurchaseResultWrapper,
  StyledButtonContainer,
  PurchaseResultTextContainer,
  PurchaseResultText,
  StyledButton,
} from './style';
import { Title } from '../../organisms/Title';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
// import { NotFound } from '../NotFound';

export const DepositResult = () => {
  const navigate = useNavigate();
  const authContext: AuthContextType = useContext(AuthContext);
  // const url = new URL(window.location.href);
  // const params = new URLSearchParams(url.search);
  // const successValue = params.get('success');

  const userId = authContext.authData?.userId;

  // if (successValue != 'true' && successValue != 'false') return <NotFound />;

  return (
    <PurchaseResultWrapper>
      <Title title="振込申請完了" subTitle="Transfer application completed" />
      {/* {successValue != null && successValue === 'true' ? (
        <PurchaseResultTextContainer>
          <PurchaseResultText>振込申請が完了しました。</PurchaseResultText>
        </PurchaseResultTextContainer>
      ) : (
        <></>
      )}
      {successValue != null && successValue === 'false' ? (
        <div>
          <PurchaseResultText>振込申請に失敗しました。</PurchaseResultText>
        </div>
      ) : (
        <></>
      )} */}
      <PurchaseResultTextContainer>
        <PurchaseResultText>振込申請が完了しました。</PurchaseResultText>
      </PurchaseResultTextContainer>
      <StyledButtonContainer>
        <StyledButton onClick={() => navigate(`/userpage/${userId}/deposit`)}>
          <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
            振込申請ページを確認する
          </Text>
        </StyledButton>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <StyledButton onClick={() => navigate('/')}>
          <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
            ホームに戻る
          </Text>
        </StyledButton>
      </StyledButtonContainer>
    </PurchaseResultWrapper>
  );
};
