import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';

export const ItemDetailContainer = styled.div`
  ${tw`m-5`}
  ${tw`grid grid-cols-2 md:grid-cols-4`}
`;

export const PictureContainer = styled.div`
  ${tw`col-span-2 flex flex-col my-10`}
`;

export const StyledItemImage = styled.img`
  ${tw`w-[90%] md:w-full mx-auto max-h-[40rem]`}
  object-fit: contain;
  :hover {
    opacity: 0.6;
  }
`;

export const StyledLike = styled.div`
  ${tw`flex justify-end items-center gap-2`}
`;

export const LikedNum = styled.div`
  ${tw`font-bold text-xl md:text-2xl cursor-pointer ml-2 text-[#E95098] transition-all duration-200`}
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid #e95098;
    transform: scale(1.02);
  }
`;

export const ItemInfoContainer = styled.div`
  ${tw`col-span-2 p-1 md:p-10 flex flex-col`}
`;

export const TagsContainer = styled.div`
  ${tw`flex flex-row flex-wrap gap-x-2 gap-y-2 pb-4`}
`;

export const StyledDescription = styled.div`
  ${tw`mt-4 mb-8 w-[100%] break-words`}
`;

export const DateDiscriptionContainer = styled.div`
  ${tw`mt-4 mb-8 w-[100%] break-words flex flex-col`}
`;

export const StyledDateDescription = styled.div`
  ${tw`my-2 w-[100%] break-words flex flex-row`}
`;

export const StyledButtonContainer = styled.div`
  ${tw`flex flex-row justify-evenly py-10`}
`;

export const StyledPriceContainer = styled.div`
  ${tw`flex flex-col space-y-4 mb-5 bg-white rounded-lg p-5`}
  border: 1px solid ${Color.BORDER10};
`;

export const StyledPriceHStack = styled.div`
  ${tw`flex flex-row items-end`}
`;

export const UserTagVStack = styled.div`
  ${tw`flex flex-col pt-4 space-y-2`}
`;

export const ArtUserInfoContainer = styled.div`
  ${tw`flex flex-row space-x-4 mb-4`}
`;

export const WideButtonContainer = styled.div`
  ${tw`flex flex-row my-4`}
`;

export const AnnotatedTextContainer = styled.div`
  ${tw`flex flex-row flex-wrap`}
  ${tw`mt-4 gap-2 items-end items-start`}
`;

export const ButtonContainer = styled.div`
  ${tw`flex flex-row items-center col-span-2 justify-start mt-8`}
`;

export const NFTInfoWrapper = styled.div`
  ${tw`mb-4 md:mb-8 bg-white px-5 pb-2 pt-4 rounded-lg`}
  border: 1px solid ${Color.BORDER10};
`;

export const NFTInfoTextContainer = styled.div`
  ${tw`mb-4 break-words`}
`;

export const NFTScanPath = styled.a`
  ${tw`flex items-center  text-[#808080] font-bold cursor-pointer break-words`}
`;

export const PurchasingLinkContainer = styled.div`
  ${tw`px-5 py-3 cursor-pointer`}
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid;
  border-radius: 25px;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  cursor: pointer;
  background-color: ${Color.BRANDMAIN};
  transition: background-color 0.1s;
  color: ${Color.BG};
  border-radius: 9999px;
  padding: 0 1.5vw;
  &:hover {
    opacity: 0.6;
  }
`;

export const BorderBox = styled.div`
  margin: 2% 5% 2% 5%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${Color.BORDER10};
`;

export const TotalPriceContainer = styled.div`
  ${tw``}
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 6%;
  margin-bottom: 5%;
`;

export const DetailPriceContainer = styled.div`
  ${tw``}
`;

export const DetailPriceText = styled.div`
  ${tw`text-sm md:text-base font-bold`}
`;

export const StyledPrice = styled.div`
  ${tw`text-center py-2 rounded-full bg-white px-4 md:px-6 w-[60px] flex justify-center items-center mt-4`}
  filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -mozfilter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
`;

export const StyledNFTText = styled.p`
  ${tw`text-sm font-bold m-0`}
  background: linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%);
  color: transparent;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
`;

export const StyledCheckBox = styled.input`
  ${tw`m-2 w-[24px] h-[24px] border-2 border-solid border-[#E95098] rounded-md`}
  :before {
    content: '';
    display: block;
    width: 10/12;
    height: 10/12;
    border-radius: 4px;
  }
  :checked {
    accent-color: #e95098;
  }
`;
