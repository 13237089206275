import React, { useState, useContext } from 'react';
import { useHome } from './hooks';
import { ItemShowCase } from '../../organisms/ItemShowCase';
import { HomeContainer, BannerContainer } from './style';
// import { Banner } from '../../organisms/Banner';
import { useBanners } from '../EditBanner/hooks';
import { API_URL } from '../../../config';
import CommonTabs from '../../atoms/Tabs';
import { Title } from '../../organisms/Title';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import AwardItemBox from '../../organisms/AwardItemShowCase';
import { NftManualBanner } from '../../nftManualBanner';
import { FaqBanner } from '../../faqBanner';
import { AuthContext } from '../../../Providers/AuthProvider';

const Home: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { banners } = useBanners();
  const [recommendQuery, setRecommendQuery] = useState('recommend=true');
  const [newerQuery, setNewerQuery] = useState('');
  const [nftItemQuery, setNftItemQuery] = useState('isNFT=true');
  const { recommendedItemsData, newerItemsData, nftItemData, tags } = useHome(
    recommendQuery,
    newerQuery,
    nftItemQuery,
  );
  const handleChangeRecommendQuery = (index: number | undefined) => {
    switch (index) {
      case 0:
        setRecommendQuery('recommend=true');
        break;
      case 1:
        setRecommendQuery('recommend=true&isNFT=true');
        break;
      case 2:
        setRecommendQuery('recommend=true&isNFT=false');
        break;
    }
  };

  const handleChangeNewerQuery = (index: number | undefined) => {
    switch (index) {
      case 0:
        setNewerQuery('');
        break;
      case 1:
        setNewerQuery('isNFT=true');
        break;
      case 2:
        setNewerQuery('isNFT=false');
        break;
    }
  };

  const handleChangeNftItemQuery = (index: number | undefined) => {
    switch (index) {
      case 0:
        setNftItemQuery('isNFT=true');
        break;
      case 1:
        setNftItemQuery('isNFT=true&isDisplay=true');
        break;
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: '20%',
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 1,
    slideToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slideToShow: 1,
          centerPadding: '10%',
        },
      },
    ],
  };

  return (
    <HomeContainer>
      <BannerContainer>
        <Slider {...settings}>
          {banners &&
            banners.length > 0 &&
            banners.map((banner) => {
              return (
                <>
                  <div key={banner.id} className="slide-item">
                    <a href={banner.url} target="_blank" rel="noreferrer">
                      <img src={API_URL + `/banners/${banner.id}/entity`}></img>
                    </a>
                  </div>
                </>
              );
            })}
        </Slider>
      </BannerContainer>
      <Title title="才能発掘イラストコンテスト" subTitle="Illust Contest" />
      <AwardItemBox />
      <Title title="NFTアート" subTitle="NFT Arts" />
      {authContext.authData.role === 'admin' ? (
        <CommonTabs
          func={handleChangeNftItemQuery}
          tabData={[
            {
              label: 'すべて',
              children: (
                <ItemShowCase
                  items={nftItemData.artsData}
                  buttonAction={nftItemData.loadMore}
                  // tags={tags}
                  type="top"
                  isLast={nftItemData.isLast}
                  kind="nft"
                />
              ),
            },
            {
              label: '出品中',
              children: (
                <ItemShowCase
                  items={nftItemData.artsData}
                  buttonAction={nftItemData.loadMore}
                  // tags={tags}
                  type="top"
                  isLast={nftItemData.isLast}
                  kind="display"
                />
              ),
            },
          ]}
        />
      ) : (
        <ItemShowCase
          items={nftItemData.artsData}
          buttonAction={nftItemData.loadMore}
          // tags={tags}
          type="top"
          isLast={nftItemData.isLast}
          kind="nft"
        />
      )}
      <NftManualBanner />
      <Title title="おすすめの作品" subTitle="Recommendation" />
      <CommonTabs
        func={handleChangeRecommendQuery}
        tabData={[
          {
            label: 'すべて',
            children: (
              <ItemShowCase
                items={recommendedItemsData.artsData}
                buttonAction={recommendedItemsData.loadMore}
                tags={tags}
                type="top"
                isLast={recommendedItemsData.isLast}
                kind="recommend"
              />
            ),
          },
          {
            label: 'NFT',
            children: (
              <ItemShowCase
                items={recommendedItemsData.artsData}
                buttonAction={recommendedItemsData.loadMore}
                tags={tags}
                type="top"
                isLast={recommendedItemsData.isLast}
                kind="recommend"
              />
            ),
          },
          {
            label: '通常作品',
            children: (
              <ItemShowCase
                items={recommendedItemsData.artsData}
                buttonAction={recommendedItemsData.loadMore}
                tags={tags}
                type="top"
                isLast={recommendedItemsData.isLast}
                kind="recommend"
              />
            ),
          },
        ]}
      />
      <Title title="新着の作品" subTitle="New arrivals" />
      <CommonTabs
        func={handleChangeNewerQuery}
        tabData={[
          {
            label: 'すべて',
            children: (
              <ItemShowCase
                items={newerItemsData.artsData}
                buttonAction={newerItemsData.loadMore}
                tags={tags}
                type="top"
                isLast={newerItemsData.isLast}
                kind="new"
              />
            ),
          },
          {
            label: 'NFT',
            children: (
              <ItemShowCase
                items={newerItemsData.artsData}
                buttonAction={newerItemsData.loadMore}
                tags={tags}
                type="top"
                isLast={newerItemsData.isLast}
                kind="new"
              />
            ),
          },
          {
            label: '通常作品',
            children: (
              <ItemShowCase
                items={newerItemsData.artsData}
                buttonAction={newerItemsData.loadMore}
                tags={tags}
                type="top"
                isLast={newerItemsData.isLast}
                kind="new"
              />
            ),
          },
        ]}
      />
      <FaqBanner />
      {/* <ItemShowCase
        title="ランキング"
        items={rankingItemsData.artsData}
        subTitle="Ranking"
        cardType={CardType.RANKING}
        buttonAction={rankingItemsData.pageHandler}
      /> */}
    </HomeContainer>
  );
};

export default Home;
