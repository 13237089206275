import React from 'react';
import '../App.css';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/pages/Home';
import { Items } from '../components/pages/Items';
import { NotFound } from '../components/pages/NotFound';
import { SignIn } from '../components/pages/SignIn';
import { StudentSignUp } from '../components/pages/StudentSignUp';
import { SignUp } from '../components/pages/SignUp';
import { ArtRegist } from '../components/pages/ArtRegist';
import { Settings } from '../components/pages/Settings';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { AdminUsersPage } from '../components/pages/AdminUser';
import { SearchPage } from '../components/pages/Search';
import { UserPage } from '../components/pages/UserPage';
import { GuideLine } from '../components/pages/GuideLine';
import { ArtEdit } from '../components/pages/ArtEdit';
import NotificationsPage from '../components/pages/NotificationsPage';
import { EditBanner } from '../components/pages/EditBanner';
import { RequestsPage } from '../components/pages/ReequestPage';
import { PortfolioPage } from '../components/pages/PortfolioPage';
import { InqueryPage } from '../components/pages/Inquiry';
import { SendEmail } from '../components/pages/ResetPassword/SendEmail';
import { ResetPassword } from '../components/pages/ResetPassword/ResetPassword';
import { TermsOfUse } from '../components/pages/TermsOfUse';
import { ChangePassword } from '../components/pages/ChangePassword/ChangePassword';
import { UserPurchaseHistoryPage } from '../components/pages/UserPurchaseHistory';
import { AdminPurchaseHistoryPage } from '../components/pages/AdminPurchaseHistory';
import { NgWordsPage } from '../components/pages/NgWords';
import LikedListPage from '../components/pages/MyLikedListPage';
import LikedByUserPage from '../components/pages/LikedByUserPage';
import { PurchaseResult } from '../components/pages/PurchaseResult';
import { UserDepositPage } from '../components/pages/UserDepositPage';
import { DepositResult } from '../components/pages/DepositResult';
import { MailCertification } from '../components/pages/MailCertification';
import { ResendAuthEmail } from '../components/pages/ResendAuthEmail';
import { CommercialTransaction } from '../components/pages/CommercialTransactionLaw';
import { NftManualPage } from '../components/pages/NftManualPage';
import { FaqPage } from '../components/pages/FaqPage';
import { ArtDelivery } from '../components/pages/ArtDelivery';
import { DeliveryCompleted } from '../components/pages/DeliveryCompleted';
import { RemittancePage } from '../components/pages/RemittancePage';
import { RemittanceCompleted } from '../components/pages/RemittanceCompleted';
import { ContestManagement } from '../components/pages/ContestManagement';
import { EventEdit } from '../components/pages/EventEdit';
import { EventConfirmation } from '../components/pages/EventConfirmation';
import { ContestReview } from '../components/pages/ContestReview';

const PageContainer = styled.div`
  ${tw`mx-auto pb-6 pt-[72px] sm:pt-[76px] w-[100vw] overflow-x-hidden`}
`;

function App(): JSX.Element {
  return (
    <PageContainer>
      <Routes>
        <Route index element={<Home />} />
        <Route path="items">
          <Route path=":itemId">
            <Route path="" element={<Items />} />
            <Route path="likedBy" element={<LikedByUserPage />} />
            <Route path="edit" element={<ArtEdit />} />
            <Route path="delivery" element={<ArtDelivery />} />
          </Route>
        </Route>
        <Route path="deliveryCompleted" element={<DeliveryCompleted />} />
        <Route path="userpage">
          <Route path=":userId" element={<UserPage />} />
          <Route path=":userId/settings" element={<Settings />} />
          <Route path=":userId/notifications" element={<NotificationsPage />} />
          <Route path=":userId/portfolio" element={<PortfolioPage />} />
          <Route
            path=":userId/purchase"
            element={<UserPurchaseHistoryPage />}
          />
          <Route path=":userId/likedlist" element={<LikedListPage />} />
          <Route path=":userId/deposit" element={<UserDepositPage />} />
          <Route path=":userId/remittance" element={<RemittancePage />} />
        </Route>
        <Route path="verification/:hash" element={<MailCertification />} />
        <Route path="resendAuthEmail" element={<ResendAuthEmail />} />
        <Route path="resetPassword" element={<SendEmail />} />
        <Route path="resetForm/:hash" element={<ResetPassword />} />
        <Route path="ChangePassword" element={<ChangePassword />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="inquiry" element={<InqueryPage />} />
        <Route path="guideline" element={<GuideLine />} />
        <Route path="TermsOfUse" element={<TermsOfUse />} />
        <Route
          path="CommercialTransactionLaw"
          element={<CommercialTransaction />}
        />
        <Route path="*" element={<NotFound />} />
        <Route path="SignIn" element={<SignIn />} />
        <Route path="regist" element={<ArtRegist />} />
        <Route path="StudentSignUp" element={<StudentSignUp />} />
        <Route path="SignUp" element={<SignUp />} />
        <Route path="AdminUser" element={<AdminUsersPage />} />
        <Route path="AdminTransaction" element={<AdminPurchaseHistoryPage />} />
        <Route path="NgWords" element={<NgWordsPage />} />
        <Route path="approval" element={<RequestsPage />} />
        <Route path="banner" element={<EditBanner />} />
        <Route path="purchaseResult" element={<PurchaseResult />} />
        <Route path="depositResult" element={<DepositResult />} />
        <Route path="nftManual" element={<NftManualPage />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="remittanceCompleted" element={<RemittanceCompleted />} />
        <Route path="contestmanagement" element={<ContestManagement />} />
        <Route path="eventedit" element={<EventEdit />} />
        <Route path="eventconfirmation" element={<EventConfirmation />} />
        <Route path="contestreview" element={<ContestReview />} />
      </Routes>
    </PageContainer>
  );
}

export default App;
