import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PurchaseResultWrapper,
  StyledButtonContainer,
  PurchaseResultTextContainer,
  PurchaseResultText,
  StyledButton,
} from './style';
import { Title } from '../../organisms/Title';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { NotFound } from '../NotFound';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

export const PurchaseResult = () => {
  const authContext: AuthContextType = useContext(AuthContext);
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const successValue = params.get('success');
  const userId = authContext.authData?.userId;
  const resultValue = params.get('result');
  console.log(resultValue);
  if (successValue != 'true' && successValue != 'false') return <NotFound />;

  return (
    <PurchaseResultWrapper>
      <Title title="購入結果" subTitle="Purchase Result" />
      {successValue != null && successValue === 'true' && (
        <PurchaseResultTextContainer>
          <PurchaseResultText>購入が完了しました。</PurchaseResultText>
        </PurchaseResultTextContainer>
      )}
      {successValue === 'false' && resultValue != 'sold' && (
        <div>
          <PurchaseResultText>購入に失敗しました。</PurchaseResultText>
        </div>
      )}
      {successValue == 'false' && resultValue === 'sold' && (
        <div>
          <PurchaseResultText>
            この作品はOpenSeaで既に取引されたため、決済をキャンセルしました。
            <br />
            お手数ですが、他の作品をご購入ください。
          </PurchaseResultText>
        </div>
      )}
      <StyledButtonContainer>
        <StyledButton onClick={() => navigate(`/userpage/${userId}/purchase`)}>
          <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
            購入履歴を確認する
          </Text>
        </StyledButton>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <StyledButton onClick={() => navigate('/')}>
          <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
            ホームに戻る
          </Text>
        </StyledButton>
      </StyledButtonContainer>
    </PurchaseResultWrapper>
  );
};
