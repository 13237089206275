import styled from '@emotion/styled';
import tw from 'twin.macro';
import { CardType } from '../../../constants/CardType';

interface Props {
  cardType: CardType | undefined;
}

const getShowCaseStyle = (cardType: CardType | undefined) => {
  if (cardType === CardType.RANKING) {
    return tw`
      // flex-nowrap
    `;
  }
};

export const TotalNumContainer = styled.div`
  ${tw`bg-[#fff] px-4 py-2 rounded-full w-[60px] text-[14px] md:text-[16px] text-center font-bold mt-2 md:mt-0`};
  filter: drop-shodow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -webkit-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -moz-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -ms-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
`;

export const StyledShowCaseContainer = styled.div<Props>`
  ${tw`flex-grow mb-8 after:content-[''] after:block mx-2 md:mx-0`};
  ${tw`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5`}
  ${({ cardType }) => getShowCaseStyle(cardType)}
`;

export const StyledPortfolioContainer = styled.div<Props>`
  ${tw`flex-grow mb-16 `};
  ${tw`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3`}
  ${({ cardType }) => getShowCaseStyle(cardType)}
`;

export const LoadingButtonContainer = styled.div`
  ${tw`flex justify-center mx-auto mt-2 mb-16`}
  width: 180px;
`;

export const TagsContainer = styled.div`
  ${tw`flex flex-row overflow-x-auto gap-x-2 gap-y-2 pb-4 mt-4`}
`;
