import styled from '@emotion/styled';
import tw from 'twin.macro';

export const LinkedBanner = styled.a`
  ${tw`cursor-pointer no-underline`}
`;

export const StyledBanner = styled.div`
  ${tw`max-w-[1440px] w-[84vw] mx-auto bg-[#FDD000] flex flex-row justify-between mt-8 rounded-[16px] md:rounded-[32px] overflow-clip`}
  filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.3));
  -moz-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.3));
  -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.3));
`;

export const LeftContainer = styled.div`
  ${tw`flex flex-col justify-center items-start my-2 ml-4 md:ml-16`}
`;

export const BannerTitle = styled.p`
  ${tw`font-extrabold text-[12px] md:text-[32px] text-white mt-0 mb-1.5 md:mb-2`}
`;

export const BannerText = styled.p`
  ${tw`font-bold text-[10px] md:text-[20px] text-white my-0`}
`;

export const RightContainer = styled.div`
  ${tw` w-1/2 md:w-[45%] flex justify-end`}
`;

export const BannerImage = styled.img`
  ${tw`w-full md:w-auto h-full md:h-auto object-cover`}
`;
