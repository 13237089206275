import { useState, useEffect } from 'react';
import { useAuth } from '../../../api/AuthService';
import { UserForSignIn } from '../../../types/domain/User';
import { useNavigate } from 'react-router-dom';
import { useAlertContext } from '../../../Providers/AlertProvider';

export const useAuthPage = () => {
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [identifier, setIdentifier] = useState<string>('');
  const [mailAddress, setMailAddress] = useState<string>('');
  const [checked, setChecked] = useState(false);
  const { loginHandler } = useAuth();
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();

  const isInputValid = identifier.length > 0 && password.length > 0;

  const checkIdentifier: boolean = identifier.includes('@');

  useEffect(() => {
    if (checkIdentifier) {
      setMailAddress(identifier);
      setUsername('');
    } else {
      setUsername(identifier);
      setMailAddress('');
    }
  }, [identifier]);

  const login = async () => {
    const user: UserForSignIn = {
      username: username ? username : '',
      email: mailAddress ? mailAddress : '',
      password: password,
    };
    await loginHandler(user)
      .then(() => {
        addAlert('ログインに成功しました', 'success');
        navigate('/', { replace: true });
      })
      .catch(() => addAlert('ログインに失敗しました', 'error'));
  };

  return {
    password,
    username,
    mailAddress,
    identifier,
    setPassword,
    setUsername,
    setMailAddress,
    setIdentifier,
    checked,
    setChecked,
    login,
    navigate,
    isInputValid,
  };
};
