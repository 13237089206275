import useSWR, { Fetcher } from 'swr';
import { Comment } from '../types/domain/Comment';
// import { getUser } from './UsersService';
import { API_URL } from '../config';
// import { userNotFound } from '../types/domain/User';
import { User } from '../types/domain/User';

interface CommentResponse {
  id: string;
  target_id: string;
  parent_comment_id: string;
  author: string;
  author_info: User;
  content: string;
  createdAt: Date;
  updatedAt: Date;
}

const responseConverter = (
  responses: CommentResponse[] | undefined,
): Comment[] | undefined => {
  if (!responses) return;

  if (!responses.length) return;

  return responses.map((response) => {
    const comment: Comment = {
      id: response.id,
      targetId: response.target_id,
      parentCommentId: response.parent_comment_id,
      author: response.author_info,
      body: response.content,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
    };
    comment.author.profileImage =
      API_URL + `/users/${comment.author.userId}/icon`;
    return comment;
  });
};

export const getComments = (artId: string) => {
  // GET
  const fetcher: Fetcher<CommentResponse[], string> = (url: string) =>
    fetch(url).then((res) => res.json());

  const apiKey = API_URL + `/arts/${artId}/comments`;

  const { data, error } = useSWR(apiKey, fetcher);

  const comments = responseConverter(data);

  return { comments, error, apiKey };
};

export interface PostCommentsProps {
  parentCommentId?: string;
  author: string;
  content: string;
  artId: string;
  token: string;
}

export const postComments = async ({
  parentCommentId,
  author,
  content,
  artId,
  token,
}: PostCommentsProps) => {
  const body = !parentCommentId
    ? {
        author: Number(author),
        content: content,
      }
    : {
        author: Number(author),
        content: content,
        parent_comment_id: parentCommentId,
      };
  const apiUrl = !parentCommentId
    ? `/restricted/arts/${artId}/comments`
    : `/restricted/arts/${artId}/comments/${parentCommentId}`;

  const res = await fetch(API_URL + apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (res.ok) {
    return;
  } else {
    return Promise.reject(Error('comment failed'));
  }
};

/**
 * Send password reset email
 * POST: /passwordResetMail
 * @param email
 */
export const sendPasswordResetEmail = async (email: string) => {
  const params = {
    email,
  };
  const response = await fetch(API_URL + `/passwordResetMail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Check password reset hash is valid
 * GET: /checkResetHash?reset_hash=xxxxxxxxxx
 * @param hash
 */
export const checkPasswordResetHash = async (hash: string) => {
  const response = await fetch(API_URL + `/checkResetHash?reset_hash=${hash}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Reset password
 * PUT: /resetPassword/:resetHash
 * @param password
 * @param hash
 */
export const resetPassword = async (password: string, hash: string) => {
  const params = {
    password,
  };
  const response = await fetch(API_URL + `//resetPassword/${hash}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Post inquiry form
 * POST: /inquiries
 * @param username
 * @param email
 * @param inquiryText
 */
export const postInquiry = async (
  username: string,
  email: string,
  inquiryText: string,
) => {
  const params = {
    username,
    email,
    inquiryText,
  };
  const response = await fetch(API_URL + `/inquiries`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};
