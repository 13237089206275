import styled from '@emotion/styled';
import tw from 'twin.macro';
import { CardType } from '../../../constants/CardType';

interface Props {
  cardType: CardType | undefined;
}

const getShowCaseStyle = (cardType: CardType | undefined) => {
  if (cardType === CardType.RANKING) {
    return tw`
      // flex-nowrap
    `;
  }
};

export const StyledShowCaseContainer = styled.div<Props>`
  ${tw`flex-grow mb-16 after:content-[''] after:block after:w-80`};
  ${tw`grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6`}
  ${({ cardType }) => getShowCaseStyle(cardType)}
`;

export const LoadingButtonContainer = styled.div`
  ${tw`flex justify-center mx-auto mt-2 mb-8`}
  width: 180px;
`;

export const TagsContainer = styled.div`
  ${tw`flex flex-row overflow-x-auto gap-x-2 gap-y-2 pb-4`}
`;

type Style<T extends HTMLElement> = React.HTMLAttributes<T>['style'];

export const bodyStyle: Style<HTMLDivElement> = {
  height: '100vh',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
};

export const containerStyle: Style<HTMLDivElement> = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '350px',
  maxHeight: '500px',
};

export const imageCardStyle: Style<HTMLDivElement> = {
  cursor: 'grab',
  userSelect: 'none',
  width: '100px',
  height: '130px',
  overflow: 'hidden',
  borderRadius: '5px',
  margin: 3,
};

export const imageStyle: Style<HTMLImageElement> = {
  pointerEvents: 'none',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
};
