import styled from '@emotion/styled';
import { Button } from '../../atoms/Button';
import tw from 'twin.macro';

export const LoginButton = styled(Button)`
  width: 100%;
  color: white;
  margin-top: 5%;
  display: block;
  font-weight: bold;
`;

export const TextContainer = styled.div`
  ${tw`w-full flex items-center justify-start gap-1 my-4`}
`;

export const TermsText = styled.a`
  cursor: 'pointer';
  margin: 2px 0px 0px 0px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: rgba(36, 38, 44, 0.64);
`;

export const TextButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  margin: 2% 0% 2% 0%;
  cursor: pointer;
`;

export const WholeWrapper = styled.div`
  ${tw`mt-12 flex flex-col w-[96%] md:w-[60%] mx-auto`}
`;

export const Spacer = styled.div`
  width: 100%;
  height: 30px;
`;
