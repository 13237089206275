import styled from '@emotion/styled';
import tw from 'twin.macro';

export const ArtEditContainer = styled.div`
  ${tw`flex flex-col sm:p-20 mx-auto max-w-[1440px] flex-wrap`}
`;

export const ArtInfoContainer = styled.div`
  ${tw`flex flex-row flex-wrap`}
`;

export const SwitchesContainer = styled.div`
  ${tw`flex w-10/12 sm:w-full max-w-[100%] mx-auto flex-row justify-start mb-4`}
`;

export const FormContainer = styled.div`
  ${tw`flex flex-col w-10/12 sm:w-full mx-auto`}
  max-width: 100%;
`;

export const TagsContainer = styled.div`
  ${tw`flex-col w-10/12 sm:w-full mx-auto`}
  max-width: 100%;
`;

export const ButtonContainer = styled.div`
  ${tw`flex flex-col justify-center items-center`}
`;

export const SignButtonWrapper = styled.div`
  ${tw`w-full my-6`}
`;

export const SwitchContainer1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
`;
export const SwitchContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  margin: 0px 12px 0px 0px;
`;
export const SwitchContainer3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 132px;
`;

export const TextContainer = styled.div`
  ${tw`md:flex gap-1 items-center justify-start mt-8 mb-2 sm:w-full`}
`;

export const TitleText = styled.div`
  ${tw`flex items-center justify-start`}
`;

export const ExhibitTextContainer = styled.div`
  ${tw`mt-4 mb-2 flex gap-3 items-center justify-start`}
`;

export const RowWrapper = styled.div`
  ${tw`flex flex-col items-start w-10/12 sm:w-full mb-3 mx-auto`}
`;

export const AnnotatedTextContainer = styled.div`
  margin: 2% 0% 2% 0%;
  ${tw`flex flex-row flex-wrap`}
  ${tw`mb-2 gap-4 items-end items-center`}
`;

export const DetailPriceContainer = styled.div`
  ${tw`mt-2`}
`;
