import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useAlertContext } from './AlertProvider';

export interface AuthDataType {
  token?: string;
  userId?: string;
  role?: string;
  emailVerified?: boolean;
  mintRequestable?: boolean;
}

const undefinedAuthData: AuthDataType = {
  token: undefined,
  userId: undefined,
  role: undefined,
  emailVerified: undefined,
  mintRequestable: undefined,
};

export type AuthContextType = {
  authData: AuthDataType;
  setAuthData: (authData: AuthDataType) => void;
  deleteAuthData: () => void;
};

//
// Storage Handling
//
const deleteAuthStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('role');
  localStorage.removeItem('emailVerified');
  localStorage.removeItem('mintRequestable');
};

const getAuthStorage = (): AuthDataType => {
  const Token = localStorage.getItem('token');
  const UserId = localStorage.getItem('userId');
  const Role = localStorage.getItem('role');
  const EmailVerified = localStorage.getItem('emailVerified');
  const MintRequestable = localStorage.getItem('mintRequestable');

  if (!Token || !UserId || !Role) {
    deleteAuthStorage();
    return undefinedAuthData;
  }
  return {
    token: Token,
    userId: UserId,
    role: Role,
    emailVerified: EmailVerified === 'true',
    mintRequestable: MintRequestable === 'true',
  };
};

// （取得した）全ての認証データをストレージに保管する関数。
const saveAuthData = (authData: AuthDataType) => {
  // 全てのデータが入っていることを検証
  if (!authData.token || !authData.userId || !authData.role) {
    deleteAuthStorage();
    return;
  }
  // 全てのデータが入っているなら、保存
  localStorage.setItem('token', authData.token);
  localStorage.setItem('userId', authData.userId);
  localStorage.setItem('role', authData.role);
  localStorage.setItem('emailVerified', String(authData.emailVerified));
  localStorage.setItem('mintRequestable', String(authData.mintRequestable));
};

export const AuthContext = createContext<AuthContextType>({
  authData: getAuthStorage(),
  setAuthData: () => {},
  deleteAuthData: () => {},
});

interface Props {
  children: ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const context: AuthContextType = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  const [authData, setAuthData] = useState(context.authData);

  const newContext: AuthContextType = {
    authData,
    setAuthData: (_authData: AuthDataType) => {
      // ストレージに保管
      saveAuthData(_authData);
      // ステートに保管
      setAuthData(_authData);
    },
    deleteAuthData: () => {
      deleteAuthStorage();
      setAuthData(undefinedAuthData);
      addAlert('ログアウトしました', 'success');
    },
  };

  return (
    <AuthContext.Provider value={newContext}>{children}</AuthContext.Provider>
  );
  // return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthProvider = () => useContext(AuthContext);
