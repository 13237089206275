import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';

export const StyledNotFoundContainer = styled.div`
  ${tw`flex w-[90%] mx-auto max-w-[1440px] my-20`}
`;

export const StyledNotFoundTextFrame = styled.div`
  ${tw`w-[100%] mx-auto py-5 rounded-xl flex flex-col items-center`}
  border: 0.3rem solid ${Color.BRANDMAIN};
`;

export const StyledButtonContainer = styled.div`
  ${tw`my-5`}
`;
