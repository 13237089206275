import useSWR, { Fetcher, mutate } from 'swr';
import { API_URL } from '../config';
import { Art } from '../types/domain/Art';

export interface Notification {
  id: string;
  userId: string;
  type: 'BUY' | 'REQUEST' | 'COMMENT' | 'LIKE' | 'PAYOUT' | 'OS_ROYALTY_SENT';
  targetId: string;
  opened: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface NotificationData {
  Art: Art;
  Notification: Notification;
}

export interface NotificationRes {
  data: NotificationData[];
  page: number;
  size: number;
  unreadTotal: number;
  totalCount: number;
}

export const getNotifications = (
  userId: string,
  token: string,
  query?: string,
) => {
  const fetcher: Fetcher<NotificationRes, string> = (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const { data, error } = useSWR(
    userId ? API_URL + `/restricted/notification/user/${userId}${query}` : null,
    fetcher,
  );

  const reload = () =>
    mutate(API_URL + `/restricted/notification/user/${userId}${query}`);

  return {
    notifications: data?.data,
    totalCount: data?.totalCount,
    error,
    reload,
  };
};

export const getNotificationsByPage = (
  userId: string,
  page: number,
  token: string,
) => {
  const fetcher: Fetcher<NotificationRes, string> = (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const currentPage = page + 1;

  const { data, error } = useSWR(
    userId
      ? API_URL +
          `/restricted/notification/user/${userId}?page=${currentPage}&size=${30}`
      : null,
    fetcher,
  );

  return {
    notificationsByPage: data?.data,
    totalCount: data?.totalCount,
    error,
  };
};

/**
 * Update unopend notifications
 * PUT: /notification/opened
 * @param ids notigication ids
 * @param token JWT token
 */
export const updateNotifications = async (ids: string[], token: string) => {
  const params = {
    ids,
  };
  const response = await fetch(API_URL + `/restricted/notification/opened`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

export const createNotification = async (
  username: string,
  content: string,
  token: string,
) => {
  const params = {
    username,
    content,
  };

  const response = await fetch(API_URL + `/restricted/notification/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((res) => res)
    .catch((e) => e);
  return response;
};
