import styled from '@emotion/styled';
import tw from 'twin.macro';

export const MailCertificationWrapper = styled.div`
  ${tw`mt-12 flex flex-col w-[80%] max-w-[1440px] mx-auto`}
`;

export const TextContainer = styled.div`
  ${tw`my-5 mx-auto flex justify-center text-center`}
`;

export const TextDetail = styled.p`
  ${tw`text-sm md:text-lg font-bold text-center`}
`;

export const StyledButtonContainer = styled.div`
  ${tw`mt-5 mb-2 mx-auto w-[90%] md:w-[50%]`}
`;

export const StyledButton = styled.button`
  ${tw`w-full py-3  mb-6 text-lg font-bold text-white rounded-full bg-[#E95098]  outline-none cursor-pointer`}
  border: 1px solid #fff;
`;
export const StyledButtonWh = styled.button`
  ${tw`w-full py-3  mb-6 text-lg font-bold text-[#E95098] rounded-full bg-white  outline-none cursor-pointer `}
  border: 1px solid #E95098;
`;

export const StyledResendButton = styled.p`
  ${tw`mx-auto flex justify-center text-center px-4 pb-1 cursor-pointer`}
  border-bottom: 1px solid #E95098;
`;

export const StyledTextContainer = styled.div`
  ${tw`mx-auto flex justify-center text-center px-4 pb-1 cursor-pointer`}
  border-bottom: 1px solid #000;
`;
