/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { Box, CircularProgress } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import { useMetamaskConnection } from './hooks';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { RequiredText } from '../../atoms/required';
import { CheckBox } from '../../atoms/CheckBox';
import NumberInput from '../../atoms/NumberInput';

import { Title } from '../../organisms/Title';
import { ConfirmationModal } from '../../organisms/ConfirmationModal';

import { TermsText } from '../../pages/SignUp/style';

import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';
import { ButtonType } from '../../../constants/Button';

import { User } from '../../../types/domain/User';

import { useAuth } from '../../../api/AuthService';
import { approveWithdraw } from '../../../api/Approve';

import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import {
  getAccount,
  getBalance,
  withdraw,
  withdrawEstimateGas,
  isContract,
} from '../../../Providers/ethers';

import {
  RemittanceWrapper,
  TitleWrapper,
  RemittancableDepositWrapper,
  RemittancableDepositContainer,
  RemittancableDepositBox,
  RemittanceAmountWrapper,
  RemittanceAmountContainer,
  RemittanceFeeWrapper,
  RemittanceFeeContainer,
  CheckBoxWrapper,
  WideButtonContainer,
} from './style';

export const RemittancePage = () => {
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRemittanceModalOpen, setIsRemittanceModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [metamaskId, setMetamaskId] = useState('');
  const [isSigning, setIsSigning] = useState(false);

  const [currentUser, setCurrentUser] = useState<User>();
  const { getCurrentUser } = useAuth();
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await getCurrentUser();
      if (user) {
        setCurrentUser(user);
      }
    };
    fetchCurrentUser();
  }, []);

  const handleMetamaskOptions = {
    authData,
    setIsApproved,
    setMetamaskId,
    setIsSigning,
  };
  const { signAndUpdateWallet, checkApproval, handleMetamaskConnection } =
    useMetamaskConnection(handleMetamaskOptions);

  const [deposit, setDeposit] = useState<number>();

  // 出金可能額API
  useEffect(() => {
    const handleGetDeposit = async () => {
      try {
        if (currentUser) {
          setIsProcessing(true);
          const address = currentUser.walletAddr;
          const provider = new ethers.BrowserProvider(window.ethereum, 'any');

          if (address && provider) {
            const balance = await getBalance(address, provider);
            console.log(balance);
            setDeposit(Number(balance));
          } else {
            setDeposit(0.0);
          }
          setIsProcessing(false);
        }
      } catch (e) {
        console.error(e);
        addAlert(
          'ユーザー情報またはMATIC残高の取得に失敗しました。ページをリロードしてください。',
          'error',
        );
      }
    };
    handleGetDeposit();
  }, [currentUser]);

  // 出勤額入力機能
  const [amountNum, setAmountNum] = useState<number>();
  const [valueErr, setValueErr] = useState('');
  const [valueCaution, setValueCaution] = useState(
    '出金額を指定しない場合、自動的に全額出金されます。',
  );
  const [amountErr, setAmountErr] = useState(false);
  const handleSetAmount = (amountNumInput: number) => {
    if (!isNaN(amountNumInput) && deposit !== undefined) {
      setValueCaution('');
      if (amountNumInput < 0.001) {
        setValueErr('0.001 MATIC以下の出金はできません。');
        setAmountErr(true);
      } else if (amountNumInput > deposit) {
        setValueErr(
          '所持しているMATIC残高を超えています。出金可能額より小さい値を入力してください。',
        );
        setAmountErr(true);
      } else {
        setValueErr('');
        setAmountNum(amountNumInput);
        setAmountErr(false);
      }
    } else {
      setValueErr('');
      setValueCaution('出金額を指定しない場合、自動的に全額出金されます。');
      setAmountNum(deposit);
      setAmountErr(false);
    }
  };

  // 手数料計算API
  const [remittanceFee, setRemittanceFee] = useState<string | null>(
    'MetaMaskの署名を行なってください。',
  );
  const [isConnecting, setIsConnecting] = useState(false);
  useEffect(() => {
    if (isApproved) {
      (async () => {
        setIsConnecting(true);
        try {
          const provider = new ethers.BrowserProvider(window.ethereum, 'any');
          const fromAddr = currentUser?.walletAddr;
          const amount = String(amountNum);

          if (fromAddr) {
            if (!(await isContract(fromAddr, provider))) {
              throw new Error('fromAddr is not smart contract address');
            }

            const remittanceFee = await withdrawEstimateGas(
              provider,
              fromAddr,
              amount,
            );
            setRemittanceFee(remittanceFee);
          }
        } catch (error) {
          setRemittanceFee('手数料が取得できません。');
          console.error('手数料の取得に失敗しました。');
        } finally {
          setIsConnecting(false);
        }
      })();
    }
  }, [isApproved]);

  // 出金機能API
  const authContext: AuthContextType = useContext(AuthContext);
  const handleRemittance = async () => {
    setIsProcessing(true);
    try {
      const provider = new ethers.BrowserProvider(window.ethereum, 'any');
      const to = await getAccount();
      const token = authContext.authData?.token;
      const fromAddr = currentUser?.walletAddr;
      const amount = String(amountNum);

      if (fromAddr && to && token) {
        if (!(await isContract(fromAddr, provider))) {
          throw new Error('fromAddr is not smart contract address');
        }
        if (await isContract(to, provider)) {
          throw new Error('toAddr is not EOA wallet address');
        }

        const res = await approveWithdraw(to, token);
        console.log(res);
        if (!res || res.status !== 200) {
          addAlert(
            'コネクションエラーが発生しました。時間をおいて再度出金してください。',
            'error',
          );
          throw new Error('approveWithdraw failed');
        }
        if (res?.status == 200) {
          await withdraw(provider, fromAddr, amount)
            .then(() => {
              navigate('/remittanceCompleted');
              addAlert('出金に成功しました。', 'success');
            })
            .catch((error) => {
              console.error(error);
              addAlert(
                '出金が拒否されたか、Matic残高が不足しています。',
                'error',
              );
              throw new Error(
                'MetaMaskのトランザクションがユーザーによって拒否されました。',
              );
            });
        } else {
          addAlert(
            '出金に失敗しました。ページをリロードして、再度お試しください。',
            'error',
          );
        }
      } else {
        addAlert(
          'ページをリロードして再度 MetaMask で署名を行なってください。',
          'warning',
        );
      }
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    checkApproval(currentUser).catch((e) => addAlert(e, 'error'));
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      const metamaskId = await getAccount();
      setMetamaskId(metamaskId);
    })();
  }, []);

  const [isDisplay, setIsDisplay] = useState(false);
  useEffect(() => {
    if (deposit === 0) {
      setIsDisplay(false);
    } else {
      setIsDisplay(true);
    }
  }, [deposit]);

  if (!isDisplay)
    return (
      <>
        <RemittanceWrapper>
          <TitleWrapper>
            <Box style={{ marginTop: '2rem' }}>
              <IconButton
                onClick={() => navigate(-1)}
                color="secondary"
                aria-label="back"
                style={{ padding: '6px', marginRight: '48px' }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <Title title="MATIC出金" subTitle="Matic Remittance" />
          </TitleWrapper>
          <Text fontType={FontType.TITLE2s}>出金可能なMATICはありません。</Text>
        </RemittanceWrapper>
      </>
    );
  else
    return (
      <>
        {/* 確認モーダル */}
        <ConfirmationModal
          title="本当に出金してもよろしいですか？"
          description="出金したNFTは署名した MetaMask のウォレットに転送されます。"
          buttonTitle="出金する"
          subButtonTitle="キャンセル"
          close={() => setIsRemittanceModalOpen(false)}
          isDisplayed={isRemittanceModalOpen}
          func={handleRemittance}
          isLoading={isProcessing}
        />

        {/* 本体 */}
        <RemittanceWrapper>
          <TitleWrapper>
            <Box style={{ marginTop: '2rem' }}>
              <IconButton
                onClick={() => navigate(-1)}
                color="secondary"
                aria-label="back"
                style={{ padding: '6px', marginRight: '48px' }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <Title title="MATIC出金" subTitle="Matic Remittance" />
          </TitleWrapper>
          <RemittancableDepositWrapper>
            <Text fontType={FontType.TITLE2} color={Color.BG_BLACK}>
              出金可能額
            </Text>
            <RemittancableDepositContainer>
              <RemittancableDepositBox>
                <Text fontType={FontType.TITLE2} color={Color.BG_BLACK}>
                  {deposit}
                </Text>
              </RemittancableDepositBox>
              <Text fontType={FontType.TITLE2} color={Color.BG_BLACK}>
                MATIC
              </Text>
            </RemittancableDepositContainer>
          </RemittancableDepositWrapper>

          <RemittanceAmountWrapper>
            <Text fontType={FontType.TITLE2} color={Color.BG_BLACK}>
              出金額
            </Text>
            <RemittanceAmountContainer>
              <NumberInput setNum={handleSetAmount} defaultValue={undefined} />
              <Text fontType={FontType.TITLE2} color={Color.BG_BLACK}>
                MATIC
              </Text>
            </RemittanceAmountContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {valueErr !== '' && (
                <Text fontType={FontType.MAIN} color={Color.RED}>
                  {valueErr}
                </Text>
              )}
              {valueCaution !== '' && (
                <Text fontType={FontType.MAIN} color={Color.BG_BLACK}>
                  {valueCaution}
                </Text>
              )}
            </div>
          </RemittanceAmountWrapper>

          {!metamaskId ? (
            <Button
              buttonType={ButtonType.ROUNDEDIMPORTANT}
              onClick={() => {
                handleMetamaskConnection();
              }}
            >
              <Text fontType={FontType.SMALL} color={Color.WHITETEXT}>
                ウォレットに接続する
              </Text>
            </Button>
          ) : (
            <>
              {!isApproved ? (
                <Button
                  buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                  onClick={async () => {
                    await signAndUpdateWallet(currentUser);
                  }}
                >
                  {!isSigning ? (
                    <Text fontType={FontType.SMALL} color={Color.MAIN}>
                      ウォレットで署名する
                    </Text>
                  ) : (
                    <CircularProgress
                      size={16}
                      color={'secondary'}
                      style={{ margin: '0px 80px 0px 80px' }}
                    />
                  )}
                </Button>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text fontType={FontType.TITLE3}>出金先：</Text>
                  <Text fontType={FontType.SMALL}>
                    {metamaskId.slice(0, 6) + ' ****** ' + metamaskId.slice(-4)}{' '}
                    のウォレットに転送されます。
                  </Text>
                </div>
              )}
            </>
          )}

          <RemittanceFeeWrapper>
            <Text fontType={FontType.TITLE2s}>手数料（目安）</Text>
            <Text fontType={FontType.SMALL_NORMAL}>
              ※手数料は、出金時に署名した MetaMask
              のウォレットから自動的に支払われます。残高が不足している場合は、出金できません。
              <br />
              ※この手数料は目安です。時間によって変動するため、出金時には異なる場合があります。
            </Text>
            <RemittanceFeeContainer>
              {!isConnecting ? (
                remittanceFee !== null && (
                  <>
                    {remittanceFee === '手数料が取得できません。' ||
                    remittanceFee === 'MetaMaskの署名を行なってください。' ? (
                      <Text fontType={FontType.TITLE3}>{remittanceFee}</Text>
                    ) : (
                      <Text fontType={FontType.TITLE3}>
                        転送手数料: {remittanceFee.toString()} MATIC
                      </Text>
                    )}
                  </>
                )
              ) : (
                <>
                  <Text fontType={FontType.TITLE3}>手数料を計算中・・・</Text>
                  <CircularProgress
                    size={16}
                    color={'secondary'}
                    style={{ margin: '0px 0px 0px 20px' }}
                  />
                </>
              )}
            </RemittanceFeeContainer>
          </RemittanceFeeWrapper>

          <CheckBoxWrapper>
            <CheckBox onClick={() => setChecked(!checked)} />
            <TermsText target="_blank" href="/TermsOfUse">
              利用規約
            </TermsText>
            <TermsText>と</TermsText>
            <TermsText target="_blank" href="/guideline">
              ガイドライン
            </TermsText>
            <TermsText>に同意する</TermsText>
            <RequiredText />
          </CheckBoxWrapper>

          <WideButtonContainer>
            <Button
              buttonType={ButtonType.ROUNDEDIMPORTANT}
              onClick={() => setIsRemittanceModalOpen(true)}
              disabled={!checked || isProcessing || amountErr}
            >
              {isProcessing ? '処理中...' : '出金する'}
            </Button>
          </WideButtonContainer>
        </RemittanceWrapper>
      </>
    );
};
