import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';

export const WholeWrapper = styled.div`
  ${tw`w-[96%] lg:w-[100%] mx-auto`}
`;

export const HistoryBackground = styled.div`
  ${tw`flex flex-col px-2 md:px-20 pt-4 pb-10 rounded-lg`}
  background-color: ${Color.COLORBG};
`;

export const TitleContainer = styled.div`
  ${tw`flex mt-10 mb-6`}
`;

export const HistoryAvatar = styled.div`
  cursor: pointer;
`;

export const HistoryAvatarImg = styled.img`
  ${tw`w-10 h-10 rounded-full mr-5`}
`;

export const HistoryRowContainer = styled.div`
  ${tw`flex flex-row py-2 md:py-6 items-center flex-wrap`}
  border-bottom: 2px solid ${Color.DIVIDER};
`;

export const CommentBodyContainer = styled.div`
  ${tw`flex flex-col`}
`;

export const CommentModuleContainer = styled.div`
  ${tw`flex flex-row gap-x-4 my-5 items-center md:w-3/6`}
`;

export const MyAvatar = styled.img`
  ${tw`w-10 h-10 md:w-12 md:h-12 rounded-full`}
`;
