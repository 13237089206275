import styled from '@emotion/styled';

export const WideButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 60px;
  width: min(80%, 500px);
`;

export const HeaderCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconCropperContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
`;
