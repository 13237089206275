/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

import {
  EventConfirmationWrapper,
  ButtonContainer,
  DateTimeInputContainer,
} from './style';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';

import { Title } from '../../organisms/Title';

import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { ButtonType } from '../../../constants/Button';

import { useAlertContext } from '../../../Providers/AlertProvider';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

import {
  updateContestItems,
  createContestItems,
} from '../../../api/ArtsService';

export const EventConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addAlert } = useAlertContext();
  const authContext: AuthContextType = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const { defaultEvent } = location.state;

  const currentDate = Math.floor(Date.now() / 1000);
  const [isHeld, setIsHeld] = useState(false);
  useEffect(() => {
    setIsHeld(
      currentDate >= defaultEvent.start_date &&
        currentDate <= defaultEvent.end_date,
    );
  }, [defaultEvent, currentDate]);

  const handleSubmit = async () => {
    const token = authContext.authData?.token;
    // `tags`を`tag_names`にリネーム
    const { tags, ...restOfEvent } = defaultEvent;
    const data = {
      ...restOfEvent,
      tag_names: tags,
    };
    setIsLoading(true);
    const calledFunction =
      'id' in defaultEvent ? updateContestItems : createContestItems;
    calledFunction(token, data)
      .then(() => {
        addAlert('イベントを設定しました。', 'success');
        navigate('/contestmanagement');
      })
      .catch((error) => {
        console.log(error);
        addAlert('イベントの設定に失敗しました。', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackToEditPage = () => {
    if ('id' in defaultEvent) {
      navigate(`/eventedit?id=${defaultEvent.id}`, {
        state: { defaultEvent },
      });
    } else {
      navigate(`/eventedit`, { state: { defaultEvent } });
    }
  };

  return (
    <EventConfirmationWrapper>
      <Title title="イラストコンテスト確認" subTitle="Event Confirmation" />
      <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
        イラストコンテストタイトル
      </Text>
      <Text fontType={FontType.TITLE3} color={Color.MAIN}>
        {defaultEvent.name}
      </Text>
      <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
        イラストコンテスト期間
      </Text>
      <DateTimeInputContainer>
        <Text fontType={FontType.TITLE3} color={Color.MAIN}>
          {moment.unix(defaultEvent.start_date).format('YYYY/MM/DD HH:mm')}
        </Text>
        <Text fontType={FontType.TITLE3} color={Color.MAIN}>
          〜
        </Text>
        <Text fontType={FontType.TITLE3} color={Color.MAIN}>
          {moment.unix(defaultEvent.end_date).format('YYYY/MM/DD HH:mm')}
        </Text>
      </DateTimeInputContainer>
      {isHeld && (
        <>
          <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
            イラストコンテスト開催状態
          </Text>
          <Text fontType={FontType.TITLE3} color={Color.MAIN}>
            {defaultEvent.is_held ? '開催中' : '停止中'} に設定
          </Text>
        </>
      )}
      <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
        イラストコンテストタグ
      </Text>
      {defaultEvent.tags.map((tag: string, index: number) => (
        <Text fontType={FontType.TITLE3} color={Color.MAIN} key={index}>
          {tag}
        </Text>
      ))}
      <ButtonContainer>
        <Button
          onClick={() => handleBackToEditPage()}
          buttonType={ButtonType.WIDEROUNDED_OUTLINED}
        >
          <Text fontType={FontType.TITLE2s} color={Color.BRAND_NEW_PINK}>
            修正する
          </Text>
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={isLoading}
          buttonType={ButtonType.CIRCLEWIDE}
        >
          <Text fontType={FontType.TITLE2s} color={Color.WHITETEXT}>
            この内容で保存する
          </Text>
        </Button>
      </ButtonContainer>
    </EventConfirmationWrapper>
  );
};
