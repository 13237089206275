import { useContext } from 'react';
import { getLikedArtsList } from '../../../api/ArtsService';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
import { getSFUserInfo, getUser } from '../../../api/UsersService';

export const useLikedArtsList = (userId: string) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const { userData } = getUser(userId);
  const { SFData } = getSFUserInfo(
    userData?.role as string,
    userData?.studentNo as string,
    authContext.authData.token as string,
  );
  const likedArtsList = getLikedArtsList(
    userId,
    authContext.authData.token as string,
  );
  return { userData, SFData, likedArtsList };
};
