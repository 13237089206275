import React from 'react';
import { usePortfolioPage } from './hooks';
import { MyPageProfile } from '../../organisms/MyPageProfile';
import { MyPageContainer } from './style';
import { useParams } from 'react-router-dom';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { NotFound } from '../NotFound';
import { PortfolioItems } from '../../organisms/PortfolioItems';

export const PortfolioPage: React.FC = () => {
  const { userId } = useParams();
  const authContext: AuthContextType = useAuthProvider();

  if (!userId) return <></>;
  if (
    authContext.authData.role !== 'admin' &&
    authContext.authData.role !== 'student'
  )
    return <NotFound />;

  const { userData, portfolioData } = usePortfolioPage(userId);

  return (
    <MyPageContainer>
      <MyPageProfile
        displayname={userData?.displayname}
        pfpUri={userData?.profileImage}
        headerUri={userData?.headerImage}
        username={userData?.username}
        bio={userData?.normalBio}
        userRole={userData?.role}
        userId={userId}
        isPortfolio
      />
      <PortfolioItems
        items={portfolioData.artsData}
        title="ポートフォリオ一覧"
        subTitle="Portfolio"
        reload={portfolioData.reload}
        userId={userId}
        kind="portfolio"
      />
    </MyPageContainer>
  );
};
