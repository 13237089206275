import React, { useContext, useEffect } from 'react';

import {
  getNotifications,
  NotificationData,
} from '../../../api/NotificationsService';

import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

export const useNotification = (userId: string) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const [unreadNotifications, setUnreadNotifications] = React.useState<
    NotificationData[] | undefined
  >([]);
  const { notifications, totalCount, reload } = getNotifications(
    userId,
    authContext.authData.token as string,
    '',
  );

  useEffect(() => {
    if (!unreadNotifications) {
      const total = notifications?.filter(
        (notification) => !notification.Notification.opened,
      );
      setUnreadNotifications(total);
    }
  }, [userId, notifications]);

  return {
    notifications,
    totalCount,
    unreadNotifications,
    setUnreadNotifications,
    reload,
  };
};
