import { useEffect, useState, useContext } from 'react';

import { User } from '../../../types/domain/User';

import { getArtDetail } from '../../../api/ArtsService';
import { useAuth } from '../../../api/AuthService';

import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

export const useItems = (id: string | undefined) => {
  if (!id) return { artData: undefined };

  const authContext: AuthContextType = useContext(AuthContext);
  const token: string | undefined = authContext.authData?.token || undefined;
  const { artData, reload } = getArtDetail(id as string, token);

  const { getCurrentUser } = useAuth();
  const [currentUser, setCurrentUser] = useState<User>();
  useEffect(() => {
    getCurrentUser().then((user) => setCurrentUser(user));
  }, []);

  return { artData, currentUser, reload };
};
