//import { styled } from '@material-ui/core/styles';
import styled from '@emotion/styled';
/*createThemeとThemeProviderを使えばtheme(色など)を後から変更できます*/

/*export const Search = styled('div')({
  position: 'relative',
  borderRadius: '9999px',
  display: 'flex',
  backgroundColor: 'rgba(126, 134, 158, 0.16)',
  '&:hover': {
    backgroundColor: 'rgba(126, 134, 158, 0.16)',
  },
  marginLeft: 0,
  width: '100%',
});*/

interface SearchProps {
  active?: boolean;
}

const getSearchStyle = (active?: boolean) => {
  if (active) return `background-color: #ebebeb;`;
  else return `background-color: rgba(255, 255, 255, 1);`;
};

export const Search = styled.div<SearchProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-left: 12px;
  border: none;
  border-radius: 20px;
  max-width: 400px;
  height: 40px;
  flex-grow: 1;
  @media (max-width: 680px) {
    margin-left: 8px;
    padding: 2px 0;
    height: 36px;
  }
  @media (max-width: 300px) {
    width: 88%;
  }
  ${({ active }) => getSearchStyle(active)}
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  right: 5px;
  width: 20%;
  min-width: 60px;
  height: 40px;
  cursor: pointer;
  background: black;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 34px;
  }
  @media (max-width: 300px) {
    min-width: 40px;
  }
`;

/*export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  position: 'relative',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));*/

/*export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));*/

export const Partition = styled.div`
  width: 2px;
  height: 24px;
  background-color: rgba(36, 38, 44, 0.64);
  margin-right: 5px;
  border-radius: 2px;
`;

export const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  flex-grow: 1;
  font-size: 16px;
  margin-left: 12px;
  :focus {
    outline-width: 0;
  }
`;
