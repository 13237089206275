import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { AddressLike } from 'ethers';

type Message = {
  from: string;
  to: string;
  value: number;
  gas: number;
  nonce: number;
  data: string;
};

/*export async function ApproveCheck() {
  const contractAddr = '0xF01e9C69cDa4E1CfE95d882E0EdAB2F6D8EBab51';
  const operatorAddr = '0x10ca10F35c84CfA168DDe419d75E21C0e1EC61C0';
  const [signer] = await ethers.getSigners();
  const c = await ethers.getContractAt('YoaniERC721H', contractAddr);
  const isApproved = await c.isApprovedForAll(signer, operatorAddr);
  await c.deployed();
  console.log('Deployed at ${yoaniERC721H.address}');
  return isApproved;
}*/

export async function ApproveCheck() {
  return true;
}

/*export async function setApproveForAll() {
  const contractAddr = '0xF01e9C69cDa4E1CfE95d882E0EdAB2F6D8EBab51';
  const operatorAddr = '0x10ca10F35c84CfA168DDe419d75E21C0e1EC61C0';
  const c = await ethers.getContractAt('YoaniERC721H', contractAddr);
  const receipt = await c.setApprovalForAll(operatorAddr, true);
  console.log(receipt);
  await c.deployed();
  console.log(`Deployed at ${c.address}`);
}*/

export const relay = async (
  token: string | undefined,
  message: Message,
  signature: any,
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res: void | AxiosResponse = await axios
    .post(
      API_URL + `/restricted/relay`,
      {
        message,
        signature,
      },
      config,
    )
    .catch((error) => {
      console.log(`Error: ${error}`);
    });
  return res;
};

export const setApproveERC721 = async (
  to: AddressLike,
  from: AddressLike,
  tokenId: string,
  token: string | undefined,
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res: void | AxiosResponse = await axios
    .post(
      API_URL + `/restricted/approveERC721`,
      {
        to,
        from,
        tokenId,
      },
      config,
    )
    .catch((error) => {
      console.log(`Error: ${error}`);
    });
  console.log(res);
  return res;
};

export const withdrawMatic = async (
  to: AddressLike,
  amount: string,
  token: string | undefined,
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res: void | AxiosResponse = await axios
    .post(
      API_URL + `/restricted/withdraw-matic`,
      {
        to,
        amount,
      },
      config,
    )
    .catch((error) => {
      console.log(`Error: ${error}`);
    });
  console.log(res);
  return res;
};

export const approveWithdraw = async (
  to: AddressLike,
  token: string | undefined,
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res: void | AxiosResponse = await axios
    .post(
      API_URL + `/restricted/approve-withdraw`,
      {
        to,
      },
      config,
    )
    .catch((error) => {
      console.log(`Error: ${error}`);
    });
  console.log(res);
  return res;
};
