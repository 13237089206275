/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RiShareBoxLine } from 'react-icons/ri';
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import moment from 'moment';
import { Box } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import {
  AnnotatedTextContainer,
  ArtUserInfoContainer,
  ItemDetailContainer,
  ItemInfoContainer,
  PictureContainer,
  StyledDescription,
  StyledDateDescription,
  DateDiscriptionContainer,
  StyledLike,
  StyledItemImage,
  StyledPriceContainer,
  StyledPriceHStack,
  TagsContainer,
  UserTagVStack,
  WideButtonContainer,
  ButtonContainer,
  LikedNum,
  NFTInfoWrapper,
  NFTInfoTextContainer,
  PurchasingLinkContainer,
  TotalPriceContainer,
  NFTScanPath,
  StyledCheckBox,
} from './style';
import { useHandlePurchase } from './purchase';

import { Comments } from '../Comments';
import { PaymentPopUp } from '../PaymentPopUp';
import { ConfirmationModal } from '../ConfirmationModal';
import { ArtPopUp } from '../../ArtPopUp';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { Tag } from '../../atoms/Tag';
import { UserTag } from '../../atoms/UserTag';
import { TweetButton } from '../../atoms/TweetButton';
import { CopyButton } from '../../atoms/CopyButton';
import { PrevNextButton } from '../../atoms/PrevNextButton';

import { ButtonType } from '../../../constants/Button';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';

import { Art } from '../../../types/domain/Art';
import { User } from '../../../types/domain/User';
import { Comment } from '../../../types/domain/Comment';

import {
  addLikeByUser,
  deleteArt,
  removeLikeByUser,
  getContestItems,
  editContestIsSelected,
} from '../../../api/ArtsService';
import { reTransferNFT } from '../../../api/PurchaseService';

import {
  AuthContext,
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';

import {
  API_URL,
  BUSINESS_TRANSACTION,
  CHAIN_NETWORK,
  FIRST_PAY_BACK_RATE,
  SECOND_PAY_BACK_RATE,
  ROYALTY,
} from '../../../config';

interface ItemDetailProps {
  artDetail: Art | undefined;
  currentUser: User | undefined;
  comments: Comment[] | undefined;
  reload: () => Promise<any> | undefined;
}

export const createScanText = () => {
  if (CHAIN_NETWORK === 'PolygonMainnet') {
    return 'PolygonScanで表示';
  } else if (CHAIN_NETWORK === 'GoerliTest') {
    return 'EtherScan(テストネットワーク)で表示';
  }
};

export const ArtDetail: React.FC<ItemDetailProps> = ({
  artDetail,
  currentUser,
  comments,
  reload,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultValue = 'create';
  const kind = location.state != null ? location.state.kind : defaultValue;
  const selectedTagId =
    location.state != null ? location.state.selectedTagId : undefined;
  const query = location.state != null ? location.state.query : undefined;
  const { addAlert } = useAlertContext();
  const authContext: AuthContextType = useAuthProvider();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isDeliveryrModalOpen, setIsDeliveryModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [currentLikes, setCurrentLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(artDetail?.isLiked as boolean);

  const resetLikeStatus = () => setIsLiked(false);
  const { authData } = useContext(AuthContext);
  const [isPicturePopUpDisplayed, setIsPicturePopUpDisplayed] = useState(false);

  const createDate = moment(artDetail?.createdAt).format('YYYY/MM/DD　HH:mm');
  const updateDate = moment(artDetail?.updatedAt).format('YYYY/MM/DD　HH:mm');

  const artValue = artDetail?.value == void 0 ? 0 : artDetail?.value;
  const RATE =
    artDetail?.creatorInfo.userId == artDetail?.ownerInfo.userId
      ? FIRST_PAY_BACK_RATE
      : SECOND_PAY_BACK_RATE;

  const commonUsersCommission = 200;

  const totalSalesAmount =
    authData.role === 'common' || !authData.role
      ? (artValue - artValue * BUSINESS_TRANSACTION) * RATE -
        commonUsersCommission
      : (artValue - artValue * BUSINESS_TRANSACTION) * RATE;

  const creatorRoyalty =
    artDetail?.creatorInfo.userId != artDetail?.ownerInfo.userId
      ? Number(artValue) * ROYALTY
      : 0;

  const totalSalesAmountFloor = Math.floor(totalSalesAmount);
  const creatorRoyaltyFloor = Math.floor(creatorRoyalty);

  const exhibitCommission =
    Number(artValue) - totalSalesAmountFloor - creatorRoyaltyFloor;

  const useHandlePurchaseOptions = {
    artDetail: artDetail,
    authData: authData,
    setIsPurchasing: setIsPurchasing,
  };

  const { purchase } = useHandlePurchase(useHandlePurchaseOptions);

  const handleNotLogin = () => {
    addAlert(
      'ログインまたはアカウント作成した上で、もう一度お試し下さい',
      'warning',
    );
  };

  const createScanURL = () => {
    if (CHAIN_NETWORK === 'PolygonMainnet') {
      return (
        'https://polygonscan.com/token/' +
        artDetail?.Token?.contractAddr +
        '?a=' +
        artDetail?.Token?.contractTokenId
      );
    } else if (CHAIN_NETWORK === 'Goerli') {
      return (
        'https://goerli.etherscan.io/token/' +
        artDetail?.Token?.contractAddr +
        '?a=' +
        artDetail?.Token?.contractTokenId
      );
    }
  };

  useEffect(() => {
    if (artDetail != undefined) {
      setCurrentLikes(artDetail?.like as number);
      setIsLiked(artDetail?.isLiked as boolean);
    }
  }, [artDetail]);

  const handleAddLike = () => {
    if (authData.token) {
      addLikeByUser(
        authData.userId as string,
        artDetail?.id as string,
        authData.token as string,
      );
      setCurrentLikes(currentLikes + 1);
      setIsLiked(true);
    } else {
      addAlert(
        'ログインまたはアカウント作成した上で、もう一度お試し下さい',
        'warning',
      );
    }
  };

  const handleRemoveLike = () => {
    if (currentLikes <= 0) return; //likesが0以下の場合はキャンセル
    if (authData.token) {
      removeLikeByUser(
        authData.userId as string,
        artDetail?.id as string,
        authData.token as string,
      );
      setCurrentLikes(currentLikes - 1);
      setIsLiked(false);
    }
  };

  const handleSearchWithTag = (tag: string) => {
    navigate(`/search?tag=${tag}`, { replace: true });
  };

  const handleDelete = () => {
    deleteArt(
      artDetail?.id as string,
      authContext.authData.token as string,
    ).then((res) => {
      if (res.ok) {
        navigate(`/userpage/${authContext.authData.userId}`);
        addAlert('作品の削除に成功しました', 'success');
      } else {
        addAlert('作品の削除に失敗しました。もう一度お試しください', 'error');
      }
    });
  };

  const handleReTransfer = () => {
    setIsProcessing(true);
    reTransferNFT(
      parseInt(artDetail?.id as string),
      parseInt(authContext?.authData.userId as string),
      authContext.authData.token as string,
    ).then((res) => {
      if (res.ok) {
        reload();
        addAlert(
          '作品の移転(Transfer)に成功しました。お知らせから詳細をご確認ください',
          'success',
        );
        setIsTransferModalOpen(false);
        setIsProcessing(false);
      } else {
        setIsProcessing(false);
        setIsTransferModalOpen(false);
        addAlert(
          '作品の移転(Transfer)に失敗しました。時間をあけてもう一度お試しください',
          'error',
        );
      }
    });
  };

  const [checked, setChecked] = useState<boolean>(
    artDetail?.isSelection || false,
  );
  useEffect(() => {
    setChecked(artDetail?.isSelection as boolean);
  }, [artDetail]);
  const like = () => {
    return (
      <StyledLike>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={isLiked ? handleRemoveLike : handleAddLike}
          edge="end"
        >
          {isLiked ? (
            <MdOutlineFavorite size="32px" color={Color.BRANDMAIN} />
          ) : (
            <MdOutlineFavoriteBorder size="32px" color={Color.BRANDMAIN} />
          )}
        </IconButton>
        <LikedNum
          onClick={() =>
            navigate(`/items/${artDetail?.id}/likedBy`, {
              state: { artDetail: artDetail },
            })
          }
        >
          <span style={{ fontSize: '28px', paddingRight: '8px' }}>
            {currentLikes}
          </span>
          Liked
        </LikedNum>
        <div style={{ width: '20px' }} />
        {isContestHeld &&
          artDetail?.contestId == heldContestId &&
          authData.role === 'admin' && (
            <>
              <StyledCheckBox
                type="checkbox"
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                  handleEditContestIsSelected();
                }}
              />
              <Text color={Color.BRAND_NEW_PINK} fontType={FontType.SUB}>
                イラストコンテスト審査用に保存
              </Text>
            </>
          )}
      </StyledLike>
    );
  };

  const handleEditContestIsSelected = () => {
    if (artDetail?.id) {
      const artId = artDetail?.id;
      const token = authContext.authData.token;

      if (typeof token !== 'string') {
        console.error('Token is undefined');
        return;
      }

      editContestIsSelected({
        updateArtInfo: {
          id: artId,
          contestId: artDetail?.contestId,
          isSelection: !checked,
        },
        token: token,
      })
        .then((response) => {
          if (response) {
            const message = !checked
              ? 'イラストコンテスト審査用アイテムに追加しました'
              : 'イラストコンテスト審査用アイテムから削除しました';
            addAlert(message, 'success');
          } else {
            const message = !checked
              ? 'イラストコンテスト審査用アイテムに追加できませんでした'
              : 'イラストコンテスト審査用アイテムから削除できませんでした';
            addAlert(message, 'error');
          }
        })
        .catch((error) => {
          console.error(
            'An error occurred while editing contest selection',
            error,
          );
          addAlert(
            '操作中にエラーが発生しました。もう一度お試しください',
            'error',
          );
        });
    }
  };

  useEffect(() => {}, [artDetail, isProcessing]);

  const handleVerify = () => {
    if (!authContext.authData.emailVerified) {
      navigate('/resendAuthEmail');
    }
  };

  const [isContestHeld, setIsContestHeld] = useState<boolean>(false);
  const [heldContestId, setHeldContestId] = useState<number | null>();
  useEffect(() => {
    getContestItems()
      .then((contestData) => {
        const heldContest = contestData.find(
          (contest: any) => contest.is_held === true,
        );
        if (heldContest) {
          setIsContestHeld(true);
          setHeldContestId(heldContest.id);
        } else {
          setIsContestHeld(false);
          setHeldContestId(null);
        }
      })
      .catch((error) => {
        console.error('コンテストデータの取得に失敗しました', error);
      });
  }, []);

  // prettier  error回避のための変数設定
  const isNFTAndDisplay = artDetail?.isNFT && artDetail?.isDisplay;
  const isPurchasingByUser =
    artDetail?.artPurchase?.status == 'purchasing' &&
    authContext.authData.userId == artDetail?.artPurchase?.buyerId;
  const notPurchasing = artDetail?.artPurchase?.status !== 'purchasing';

  if (!artDetail) return <></>;
  else {
    return (
      <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <ArtPopUp
          close={() => setIsPicturePopUpDisplayed(false)}
          isDisplayed={isPicturePopUpDisplayed}
          src={artDetail?.entityUrl}
        />
        <PaymentPopUp
          close={() => setIsPopupOpen(false)}
          isDisplayed={isPopupOpen}
          setIsDisplayed={setIsPopupOpen}
          setIsPurchasing={setIsPurchasing}
          amount={Number(artDetail?.value)}
          artDetail={artDetail}
          reload={reload}
        />
        <ConfirmationModal
          title={`${artDetail.name}の削除`}
          description="削除された作品はデータの復元ができません。本当によろしいですか？"
          buttonTitle="削除する"
          subButtonTitle="キャンセル"
          close={() => setIsModalOpen(false)}
          isDisplayed={isModalOpen}
          func={handleDelete}
        />
        <ConfirmationModal
          title={`${artDetail.name}の再移転(Transfer)申請`}
          description="再移転(Transfer)の申請を行います。※移転成功後にNFTの所有権が変更されます"
          buttonTitle="実行する"
          subButtonTitle="キャンセル"
          close={() => setIsTransferModalOpen(false)}
          isDisplayed={isTransferModalOpen}
          func={handleReTransfer}
          isLoading={isProcessing}
        />
        <ConfirmationModal
          title={`${artDetail.name}の出庫申請`}
          description="出庫の申請を行います。 
          ※出庫すると他のNFTプラットフォームでNFTの使用、売買が可能となります"
          buttonTitle="出庫する"
          subButtonTitle="キャンセル"
          close={() => setIsDeliveryModalOpen(false)}
          isDisplayed={isDeliveryrModalOpen}
          func={() => navigate(`/items/${artDetail?.id}/delivery`)}
          isLoading={isProcessing}
        />
        <ItemDetailContainer>
          <PictureContainer>
            <Box>
              <IconButton
                onClick={() => navigate(-1)}
                color="secondary"
                aria-label="back"
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <StyledItemImage
              src={artDetail?.entityUrl}
              onClick={() => setIsPicturePopUpDisplayed(true)}
            />
            {like()}
          </PictureContainer>
          <ItemInfoContainer>
            <TagsContainer>
              {artDetail.isNFT && (
                <div
                  style={{
                    background:
                      'linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%)',
                    borderRadius: '100px',
                  }}
                >
                  <Tag
                    title="NFT"
                    key="nft-tag"
                    color={Color.WHITETEXT}
                    bgColor={Color.BRANDMAIN}
                  />
                </div>
              )}
              {artDetail.isPortfolio && (
                <div
                  style={{
                    background:
                      'linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%)',
                    borderRadius: '100px',
                  }}
                >
                  <Tag
                    title="ポートフォリオ"
                    key="nft-tag"
                    color={Color.WHITETEXT}
                    bgColor={Color.BRANDMAIN}
                  />
                </div>
              )}
              {artDetail.tags?.map((tag) => {
                return (
                  <Tag
                    title={tag.name}
                    key={artDetail.tags?.indexOf(tag)}
                    func={() => handleSearchWithTag(tag.id)}
                    color={Color.WHITETEXT}
                    bgColor={Color.BRANDMAIN}
                  />
                );
              })}
            </TagsContainer>
            <Text fontType={FontType.TITLE1}>{artDetail?.name}</Text>
            {/* {nftItem()} */}
            <StyledDescription>
              <Text fontType={FontType.SUB}>{artDetail?.about}</Text>
            </StyledDescription>
            <Text fontType={FontType.TITLE2s}>作品の情報</Text>
            <ArtUserInfoContainer>
              <UserTagVStack>
                <Text fontType={FontType.TITLE4}>オーナー</Text>
                {artDetail?.ownedByAddress ? (
                  artDetail?.isSoldByOpensea ? (
                    //OpenSeaで販売されている場合
                    <UserTag
                      userTagImageUri={`${process.env.PUBLIC_URL}/opensea_logo.png`}
                      userName={artDetail?.ownedByAddress}
                      onclick={() =>
                        window.open(
                          `https://opensea.io/accounts/${artDetail?.ownedByAddress}`,
                          '_blank',
                        )
                      }
                    />
                  ) : (
                    //出庫されている場合
                    <UserTag
                      userTagImageUri={`${process.env.PUBLIC_URL}/polygon_logo.png`}
                      userName={artDetail?.ownedByAddress}
                      onclick={() =>
                        window.open(
                          `https://polygonscan.com/address/${artDetail?.ownedByAddress}`,
                          '_blank',
                        )
                      }
                    />
                  )
                ) : (
                  //YoaniMP内に存在する場合
                  <UserTag
                    userTagImageUri={artDetail?.ownerInfo.profileImage}
                    userName={artDetail?.ownerInfo.username}
                    onclick={() =>
                      navigate(`/userpage/${artDetail?.ownerInfo.userId}`)
                    }
                  />
                )}
              </UserTagVStack>
              <UserTagVStack>
                <Text fontType={FontType.TITLE4}>クリエイター</Text>
                <UserTag
                  userTagImageUri={
                    API_URL + `/users/${artDetail?.creatorInfo.userId}/icon`
                  }
                  userName={artDetail?.creatorInfo.username}
                  onclick={() =>
                    navigate(`/userpage/${artDetail?.creatorInfo.userId}`)
                  }
                />
              </UserTagVStack>
            </ArtUserInfoContainer>
            <DateDiscriptionContainer>
              <StyledDateDescription>
                <Text fontType={FontType.TITLE5}>作成日：</Text>
                <Text fontType={FontType.SMALL_NORMAL}>{createDate}</Text>
              </StyledDateDescription>
              {createDate !== updateDate ? (
                <StyledDateDescription>
                  <Text fontType={FontType.TITLE5}>更新日：</Text>
                  <Text fontType={FontType.SMALL_NORMAL}>{updateDate}</Text>
                </StyledDateDescription>
              ) : (
                <></>
              )}
            </DateDiscriptionContainer>
            {artDetail.isNFT &&
              artDetail.Token &&
              artDetail.Token.contractAddr != '' && (
                <NFTInfoWrapper>
                  <NFTInfoTextContainer>
                    <Text fontType={FontType.TITLE3}>Contract Address</Text>
                    <NFTScanPath
                      href={createScanURL()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiShareBoxLine style={{ marginRight: '2%' }} />
                      <p style={{ wordBreak: 'break-all', margin: 0 }}>
                        {artDetail.Token.contractAddr}
                      </p>
                    </NFTScanPath>
                  </NFTInfoTextContainer>
                  <NFTInfoTextContainer>
                    <Text fontType={FontType.TITLE3}>Contract Token ID</Text>
                    {artDetail.Token.contractTokenId}
                  </NFTInfoTextContainer>
                </NFTInfoWrapper>
              )}
            {isNFTAndDisplay &&
            (isPurchasingByUser || notPurchasing) &&
            !artDetail.isSoldByOpensea &&
            authContext.authData.role === 'admin' ? (
              <StyledPriceContainer>
                <TotalPriceContainer>
                  <Text fontType={FontType.TITLE4}>価格</Text>
                  <StyledPriceHStack>
                    <Text fontType={FontType.TITLE2} color={Color.BRANDMAIN}>
                      ¥ {artValue.toLocaleString()}
                    </Text>
                    <Text fontType={FontType.SUB}>（税・手数料込）</Text>
                  </StyledPriceHStack>
                </TotalPriceContainer>
              </StyledPriceContainer>
            ) : (
              <></>
            )}
            {(() => {
              if (
                artDetail.isNFT &&
                !artDetail.isSoldByOpensea &&
                authContext.authData.role === 'admin'
              ) {
                switch (artDetail.artPurchase?.status) {
                  case 'no_purchase':
                    if (!artDetail.isDisplay) {
                      return <></>;
                    }

                    if (authData.userId == null) {
                      return (
                        <>
                          <Button
                            buttonType={ButtonType.ROUNDEDIMPORTANT}
                            onClick={handleNotLogin}
                          >
                            <p style={{ fontWeight: 'bold' }}>購入する</p>
                          </Button>
                        </>
                      );
                    } else if (artDetail.ownerInfo.userId != authData.userId) {
                      return (
                        <>
                          {authContext.authData.emailVerified ? (
                            <Button
                              buttonType={ButtonType.ROUNDEDIMPORTANT}
                              onClick={() => purchase()}
                              disabled={isPurchasing}
                            >
                              <p style={{ fontWeight: 900 }}>
                                {isPurchasing ? '購入処理中' : '購入する'}
                              </p>
                            </Button>
                          ) : (
                            <Button
                              buttonType={ButtonType.ROUNDEDIMPORTANT}
                              onClick={handleVerify}
                            >
                              <p style={{ fontWeight: 900 }}>購入する</p>
                            </Button>
                          )}
                        </>
                      );
                    }
                    break;
                  case 'purchasing':
                    if (artDetail.artPurchase.buyerId === authData.userId) {
                      return (
                        <>
                          <PurchasingLinkContainer>
                            <a
                              href={artDetail.artPurchase.purchaseLink}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                color: 'white',
                                textDecoration: 'none',
                                fontWeight: 'bold',
                              }}
                            >
                              決済ページへアクセスする
                            </a>
                          </PurchasingLinkContainer>
                          <div
                            style={{
                              margin: '0.5rem 0px 0.5rem 0px',
                              width: '1px',
                            }}
                          />
                          <Text
                            fontType={FontType.SMALL}
                            color={Color.GRAYSCALE64}
                          >
                            ※上記ボタンから決済ページへアクセスが可能です。
                            <br />
                            ※決済ページの有効期限は30分間です。タイムアウトした場合は再度購入ボタンを押してください。
                          </Text>
                        </>
                      );
                    }
                    break;
                  case 'transfer_fail':
                    if (artDetail.artPurchase.buyerId === authData.userId) {
                      return (
                        <>
                          <Button
                            buttonType={ButtonType.ROUNDEDIMPORTANT}
                            onClick={() => setIsTransferModalOpen(true)}
                            disabled={isProcessing}
                          >
                            {isProcessing
                              ? '再移転中(Transfer)'
                              : '再移転(Transfer)'}
                          </Button>
                          <Text
                            fontType={FontType.SMALL}
                            color={Color.GRAYSCALE64}
                          >
                            ※作品の移転に失敗した場合はこのボタンを押して、作品の再移転を実行してください。
                          </Text>
                        </>
                      );
                    }
                    break;
                }
              }
              return <></>;
            })()}
            {artDetail.isNFT &&
            artDetail?.isDisplay &&
            !artDetail?.ownedByAddress &&
            authContext.authData.userId == artDetail.ownerInfo.userId ? (
              <>
                <AnnotatedTextContainer>
                  <Text fontType={FontType.TITLE3}>
                    売上予定価格（※作品のオーナーのみに表示されています。）
                  </Text>
                </AnnotatedTextContainer>
                <div style={{ marginBottom: '4px', width: '1px' }} />
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  販売価格：
                  {artValue.toLocaleString()}
                  {' 円'}
                </Text>
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  システム利用料：
                  {'-'}
                  {exhibitCommission.toLocaleString()}
                  {' 円'}
                </Text>
                {creatorRoyalty != 0 && (
                  <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                    クリエイターロイヤリティ：
                    {'-'}
                    {creatorRoyaltyFloor.toLocaleString()}
                    {' 円'}
                  </Text>
                )}
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: 'lightgray',
                    margin: '4px 0px 4px 0px',
                  }}
                />
                <Text fontType={FontType.SMALL} color={Color.GRAYSCALE64}>
                  販売利益：
                  {Math.floor(totalSalesAmount).toLocaleString()}
                  {' 円'}
                </Text>
              </>
            ) : (
              <></>
            )}
            {artDetail.isNFT &&
            artDetail?.isDisplay &&
            !artDetail?.ownedByAddress &&
            authContext.authData.userId == artDetail.creatorInfo.userId &&
            artDetail.creatorInfo.userId != artDetail.ownerInfo.userId ? (
              <>
                <AnnotatedTextContainer>
                  <Text fontType={FontType.TITLE3}>
                    取得予定ロイヤリティ（※作品のクリエイターのみに表示されています。）
                  </Text>
                </AnnotatedTextContainer>
                <AnnotatedTextContainer>
                  <Text fontType={FontType.TITLE4} color={Color.MAIN}>
                    {creatorRoyaltyFloor.toLocaleString()}
                    {' 円'}
                  </Text>
                  <div style={{ marginTop: '2px' }}>
                    <Text
                      fontType={FontType.SMALL_NORMAL}
                      color={Color.BRAND30}
                    >
                      =（税込アート価格 - 取引手数料）× ロイヤリティ（{ROYALTY}
                      ）
                    </Text>
                  </div>
                </AnnotatedTextContainer>
              </>
            ) : (
              <></>
            )}

            {authData.userId === artDetail?.ownerInfo.userId &&
            !artDetail?.ownedByAddress &&
            artDetail.editable ? (
              <>
                {artDetail.creatorInfo.userId != artDetail.ownerInfo.userId ? (
                  <>
                    <WideButtonContainer>
                      <Button
                        buttonType={ButtonType.CIRCLEWIDE}
                        onClick={() => navigate('edit')}
                      >
                        出品設定をする
                      </Button>
                    </WideButtonContainer>
                    {artDetail.isNFT ? (
                      isContestHeld && heldContestId === artDetail.contestId ? (
                        <></>
                      ) : (
                        <WideButtonContainer>
                          <Button
                            buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                            onClick={() => setIsDeliveryModalOpen(true)}
                          >
                            出庫する
                          </Button>
                        </WideButtonContainer>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <WideButtonContainer>
                      <Button
                        buttonType={ButtonType.CIRCLEWIDE}
                        onClick={() => navigate('edit')}
                      >
                        作品を編集する
                      </Button>
                    </WideButtonContainer>
                    {artDetail.isNFT ? (
                      isContestHeld && heldContestId === artDetail.contestId ? (
                        <></>
                      ) : (
                        <WideButtonContainer>
                          <Button
                            buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                            onClick={() => setIsDeliveryModalOpen(true)}
                          >
                            出庫する
                          </Button>
                        </WideButtonContainer>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {authData.userId === artDetail?.creatorInfo.userId &&
                  authData.userId === artDetail?.ownerInfo.userId &&
                  !artDetail.isNFT && (
                    <WideButtonContainer>
                      <Button
                        buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                        onClick={() => setIsModalOpen(true)}
                      >
                        作品を削除する
                      </Button>
                    </WideButtonContainer>
                  )}
              </>
            ) : (
              <></>
            )}
            <PrevNextButton
              kind={kind}
              artId={artDetail.id}
              selectedTagId={selectedTagId}
              creatorId={artDetail.creatorInfo.userId}
              ownerId={artDetail.ownerInfo.userId}
              userId={authData.userId as string}
              resetLikeStatus={resetLikeStatus}
              query={query}
            />
            <ButtonContainer>
              <TweetButton />
              <CopyButton />
            </ButtonContainer>
          </ItemInfoContainer>
        </ItemDetailContainer>
        <Comments
          comments={comments}
          myInfo={currentUser}
          artId={artDetail.id}
        />
      </div>
    );
  }
};
