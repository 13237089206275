import useSWR, { Fetcher, mutate } from 'swr';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { API_URL } from '../config';
import { Art } from '../types/domain/Art';
import { User } from '../types/domain/User';

export type SFUser = {
  studentNo: string;
  firstname: string;
  lastname: string;
  year: string;
  schoolName: string;
  classType: string;
  emamorningAfternoonil: string;
  classDepartment: string;
  classFaculty: string;
  classYear: string;
  classNo: string;
};

export type UserRes = {
  userId: string;
  displayname: string;
  username: string;
  salesforceId: string;
  role: string;
  belongs: string;
  normalBio: string;
  portfolioBio: string;
  walletAddr?: string;
  headerImage?: string;
  email: string;
  salesforceUser: SFUser;
};

export const getAllUsers = (token: string) => {
  const fetcher: Fetcher<UserRes[], string> = (url: string) =>
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const reload = () => mutate(API_URL + '/restricted/users/salesforce');

  const { data, error } = useSWR(
    API_URL + '/restricted/users/salesforce',
    fetcher,
  );

  return { users: data, error, reload };
};

export const getUsersWithSearch = (
  index: number,
  token: string,
  query?: string,
) => {
  const fetcher: Fetcher<UserRes[], string> = (url: string) =>
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const { data, error } = useSWR(
    API_URL +
      `/restricted/users/salesforce/search?${query}&count=30&offset=${
        index * 30
      }`,
    fetcher,
  );

  return { searchedUsers: data, error };
};

export const getAllUsersWithSearch = (token: string, query?: string) => {
  const fetcher: Fetcher<UserRes[], string> = (url: string) =>
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const { data, error } = useSWR(
    API_URL + `/restricted/users/salesforce/search?${query}`,
    fetcher,
  );

  return { searchedAllUsers: data, error };
};

export const getSFSchools = (token: string) => {
  const fetcher: Fetcher<string[], string> = (url: string) =>
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const { data, error } = useSWR(
    API_URL + `/restricted/users/salesforce/school`,
    fetcher,
  );

  return { schools: data, error };
};

export const getSFFaculties = (token: string) => {
  const fetcher: Fetcher<string[], string> = (url: string) =>
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const { data, error } = useSWR(
    API_URL + `/restricted/users/salesforce/faculty`,
    fetcher,
  );

  return { faculties: data, error };
};

export const getSFDepartments = (token: string) => {
  const fetcher: Fetcher<string[], string> = (url: string) =>
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const { data, error } = useSWR(
    API_URL + `/restricted/users/salesforce/department`,
    fetcher,
  );
  return { departments: data, error };
};

export interface Banner extends BannerRes {
  url: string;
}

export interface BannerRes {
  id: string;
  createdAt: string;
  ownedAt: string;
  url: string;
}

export const getBanners = () => {
  const fetcher: Fetcher<BannerRes[], string> = (url: string) =>
    fetch(url, { mode: 'cors' }).then((res) => res.json());

  const reload = () => mutate(API_URL + '/banners');

  const { data, error } = useSWR(API_URL + '/banners', fetcher);

  return { banners: data, error, reload };
};

/**
 * Create banner
 * POST: /banners
 * @param token JWT token
 */
export const createBanner = async (token: string, url: string, file: File) => {
  const form = new FormData();
  if (url) {
    form.append('url', url);
  }
  form.append('file', file as File);

  const response = await fetch(API_URL + `/restricted/banners`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: form,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Create banner entity
 * POST: /banners/:bannerId/
 * @param id tag id
 * @param file banner file
 * @param token JWT token
 */
export const createBannerEntity = async (
  id: string,
  file: File,
  token: string,
) => {
  const form = new FormData();
  form.append('file', file as File);

  const response = await fetch(API_URL + `/restricted/banners/${id}`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: form,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Update banner
 * PUT: /banners/:bannerId
 * @param id tag id
 * @param url banner url
 * @param token JWT token
 */
export const updateBanner = async (
  id: string,
  url: string,
  file: File,
  token: string,
) => {
  const form = new FormData();
  form.append('url', url);
  form.append('file', file as File);
  const response = await fetch(API_URL + `/restricted/banners/${id}`, {
    method: 'PUT',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: form,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Delete banner
 * DELETE: /banners/:bannerId
 * @param id tag id
 * @param token JWT token
 */
export const deleteBanner = async (id: string, token: string) => {
  const response = await fetch(API_URL + `/restricted/banners/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

export type RequestData = {
  art: Art;
  request: Request;
  user: User;
};

export type Request = {
  id: string;
  type: string;
  status: 'Pending' | 'Accepted' | 'Rejected';
  judgedBy: number;
  requestedBy: number;
  targetId: number;
  createdAt: string;
  updatedAt: string;
};

export type RequestRes = {
  requests: RequestData[];
  page: number;
  size: number;
  totalCount: number;
};

export const getRequests = (token: string, status?: string, query?: string) => {
  const fetcher: Fetcher<RequestRes, string> = (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }).then((res) => res.json());

  const getKey: SWRInfiniteKeyLoader = (
    pageIndex: number,
    previousPageData: RequestRes,
  ) => {
    if (previousPageData && !previousPageData.requests.length) return null;
    return (
      API_URL +
      `/restricted/requests/status${
        status
          ? `/${status}?${query}&page=${pageIndex + 1}&size=${10}&r-sf=false`
          : ''
      }`
    );
  };

  const options = {
    revalidateOnFocus: false,
    ervalidateOnReconnect: false,
    revalidateIfStale: true,
  };

  const { data, error, size, setSize } = useSWRInfinite(
    getKey,
    fetcher,
    options,
  );
  const requestsDataArray = data ? data.map((d) => d.requests).flat() : [];
  const reload = () =>
    mutate(
      API_URL +
        `/restricted/requests/status${
          status ? `/${status}?${query}&page=1&size=${10}&r-sf=false` : ''
        }`,
    );
  const loadMore = () => setSize(size + 1);

  const total = data ? data[0].totalCount : 0;
  const isLast = total === requestsDataArray.length;
  return {
    requestsDataArray,
    error,
    size,
    setSize,
    reload,
    loadMore,
    total,
    isLast,
  };
};

/**
 * Update request
 * PATCH: /requests/:requestId/judge
 * @param id request id
 * @param id userId who judge
 * @param status accepted or rejected
 * @param token JWT token
 */
export const judgeRequest = async (
  id: string,
  userId: string,
  status: 'Accepted' | 'Rejected',
  token: string,
) => {
  const params = {
    judgeBy: Number(userId),
    status,
  };
  const response = await fetch(API_URL + `/restricted/requests/${id}/judge`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};
