import React from 'react';
import { useLikedArtsList } from './hooks';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '../../organisms/Title';
import {
  WholeWrapper,
  LikedListWrapper,
  ItemsContainer,
  ItemContainer,
  ImageContainer,
  ArtImage,
  LikeWrapper,
  LikeContainer,
  ArtTitle,
  ArtInfo,
  ArtOwnerIcon,
  LoadingButtonContainer,
  LoadingButton,
  TotalNumContainer,
} from './style';
import { Text } from '../../atoms/Text';
import { Color } from '../../../constants/Color';
import { NotFound } from '../NotFound';
import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MdOutlineFavorite } from 'react-icons/md';
import { MyPageProfile } from '../../organisms/MyPageProfile';

const LikedListPage = () => {
  const authContext: AuthContextType = useAuthProvider();
  if (!authContext.authData.token) return <NotFound />;
  const { userData, SFData, likedArtsList } = useLikedArtsList(
    authContext.authData.userId as string,
  );
  const navigate = useNavigate();
  const { userId } = useParams();

  if (userId != authContext.authData.userId) return <NotFound />;
  return (
    <WholeWrapper>
      <MyPageProfile
        displayname={userData?.displayname}
        pfpUri={userData?.profileImage}
        headerUri={userData?.headerImage}
        username={userData?.username}
        bio={userData?.normalBio}
        userRole={userData?.role}
        userId={userId}
        isPortfolio={false}
        SFData={SFData}
      />
      <Title title={'いいねした作品'} subTitle={'Liked List'} />
      {likedArtsList.total ? (
        <TotalNumContainer>{likedArtsList.total}件</TotalNumContainer>
      ) : (
        <></>
      )}
      <LikedListWrapper>
        {likedArtsList.artsData.length ? (
          <ItemsContainer>
            {likedArtsList?.artsData.map((art, i) => (
              <ItemContainer key={i}>
                <ImageContainer
                  onClick={() =>
                    navigate(`/items/${art.id}`, {
                      state: { kind: 'myLiked' },
                    })
                  }
                >
                  <ArtImage src={art.entityUrl} />
                  <LikeWrapper>
                    <LikeContainer>
                      <MdOutlineFavorite size="16px" color={Color.BRANDMAIN} />
                      {art.like}
                    </LikeContainer>
                  </LikeWrapper>
                </ImageContainer>
                <ArtInfo>
                  <ArtTitle>{art.name}</ArtTitle>
                  <ArtOwnerIcon
                    onClick={() => navigate(`/userpage/${art.ownedBy}`)}
                    src={art.profileImage}
                  />
                </ArtInfo>
              </ItemContainer>
            ))}
          </ItemsContainer>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            margin="2rem"
          >
            <SearchIcon style={{ fontSize: '50px' }} color="disabled" />
            <Text color={Color.BORDER20}>表示する作品がありません</Text>
          </Box>
        )}
      </LikedListWrapper>
      {!likedArtsList.isLast &&
        likedArtsList.size * 5 <= likedArtsList.total - 5 && (
          <LoadingButtonContainer onClick={() => likedArtsList.loadMore()}>
            <LoadingButton>もっと見る</LoadingButton>
          </LoadingButtonContainer>
        )}
    </WholeWrapper>
  );
};

export default LikedListPage;
