import styled from '@emotion/styled';

export const Required = styled.p`
  color: #e95098;
  font-size: 12px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
