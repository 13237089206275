import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';

export const MyPageProfileContainer = styled.div`
  ${tw`flex flex-col max-w-6xl mx-auto w-full`}
`;

export const MyPageHeaderContainer = styled.div`
  ${tw`flex pt-5 md:pt-20`}
`;

export const MyPageHeaderImg = styled.img`
  ${tw`w-full rounded-2xl`}
  height: 35vw;
  max-height: 20rem;
  object-fit: cover;
`;

export const MyPagePFPBase = styled.div`
  ${tw`flex`}
`;

export const MyPagePFP = styled.img`
  ${tw`rounded-full`}
  margin: -4rem auto 0;
  height: 18vw;
  width: 18vw;
  max-height: 10rem;
  max-width: 10rem;
  z-index: 4;
  object-fit: cover;
  border: 0.5vw solid ${Color.WHITETEXT};
`;

export const MyPageNameContainer = styled.div`
  ${tw`flex flex-col items-center break-all px-8`}
  margin: 0 auto;
`;

export const MyPageSubContainer = styled.div`
  ${tw`flex flex-row gap-x-2 items-center`}
  margin: 0 auto;
`;

export const MyPageIDContainer = styled.div`
  margin: 0 auto;
  ${tw`pt-2 pb-10`}
`;

export const MyPageBioContainer = styled.div`
  ${tw`p-4 mx-auto w-[82%] md:w-[72%] rounded-lg`}
`;

export const MyPageButtonsContainer = styled.div`
  ${tw`mx-auto my-4 w-[90%] md:w-[75%]`}
`;

export const HeaderPFPContainer = styled.div`
  ${tw`flex flex-col mb-4`}
`;

export const ButtonRowContainer = styled.div`
  ${tw`flex justify-center mb-4 gap-2`}
`;

export const ButtonWrapper = styled.div`
  ${tw`flex justify-center items-center py-2 cursor-pointer w-full px-8 rounded-lg transition duration-300 ease-in-out`}
  border: 1px solid #8A8A8A;
  &:hover {
    background-color: #fff;
    border: 1px solid #E95098;
`;

export const MailCertifyButtonWrapper = styled.div`
  ${tw`flex justify-center items-center mx-auto min-w-[240px] mt-5`}
`;

export const ButtonContainer = styled.div`
  ${tw`w-[90%] mx-auto text-center`}
`;

export const ButtonText = styled.p`
  ${tw`text-[10px] md:text-xs font-bold mt-2 mb-0`}
`;
