import styled from '@emotion/styled';
import tw from 'twin.macro';

export const WholeWrapper = styled.div`
  ${tw`w-[90%] md:w-[60%] mx-auto`}
`;

export const LikedUsersWrapper = styled.div`
  ${tw`mt-12`}
`;

export const LikedUserContainer = styled.div`
  ${tw`flex justify-start gap-4 items-center mb-4 bg-white py-4 px-4 rounded-lg cursor-pointer `}
  filter: drop-shodow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -webkit-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -moz-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -ms-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
`;

export const UserIcon = styled.img`
  ${tw`w-12 h-12 rounded-full hover:scale-110 transition-all duration-300`}
`;

export const UserName = styled.div`
  ${tw`text-[20px] font-bold`}
`;

export const LoadingButtonContainer = styled.div`
  ${tw`flex justify-center mx-auto mt-2 mb-16 px-12 py-4 bg-[#E95098] rounded-full cursor-pointer`}
`;

export const LoadingButton = styled.div`
  ${tw`font-bold text-white `};
`;

export const TotalNumContainer = styled.div`
  ${tw`bg-[#fff] px-4 py-2 rounded-full w-[60px] text-[14px] md:text-[16px] text-center font-bold mt-2 md:mt-0`};
  filter: drop-shodow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -webkit-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -moz-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -ms-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
`;
