import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Color } from '../../../constants/Color';
import { CardType } from '../../../constants/CardType';
import { skeleton } from '../../../lib/skeletonAnimation';

interface Props {
  cardType?: CardType;
  isSortable?: boolean;
}

const getCardStyle = (cardType: CardType | undefined) => {
  if (cardType == CardType.NORMAL) {
    return tw`drop-shadow-md`;
  } else {
    return tw``;
  }
};

export const StyledItemCardContainer = styled.div<Props>`
  ${tw`rounded-md bg-white m-1 md:m-2`}
  ${({ cardType }) => getCardStyle(cardType)}
  cursor: ${(props) => !props.isSortable && 'pointer'}
  filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -moz-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  transition: transform 0.2s ease-out;
  &:hover {
    ${(props) => !props.isSortable && 'transform: scale(1.02)'}
  }
`;

// モバイルサイズの時は作品名を表示しないバージョン
export const StyledArtistName = styled.div`
  ${tw`text-[4px] flex flex-row ml-1 hidden md:block`}
`;
// export const StyledArtistName = styled.div`
//   ${tw`text-[4px] flex flex-row ml-1`}
// `;

export const StyledItemBottom = styled.div`
  ${tw`flex items-center justify-between mx-2 mb-2 relative`}
`;

// モバイルサイズの時は作品名を表示しないバージョン
export const StyledTitle = styled.div`
  ${tw`flex justify-between whitespace-nowrap my-1 md:my-2 mx-2 items-start hidden md:block`}
`;
// export const StyledTitle = styled.div`
//   ${tw`flex justify-between whitespace-nowrap my-1 md:my-2 mx-2 items-start hidden md:block`}
// `;

interface ImgProps {
  url: string | undefined;
  isSortable: boolean | undefined;
}

export const StyledImageContainer = styled.div<ImgProps>`
  ${tw`flex justify-center relative bg-cover rounded-t-md ease-out duration-300 h-[200px] md:h-[100px] md:pt-[100%] cursor-pointer hover:opacity-90`}
  transform: translateX;
  background-image: url(${({ url }) => {
    return url;
  }});
  background-position: 50% 50%;
`;

export const StyledImageSkeleton = styled.div`
  ${tw`flex flex-col relative bg-cover rounded-md`}
  padding-top: 100%;
  ${skeleton}
`;

export const StyledPrice = styled.div`
  ${tw`absolute text-center py-1 rounded-xl bg-white px-4 md:px-6 bottom-[85%] right-2 md:bottom-2 md:right-2 flex md:block md:justify-center md:items-center`}
`;

export const StyledNFTText = styled.p`
  ${tw`text-xs font-bold m-0`}
  background: linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%);
  color: transparent;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
`;

export const StyledPriceText = styled.p`
  ${tw`text-sm font-bold m-0 hidden md:block`}
  background: linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%);
  color: transparent;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
`;

export const StyledRank = styled.div`
  ${tw`absolute flex justify-center items-center w-12 h-12 rounded-full`}
  top: 5%;
  left: 5%;
  background: ${Color.BRAND20};
`;

export const StyledLike = styled.div`
  ${tw`absolute bottom-0 right-0 flex rounded-full gap-1 h-[32px] md:p-0 bg-white md:bg-none md:justify-end items-center z-10`}
  @media (max-width: 768px) {
    filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
    -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
    -mozfilter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
    -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  }
`;

export const TextContainer = styled.div`
  ${tw`flex justify-center items-center pr-3`}
`;

export const StyledArtistPFP = styled.img`
  ${tw`h-6 w-6 rounded-full hidden md:block`}
`;

export const StyledArtistInfo = styled.div`
  ${tw`flex items-center`}
`;
