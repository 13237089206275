import styled from '@emotion/styled';

export const FullOverlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(100, 100, 100, 0.8);
  z-index: 65535;
`;

export const Window = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ClearHeader = styled.div`
  width: min(40em, 100%);
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  background-color: transparent;
  width: 3em;
  height: 3em;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  width: min(40em, 100%);
  height: min(30em, 100%);
  border-radius: 20px 20px 20px 20px;
  align-items: center;
`;

export const BigClearHeader = styled.div`
  width: min(60em, 100%);
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BigContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  width: min(60em, 100%);
  height: min(38em, 100%);
  border-radius: 20px 20px 20px 20px;
  align-items: center;
`;
