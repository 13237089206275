import React, { useState } from 'react';
import PasswordInput from '../../atoms/PasswordInput';
import TextInput from '../../atoms/TextInput';
import {
  LoginButton,
  Spacer,
  TermsText,
  TextButton,
  TextContainer,
  WholeWrapper,
} from './style';
import { Text } from '../../atoms/Text';
import { ButtonType } from '../../../constants/Button';
import { Color } from '../../../constants/Color';
import { useNavigate } from 'react-router-dom';
import { UserForSignIn, UserForSignUp } from '../../../types/domain/User';
import { useAuth } from '../../../api/AuthService';
import { Title } from '../../organisms/Title';
import { FontType } from '../../../constants/Fonts';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { CheckBox } from '../../atoms/CheckBox';
import { RequiredText } from '../../atoms/required';

interface Props {
  studentNo?: string;
}

export const SignUp: React.FC<Props> = ({ studentNo }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [mailAddress, setMailAddress] = useState('');
  const [accountId, setAccountId] = useState('');
  const [username, setUsername] = useState('');
  const { signUpHandler, loginHandler } = useAuth();
  const { addAlert } = useAlertContext();
  const [checked, setChecked] = useState(false);

  const isValidButton: boolean =
    password.length > 0 &&
    passwordConfirm.length > 0 &&
    mailAddress.length > 0 &&
    accountId.length > 0 &&
    username.length > 0 &&
    checked;

  const signUp = () => {
    if (password != passwordConfirm) {
      addAlert('パスワードが一致していません', 'error');
    } else {
      const user: UserForSignUp = {
        username: accountId,
        displayname: username,
        email: mailAddress,
        normalBio: '',
        profileBio: '',
        password: password,
        studentNo: studentNo,
      };
      const userLoginData: UserForSignIn = {
        username: accountId,
        email: mailAddress,
        password: password,
      };

      signUpHandler(user)
        .then(() => loginHandler(userLoginData))
        .then(() =>
          addAlert(
            'ユーザー登録に成功しました。認証メールを送信しました。メールの受信ボックスをご確認ください。',
            'success',
          ),
        )
        .then(() => navigate('/'))
        .catch((e) => addAlert(e, 'error'));
    }
  };

  // const checked = (check: boolean) => {
  //   if (check) {
  //     if (password && passwordConfirm && mailAddress && accountId && username) {
  //       setDisable(false);
  //     } else {
  //       setDisable(true);
  //     }
  //   } else {
  //     setDisable(true);
  //   }
  // };

  return (
    <WholeWrapper>
      <Title title={'アカウント登録'} subTitle={'Sign Up'} />
      {studentNo && (
        <Text fontType={FontType.TITLE4}>学籍番号：{studentNo}</Text>
      )}
      <TextContainer>
        <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
          メールアドレス
        </Text>
        <RequiredText />
      </TextContainer>
      <TextInput setText={setMailAddress} placeholder="例：yoani@example.com" />
      <TextContainer>
        <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
          ユーザー名（半角英数字・一意の文字列）
        </Text>
        <RequiredText />
      </TextContainer>
      <TextInput setText={setAccountId} placeholder="例：yoani" />
      <TextContainer>
        <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
          表示名（全角・半角）
        </Text>
        <RequiredText />
      </TextContainer>
      <TextInput
        setText={setUsername}
        placeholder="表示名を入力する（全角・半角）"
      />
      <TextContainer>
        <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
          パスワード
        </Text>
        <RequiredText />
      </TextContainer>
      <PasswordInput
        placeholder="パスワードを入力する"
        setPassword={setPassword}
      />
      <TextContainer>
        <Text color={Color.BORDER20} fontType={FontType.SMALL_NORMAL}>
          パスワードを確認する
        </Text>
        <RequiredText />
      </TextContainer>
      <PasswordInput
        placeholder="パスワードを入力する"
        setPassword={setPasswordConfirm}
      />
      <Spacer />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CheckBox onClick={() => setChecked(!checked)} />
        <TermsText target="_blank" href="/TermsOfUse">
          利用規約
        </TermsText>
        <TermsText>と</TermsText>
        <TermsText target="_blank" href="/guideline">
          ガイドライン
        </TermsText>
        <TermsText>に同意する</TermsText>
        <RequiredText />
      </div>
      <Spacer />
      {isValidButton ? (
        <LoginButton buttonType={ButtonType.GRADIENT} onClick={() => signUp()}>
          <p
            style={{
              color: '#fff',
              whiteSpace: 'pre-wrap',
              fontWeight: 'bold',
              minWidth: 'max-content',
              margin: 0,
              cursor: 'pointer',
            }}
          >
            新規アカウント登録
          </p>
        </LoginButton>
      ) : (
        <LoginButton buttonType={ButtonType.DISABLE}>
          <p
            style={{
              color: '#909090',
              whiteSpace: 'pre-wrap',
              fontWeight: 'bold',
              minWidth: 'max-content',
              margin: 0,
              cursor: 'pointer',
            }}
          >
            新規アカウント登録
          </p>
        </LoginButton>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }} />
        <TextButton onClick={() => navigate('/SignIn')}>
          すでにアカウントをお持ちの方はこちら
        </TextButton>
        <div style={{ flex: 1 }} />
      </div>
    </WholeWrapper>
  );
};
