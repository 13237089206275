import React from 'react';
import { StyledInput } from './styled';

type Props = {
  setText: (text: string) => void;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isAvailable?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  type?: string;
};

export default function TextInput({
  disabled,
  onKeyDown,
  placeholder,
  setText,
  defaultValue,
  type = 'text',
}: Props): JSX.Element {
  return (
    <>
      <StyledInput
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        type={type}
      />
    </>
  );
}
