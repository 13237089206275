import React, { useState, useEffect } from 'react';
import { StyledInput } from './styled';

type Props = {
  setDateTime: (datetime: number) => void;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isAvailable?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  type?: string;
};

export default function DateTimeInput({
  disabled,
  onKeyDown,
  placeholder,
  setDateTime,
  defaultValue,
  type = 'datetime-local',
}: Props): JSX.Element {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <StyledInput
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => {
          const date = new Date(e.target.value);
          const timestamp = Math.floor(date.getTime() / 1000);
          setDateTime(timestamp);
          setValue(e.target.value);
        }}
        onKeyDown={onKeyDown}
        value={value}
        type={type}
      />
    </>
  );
}
