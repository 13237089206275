import styled from '@emotion/styled';
import tw from 'twin.macro';

export const EventEditWrapper = styled.div`
  ${tw`my-12 flex flex-col gap-[40px] w-[80%] max-w-[1440px] mx-auto`}
`;

export const ButtonContainer = styled.div`
  ${tw`flex flex-row justify-center gap-[32px] w-full max-w-[1000px] h-[80px] mt-[40px] mx-auto`}
`;

export const TagContainer = styled.div`
  ${tw`flex flex-row gap-[20px] w-full h-[48px] mx-auto`}
`;

export const AddTextInputButton = styled.button`
  ${tw`w-full h-[64px] py-[8px] px-[16px] rounded-[10px] bg-transparent outline-dashed outline-2 border-none cursor-pointer`}
`;

export const DeleteTextInputButton = styled.button`
  ${tw`w-[32px] h-[32px] rounded-[10px] bg-transparent border-none cursor-pointer my-auto`}
`;

export const DateTimeInputContainer = styled.div`
  ${tw`flex flex-col sm:flex-row items-center gap-[20px] sm:gap-[5%] w-full mx-auto`}
`;
