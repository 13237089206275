import { API_URL } from '../config';
import { Art } from '../types/domain/Art';
import { Request } from '../types/domain/Request';

export interface RequestBody {
  type: string;
  targetId: number;
  requestedBy: number | undefined;
}

export interface GetRequestResponse {
  data: { art: Art; request: Request }[];
}

export const sendRequest = async (requestBody: RequestBody, token: string) => {
  const response = await fetch(API_URL + `/restricted/requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/*export const GetAllRequested = async (userId: number) => {
  const response = await fetch(API_URL + `/requests/users/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.body;
};

export const CheckIsRequested = async (artId: number, userId: number) => {
  const req: unknown = await GetAllRequested(userId);
  const json = req as GetRequestResponse;
  let n = 0;
  let ans = false;
  console.log(json);
  while (json.data[n].request.targetid) {
    console.log(n);
    if (
      artId == json.data[n].request.targetid &&
      json.data[n].request.status == 'Pending'
    ) {
      console.log(json.data[n].request.targetid);
      ans = true;
      n++;
    }
  }
  return ans;
};*/

export interface CheckRequestResponse {
  Data: boolean;
}

/*export const CheckIsRequested = async (artId: number) => {
  const fetcher: Fetcher<CheckRequestResponse, string> = (url: string) =>
    fetch(url, { mode: 'cors' }).then((res) => res.json());

  const response = await fetch(API_URL + `/requests/arts/${artId}/ispending`, {
    method: 'GET',
  }).json();
  console.log(response.body);
  if (response.body != null) {
    return response.body == true;
  } else {
    return null;
  }

  const { data, error } = useSWR(
    API_URL + `/requests/arts/${artId}/ispending`,
    fetcher,
  );
  console.log(data?.Data);
  console.log(error);
  return data?.Data;
};*/

export const CheckIsRequested = (artId: number, token: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open(
    'GET',
    API_URL + `/restricted/requests/arts/${artId}/ispending`,
    false,
  );
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  xhr.send();
  xhr.onload = function () {
    if (xhr.status != 200) {
      console.log(`Error ${xhr.status}: ${xhr.statusText}`);
    }
  };
  const json = JSON.parse(xhr.response);
  const pending = json.pending == true;
  const type = String(json.type);
  return { pending, type };
};
