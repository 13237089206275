import styled from '@emotion/styled';
import tw from 'twin.macro';

export const ButtonContainer = styled.div`
  ${tw`mr-2 lg:w-[30%]`}
`;
export const StyledButton = styled.div`
  ${tw`flex justify-center items-center bg-[#fff] mt-4 lg:w-[100%] rounded-full cursor-pointer w-[40px] h-[40px] text-[12px]`}
  transition: all .3s;
  filter: drop-shodow(0px 3px 2px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.1));
  -moz-filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.1));
  -ms-filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.1));
  :hover {
    filter: drop-shodow(0px 3px 5px rgba(29, 155, 240, 0.3));
    -webkit-filter: drop-shadow(0px 3px 5px rgba(29, 155, 240, 0.3));
    -moz-filter: drop-shadow(0px 3px 5px rgba(29, 155, 240, 0.3));
    -ms-filter: drop-shadow(0px 3px 5px rgba(29, 155, 240, 0.3));
  }
`;

export const IconWrapper = styled.div`
  ${tw`flex justify-center items-center bg-black  w-[30px] h-[30px] rounded-lg cursor-pointer mr-0 lg:mr-1`}
`;

export const IconContainer = styled.img`
  ${tw`w-[14px] h-[14px] `}
`;

export const TextContainer = styled.div`
  ${tw`font-bold hidden lg:block`}
`;
