import { ENV } from '../../../config';
import { Art } from '../../../types/domain/Art';

export interface AwardArtItemType {
  department?: string;
  tag?: string;
  awardCategory: string;
  artId: string;
  artData?: Art;
  color: string;
}

const awardArtItemsListProd: AwardArtItemType[] = [
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '総合最優秀賞',
    artId: '2183',
    color: '#00A1E9',
  },
  // 現役アイドルが推したいアイドル
  {
    department: 'ジュニア部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '大賞',
    artId: '2074',
    color: '#E95098',
  },
  {
    department: 'ジュニア部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '審査員賞',
    artId: '1531',
    color: '#E95098',
  },
  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '大賞',
    artId: '2127',
    color: '#E95098',
  },
  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '審査員賞',
    artId: '1875',
    color: '#E95098',
  },
  // 世界に羽ばたくアニメヒーロー
  {
    department: 'ジュニア部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '大賞',
    artId: '2079',
    color: '#FDD000',
  },
  {
    department: 'ジュニア部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '審査員賞',
    artId: '2130',
    color: '#FDD000',
  },
  {
    department: '一般部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '大賞',
    artId: '2111',
    color: '#FDD000',
  },
  {
    department: '一般部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '審査員賞',
    artId: '2114',
    color: '#FDD000',
  },
  // 不可能を可能に！理想のメタバースワールド
  {
    department: 'ジュニア部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '大賞',
    artId: '2156',
    color: '#00A1E9',
  },
  {
    department: 'ジュニア部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '審査員賞',
    artId: '2044',
    color: '#00A1E9',
  },
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '大賞',
    artId: '2153',
    color: '#00A1E9',
  },
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '審査員賞',
    artId: '2102',
    color: '#00A1E9',
  },
  // 他受賞作品
  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: 'イコラブ大場花菜賞',
    artId: '1997',
    color: '#E95098',
  },
  {
    department: '一般部門',
    tag: '不可能を可能に！理想のメタバースワールド',
    awardCategory: '読売中高生新聞賞',
    artId: '2362',
    color: '#00A1E9',
  },
  // 入賞作品
  {
    department: 'ジュニア部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '入賞',
    artId: '2161',
    color: '#E95098',
  },

  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '入賞',
    artId: '2147',
    color: '#E95098',
  },
  {
    department: 'ジュニア部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '入賞',
    artId: '2045',
    color: '#FDD000',
  },
  {
    department: '一般部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '入賞',
    artId: '1677',
    color: '#FDD000',
  },
  {
    department: 'ジュニア部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '入賞',
    artId: '1977',
    color: '#00A1E9',
  },
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '入賞',
    artId: '2029',
    color: '#00A1E9',
  },
];

const awardArtItemsListDev: AwardArtItemType[] = [
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '総合最優秀賞',
    artId: '605',
    color: '#00A1E9',
  },
  // 現役アイドルが推したいアイドル
  {
    department: 'ジュニア部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '大賞',
    artId: '606',
    color: '#E95098',
  },
  {
    department: 'ジュニア部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '審査員賞',
    artId: '607',
    color: '#E95098',
  },
  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '大賞',
    artId: '608',
    color: '#E95098',
  },
  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '審査員賞',
    artId: '609',
    color: '#E95098',
  },
  // 世界に羽ばたくアニメヒーロー
  {
    department: 'ジュニア部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '大賞',
    artId: '610',
    color: '#FDD000',
  },
  {
    department: 'ジュニア部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '審査員賞',
    artId: '611',
    color: '#FDD000',
  },
  {
    department: '一般部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '大賞',
    artId: '612',
    color: '#FDD000',
  },
  {
    department: '一般部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '審査員賞',
    artId: '613',
    color: '#FDD000',
  },
  // 不可能を可能に！理想のメタバースワールド
  {
    department: 'ジュニア部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '大賞',
    artId: '614',
    color: '#00A1E9',
  },
  {
    department: 'ジュニア部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '審査員賞',
    artId: '615',
    color: '#00A1E9',
  },
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '大賞',
    artId: '616',
    color: '#00A1E9',
  },
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '審査員賞',
    artId: '617',
    color: '#00A1E9',
  },
  // 他受賞作品
  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: 'イコラブ大場花菜賞',
    artId: '618',
    color: '#E95098',
  },
  {
    department: '一般部門',
    tag: '不可能を可能に！理想のメタバースワールド',
    awardCategory: '読売中高生新聞賞',
    artId: '619',
    color: '#00A1E9',
  },
  // 入賞作品
  {
    department: 'ジュニア部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '入賞',
    artId: '620',
    color: '#E95098',
  },
  {
    department: '一般部門',
    tag: '#現役アイドルが推したいアイドル',
    awardCategory: '入賞',
    artId: '621',
    color: '#E95098',
  },
  {
    department: 'ジュニア部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '入賞',
    artId: '622',
    color: '#FDD000',
  },
  {
    department: '一般部門',
    tag: '#世界に羽ばたく近未来アニメヒーロー',
    awardCategory: '入賞',
    artId: '623',
    color: '#FDD000',
  },
  {
    department: 'ジュニア部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '入賞',
    artId: '624',
    color: '#00A1E9',
  },
  {
    department: '一般部門',
    tag: '#不可能を可能に！理想のメタバースワールド',
    awardCategory: '入賞',
    artId: '625',
    color: '#00A1E9',
  },
];

let awardArtItemsList: AwardArtItemType[] = [];

if (ENV === 'prod') {
  awardArtItemsList = awardArtItemsListProd;
} else if (ENV === 'dev') {
  awardArtItemsList = awardArtItemsListDev;
}
export { awardArtItemsList };
