import { getArts, getTags } from '../../../api/ArtsService';
import { useContext } from 'react';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

export const useHome = (
  recomendQuery?: string,
  newerQuery?: string,
  nftItemQuery?: string,
) => {
  const authContext: AuthContextType = useContext(AuthContext);
  const token: string | undefined = authContext.authData?.token || undefined;

  const recommendedItemsData = getArts(recomendQuery, token);
  const newerItemsData = getArts(newerQuery + '&newer=true', token);
  // const contestData = getArts(
  //   'tag=490,491,492,493,494,495,496,497,505,508,513,514,516,549,570' +
  //     '&newer=true',
  // );

  const nftItemData = getArts(nftItemQuery + '&newer=true', token);
  const rankingItemsData = getArts();
  const { tags } = getTags('popularity=true&count=10');
  return {
    recommendedItemsData,
    rankingItemsData,
    newerItemsData,
    // contestData,
    nftItemData,
    tags,
  };
};
