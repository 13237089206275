import styled from '@emotion/styled';
import tw from 'twin.macro';

export const HomeContainer = styled.div`
  ${tw`flex flex-col justify-center w-[84vw] max-w-[1440px] mx-auto`}
`;

export const BannerContainer = styled.div`
  ${tw`py-4`}
  width: 100vw;
  margin: 0 calc(50% - 50vw);
`;

export const ContestText = styled.div`
  ${tw`text-center mb-4 border-b-2 hidden md:block`}
  border-Bottom: '3px solid #E95098';
`;

export const Kaisai = styled.div`
  ${tw`bg-white text-center py-1 md:py-2 rounded-full w-[100px] text-center ml-4 md:mb-4`}
`;
export const KaisaiText = styled.div`
  ${tw`text-center text-xs md:text-[16px] font-bold text-[#E95098]`}
`;
export const DeadLineText = styled.div`
  ${tw`flex justify-end mr-4 mb-4 hidden md:block`}
`;
