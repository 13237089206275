import { LoginButton, TextContainer, WholeWrapper, Spacer } from './style';
import { Text } from '../../atoms/Text';
import { Color } from '../../../constants/Color';
import PasswordInput from '../../atoms/PasswordInput';
import { ButtonType } from '../../../constants/Button';
import React from 'react';
import { useChangePasswordPage } from './hooks';
import { Title } from '../../organisms/Title';
import { NotFound } from '../NotFound';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';

export const ChangePassword: React.FC = () => {
  const {
    setNewPassword,
    setOldPassword,
    ChangingPassword,
    setNewPasswordValidation,
  } = useChangePasswordPage();

  const authContext: AuthContextType = useAuthProvider();

  if (!authContext.authData.token) return <NotFound />;

  return (
    <WholeWrapper>
      <Title title={'パスワード変更'} subTitle={'Change'} />
      <TextContainer>
        <Text color={Color.BORDER20}>古いパスワード</Text>
      </TextContainer>
      <PasswordInput
        placeholder="パスワードを入力する"
        setPassword={setOldPassword}
      />
      <TextContainer>
        <Text color={Color.BORDER20}>新しいパスワード</Text>
      </TextContainer>
      <PasswordInput
        placeholder="新しいパスワードを入力する"
        setPassword={setNewPassword}
      />
      <TextContainer>
        <Text color={Color.BORDER20}>新しいパスワード(確認)</Text>
      </TextContainer>
      <PasswordInput
        placeholder="新しいパスワードを確認する"
        setPassword={setNewPasswordValidation}
      />
      <Spacer />
      <LoginButton
        buttonType={ButtonType.ROUNDEDIMPORTANT}
        onClick={() => ChangingPassword()}
      >
        パスワードを変更する
      </LoginButton>
    </WholeWrapper>
  );
};
