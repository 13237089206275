import React, { useState } from 'react';
import { Text } from '../../atoms/Text';
import {
  ArtUserInfoContainer,
  ItemDetailContainer,
  ItemInfoContainer,
  PictureContainer,
  StyledItemImage,
  StyledPriceContainer,
  UserTagVStack,
  ButtonsContainer,
  SalesforceUserInfoContainer,
  SalesforceUserInfoCol,
  ArtValueContainer,
  ArtValue,
} from './style';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { UserTag } from '../../atoms/UserTag';
import { useNavigate } from 'react-router-dom';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { ConfirmationModal } from '../ConfirmationModal';
import { judgeRequest, Request } from '../../../api/AdminService';
import { User } from '../../../types/domain/User';
import { API_URL } from '../../../config';
import Spacer from '../../atoms/Spacer/Spacer';
import SpacerX from '../../atoms/Spacer/SpacerX';
import { Tag } from '../../atoms/Tag';
import { Box } from '@material-ui/core';
import dayjs from 'dayjs';

interface ItemDetailProps {
  artDetail: any | undefined;
  user: User | undefined;
  request: Request | undefined;
  reload: () => Promise<any>;
  status: string;
  value?: number;
}

export const RequestedArt: React.FC<ItemDetailProps> = ({
  artDetail,
  request,
  user,
  reload,
  status,
  value,
}) => {
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();
  const authContext: AuthContextType = useAuthProvider();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let isPortfolio = true;
  if (request == undefined) {
    isPortfolio = false;
  } else {
    isPortfolio = request.type == 'Portfolio';
  }

  const handleAccept = () => {
    setIsLoading(true);
    judgeRequest(
      request?.id as string,
      authContext.authData.userId as string,
      'Accepted',
      authContext.authData.token as string,
    ).then((res) => {
      if (res.ok) {
        reload();
        setIsPopupOpen(false);
        addAlert('申請を承認しました', 'success');
        setIsLoading(false);
      } else {
        setIsPopupOpen(false);
        addAlert(
          `申請の承認に失敗しました。時間をおいてもう一度お試し下さい`,
          'error',
        );
        setIsLoading(false);
      }
    });
  };
  const handleReject = () => {
    judgeRequest(
      request?.id as string,
      authContext.authData.userId as string,
      'Rejected',
      authContext.authData.token as string,
    ).then((res) => {
      if (res.ok) {
        reload();
        setIsPopupOpen(false);
        setIsModalOpen(false);
        addAlert('申請を拒否しました', 'success');
      } else {
        setIsPopupOpen(false);
        setIsModalOpen(false);
        addAlert('申請の拒否に失敗しました', 'error');
      }
    });
  };

  if (!artDetail) return <></>;
  else {
    return (
      <>
        <ConfirmationModal
          title={`${artDetail.name}の申請承認`}
          description={`選択した作品の申請を承認します。本当によろしいですか？ ※NFT申請の場合この操作には時間がかかります`}
          buttonTitle="承認する"
          subButtonTitle="キャンセル"
          close={() => setIsPopupOpen(false)}
          isDisplayed={isPopupOpen}
          func={handleAccept}
          isLoading={isLoading}
        />
        <ConfirmationModal
          title={`${artDetail.name}の申請拒否`}
          description="選択した作品の申請を拒否します。本当によろしいですか？"
          buttonTitle="拒否する"
          subButtonTitle="キャンセル"
          close={() => setIsModalOpen(false)}
          isDisplayed={isModalOpen}
          func={handleReject}
        />
        <ItemDetailContainer>
          <PictureContainer onClick={() => navigate(`/items/${artDetail?.ID}`)}>
            <StyledItemImage src={API_URL + `/arts/${artDetail?.ID}/entity`} />
          </PictureContainer>
          <ItemInfoContainer>
            <Text
              onClick={() => navigate(`/items/${artDetail?.ID}`)}
              fontType={FontType.TITLE2}
            >
              {artDetail?.name}
            </Text>
            <StyledPriceContainer>
              {isPortfolio ? (
                <Box display="flex">
                  <Tag
                    title="ポートフォリオ申請"
                    key="nft-tag"
                    color={Color.WHITETEXT}
                    bgColor={Color.BRAND_YELLOW}
                  />
                </Box>
              ) : (
                <Box display="flex">
                  <Tag
                    title="NFT申請"
                    key="nft-tag"
                    color={Color.WHITETEXT}
                    bgColor={Color.BRAND_YELLOW}
                  />
                </Box>
              )}
            </StyledPriceContainer>
            {!isPortfolio && (
              <ArtValueContainer>
                <Text fontType={FontType.TITLE4}>価格</Text>
                <ArtValue>￥ {value?.toLocaleString()}</ArtValue>
              </ArtValueContainer>
            )}
            <ArtUserInfoContainer>
              <UserTagVStack>
                <Text fontType={FontType.TITLE4}>オーナー</Text>
                <UserTag
                  userTagImageUri={API_URL + `/users/${user?.userId}/icon`}
                  userName={user?.username}
                  onclick={() => navigate(`/userpage/${user?.userId}`)}
                />
              </UserTagVStack>
            </ArtUserInfoContainer>
            <SalesforceUserInfoContainer>
              {user?.salesforceUser?.studentNo && user?.role !== 'admin' && (
                <>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>氏名</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {`${user?.salesforceUser?.lastname} ${user?.salesforceUser?.firstname}`}
                    </Text>
                  </SalesforceUserInfoCol>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>学籍番号</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {user?.salesforceUser?.studentNo}
                    </Text>
                  </SalesforceUserInfoCol>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>学校</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {user?.salesforceUser?.schoolName}
                    </Text>
                  </SalesforceUserInfoCol>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>学部</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {user?.salesforceUser?.classFaculty}
                    </Text>
                  </SalesforceUserInfoCol>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>学科</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {user?.salesforceUser?.classDepartment}
                    </Text>
                  </SalesforceUserInfoCol>
                </>
              )}
              {status === `pending` && (
                <>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>申請日</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {dayjs(artDetail.CreatedAt).format('YYYY/MM/DD HH:mm')}
                    </Text>
                  </SalesforceUserInfoCol>
                </>
              )}
              {status === `accepted` && (
                <>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>申請日</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {dayjs(artDetail.CreatedAt).format('YYYY/MM/DD HH:mm')}
                    </Text>
                  </SalesforceUserInfoCol>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>承認日</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {dayjs(artDetail.UpdatedAt).format('YYYY/MM/DD HH:mm')}
                    </Text>
                  </SalesforceUserInfoCol>
                </>
              )}
              {status === `rejected` && (
                <>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>申請日</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {dayjs(artDetail.CreatedAt).format('YYYY/MM/DD HH:mm')}
                    </Text>
                  </SalesforceUserInfoCol>
                  <SalesforceUserInfoCol>
                    <Text fontType={FontType.TITLE4}>却下日</Text>
                    <SpacerX width={8} />
                    <Text fontType={FontType.TITLE4} color={Color.BRAND30}>
                      {dayjs(artDetail.UpdatedAt).format('YYYY/MM/DD HH:mm')}
                    </Text>
                  </SalesforceUserInfoCol>
                </>
              )}
            </SalesforceUserInfoContainer>

            {request?.status === 'Pending' && (
              <ButtonsContainer>
                <Button
                  buttonType={ButtonType.CIRCLEWIDE}
                  onClick={() => setIsPopupOpen(true)}
                >
                  申請を承認する
                </Button>
                <Spacer width={16} height={16} />
                <Button
                  buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                  onClick={() => setIsModalOpen(true)}
                >
                  申請を拒否する
                </Button>
              </ButtonsContainer>
            )}
          </ItemInfoContainer>
        </ItemDetailContainer>
      </>
    );
  }
};
