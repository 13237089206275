/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiPlusSquare } from 'react-icons/fi';
import { TiDeleteOutline } from 'react-icons/ti';
import moment from 'moment';

import {
  EventEditWrapper,
  ButtonContainer,
  TagContainer,
  AddTextInputButton,
  DeleteTextInputButton,
  DateTimeInputContainer,
} from './style';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import TextInput from '../../atoms/TextInput';
import DateTimeInput from '../../atoms/DateTimeInput';

import { Title } from '../../organisms/Title';
import { ConfirmationModal } from '../../organisms/ConfirmationModal';

import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { ButtonType } from '../../../constants/Button';

import { useAlertContext } from '../../../Providers/AlertProvider';

import { getContestItems } from '../../../api/ArtsService';

interface EventProps {
  id?: number;
  name: string;
  start_date: number;
  end_date: number;
  is_held?: boolean;
  is_deleted?: boolean;
  is_reservation?: boolean;
  tags: string[];
}

export const EventEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addAlert } = useAlertContext();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [defaultEvent, setDefaultEvent] = useState<EventProps>({
    name: '',
    start_date: NaN,
    end_date: NaN,
    tags: [''],
  });

  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  useEffect(() => {
    const fetchEvents = async () => {
      if (id) {
        try {
          const fetchedItems = await getContestItems(id);
          setDefaultEvent({
            ...fetchedItems,
            name: fetchedItems.name || '',
            start_date: fetchedItems.start_date || NaN,
            end_date: fetchedItems.end_date || NaN,
            tags: fetchedItems.tags || [''],
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
    if (location.state?.defaultEvent) {
      setDefaultEvent(location.state.defaultEvent);
    } else {
      fetchEvents();
    }
  }, []);

  const [isReservationItemExist, setIsReservationItemExist] = useState(false);
  useEffect(() => {
    const checkEventIsReservations = async () => {
      try {
        const eventList = await getContestItems();
        setIsReservationItemExist(
          eventList.some((event: any) => event.is_reservation === true),
        );
      } catch (error) {
        console.error('イベントの取得に失敗しました', error);
      }
    };
    checkEventIsReservations();
  });

  const [isAlreadyReservation, setIsAlreadyReservation] = useState(false);
  useEffect(() => {
    const checkIsReservation = async () => {
      if (id) {
        try {
          const fetchedItems = await getContestItems(id);
          setIsAlreadyReservation(fetchedItems.is_reservation);
          console.log('fetchedItems', fetchedItems);
        } catch (error) {
          console.error(error);
        }
      }
    };
    checkIsReservation();
  }, [id, defaultEvent.is_reservation]);

  const updateDefaultEvent = (key: keyof EventProps, value: any) => {
    setDefaultEvent((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const addTextInputs = () => {
    setDefaultEvent((prevDetails) => {
      if (prevDetails.tags.length < 10) {
        return {
          ...prevDetails,
          tags: [...prevDetails.tags, ''],
        };
      } else {
        addAlert('タグは10個までしか追加できません。', 'error');
        return prevDetails;
      }
    });
  };

  const updateTag = (index: number, text: string) => {
    setDefaultEvent((prevDetails) => {
      const newTagNames = [...prevDetails.tags];
      newTagNames[index] = text;
      return { ...prevDetails, tags: newTagNames };
    });
  };

  const deleteTag = (index: number) => {
    setDefaultEvent((prevDetails) => {
      const newTagNames = [...prevDetails.tags];
      newTagNames.splice(index, 1);
      return { ...prevDetails, tags: newTagNames };
    });
  };

  const currentDate = Math.floor(Date.now() / 1000);
  const [isHeld, setIsHeld] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isReservation, setIsReservation] = useState(false);

  useEffect(() => {
    if (defaultEvent) {
      setIsHeld(
        currentDate >= defaultEvent.start_date &&
          currentDate <= defaultEvent.end_date,
      );
      setIsStarted(currentDate > defaultEvent.start_date);
      setIsFinished(currentDate > defaultEvent.end_date);
      setIsReservation(currentDate < defaultEvent.start_date);
    }
  }, [defaultEvent, currentDate]);

  const handleModalOpen = () => {
    if (!defaultEvent.name.trim()) {
      addAlert('タイトルを入力してください。', 'error');
      return;
    }

    if (isNaN(defaultEvent.start_date) || isNaN(defaultEvent.end_date)) {
      addAlert('イベント開催期間を設定してください。', 'error');
      return;
    }

    if (isReservationItemExist && isReservation) {
      addAlert('すでに予約済みのイラストコンテストが存在します。', 'error');
      return;
    }

    const diffSec = defaultEvent.end_date - defaultEvent.start_date;
    //1日未満
    if (diffSec < 86400) {
      addAlert('イベント開催期間は最低1日以上設けてください。', 'error');
      return;
    }
    //1年（365日）以上
    if (diffSec >= 31536000) {
      addAlert('イベント開催期間は一年以内で設定してください。', 'error');
      return;
    }

    if (defaultEvent.tags.length === 0) {
      addAlert('タグは最低１つ以上設定してください。', 'error');
      return;
    }

    if (defaultEvent.tags.some((tagName) => !tagName.trim())) {
      addAlert(
        '空のタグがあります。削除するか、すべてのタグを入力してください。',
        'error',
      );
      return;
    }

    const hasDuplicateTags =
      new Set(defaultEvent.tags).size !== defaultEvent.tags.length;
    if (hasDuplicateTags) {
      addAlert(
        '重複しているタグがあります。削除するか、タグの修正をしてください。',
        'error',
      );
      return;
    }

    isStarted &&
      addAlert('イベント開始日時が現在より前になっています。', 'warning');
    isFinished &&
      addAlert('イベント終了日時が現在より前になっています。', 'error');

    setIsConfirmationModalOpen(true);
  };

  const handleNavigateToConfirmationPage = () => {
    if ('id' in defaultEvent) {
      navigate(`/eventconfirmation?id=${defaultEvent.id}`, {
        state: { defaultEvent },
      });
    } else {
      navigate(`/eventconfirmation`, { state: { defaultEvent } });
    }
  };

  return (
    <>
      {/* 確認モーダル */}
      <ConfirmationModal
        title="この内容でイベントを設定します。よろしいですか？"
        description={
          '　⚠️　開催中のイベントの場合、変更は慎重に行なってください。'
        }
        description2={
          isStarted
            ? '　⚠️　イベント開始日時が現在より前になっています。'
            : undefined
        }
        description3={
          isFinished
            ? '　⚠️　イベント終了日時が現在より前になっています。'
            : undefined
        }
        buttonTitle="確認画面へ"
        subButtonTitle="キャンセル"
        close={() => setIsConfirmationModalOpen(false)}
        isDisplayed={isConfirmationModalOpen}
        func={handleNavigateToConfirmationPage}
      />

      {/* 本体 */}
      <EventEditWrapper>
        <Title title="イラストコンテスト編集" subTitle="Event Edit" />
        <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
          イラストコンテストタイトル
        </Text>
        <TextInput
          setText={(text) => updateDefaultEvent('name', text)}
          defaultValue={defaultEvent.name}
          placeholder="イラストコンテストタイトル"
        />
        <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
          イラストコンテスト期間
        </Text>
        <DateTimeInputContainer>
          <DateTimeInput
            setDateTime={(datetime) => {
              updateDefaultEvent('start_date', datetime);
              if (isReservation) {
                updateDefaultEvent('is_reservation', true);
              }
              if (isStarted && isAlreadyReservation) {
                updateDefaultEvent('is_reservation', false);
              }
            }}
            defaultValue={moment
              .unix(defaultEvent.start_date)
              .format('YYYY-MM-DDTHH:mm')}
            placeholder="開始日時"
          />
          <Text fontType={FontType.TITLE3} color={Color.MAIN}>
            〜
          </Text>
          <DateTimeInput
            setDateTime={(datetime) => updateDefaultEvent('end_date', datetime)}
            defaultValue={moment
              .unix(defaultEvent.end_date)
              .format('YYYY-MM-DDTHH:mm')}
            placeholder="終了日時"
          />
        </DateTimeInputContainer>
        {isHeld && (
          <>
            <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
              イラストコンテスト開催状態変更
              <Text fontType={FontType.TITLE3} color={Color.RED}>
                危険な操作です！運営上の問題が発生した場合のみ使用してください。
              </Text>
            </Text>
            <ButtonContainer>
              <Button
                disabled={defaultEvent.is_held}
                onClick={() => updateDefaultEvent('is_held', true)}
                buttonType={ButtonType.WIDEROUNDED_OUTLINED}
              >
                <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
                  開催中にする
                </Text>
              </Button>
              <Button
                disabled={!defaultEvent.is_held}
                onClick={() => updateDefaultEvent('is_held', false)}
                buttonType={ButtonType.CIRCLEWIDE}
              >
                <Text fontType={FontType.TITLE2s} color={Color.WHITETEXT}>
                  停止する
                </Text>
              </Button>
            </ButtonContainer>
          </>
        )}
        <Text fontType={FontType.TITLE2s} color={Color.MAIN}>
          イラストコンテストタグ
        </Text>
        {defaultEvent.tags.map((tag, index) => (
          <TagContainer>
            <TextInput
              key={index}
              setText={(text) => updateTag(index, text)}
              defaultValue={tag}
              placeholder="イラストコンテストタグ"
            />
            <DeleteTextInputButton onClick={() => deleteTag(index)}>
              <TiDeleteOutline size={32} color={Color.MAIN} />
            </DeleteTextInputButton>
          </TagContainer>
        ))}
        <AddTextInputButton onClick={addTextInputs}>
          <Text fontType={FontType.TITLE3} color={Color.MAIN}>
            タグを追加
          </Text>
          <FiPlusSquare size={24} color={Color.MAIN} />
        </AddTextInputButton>
        <ButtonContainer>
          <Button
            onClick={() => navigate('/contestmanagement')}
            buttonType={ButtonType.WIDEROUNDED_OUTLINED}
          >
            <Text fontType={FontType.TITLE2s} color={Color.BRAND_NEW_PINK}>
              戻る
            </Text>
          </Button>
          <Button
            onClick={() => handleModalOpen()}
            buttonType={ButtonType.CIRCLEWIDE}
          >
            <Text fontType={FontType.TITLE2s} color={Color.WHITETEXT}>
              確認画面へ
            </Text>
          </Button>
        </ButtonContainer>
      </EventEditWrapper>
    </>
  );
};
