import styled from '@emotion/styled';

import { ColorType } from '../../../constants/Color';
import { FontType, applyFont } from '../../../constants/Fonts';
import tw from 'twin.macro';

interface Props {
  fontType: FontType;
  color: ColorType;
  reactive: boolean;
  onClick?: () => void;
}

export const StyledText = styled.p<Props>`
  ${({ fontType }) => applyFont(fontType)}
  color: ${({ color }) => color};
  white-space: pre-wrap;
  min-width: 100%;
  margin: 0;
  ${({ onClick }) => onClick && tw`hover:cursor-pointer`}
  ${({ reactive }) =>
    reactive && '&:hover {text-decoration: underline; cursor: pointer;}'}
`;
