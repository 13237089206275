import useSWR, { Fetcher, mutate } from 'swr';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { User } from '../types/domain/User';
import { Tag } from '../types/domain/Tag';
import { Token } from '../types/domain/Token';
import { Art, ArtForRegistAPI } from '../types/domain/Art';
import { API_URL } from '../config';
import { AddressLike } from 'ethers';

export interface ArtsResponse {
  id: string;
  name: string;
  entityUrl?: string;
  creatorInfo: User;
  value?: number;
  like: number;
  insideLike: number;
  ownerInfo: User;
  about: string;
  isNFT: boolean;
  isDisplay: boolean;
  isPortfolio: boolean;
  tags: Tag[];
  token: Token;
  editable: boolean;
  likedUsers: User[];
  insideLikedUsers: User[];
  profileImage?: string;
  ownedBy?: number;
  isLiked?: boolean;
  isInsideLiked?: boolean;
  isSoldByOpensea?: boolean;
  ownedByAddress?: string;
  contestId?: number;
  isSelection?: boolean;
  isReservation?: boolean;
}
export interface LikedArtsResponse {
  ID: string;
  name: string;
  entityUrl?: string;
  creatorInfo: User;
  value?: number;
  like: number;
  insideLike: number;
  ownerInfo: User;
  about: string;
  isNFT: boolean;
  isDisplay: boolean;
  isPortfolio: boolean;
  tags: Tag[];
  token?: Token;
  editable: boolean;
  likedUsers?: User[];
  insideLikedUsers?: User[];
  profileImage?: string;
  ownedBy?: number;
  createdAt?: string;
  deletedAt?: string;
  updatedAt?: string;
}

export interface ArtsDataResponse {
  Total: number;
  Arts: ArtsResponse[];
}
export interface LikedArtsDataResponse {
  total: number;
  arts: LikedArtsResponse[];
}
export interface LikedArtsListResponse {
  status: boolean;
  code: number;
  data: LikedArtsDataResponse;
  message: string;
}

// 新バージョンのAPI
export const getArts = (query?: string, token?: string) => {
  const fetcher: Fetcher<ArtsDataResponse, string> = (url: string) =>
    fetch(url, {
      method: 'GET',
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    }).then((res) => res.json());

  // APIから受け取ったレスポンスをArt型に変換する関数
  const artsConverter = (artsDataResponse: ArtsDataResponse): Art[] => {
    return artsDataResponse.Arts?.map((artResponse: ArtsResponse) => {
      artResponse.entityUrl =
        API_URL + `/arts/${artResponse.id}/entity/compressed`;
      artResponse.ownerInfo.profileImage =
        API_URL + `/users/${artResponse.ownerInfo.userId}/icon`;
      artResponse.creatorInfo.profileImage =
        API_URL + `/users/${artResponse.creatorInfo.userId}/icon`;
      const art: Art = {
        ...artResponse,
        isPortfolio: false,
        isHide: false,
      };
      return art;
    });
  };

  const getKey: SWRInfiniteKeyLoader = (
    index: number,
    previousPageData: ArtsDataResponse | null,
  ) => {
    if (previousPageData && !previousPageData.Arts.length) return null;
    if (query)
      return (
        API_URL + `/restricted/arts/search?${query}&count=5&offset=${index * 5}`
      );
    return API_URL + `/restricted/arts/search?count=5&offset=${index * 5}`;
  };

  const options = {
    revalidateFirstPage: false,
    revalidateAll: false,
    revalidationOnReconnect: false,
    revalidationOnFocus: false,
  };

  const { data, error, size, setSize } = useSWRInfinite(
    getKey,
    fetcher,
    options,
  );

  const artsData = data
    ? data.flatMap((artsDataResponse) => artsConverter(artsDataResponse))
    : [];
  const reload = () =>
    mutate(API_URL + `/restricted/arts/search?count=5&offset=5`);

  const loadMore = () => {
    setSize(size + 1);
  };

  const total = data?.[0]?.Total ?? 0;

  const isLast: boolean = artsData.length === total;

  return { artsData, error, size, loadMore, reload, total, isLast };
};

export const getUserArts = (token: string, query?: string) => {
  const fetcher: Fetcher<ArtsDataResponse, string> = (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());

  // APIから受け取ったレスポンスをArt型に変換する関数
  const artsConverter = (artsDataResponse: ArtsDataResponse): Art[] => {
    return artsDataResponse.Arts.map((artResponse: ArtsResponse) => {
      artResponse.entityUrl =
        API_URL + `/arts/${artResponse.id}/entity/compressed`;
      artResponse.ownerInfo.profileImage =
        API_URL + `/users/${artResponse.ownerInfo.userId}/icon`;
      artResponse.creatorInfo.profileImage =
        API_URL + `/users/${artResponse.creatorInfo.userId}/icon`;
      const art: Art = {
        ...artResponse,
        isPortfolio: false,
        isHide: false,
      };
      return art;
    });
  };

  const getKey: SWRInfiniteKeyLoader = (
    index: number,
    previousPageData: ArtsDataResponse | null,
  ) => {
    if (previousPageData && !previousPageData.Arts.length) return null;
    if (query)
      return (
        API_URL +
        `/restricted/arts/personal?${query}&count=5&offset=${index * 5}`
      );
    else
      return API_URL + `/restricted/arts/personal?count=5&offset=${index * 5}`;
  };

  const options = {
    revalidateAll: false,
  };

  const { data, error, size, setSize } = useSWRInfinite(
    getKey,
    fetcher,
    options,
  );

  const artsData = data
    ? data.flatMap((artsDataResponse) => artsConverter(artsDataResponse))
    : [];

  const loadMore = () => {
    setSize(size + 1);
  };

  const reload = () =>
    mutate(API_URL + `/restricted/arts/personal?count=5&offset=5`);

  const total = data?.[0]?.Total ?? 0;

  const isLast: boolean = artsData.length === total;

  return { artsData, error, size, setSize, loadMore, reload, total, isLast };
};

export const getAllArts = (query?: string) => {
  const fetcher: Fetcher<ArtsDataResponse, string> = (url: string) =>
    fetch(url).then((res) => res.json());

  // APIから受け取ったレスポンスをArt型に変換する関数
  const artsConverter = (artsDataResponse: ArtsDataResponse): Art[] => {
    return artsDataResponse.Arts.map((artResponse: ArtsResponse) => {
      artResponse.entityUrl =
        API_URL + `/arts/${artResponse.id}/entity/compressed`;
      artResponse.ownerInfo.profileImage =
        API_URL + `/users/${artResponse.ownerInfo.userId}/icon`;
      artResponse.creatorInfo.profileImage =
        API_URL + `/users/${artResponse.creatorInfo.userId}/icon`;
      const art: Art = {
        ...artResponse,
        isPortfolio: false,
        isHide: false,
      };
      return art;
    });
  };

  const getKey: SWRInfiniteKeyLoader = (
    index: number,
    previousPageData: any | null,
  ) => {
    if (previousPageData && !previousPageData.Artslength) return null;
    if (query) return API_URL + `/restricted/arts/search?${query}`;
    else return API_URL + `/restricted/arts/search`;
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { data, error, mutate } = useSWRInfinite(getKey, fetcher);

  const artsData = data
    ? data.flatMap((artsDataResponse) => artsConverter(artsDataResponse))
    : [];
  const reload = () => mutate();

  return { artsData, error, reload };
};

export const getArtDetail = (id: string, token?: string) => {
  const fetcher: Fetcher<ArtsResponse, string> = (url: string) =>
    fetch(url, {
      method: 'GET',
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    }).then((res) => res.json());

  const artsConverter = (
    artsResponse: ArtsResponse | undefined,
  ): Art | undefined => {
    if (!artsResponse) return;
    artsResponse.entityUrl = API_URL + `/arts/${artsResponse.id}/entity`;
    artsResponse.ownerInfo.profileImage =
      API_URL + `/users/${artsResponse.ownerInfo.userId}/icon`;
    const art: Art = {
      ...artsResponse,
      isHide: false,
    };
    return art;
  };

  const reload = () => mutate(API_URL + `/restricted/arts/${id}`);

  const { data, error } = useSWR(API_URL + `/restricted/arts/${id}`, fetcher);

  const artData = artsConverter(data);

  return { artData, error, reload };
};

export const getContestArt = (id: string, token?: string) => {
  const fetcher: Fetcher<ArtsResponse, string> = (url: string) =>
    fetch(url, {
      method: 'GET',
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    }).then((res) => res.json());

  const artsConverter = (
    artsResponse: ArtsResponse | undefined,
  ): Art | undefined => {
    if (!artsResponse) return;
    artsResponse.entityUrl =
      API_URL + `/arts/${artsResponse.id}/entity/compressed`;
    artsResponse.ownerInfo.profileImage =
      API_URL + `/users/${artsResponse.ownerInfo.userId}/icon`;
    const art: Art = {
      ...artsResponse,
      isHide: false,
    };
    return art;
  };

  const reload = () => mutate(API_URL + `/restricted/arts/${id}`);

  const { data, error } = useSWR(API_URL + `/restricted/arts/${id}`, fetcher);

  const artData = artsConverter(data);

  return { artData, error, reload };
};

/**
 * register art to DB and blockchain
 * POST: /arts
 * @param art art
 * @param file file
 */
export const registerArt = async (
  art: ArtForRegistAPI,
  file: File,
  token: string,
) => {
  const header = {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
  };
  const form = new FormData();
  form.append('name', art.name);
  form.append('createdBy', art.createdBy);
  form.append('ownedBy', art.ownedBy);
  form.append('about', art.about);
  form.append('isDisplay', art.isDisplay ? '1' : '0');
  form.append('value', String(art.value));
  form.append('file', file as File);
  if (art.contestId) {
    form.append('contestId', art.contestId);
  }

  const response = await fetch(API_URL + `/restricted/arts`, {
    method: 'POST',
    headers: header,
    body: form,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  const data = response.json();
  console.log(data);
  return data;
};

export interface UpdateArtInfo {
  id: string;
  name?: string;
  about?: string;
  value?: number;
  isDisplay?: boolean;
  contestId?: number | null;
  isSelection?: boolean;
  isReservation?: boolean;
}

interface UpdateArtProps {
  updateArtInfo: UpdateArtInfo;
  token: string;
}

export const updateArt = async ({ updateArtInfo, token }: UpdateArtProps) => {
  const res = await fetch(API_URL + `/restricted/arts/${updateArtInfo.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      name: updateArtInfo.name,
      about: updateArtInfo.about,
      value: updateArtInfo.value,
      isDisplay: updateArtInfo.isDisplay,
    }),
  });
  if (res.ok) {
    return true;
  } else {
    console.error('update art error');
  }
};

/**
 * Delete art
 * DELETE: /arts/:artId
 * @param id art id
 * @param token JWT token
 */
export const deleteArt = async (id: string, token: string) => {
  const response = await fetch(API_URL + `/restricted/arts/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Get all tags
 * GET: /arts/tags
 */
export const getTags = (query?: string) => {
  const fetcher: Fetcher<Tag[], string> = (url: string) =>
    fetch(url).then((res) => res.json());

  const { data, error } = useSWR(
    API_URL + `/arts/tags/search?${query}`,
    fetcher,
  );

  return { tags: data, error };
};

/**
 * Register tag
 * POST: /arts/tags
 * @param name tag name
 * @param token JWT token
 */
export const registerTag = async (name: string, token: string) => {
  const response = await fetch(API_URL + `/restricted/arts/tags`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      name,
    }),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Delete tag
 * DELETE: /arts/tags/:tagId
 * @param id tag id
 * @param token JWT token
 */
export const deleteTag = async (id: string, token: string) => {
  const response = await fetch(API_URL + `/restricted/tags/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Taggig to art
 * POST: /arts/:artId/tagging
 * @param artId art id
 * @param tags tags array
 */
export const taggingToArt = async (
  artId: string,
  tags: Tag[],
  token: string,
) => {
  const selectedTags = JSON.stringify(tags);
  const response = await fetch(API_URL + `/restricted/arts/${artId}/tagging`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: selectedTags,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Untaggig to art
 * POST: /arts/:artId/untagging
 * @param artId art id
 * @param tags tags array
 */
export const untaggingToArt = async (
  artId: string,
  tags: Tag[],
  token: string,
) => {
  const selectedTags = JSON.stringify(tags);
  const response = await fetch(
    API_URL + `/restricted/arts/${artId}/untagging`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: selectedTags,
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Add one like to art field by anonymous user
 * POST: /arts/:artId/like
 * @param id artId
 */
export const addLike = async (id: string) => {
  await fetch(API_URL + `/arts/${id}/like`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }).catch((e) => console.error(e)); //Global errorsの実装完了次第ハンドリング
};

/**
 * Fetch likes that user added
 * GET: /users/:userId/likes
 * @param userId artId
 * @param token JWTtoken
 */
export const getUserLikes = async (userId: string, token: string) => {
  const response = await fetch(
    API_URL + `/restricted/users/${userId}/arts/likes`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Add one like to art field
 * POST: users/userId/arts/:artId/like
 * @param userId artId
 * @param artId artId
 * @param token JWTtoken
 */
export const addLikeByUser = async (
  userId: string,
  artId: string,
  token: string,
) => {
  const response = await fetch(
    API_URL + `/restricted/users/${userId}/arts/${artId}/like`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Remove one like to art field
 * POST: users/userId/arts/:artId/like
 * @param userId artId
 * @param artId artId
 * @param token JWTtoken
 */
export const removeLikeByUser = async (
  userId: string,
  artId: string,
  token: string,
) => {
  const response = await fetch(
    API_URL + `/restricted/users/${userId}/arts/${artId}/dislike`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Fetch inside likes that user added
 * GET: /users/:userId/likes
 * @param userId artId
 * @param token JWTtoken
 */
export const getUserInsideLikes = async (userId: string, token: string) => {
  const response = await fetch(
    API_URL + `/restricted/users/${userId}/arts/insideLikes`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Add one inside like to art field
 * POST: users/userId/arts/:artId/like
 * @param userId artId
 * @param artId artId
 * @param token JWTtoken
 */
export const addInsideLikeByAdminUser = async (
  userId: string,
  artId: string,
  token: string,
) => {
  const response = await fetch(
    API_URL + `/restricted/users/${userId}/arts/${artId}/insideLikeArt`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Remove one like to art field
 * POST: users/userId/arts/:artId/like
 * @param userId artId
 * @param artId artId
 * @param token JWTtoken
 */
export const removeInsideLikeByUser = async (
  userId: string,
  artId: string,
  token: string,
) => {
  const response = await fetch(
    API_URL + `/restricted/users/${userId}/arts/${artId}/disInsideLikeArt`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

export const mintArt = async (artId: string, token: string) => {
  const response = await fetch(API_URL + `/restricted/arts/${artId}/mint`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Sort portfolio items
 * PUT /portfolio/order/:userId
 * @param userId artId
 * @param artIds artIds
 * @param token JWTtoken
 */
export const sortPortfolioItems = async (
  userId: string,
  artIds: number[],
  token: string,
) => {
  const response = await fetch(
    API_URL + `/restricted/arts/portfolio/order/${userId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        artIds: artIds,
      }),
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

// {userId}がいいねした作品を取得
export const getLikedArtsList = (userId?: string, token?: string) => {
  const fetcher: Fetcher<LikedArtsListResponse, string> = (url: string) =>
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());

  const artsConverter = (likedArtsResponse: LikedArtsDataResponse) => {
    return likedArtsResponse.arts.map((artResponse: LikedArtsResponse) => {
      artResponse.entityUrl =
        API_URL + `/arts/${artResponse.ID}/entity/compressed`;
      artResponse.profileImage = API_URL + `/users/${artResponse.ownedBy}/icon`;
      const art: Art = {
        ...artResponse,
        isHide: false,
        id: artResponse.ID,
      };
      return art;
    });
  };

  const getKey: SWRInfiniteKeyLoader = (
    index: number,
    previousPageData: LikedArtsListResponse | null,
  ) => {
    if (previousPageData && !previousPageData.data.arts.length) return null;
    return (
      API_URL + `/restricted/users/${userId}/arts/liked-arts?page=${index + 1}`
    );
  };
  const options = {
    revalidateAll: true,
  };

  const { data, error, size, setSize } = useSWRInfinite(
    getKey,
    fetcher,
    options,
  );

  const extractData = data ? data.flatMap((eData) => eData.data) : [];
  const artsData = extractData
    ? extractData.flatMap((likedArtsResponse) =>
        artsConverter(likedArtsResponse),
      )
    : [];

  const loadMore = () => {
    setSize(size + 1);
  };

  const reload = () =>
    mutate(API_URL + `/restricted/users/${userId}/arts/liked-arts?page=1`);

  const total = extractData?.[0]?.total ?? 0;

  const isLast: boolean = artsData.length === total;

  return { artsData, error, size, setSize, loadMore, reload, total, isLast };
};

export const itemTransferedOutside = async (
  contractAddress: AddressLike,
  contractTokenId: number,
  ownedByAddress: AddressLike,
  transactionHash: string,
  token: string | undefined,
) => {
  const response = await fetch(API_URL + `/arts/itemTransferedOutside`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      contractAddress,
      contractTokenId,
      ownedByAddress,
      transactionHash,
    }),
  });
  if (!response.ok) {
    console.error('itemTransferedOutside error');
    throw new Error('Network response was not ok');
  } else {
    const data = await response;
    return data;
  }
};

export const getContestItems = async (contestId?: string) => {
  const response = await fetch(
    `${API_URL}${contestId ? `/arts/contests/${contestId}` : `/arts/contests`}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (!response.ok) {
    console.error('getContestItems error');
    throw new Error('Network response was not ok');
  } else {
    const data = await response.json();
    return data;
  }
};

export const updateContestItems = async (
  token: string | undefined,
  dataUpdated: object,
) => {
  const response = await fetch(API_URL + `/restricted/arts/contests/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(dataUpdated),
  });
  if (!response.ok) {
    console.error('updateContestItems error');
    throw new Error('Network response was not ok');
  } else {
    const data = await response.json();
    return data;
  }
};

export const createContestItems = async (
  token: string | undefined,
  dataCreated: object,
) => {
  const response = await fetch(API_URL + `/restricted/arts/contests/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(dataCreated),
  });
  if (!response.ok) {
    console.error('createContestItems error');
    throw new Error('Network response was not ok');
  } else {
    const data = await response.json();
    return data;
  }
};

export const getSelectedContestArts = (
  contestId: number | null,
  isSelection: boolean,
  token: string | undefined,
) => {
  const fetcher: Fetcher<ArtsDataResponse, string> = (url: string) =>
    fetch(url, {
      method: 'GET',
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    }).then((res) => res.json());

  const artsConverter = (artsDataResponse: ArtsDataResponse): Art[] => {
    if (!artsDataResponse.Arts) {
      return [];
    }
    return artsDataResponse.Arts.map((artResponse: ArtsResponse) => {
      artResponse.entityUrl =
        API_URL + `/arts/${artResponse.id}/entity/compressed`;
      artResponse.ownerInfo.profileImage =
        API_URL + `/users/${artResponse.ownerInfo.userId}/icon`;
      artResponse.creatorInfo.profileImage =
        API_URL + `/users/${artResponse.creatorInfo.userId}/icon`;
      const art: Art = {
        ...artResponse,
        isPortfolio: false,
        isHide: false,
      };
      return art;
    });
  };

  const getKey: SWRInfiniteKeyLoader = (
    index: number,
    previousPageData: ArtsDataResponse | null,
  ) => {
    if (previousPageData && !previousPageData.Arts.length) return null;
    return API_URL + `/restricted/arts`;
  };

  const options = {
    revalidateFirstPage: false,
    revalidateAll: false,
    revalidationOnReconnect: false,
    revalidationOnFocus: false,
  };

  const { data, error, size, setSize } = useSWRInfinite(
    getKey,
    fetcher,
    options,
  );

  const filterArtsData = (artsData: Art[]) => {
    return artsData.filter(
      (art) => art.contestId === contestId && art.isSelection === isSelection,
    );
  };

  const artsData = data
    ? data.flatMap((artsDataResponse) =>
        filterArtsData(artsConverter(artsDataResponse)),
      )
    : [];

  const reload = () =>
    mutate(API_URL + `/restricted/arts/contest/search?count=5&offset=0`);

  const loadMore = () => {
    setSize(size + 1);
  };

  const total = data?.[0]?.Total ?? 0;

  const isLast: boolean = artsData.length === total;

  return { artsData, error, size, loadMore, reload, total, isLast };
};

export const editContestId = async ({
  updateArtInfo,
  token,
}: UpdateArtProps): Promise<boolean> => {
  try {
    const response = await fetch(
      API_URL + `/restricted/arts/${updateArtInfo.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          contestId: updateArtInfo.contestId,
        }),
      },
    );
    if (response.ok) {
      return true;
    } else {
      console.error('update contestId error');
      return false;
    }
  } catch (error) {
    console.error('fetch error:', error);
    return false;
  }
};

export const editContestIsSelected = async ({
  updateArtInfo,
  token,
}: UpdateArtProps): Promise<boolean> => {
  try {
    const response = await fetch(
      API_URL + `/restricted/arts/${updateArtInfo.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          contestId: updateArtInfo.contestId,
          isSelection: updateArtInfo.isSelection,
        }),
      },
    );
    if (response.ok) {
      return true;
    } else {
      console.error('update contestIsSelection error');
      return false;
    }
  } catch (error) {
    console.error('fetch error:', error);
    return false;
  }
};
