import { API_URL } from '../config';

export interface PurchaseRequest {
  artId: number;
  buyerUserId: number;
  sellerUserId: number;
  value: number;
  paymentId: number;
  status: string;
  transactionhash?: string;
}

export const Purchase = async (token: string, req: PurchaseRequest) => {
  await fetch(API_URL + `/restricted/purchases`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(req),
  })
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e;
    });
};

/**
 * Get user purchase history
 * GET: GET /purchases/users/:userID
 * @param userId userId
 * @param token JWTtoken
 */
export const fetchPurchaseHistory = async (userId: string, token: string) => {
  const response = await fetch(
    API_URL + `/restricted/purchases/users/${userId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Get All purchase history
 * GET: GET /purchases/users/:userID
 * @param token JWTtoken
 */
export const fetchAllPurchaseHistory = async (token: string) => {
  const response = await fetch(API_URL + `/restricted/purchases/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Issue invoiceID to purchase
 * POST: /purchases/invoices
 * @param buyerId byerId
 * @param artId artId
 * @param ownerId ownerId
 * @param token JWTtoken
 */
export const issueInvoiceId = async (
  buyerId: number,
  artId: number,
  ownerId: number,
  token: string,
) => {
  const params = JSON.stringify({
    buyerId,
    artId,
    ownerId,
  });
  const response = await fetch(API_URL + `/restricted/purchases/gmo/invoices`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Save orderID to DB
 * POST /purchases/orders
 * @param invoiceId invoiceId
 * @param artId artId
 * @param buyerUserId buyerUserId
 * @param sellerUserId sellerUserId
 * @param value value
 * @param orderId orderId
 * @param token JWTtoken
 */
export const saveOrderId = async (
  invoiceId: string,
  buyerUserId: number,
  sellerUserId: number,
  artId: number,
  value: number,
  orderId: string,
  token: string,
) => {
  const params = JSON.stringify({
    invoiceId,
    buyerUserId,
    sellerUserId,
    orderId,
    artId,
    value,
  });
  const response = await fetch(API_URL + `/restricted/purchases/orders`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * run first transfer
 * POST /purchases
 * @param invoiceId invoiceId
 * @param status status
 * @param orderId orderId
 * @param token JWTtoken
 */
export const firstTransfer = async (
  invoiceId: string,
  status: 'completed' | 'failed',
  orderId: string,
  token: string,
) => {
  const params = JSON.stringify({
    invoiceId,
    status,
    orderId,
  });
  const response = await fetch(API_URL + `/restricted/purchases`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * transfer NFT
 * POST /arts/:artId/transfer
 * @param artId artId
 * @param token token
 */
export const transferNFT = async (artId: string, token: string) => {
  const params = JSON.stringify({
    artId,
  });
  const response = await fetch(API_URL + `/restricted/arts/${artId}/transfer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * re-transfer NFT
 * POST /purchases/reTransfer
 * @param artId artId
 * @param buyerId buyerId
 * @param token token
 */
export const reTransferNFT = async (
  artId: number,
  buyerId: number,
  token: string,
) => {
  const params = JSON.stringify({
    artId,
    buyerId,
  });
  const response = await fetch(API_URL + `/restricted/purchases/reTransfer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Payout to admin
 * POST /paypal/payout
 * @param orderId orderId
 * @param token token
 */
export const payoutToAdmin = async (orderId: string, token: string) => {
  const params = JSON.stringify({
    orderId,
    token,
  });
  const response = await fetch(
    API_URL + `/restricted/purchases/paypal/payout`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Confirm user payment
 * POST /confirmPayment
 * @param userId uerId
 * @param artId artId
 * @param invoiceId invoiceId
 * @param orderId invoiceId
 * @param token token
 */
export const confirmUserPayment = async (
  userId: number,
  artId: number,
  invoiceId: string,
  orderId: string,
  token: string,
) => {
  const params = JSON.stringify({
    userId,
    artId,
    invoiceId,
    orderId,
  });
  const response = await fetch(
    API_URL + `/restricted/purchases/confirmPayment`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Save user payment as compmleted to DB
 * POST /purchases/:orderId/payment
 * @param orderId invoiceId
 * @param token token
 */
export const completeUserPayment = async (orderId: string, token: string) => {
  const response = await fetch(
    API_URL + `/restricted/purchases/${orderId}/payment`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Save purchaseRecord as failed to DB
 * POST /purchases/status/fail
 * @param artId artId
 * @param buyerUserId buyerUserId
 * @param sellerUserId sellerUserId
 * @param token token
 */
export const failedPurchaseOrder = async (
  artId: number,
  buyerUserId: number,
  sellerUserId: number,
  token: string,
) => {
  const params = JSON.stringify({
    artId,
    buyerUserId,
    sellerUserId,
  });
  const response = await fetch(API_URL + `/restricted/purchases/status/fail`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

/**
 * Mail validation check pre purchase
 * GET /purchases/:artId/prePurchaseCheck
 * @param artId artId
 */
export const prePurchaseCheck = async (artId: string, token: string) => {
  const response = await fetch(
    API_URL + `/restricted/purchases/${artId}/prePurchaseCheck`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const getDepositUrl = async (
  password: string,
  depositType: string,
  purchaseId?: number,
  token?: string,
) => {
  const params = JSON.stringify({
    password,
    depositType,
    purchaseId,
  });
  const response = await fetch(
    API_URL + `/restricted/purchases/gmo/depositlink`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: params,
    },
  )
    .then((res) => res.json())
    .catch((e) => {
      return e;
    });
  return response;
};
