import styled from '@emotion/styled';
import tw from 'twin.macro';

export const PrevNextButtonWrapper = styled.div`
  ${tw`flex gap-4 mt-8 md:mt-16 lg:mt-20`}
`;

export const PrevButton = styled.div`
  ${tw`flex items-center gap-2 px-4 py-2 bg-[#E95098] rounded-full  cursor-pointer text-white font-bold text-[12px] hover:opacity-80 transition-all w-[50%] justify-center`}
`;
export const PrevButtonDisabled = styled.div`
  ${tw`flex items-center gap-2 px-4 py-2 bg-white rounded-full text-[12px] font-bold bg-[#BCBCBC] text-[#909090] w-[50%] justify-center`}
`;

export const NextButton = styled.div`
  ${tw`flex items-center gap-2 px-4 py-2 bg-[#E95098] rounded-full  cursor-pointer text-white font-bold text-[12px] hover:opacity-80 transition-all w-[50%] justify-center`}
`;

export const NextButtonDisabled = styled.div`
  ${tw`flex items-center gap-2 px-4 py-2 bg-white rounded-full text-[12px] font-bold bg-[#BCBCBC] text-[#909090] w-[50%] justify-center`}
`;
