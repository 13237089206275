import React, { useEffect } from 'react';
import { Text } from '../../atoms/Text';
import {
  StyledContainer,
  StyledSmallContainer,
  MyPageHeaderContainer,
  MyPageHeaderImg,
  TopPartContainer,
  HeaderInput,
  HeaderOverlay,
  StyledTextInputContainer,
} from './style';
import { useState } from 'react';
import { Button } from '../../atoms/Button';
import { ButtonType } from '../../../constants/Button';
import { AuthContextType } from '../../../Providers/AuthProvider';
import { BsCardImage } from 'react-icons/bs';
import { Color } from '../../../constants/Color';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { FontType } from '../../../constants/Fonts';
import { Box, CircularProgress } from '@material-ui/core';
import { StyledInput } from '../../atoms/TextInput/styled';
import { createBanner, updateBanner } from '../../../api/AdminService';

// interface BannerField {
//   url: string;
//   src: any;
// }

interface Props {
  index: number;
  banner: any;
  authContext: AuthContextType;
  removeFunc: (index: number, id: string, isEmpty: boolean) => void;
  reload: () => Promise<any>;
}

export const BannerInput: React.FC<Props> = ({
  index,
  banner,
  authContext,
  removeFunc,
  reload,
}) => {
  const { addAlert } = useAlertContext();
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined,
  );
  const [header, setHeader] = useState<File>();
  const [bannerUrl, setBannerUrl] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    if (currentIndex === undefined) {
      setCurrentIndex(index);
    } else {
      setCurrentIndex((prev) => prev);
    }
  }, []);

  const handleHeaderInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        alert('ファイル形式が正しくありません。');
      } else {
        setHeader(file);
        setImageUrl(window.URL.createObjectURL(file));
      }
    }
  };

  const handleUpdate = () => {
    setIsLoading(true);
    if (!banner.isEmpty) {
      updateBanner(
        banner.id,
        bannerUrl as string,
        header as File,
        authContext.authData.token as string,
      ).then((res) => {
        if (res.ok) {
          reload();
          setIsLoading(false);
          addAlert('バナー情報を更新しました', 'success');
        } else {
          setIsLoading(false);
          addAlert('バナー情報の更新に失敗しました', 'error');
        }
      });
    } else {
      createBanner(
        authContext.authData.token as string,
        bannerUrl as string,
        header as File,
      ).then((res) => {
        if (res.ok) {
          reload();
          setIsLoading(false);
          addAlert('バナー情報を登録しました', 'success');
        } else {
          setIsLoading(false);
          addAlert('バナー情報の登録に失敗しました', 'error');
        }
      });
    }
  };

  return (
    <TopPartContainer key={banner.bannerId}>
      <StyledContainer>
        <StyledSmallContainer>
          <Text fontType={FontType.TITLE3}>{`バナー${index + 1}`}</Text>
          <Box display="flex">
            <Button
              buttonType={ButtonType.NORMAL}
              onClick={() =>
                removeFunc(banner.index, banner.id, banner.isEmpty)
              }
            >
              削除する
            </Button>
            <Button
              disabled={isLoading || (banner.isEmpty && !header)}
              buttonType={ButtonType.IMPORTANT}
              onClick={handleUpdate}
            >
              {!isLoading ? (
                '保存する'
              ) : (
                <CircularProgress size={16} style={{ color: '#fff' }} />
              )}
            </Button>
          </Box>
        </StyledSmallContainer>
      </StyledContainer>
      <StyledContainer>
        <StyledTextInputContainer>
          <StyledInput
            key={banner.id}
            placeholder="URL"
            defaultValue={banner.url}
            onChange={(e) => setBannerUrl(e.target.value)}
          />
        </StyledTextInputContainer>
      </StyledContainer>
      <MyPageHeaderContainer>
        <HeaderInput
          key={banner.id}
          type="file"
          multiple={false}
          onChange={handleHeaderInput}
          accept={'.png, .jpeg'}
        />
        {header ? (
          <>
            <HeaderOverlay>
              <BsCardImage size="5rem" color={Color.WHITETEXT} />
            </HeaderOverlay>
            <MyPageHeaderImg src={imageUrl} />
          </>
        ) : (
          <>
            <HeaderOverlay>
              <BsCardImage size="5rem" color={Color.WHITETEXT} />
            </HeaderOverlay>
            <MyPageHeaderImg src={banner.image} />
          </>
        )}
      </MyPageHeaderContainer>
      <p style={{ fontSize: '12px', marginLeft: '12px' }}>
        画像サイズ推奨(2400 × 640)(15 : 4)
      </p>
    </TopPartContainer>
  );
};
