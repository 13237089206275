import styled from '@emotion/styled';
import tw from 'twin.macro';

export const StyledInput = styled.input`
  ${tw`flex items-center justify-center w-[80%] lg:w-[40%] h-full text-center font-bold md:text-3xl text-[20px] bg-white border-solid border-2 border-black`}
  appearance: none;
  &:read-only::placeholder {
    color: black;
  }
`;
