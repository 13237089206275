import { getAllArts } from '../../../api/ArtsService';
import { getUser } from '../../../api/UsersService';

export const usePortfolioPage = (userId: string) => {
  const { userData } = getUser(userId);

  const portfolioData = getAllArts(`createdBy=${userId}&isPortfolio=true`);

  return { userData, portfolioData };
};
