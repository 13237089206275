import React, { useState } from 'react';
import PasswordInput from '../../atoms/PasswordInput';
import TextInput from '../../atoms/TextInput';
import {
  LoginButton,
  Spacer,
  TextButton,
  TextContainer,
  WholeWrapper,
} from './style';
import { Text } from '../../atoms/Text';
import { ButtonType } from '../../../constants/Button';
import { Color } from '../../../constants/Color';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../api/AuthService';
import { Title } from '../../organisms/Title';
import { FontType } from '../../../constants/Fonts';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { SignUp } from '../SignUp';
import { RequiredText } from '../../atoms/required';

export const StudentSignUp: React.FC = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [studentNo, setStudentNo] = useState('');
  const { studentSignUpHandler } = useAuth();
  const { addAlert } = useAlertContext();
  const [studentChecked, setStudentChecked] = useState<string>();

  const isValidButton: boolean = password.length > 0 && studentNo.length > 0;

  const studentSignUp = () => {
    if (!password || !studentNo) return;
    studentSignUpHandler({ studentNo: studentNo, passwordTemp: password })
      .then(() => addAlert('ログイン情報を確認できました。', 'success'))
      .then(() => setStudentChecked(studentNo))
      .catch(() => addAlert('ログイン情報に誤りがあります。', 'error'));
  };

  return (
    <>
      {studentChecked ? (
        <SignUp studentNo={studentChecked} />
      ) : (
        <WholeWrapper>
          <Title
            title={'代アニ学生 アカウント登録'}
            subTitle={'Yoani Student Sign Up'}
          />
          <Text fontType={FontType.TITLE3}>
            代々木アニメーション学院の学生は、メールにて送付した仮パスワードを使用して、アカウントを作成してください。
          </Text>
          <TextContainer>
            <Text color={Color.BORDER20}>学籍番号</Text>
            <RequiredText />
          </TextContainer>
          <TextInput setText={setStudentNo} placeholder="例：00A0000" />
          <TextContainer>
            <Text color={Color.BORDER20}>仮パスワード</Text>
            <RequiredText />
          </TextContainer>
          <PasswordInput placeholder="例：20001010" setPassword={setPassword} />
          <Spacer />
          {isValidButton ? (
            <LoginButton
              buttonType={ButtonType.ROUNDEDIMPORTANT}
              onClick={() => studentSignUp()}
            >
              登録する
            </LoginButton>
          ) : (
            <LoginButton
              buttonType={ButtonType.DISABLE}
              onClick={() => studentSignUp()}
            >
              登録する
            </LoginButton>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }} />
            <TextButton onClick={() => navigate('/SignIn')}>
              すでにアカウントをお持ちの方
            </TextButton>
            <div style={{ flex: 1 }} />
          </div>
        </WholeWrapper>
      )}
    </>
  );
};
