import styled from '@emotion/styled';
import { Button } from '../../atoms/Button';
import tw from 'twin.macro';

export const StyledNotFoundContainer = styled.div`
  ${tw`flex flex-col items-center justify-center h-screen`}
`;

export const SuccessImg = styled.img`
  ${tw`flex-grow w-full object-contain`}
  max-width: 14rem;
`;

export const ImgContainer = styled.div`
  ${tw`flex flex-col items-center pr-14`}
`;

export const StyledNotFoundTextFrame = styled.div`
  ${tw`mx-auto my-10 px-10 py-5 rounded-xl flex flex-col items-center`}
`;

export const StyledButtonContainer = styled.div`
  ${tw`my-5`}
`;

export const LoginButton = styled(Button)`
  margin-top: 30px;
  display: block;
`;

export const Spacer = styled.div`
  width: 100%;
  height: 30px;
`;

export const TextContainer = styled.div`
  width: 100%;
  margin: 4% 0% 4% 0%;
`;

export const TextButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  margin: 2% 0% 2% 0%;
  cursor: pointer;
`;

export const WholeWrapper = styled.div`
  ${tw`flex flex-col mt-12 w-[90%] md:w-[60%] mx-auto`}
`;
