/* eslint-disable react/display-name */
import React, { forwardRef, HTMLAttributes, CSSProperties } from 'react';
import { CardType } from '../../../constants/CardType';
import { Art } from '../../../types/domain/Art';
import { ArtCard } from '../../organisms/ArtCard';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  item?: Art;
  cardType?: CardType;
  index: number;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    { id, item, cardType, index, withOpacity, isDragging, style, ...props },
    ref,
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? '0.5' : '1',
      transformOrigin: '50% 50%',
      borderRadius: '10px',
      cursor: isDragging ? 'grabbing' : 'grab',
      backgroundColor: '#ffffff',
      padding: '10%',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: isDragging
        ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
        : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      ...style,
    };

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        <ArtCard
          isSortable
          _art={item as Art}
          key={`artcard-${index}`}
          cardType={cardType}
        />
      </div>
    );
  },
);

export default Item;
