import React from 'react';
import { StyledTextArea } from './styled';

type Props = {
  setText: (text: string) => void;
  placeholder?: string;
  defaultValue?: string;
};

export default function TextArea({
  placeholder,
  setText,
  defaultValue,
}: Props): JSX.Element {
  return (
    <div>
      <StyledTextArea
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        defaultValue={defaultValue}
      />
    </div>
  );
}
