import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCardImage } from 'react-icons/bs';

import {
  SettignContainer,
  StyledContainer,
  StyledSmallContainer,
  StyledTextAreaContainer,
  ChangeButtonContainer,
  SubContainer,
  MyPagePFP,
  MyPagePFPBase,
  MyPagePFPContainer,
  MyPageHeaderContainer,
  MyPageHeaderImg,
  TopPartContainer,
  HeaderOverlay,
  PFPoverlay,
  TextButton,
  MiddlePartContainer,
  StyledWrapper,
} from './style';
import { NotFound } from '../NotFound';
import { CropperPopUp } from '../../CropperPopUp';

import { Text } from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import TextArea from '../../atoms/TextArea';

import { Title } from '../../organisms/Title';

import { ButtonType } from '../../../constants/Button';
import { Color } from '../../../constants/Color';
import { FontType } from '../../../constants/Fonts';

import { UpdateInfo, useAuth } from '../../../api/AuthService';
import {
  EXIST_PENDING_REQUEST,
  INVALID_EMAIL_ADDRESS,
  TRANSFER_FAILED_PURCHASE_SELLER,
  WALLET_ADDRESS_CANNOT_BE_CHANGED,
} from '../../../api/errorStatus';

import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';

import { User } from '../../../types/domain/User';

import { API_URL } from '../../../config';

export const Settings: React.FC = () => {
  const authContext: AuthContextType = useContext(AuthContext);
  const { addAlert } = useAlertContext();
  const { updateUserInfo } = useAuth();
  const [newUsername, setNewUsername] = useState('');
  const [newAccountId, setNewAccountId] = useState('');
  const [newMailAddress, setNewMailAddress] = useState('');
  const [newBio, setNewBio] = useState('');
  const { getCurrentUser } = useAuth();
  const [currentUser, setCurrentUser] = useState<User>();
  const navigate = useNavigate();
  const [pfpUrl, setPfpUrl] = useState<string>();
  const [headerUrl, setHeaderUrl] = useState<string>();
  const [isHeaderCropperDisplayed, setIsHeaderCropperDisplayed] =
    useState(false);
  const [isPFPCropperDisplayed, setIsPFPCropperDisplayed] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setNewUsername(currentUser.username);
      setNewAccountId(currentUser.displayname);
      setNewMailAddress(currentUser.email);
      setNewBio(currentUser.normalBio);
    }
  }, []);

  const handleUpdate = () => {
    const updateInfo: UpdateInfo = {
      username: newUsername,
      email: newMailAddress,
      normalBio: newBio,
      portfolioBio: newBio,
      displayname: newAccountId,
    };
    updateUserInfo(updateInfo)
      .then(() => navigate('/'))
      .then(() => addAlert('ユーザー情報を更新しました', 'success'))
      .catch((err) => {
        if (err.message === 'EXIST_PENDING_REQUEST') {
          addAlert(EXIST_PENDING_REQUEST, 'warning');
        } else if (err.message === 'TRANSFER_FAILED_PURCHASE_SELLER') {
          addAlert(TRANSFER_FAILED_PURCHASE_SELLER, 'warning');
        } else if (err.message === 'INVALID_EMAIL_ADDRESS') {
          addAlert(INVALID_EMAIL_ADDRESS, 'warning');
        } else if (err.message === 'WALLET_ADDRESS_CANNOT_BE_CHANGED') {
          addAlert(WALLET_ADDRESS_CANNOT_BE_CHANGED, 'warning');
        } else {
          addAlert(
            'ユーザー情報の更新に失敗しました。ユーザーネームまたはメールアドレスがすでに使われている可能性があります。',
            'error',
          );
        }
      });
  };

  useEffect(() => {
    getCurrentUser().then((res) => setCurrentUser(res));
  }, []);

  if (!authContext.authData.token) return <NotFound />;

  return (
    <SettignContainer>
      <CropperPopUp
        width={400}
        height={400}
        defaultImg={`${API_URL}/users/${authContext.authData.userId}/icon`}
        isDisplayed={isHeaderCropperDisplayed}
        setIsDisplayed={setIsHeaderCropperDisplayed}
        fileType={'header'}
        setImageString={setHeaderUrl}
      />
      <CropperPopUp
        width={400}
        height={400}
        defaultImg={`${API_URL}/users/${authContext.authData.userId}/icon`}
        isDisplayed={isPFPCropperDisplayed}
        setIsDisplayed={setIsPFPCropperDisplayed}
        fileType={'icon'}
        setImageString={setPfpUrl}
      />
      <TopPartContainer>
        <MyPageHeaderContainer>
          {!headerUrl ? (
            <>
              <HeaderOverlay onClick={() => setIsHeaderCropperDisplayed(true)}>
                <BsCardImage size="5rem" color={Color.WHITETEXT} />
              </HeaderOverlay>
              <MyPageHeaderImg
                src={`${API_URL}/users/${authContext.authData.userId}/header`}
              />
            </>
          ) : (
            <>
              <HeaderOverlay onClick={() => setIsHeaderCropperDisplayed(true)}>
                <BsCardImage size="5rem" color={Color.WHITETEXT} />
              </HeaderOverlay>
              <MyPageHeaderImg src={headerUrl} />
            </>
          )}
        </MyPageHeaderContainer>
        <MyPagePFPBase>
          <MyPagePFPContainer>
            {!pfpUrl ? (
              <>
                <PFPoverlay onClick={() => setIsPFPCropperDisplayed(true)}>
                  <BsCardImage size="5rem" color={Color.WHITETEXT} />
                </PFPoverlay>
                <MyPagePFP
                  src={`${API_URL}/users/${authContext.authData.userId}/icon`}
                />
              </>
            ) : (
              <>
                <PFPoverlay onClick={() => setIsPFPCropperDisplayed(true)}>
                  <BsCardImage size="5rem" color={Color.WHITETEXT} />
                </PFPoverlay>
                <MyPagePFP src={pfpUrl} />
              </>
            )}
          </MyPagePFPContainer>
        </MyPagePFPBase>
      </TopPartContainer>
      <MiddlePartContainer>
        <Title title="設定" subTitle="Setting" />
        <div style={{ height: '20px', width: '1px' }} />
        <StyledWrapper>
          <StyledContainer>
            <StyledSmallContainer>
              <Text fontType={FontType.TITLE3}>ユーザーネーム</Text>
            </StyledSmallContainer>
          </StyledContainer>
          <StyledContainer>
            <StyledSmallContainer>
              <TextInput
                defaultValue={currentUser?.username}
                placeholder={currentUser?.username}
                setText={setNewUsername}
              />
            </StyledSmallContainer>
          </StyledContainer>
        </StyledWrapper>
        <StyledWrapper>
          <StyledContainer>
            <StyledSmallContainer>
              <Text fontType={FontType.TITLE3}>表示名</Text>
            </StyledSmallContainer>
          </StyledContainer>
          <StyledContainer>
            <StyledSmallContainer>
              <TextInput
                defaultValue={currentUser?.displayname}
                placeholder={currentUser?.displayname}
                setText={setNewAccountId}
              />
            </StyledSmallContainer>
          </StyledContainer>
        </StyledWrapper>
        <StyledWrapper>
          <StyledContainer>
            <StyledSmallContainer>
              <Text fontType={FontType.TITLE3}>メールアドレス</Text>
            </StyledSmallContainer>
          </StyledContainer>
          <StyledContainer>
            <StyledSmallContainer>
              <TextInput
                defaultValue={currentUser?.email}
                placeholder={currentUser?.email}
                setText={setNewMailAddress}
              />
            </StyledSmallContainer>
          </StyledContainer>
        </StyledWrapper>
        <StyledContainer>
          <StyledSmallContainer>
            <Text fontType={FontType.TITLE3}>自己紹介</Text>
          </StyledSmallContainer>
        </StyledContainer>
        <StyledTextAreaContainer>
          <TextArea
            defaultValue={currentUser?.normalBio}
            setText={setNewBio}
            placeholder={currentUser?.normalBio}
          />
        </StyledTextAreaContainer>
      </MiddlePartContainer>
      <ChangeButtonContainer>
        <SubContainer>
          <Button
            buttonType={ButtonType.ROUNDEDIMPORTANT}
            onClick={handleUpdate}
          >
            変更を保存する
          </Button>
        </SubContainer>
      </ChangeButtonContainer>
      <ChangeButtonContainer>
        <TextButton onClick={() => navigate('/ChangePassword')}>
          パスワードを変更する
        </TextButton>
      </ChangeButtonContainer>
    </SettignContainer>
  );
};
