import React, { useState } from 'react';
import { verifyEmail, resendVerifyEmail } from '../../../api/UsersService';

import {
  MailCertificationWrapper,
  TextContainer,
  TextDetail,
  StyledButtonContainer,
  StyledButton,
  // StyledButtonDisable,
  // StyledResendButton,
  // StyledTextContainer,
  StyledButtonWh,
  StyledResendButton,
} from './style';
import { Title } from '../../organisms/Title';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { Button } from '../../atoms/Button';

import { useAlertContext } from '../../../Providers/AlertProvider';
import { AuthContextType, AuthContext } from '../../../Providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

import { useUser } from './hooks';
import { ButtonType } from '../../../constants/Button';

export const MailCertification = () => {
  const { addAlert } = useAlertContext();
  const authContext: AuthContextType = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [alreadyVerified, setAlreadyVerified] = useState<boolean>(false);

  const userId = authContext ? authContext.authData.userId : '0';
  const { userData } = useUser(userId);
  const email = userData?.email;

  const handleClick = async () => {
    const url = new URL(window.location.href);
    const hash = url.pathname.split('/').pop();

    try {
      const data = await verifyEmail(hash);
      console.log(data);
      if (data.code === 200) {
        setIsSuccess(true);
        addAlert('メールアドレスの認証が完了しました。', 'success');
      } else if (data.code == 422) {
        setIsSuccess(true);
        setAlreadyVerified(true);
        addAlert('認証済みのメールアドレスです。', 'warning');
      } else {
        addAlert(data.message, 'error');
      }
    } catch (e) {
      console.log(e);
      setIsSuccess(false);
    }
  };

  const handleResend = async () => {
    try {
      const data = await resendVerifyEmail(email);
      if (data.code === 200) {
        addAlert('メールアドレスの認証メールを再送しました。', 'success');
      } else {
        addAlert(
          '一度ログインし、マイページから認証メールを再送してください。',
          'error',
        );
        addAlert(data.mesasge, 'error');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleResetting = () => {
    if (userId === undefined) {
      addAlert(
        '一度ログインしてからマイページの設定にアクセスしてください。',
        'error',
      );
      setIsSuccess(false);
      return;
    } else {
      navigate(`/userpage/${userId}/settings`);
    }
  };

  return (
    <MailCertificationWrapper>
      <Title title="メールアドレス認証" subTitle="Email Certification" />
      {!isSuccess ? (
        <StyledButtonContainer>
          <TextContainer>
            <TextDetail>
              以下のボタンを押して、メールアドレスの認証を完了してください。
            </TextDetail>
          </TextContainer>
          <StyledButton onClick={handleClick}>
            <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
              メールアドレスを認証する
            </Text>
          </StyledButton>
        </StyledButtonContainer>
      ) : (
        <StyledButtonContainer>
          <TextContainer>
            {alreadyVerified && (
              <TextDetail>すでに認証済みのメールアドレスです。</TextDetail>
            )}
            {!alreadyVerified && isSuccess && (
              <TextDetail>メールアドレスの認証を完了しました。</TextDetail>
            )}
          </TextContainer>
          <StyledButton onClick={() => navigate('/')}>
            <Text fontType={FontType.TITLE3} color={Color.WHITETEXT}>
              トップページへ
            </Text>
          </StyledButton>
          {userId != undefined && userId != '0' && (
            <StyledButtonWh onClick={() => navigate(`/userpage/${userId}`)}>
              <Text fontType={FontType.TITLE3} color={Color.BRANDMAIN}>
                マイページへ
              </Text>
            </StyledButtonWh>
          )}
        </StyledButtonContainer>
      )}
      {!isSuccess && (
        <>
          <StyledResendButton onClick={handleResend}>
            <Text fontType={FontType.TITLE3} color={Color.BRANDMAIN}>
              認証メールを再送する
            </Text>
          </StyledResendButton>
          <StyledButtonContainer>
            <Button
              buttonType={ButtonType.WIDEROUNDED_OUTLINED}
              onClick={handleResetting}
            >
              <Text fontType={FontType.TITLE3}>メールアドレスを再設定する</Text>
            </Button>
          </StyledButtonContainer>
        </>
      )}
    </MailCertificationWrapper>
  );
};
