import React, { useEffect, useState } from 'react';
import { AwardArtItemType } from '../organisms/AwardItemShowCase/awardItemsList';

import { AuthContextType, useAuthProvider } from '../../Providers/AuthProvider';
import {
  StyledArtistInfo,
  StyledArtistName,
  StyledArtistPFP,
  StyledAwardCategoryContainer,
  StyledAwardItemContainer,
  StyledAwardText,
  StyledAwardTextContainer,
  StyledAwardTextWrapper,
  StyledImageContainer,
  StyledItemBottom,
  StyledLike,
  StyledNFTText,
  StyledPrice,
  StyledPriceText,
  StyledText,
  StyledTitle,
  TextContainer,
} from './style';
import { NowrapText } from '../atoms/NowrapText';
import { Text } from '../atoms/Text';
import { FontType } from '../../constants/Fonts';
import { Color } from '../../constants/Color';

import { useAlertContext } from '../../Providers/AlertProvider';
import { addLikeByUser, removeLikeByUser } from '../../api/ArtsService';
import { useNavigate } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import { FaAward } from 'react-icons/fa';
import { RiAwardFill, RiAwardLine } from 'react-icons/ri';
import { TbMilitaryAward } from 'react-icons/tb';
import { BsFlower2 } from 'react-icons/bs';
import { GiNewspaper } from 'react-icons/gi';
import { priceRenderer } from '../../lib/priceRenderer';

interface AwardArtItemCardProps {
  awardArt: AwardArtItemType;
  kind: string;
}

export const AwardArtItemCard: React.FC<AwardArtItemCardProps> = ({
  awardArt,
  kind,
}) => {
  const { addAlert } = useAlertContext();
  const [isLiked, setIsLiked] = useState(awardArt?.artData?.isLiked as boolean);
  const [currentLikes, setCurrentLikes] = useState<number>(0);
  const authContext: AuthContextType = useAuthProvider();
  const navigate = useNavigate();

  useEffect(() => {
    if (awardArt != undefined) {
      setCurrentLikes(awardArt?.artData?.like as number);
    }
  }, [awardArt.artData]);

  useEffect(() => {
    if (awardArt?.artData != undefined) {
      setIsLiked(awardArt?.artData?.isLiked as boolean);
    }
  }, [awardArt]);

  const handleAddLike = () => {
    if (authContext.authData.token) {
      addLikeByUser(
        authContext.authData.userId as string,
        awardArt.artData?.id as string,
        authContext.authData.token as string,
      );
      setCurrentLikes(currentLikes + 1);
      setIsLiked(true);
    } else {
      addAlert(
        'ログインまたはアカウント作成した上で、もう一度お試し下さい',
        'warning',
      );
    }
  };
  const handleRemoveLike = () => {
    if (currentLikes <= 0) return; //likesが0以下の場合はキャンセル
    if (authContext.authData.token) {
      removeLikeByUser(
        authContext.authData.userId as string,
        awardArt.artData?.id as string,
        authContext.authData.token as string,
      );
      setCurrentLikes(currentLikes - 1);
      setIsLiked(false);
    }
  };

  const nftItem = () => {
    if (awardArt?.artData?.isNFT && !awardArt?.artData?.isDisplay) {
      return (
        <StyledPrice>
          <StyledNFTText>NFT</StyledNFTText>
        </StyledPrice>
      );
    }
  };

  const isNFTAndDisplay =
    awardArt?.artData?.isNFT && awardArt?.artData?.isDisplay;
  const isPurchasingByUser =
    awardArt?.artData?.artPurchase?.status == 'purchasing' &&
    authContext.authData.userId == awardArt?.artData?.artPurchase?.buyerId;
  const notPurchasing = awardArt?.artData?.artPurchase?.status !== 'purchasing';

  const artValue =
    awardArt?.artData?.value == void 0 ? 0 : awardArt?.artData?.value;

  const price = () => {
    if (
      !awardArt?.artData?.value ||
      !(awardArt?.artData.isNFT && awardArt?.artData.isDisplay)
    )
      return;
    return (
      <StyledPrice>
        <StyledNFTText>NFT</StyledNFTText>
        {authContext.authData.role == 'admin' && (
          <StyledPriceText>{priceRenderer(artValue)}</StyledPriceText>
        )}
      </StyledPrice>
    );
  };

  const like = () => {
    return (
      <StyledLike>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={isLiked ? handleRemoveLike : handleAddLike}
          edge="end"
        >
          {isLiked ? (
            <MdOutlineFavorite size="20px" color={Color.BRANDMAIN} />
          ) : (
            <MdOutlineFavoriteBorder size="20px" color={Color.BRANDMAIN} />
          )}
        </IconButton>
        <TextContainer>
          <Text fontType={FontType.SUB} color={Color.BRANDMAIN}>
            {currentLikes}
          </Text>
        </TextContainer>
      </StyledLike>
    );
  };

  return (
    <StyledAwardItemContainer color={awardArt.color}>
      <StyledAwardTextWrapper>
        <StyledAwardCategoryContainer>
          {awardArt?.awardCategory === '総合最優秀賞' && (
            <FaAward size="24px" color={Color.MAIN} />
          )}
          {awardArt?.awardCategory === '大賞' && (
            <RiAwardFill size="24px" color={Color.MAIN} />
          )}
          {awardArt?.awardCategory === '審査員賞' && (
            <RiAwardLine size="24px" color={Color.MAIN} />
          )}
          {awardArt?.awardCategory === '入賞' && (
            <TbMilitaryAward size="24px" color={Color.MAIN} />
          )}
          {awardArt?.awardCategory === 'イコラブ大場花菜賞' && (
            <BsFlower2 size="24px" color={Color.MAIN} />
          )}
          {awardArt?.awardCategory === '読売中高生新聞賞' && (
            <GiNewspaper size="24px" color={Color.MAIN} />
          )}
          <StyledAwardText>{awardArt?.awardCategory}</StyledAwardText>
        </StyledAwardCategoryContainer>
        <StyledAwardTextContainer color={awardArt?.color}>
          <StyledText>
            {awardArt?.department ? awardArt.department : ''}
          </StyledText>
          <StyledText>{awardArt?.tag}</StyledText>
        </StyledAwardTextContainer>
      </StyledAwardTextWrapper>

      <StyledImageContainer
        url={awardArt.artData?.entityUrl}
        onClick={() =>
          navigate(`/items/${awardArt?.artId}`, {
            state: {
              kind: kind,
            },
          })
        }
      >
        {isNFTAndDisplay && (isPurchasingByUser || notPurchasing) ? (
          <>{price()}</>
        ) : (
          <></>
        )}
        {nftItem()}
      </StyledImageContainer>
      <StyledTitle>
        <Text fontType={FontType.TITLE4}>
          {awardArt.artData && awardArt.artData.name.length > 10
            ? awardArt?.artData?.name.slice(0, 10) + '...'
            : awardArt?.artData?.name}
        </Text>
      </StyledTitle>
      <StyledItemBottom>
        <StyledArtistInfo>
          {awardArt?.artData?.ownerInfo.profileImage ? (
            <StyledArtistPFP src={awardArt?.artData?.ownerInfo.profileImage} />
          ) : (
            <StyledArtistPFP
              src={`${process.env.PUBLIC_URL}/default-icon.png`}
            />
          )}
          <StyledArtistName>
            <NowrapText fontType={FontType.TITLE5} color={Color.BRAND30}>
              {awardArt?.artData?.ownerInfo.displayname
                ? awardArt?.artData?.ownerInfo.displayname.slice(0, 10) + '...'
                : 'deleted user'}
            </NowrapText>
          </StyledArtistName>
        </StyledArtistInfo>
        {like()}
      </StyledItemBottom>
    </StyledAwardItemContainer>
  );
};
