import styled from '@emotion/styled';
import tw from 'twin.macro';

export const StyledShowCaseContainer = styled.div`
  ${tw`flex-grow mb-8 after:content-[''] after:block mx-2 md:mx-0`};
  ${tw`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5`}
`;

export const StyledPortfolioContainer = styled.div`
  ${tw`flex-grow mb-16 `};
  ${tw`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3`}
`;
