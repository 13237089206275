export interface QAProps {
  type: 'text' | 'image';
  question?: string;
  answer?: string;
  url?: string;
  link?: string;
  linkText?: string;
}

export const QAs1: QAProps[] = [
  {
    type: 'text',
    question: 'Q. NFTの作品の出品方法を教えてください。',
    answer: 'A. ハックツのマイページから作品を登録することができます。',
  },
  {
    type: 'text',
    question: 'Q. 最低出品額を教えてください。',
    answer: 'A. 350円から出品することができます。',
  },
  {
    type: 'text',
    question: 'Q. 作品名や作品説明を変更したいです。',
    answer:
      'A. 作品の詳細ページから、「作品を編集する」ボタンを押すと作品の編集をすることができます。',
  },
  {
    type: 'text',
    question: 'Q. NFTの出品を取り消したいです。',
    answer:
      'A. 「作品を編集する」ボタンを押し、作品編集ページに進みます。「出品有無」という項目をオフにすることでNFTの出品を取り消すことができます。',
  },
];

export const QAs2: QAProps[] = [
  {
    type: 'text',
    question: 'Q. NFTの購入時にどのような支払い方法が使えますか？',
    answer:
      'A. 現在はクレジットカードのみの対応となっております。GMO-PGマルチペイメントサービスを利用した決済となります。',
  },
  {
    type: 'text',
    question: 'Q. 購入したアイテムの支払いをキャンセル・返品できますか？',
    answer:
      'A. サービスの特性上、お支払いのキャンセル及び返品はできません。詳細はサービス利用規約をご覧ください。',
    link: 'TermsOfUse',
    linkText: 'サービス利用規約',
  },
];

export const QAs3: QAProps[] = [
  {
    type: 'text',
    question: 'Q. パスワードを変更したいです。',
    answer:
      'A. ログイン画面から「パスワードをお忘れの方」を押します。入力されたメールアドレス宛にパスワードを再設定するリンクが送信されます。パスワード再設定リンクからパスワードを再設定してください。',
    link: 'SignIn',
    linkText: 'ログイン画面',
  },
  {
    type: 'text',
    question: 'Q. 法人名義でのアカウント登録は可能ですか？',
    answer: 'A. 本サービスでは個人と法人での区別は行っておりません。',
  },
  {
    type: 'text',
    question: 'Q. アカウントは複数作れますか？',
    answer:
      'A. はい。可能です。ただし、同じメールアドレスや同じユーザーネームでは登録できません。',
  },
  {
    type: 'text',
    question: 'Q. 各種登録情報を変更したいです。',
    answer: 'A. マイページから「設定」へ進み、各種登録情報を変更してください。',
  },
  {
    type: 'text',
    question:
      'Q. Yoani NFT MPで作成したNFT作品を他のNFTマーケットプレイスに出品することはできますか？',
    answer:
      'A. 出庫機能を用いて外部のウォレットに持ち出すことで出品することができます。',
  },
];

export const QAs4: QAProps[] = [
  {
    type: 'text',
    question: 'Q. クリエイターアカウントで発生した売上はどこで確認できますか',
    answer:
      'A. マイページの「購入・販売履歴」または「販売履歴」から確認可能です。',
  },
  {
    type: 'text',
    question: 'Q. クリエイターアカウントの売上金振込先を変更したいです。',
    answer: 'A. 振込先はGMO送金サービスのページにて設定できます。',
  },
];
