import React from 'react';
import { Text } from '../Text';
import { TagNameContainer, UserProfileImage, UserTagContainer } from './style';
import { FontType } from '../../../constants/Fonts';

interface UserTagProps {
  userTagImageUri: string | undefined;
  userName: string | undefined;
  onclick?: void | (() => void);
}

export const UserTag: React.FC<UserTagProps> = ({
  userTagImageUri,
  userName,
  onclick,
}) => {
  if (!userTagImageUri || !userName)
    return (
      <UserTagContainer>
        <UserProfileImage src={`${process.env.PUBLIC_URL}/default-icon.png`} />
        <TagNameContainer>
          <Text fontType={FontType.TAG}>deleted user</Text>
        </TagNameContainer>
      </UserTagContainer>
    );
  if (onclick != undefined) {
    return (
      <UserTagContainer onClick={onclick}>
        <UserProfileImage src={userTagImageUri} />
        <TagNameContainer>
          {userName.length > 8 ? (
            <Text fontType={FontType.TAG}>{userName.slice(0, 12) + '...'}</Text>
          ) : (
            <Text fontType={FontType.TAG}>{userName}</Text>
          )}
        </TagNameContainer>
      </UserTagContainer>
    );
  } else {
    return (
      <UserTagContainer>
        <UserProfileImage src={userTagImageUri} />
        <TagNameContainer>
          <Text fontType={FontType.TAG}>{userName}</Text>
        </TagNameContainer>
      </UserTagContainer>
    );
  }
};
