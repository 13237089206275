import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { PAYPAL_CLIENT_ID } from '../../../config';
import { Art } from '../../../types/domain/Art';
import {
  confirmUserPayment,
  failedPurchaseOrder,
  saveOrderId,
} from '../../../api/PurchaseService';
import {
  AuthContextType,
  useAuthProvider,
} from '../../../Providers/AuthProvider';

type Props = {
  artDetail: Art;
  amount: number;
  onPayment: (order: string) => void;
  onClick: () => void;
  setIsDisplayed: (status: boolean) => void;
  setIsLoading: (status: boolean) => void;
  setInvoiceId: (id: string) => void;
  invoiceId: string;
};
export const PaymentButton: FC<Props> = ({
  artDetail,
  amount,
  onPayment,
  onClick,
  invoiceId,
  setIsDisplayed,
  setIsLoading,
  setInvoiceId,
}) => {
  const { addAlert } = useAlertContext();
  const authContext: AuthContextType = useAuthProvider();
  const [success, setSuccess] = useState(false);
  const initialOptions = {
    'client-id': PAYPAL_CLIENT_ID,
    currency: 'JPY',
  };

  const beforeUnloadhandler = (event: any) => {
    event.preventDefault();
    event.returnValue = '購入手続きが中断されます、本当によろしいですか？';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadhandler);
    return () =>
      window.removeEventListener('beforeunload', beforeUnloadhandler);
  }, []);

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        onClick={onClick}
        disabled={success}
        style={{
          shape: 'pill',
          layout: 'vertical',
          label: 'buynow',
          color: 'silver',
        }}
        fundingSource="paypal"
        onApprove={async (data, actions) => {
          if (actions.order === undefined) return new Promise(() => {});
          const result = await confirmUserPayment(
            parseInt(authContext.authData.userId as string),
            parseInt(artDetail.id as string),
            invoiceId,
            data.orderID,
            authContext.authData.token as string,
          );
          if (result.ok) {
            //PayPal内での決済完了の処理実行
            return actions?.order?.capture().then(async function () {
              setSuccess(true);
              setIsDisplayed(false);
              addAlert(
                'お支払いが正常に受理されました。作品の移転(Transfer)完了までもうしばらくお待ち下さい',
                'success',
              );
              //決済完了のステータスをDBに登録しPayoutおよびTransferを実行
              onPayment(data.orderID);
            });
          } else {
            throw new Error('This art cannot be purchased');
          }
        }}
        onError={(err) => {
          failedPurchaseOrder(
            parseInt(artDetail.id),
            parseInt(authContext.authData.userId as string),
            parseInt(artDetail.ownerInfo.userId),
            authContext.authData.token as string,
          );
          addAlert(
            'お支払いに失敗しました。時間を空けてもう一度お試し下さい',
            'error',
          );
          console.log('onError', err);
          setIsDisplayed(false);
          setIsLoading(true);
          setInvoiceId('');
        }}
        onCancel={(data) => {
          addAlert('購入手続がキャンセルされました', 'warning');
          console.log('cancel', data);
          setIsDisplayed(false);
          setIsLoading(true);
          setInvoiceId('');
          failedPurchaseOrder(
            parseInt(artDetail.id),
            parseInt(authContext.authData.userId as string),
            parseInt(artDetail.ownerInfo.userId),
            authContext.authData.token as string,
          );
        }}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  invoice_id: invoiceId,
                  // invoice_id: 'b952e722-f7e2-4f50-bbe2-e9f892f49984',
                  amount: {
                    currency_code: 'JPY',
                    value: amount.toString(),
                  },
                },
              ],
            })
            .then((orderId) => {
              saveOrderId(
                invoiceId,
                parseInt(authContext.authData.userId as string),
                parseInt(artDetail.ownerInfo.userId as string),
                parseInt(artDetail.id as string),
                parseInt(amount.toString()),
                orderId as string,
                authContext.authData.token as string,
              ).then((res) => {
                if (!res.ok) {
                  addAlert(
                    '購入手続きに失敗しました。時間をあけてもう一度お試し下さい',
                    'error',
                  );
                  setIsDisplayed(false);
                  throw new Error('Order procedure is failed');
                }
              });
              return orderId;
            });
        }}
      />
    </PayPalScriptProvider>
  );
};
