import styled from '@emotion/styled';
import tw from 'twin.macro';

export const FixedHeader = styled.div`
  ${tw` fixed w-[100vw] z-50 bg-[#1a1a1a]`}
`;

export const HeaderContainer = styled.div`
  ${tw`max-w-[1440px] flex items-center w-[100%] mx-auto`}
`;

export const StyledHeader = styled.div`
  ${tw`flex items-center py-[10px] px-2 sm:px-4`}
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
`;

export const StyledHeaderLogo = styled.img`
  ${tw`h-9 sm:h-14 cursor-pointer`}
`;

export const MobileHeaderContainer = styled.div`
  ${tw`flex flex-grow items-center justify-between`}
`;

// export const MushimeganeContainer = styled.div`
//   ${tw`cursor-pointer`}
// `;

export const StyledHeaderPFP = styled.img`
  ${tw`h-10 w-10 rounded-full`}
  object-fit: cover;
`;

export const ProfileButtonContainer = styled.div`
  ${tw`flex flex-row items-center hover:cursor-pointer hover:opacity-70 mx-2`}
`;
