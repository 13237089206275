import styled from '@emotion/styled';
import tw from 'twin.macro';

export const HomeContainer = styled.div`
  ${tw`flex flex-col max-w-[1440px] mx-auto px-2 md:px-10`}
`;

export const UsersContainer = styled.div`
  ${tw`flex flex-row justify-start overflow-x-auto mb-8 md:mb-20 py-4`}
`;

export const UserContainer = styled.div`
  ${tw`w-[120px] h-[140px] md:w-[200px] md:h-[240px] rounded-lg relative overflow-hidden bg-[#fff] mr-8 flex-shrink-0 cursor-pointer`}
  filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -moz-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
`;

export const BackgroundImage = styled.img`
  ${tw`w-[100%] h-[75%] object-cover hover:scale-110 transition-all duration-300`}
`;

export const UserIcon = styled.img`
  ${tw`w-[50px] md:w-[80px] rounded-full absolute z-10`}
  transform: translate(-50%, -50%);
  top: 65%;
  left: 50%;
`;

export const TextBox = styled.div`
  ${tw`h-[25%] flex justify-center items-center`}
`;
export const UserDisplayName = styled.div`
  ${tw`text-[18px] font-bold`}
`;
