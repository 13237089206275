import styled from '@emotion/styled';
import tw from 'twin.macro';

interface BorderCorlorProps {
  borderColor: string;
}

// ManualPageTitel
export const ManualPageTitleWrapper = styled.div`
  ${tw`mt-16 mb-4 w-full`}
  border-left: 4px solid #E95098;
`;

export const ManualPageTitleText = styled.h2`
  ${tw`text-2xl md:text-4xl font-bold ml-2`}
`;

// Character
export const CharacterContainer = styled.div`
  ${tw`flex flex-col items-center justify-center mt-auto`}
`;

export const CharacterImage = styled.img<BorderCorlorProps>`
  ${tw`w-[48px] h-[48px] mx-1 md:w-[60px] md:h-[60px] rounded-full object-contain`}
  border: 3px solid ${(props) => props.borderColor};
`;

export const CharacterName = styled.p`
  ${tw`text-xs font-bold`}
`;

// Dialog
export const DialogWrapper = styled.div`
  ${tw`flex items-end w-full mb-8`}
`;

export const DialogContainer = styled.div<BorderCorlorProps>`
  ${tw`w-full ml-2 md:ml-4 flex items-center justify-center rounded-r-3xl rounded-tl-3xl  md:rounded-r-full md:rounded-tl-full px-8 mb-8`}
  border: 3px solid ${(props) => props.borderColor};
`;

export const DialogText = styled.p`
  ${tw`text-sm md:text-lg font-bold`}
`;

// Image
export const ImageWrapper = styled.div`
  ${tw`w-full md:w-[80%] mx-auto my-8 flex justify-center items-center`}
`;

export const ImageContainer = styled.img`
  ${tw`w-full`}
`;

export const ImageSpContainer = styled.img`
  ${tw`w-[60%] mx-auto`}
`;

// Whole
export const NftManualPageWrapper = styled.div`
  ${tw`w-[90%] md:w-full max-w-[1000px] mx-auto px-4 md:px-20 md:mt-12 pb-12 bg-white bg-opacity-80 rounded-2xl overflow-hidden`}
`;
