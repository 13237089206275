import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {
  WholeWrapper,
  UserIcon,
  UserName,
  LikedUsersWrapper,
  LikedUserContainer,
  LoadingButtonContainer,
  LoadingButton,
  TotalNumContainer,
} from './style';
import { useLikedByUsersList } from './hooks';

import { Title } from '../../organisms/Title';
import {
  PictureContainer,
  StyledItemImage,
} from '../../organisms/ArtDetail/style';

import { API_URL } from '../../../config';

import { User } from '../../../types/domain/User';

const LikedByUserPage = () => {
  const navigate = useNavigate();
  const locate = useLocation();
  const artId = location.pathname.split('/')[2];
  const artSrc =
    locate.state != null
      ? locate.state.artDetail.entityUrl
      : API_URL + `/arts/${artId}/entity`;
  const { likedByUsersList } = useLikedByUsersList(artId);
  return (
    <WholeWrapper>
      <PictureContainer>
        <Box>
          <IconButton
            onClick={() => navigate(-1)}
            color="secondary"
            aria-label="back"
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
        <StyledItemImage src={artSrc} />
      </PictureContainer>
      <Title title="いいねしたユーザー" subTitle="Liked by" />
      {likedByUsersList.total ? (
        <TotalNumContainer>{likedByUsersList.total}件</TotalNumContainer>
      ) : (
        <></>
      )}
      <LikedUsersWrapper>
        {likedByUsersList.usersData.map((user: User, i: number) => (
          <LikedUserContainer
            key={i}
            onClick={() => navigate(`/userpage/${user.userId}`)}
          >
            <UserIcon src={API_URL + `/users/${user.userId}/icon`} />
            <UserName>{user.username}</UserName>
          </LikedUserContainer>
        ))}
        {!likedByUsersList.isLast && (
          <LoadingButtonContainer onClick={() => likedByUsersList.loadMore()}>
            <LoadingButton>もっと見る</LoadingButton>
          </LoadingButtonContainer>
        )}
      </LikedUsersWrapper>
    </WholeWrapper>
  );
};

export default LikedByUserPage;
