import React from 'react';
import { useSearch } from './hooks';
import { ItemShowCase } from '../../organisms/ItemShowCase';
import {
  HomeContainer,
  UsersContainer,
  BackgroundImage,
  UserContainer,
  UserIcon,
  TextBox,
  UserDisplayName,
} from './style';
import { useLocation } from 'react-router-dom';
import { User } from '../../../types/domain/User';
import { Title } from '../../organisms/Title';
import { TotalNumContainer } from '../../organisms/ItemShowCase/style';
import { useNavigate } from 'react-router-dom';

export const SearchPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { searchedItemsData, tags, searchedUsers } = useSearch(
    decodeURI(location.search.replace('?', '')),
  );
  let kind = 'search';
  const str = '?tag=';
  const isFound = location.search.includes(str);
  if (isFound) {
    kind = 'tag';
  } else {
    kind = 'search';
  }
  const query = location.search.replace('?q=', '').replace('?tag=', '');
  const decodedQuery = decodeURI(query.replace(/\+/g, ' '));

  return (
    <HomeContainer>
      {searchedUsers.total != 0 ? (
        <>
          <Title title="ユーザー検索結果" />
          {searchedUsers.total ? (
            <TotalNumContainer>{searchedUsers.total}件</TotalNumContainer>
          ) : (
            <></>
          )}
          <UsersContainer>
            {searchedUsers.usersData.map((user: User, i) => (
              <UserContainer
                key={i}
                onClick={() => navigate(`/userpage/${user.userId}`)}
              >
                <BackgroundImage src={user?.headerImage} />
                <UserIcon src={user?.profileImage} />
                <TextBox>
                  <UserDisplayName>{user?.displayname}</UserDisplayName>
                </TextBox>
              </UserContainer>
            ))}
            <div ref={searchedUsers.ref} />
          </UsersContainer>
        </>
      ) : (
        <></>
      )}
      <ItemShowCase
        title="作品検索結果"
        subTitle={`${decodeURI(
          location.search
            .replace('?q=', '')
            .replace('?tag=', '')
            .replace(/\+/g, ' '),
        )}`}
        items={searchedItemsData.artsData}
        buttonAction={searchedItemsData.loadMore}
        tags={tags}
        type="top"
        total={searchedItemsData.total}
        isLast={searchedItemsData.isLast}
        kind={kind}
        query={decodedQuery}
      />
    </HomeContainer>
  );
};
