import styled from '@emotion/styled';
import tw from 'twin.macro';

export const ContestReviewWrapper = styled.div`
  ${tw`my-12 flex flex-col gap-[40px] w-[80%] max-w-[1440px] mx-auto`}
`;

export const TitleWrapper = styled.div`
  ${tw`flex flex-row items-start justify-start w-full `}
`;

export const SubTitleWrapper = styled.div`
  ${tw`flex flex-row items-center justify-between w-full `}
`;

export const ButtonItemWrapper = styled.div`
  ${tw`flex flex-row justify-center items-center gap-[12px] sm:gap-[32px] mx-[0] sm:mx-[32px]`}
`;

export const ButtonImage = styled.img`
  ${tw`w-[24px] sm:w-[48px] h-[24px] sm:h-[48px] object-cover`}
`;
