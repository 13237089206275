import styled from '@emotion/styled';
import tw from 'twin.macro';

export const WholeWrapper = styled.div`
  ${tw`flex flex-col max-w-[1440px] mx-auto w-[95%]`}
`;

export const LikedListWrapper = styled.div`
  ${tw`mt-8 md:mt-12 w-[96%] mx-auto`}
`;

export const ItemsContainer = styled.div`
  ${tw`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 justify-center mb-12 w-full gap-2`}
`;

export const ItemContainer = styled.div`
  ${tw`bg-white rounded-md mb-4 h-[240px] md:h-[340px]`}
  filter: drop-shodow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -moz-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  -ms-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
`;

export const ImageContainer = styled.div`
  ${tw`h-[80%] w-full overflow-hidden relative`}
`;
export const ArtImage = styled.img`
  ${tw`flex justify-center object-cover w-full h-full hover:scale-110 hover:opacity-80 transition-all duration-300 ease-in-out cursor-pointer`}
`;

export const LikeWrapper = styled.div`
  ${tw`flex justify-center items-center absolute bottom-0 right-0 mr-2 mb-2 bg-white rounded-full py-1 px-3 md:py-2 md:px-4`}
`;
export const LikeContainer = styled.div`
  ${tw`flex items-center text-[14px] md:text-[16px] font-bold text-[#E95098] gap-1`}
`;

export const ArtInfo = styled.div`
  ${tw`flex justify-between items-center px-2 md:px-4 h-[20%]`}
`;

export const ArtTitle = styled.div`
  ${tw`font-bold text-[14px] md:text-[20px]`}
`;

export const ArtOwnerIcon = styled.img`
  ${tw`w-[20px] h-[20px] md:h-[30px] md:w-[30px] rounded-full block hover:scale-110 transition-all duration-300 ease-in-out cursor-pointer`}
`;

export const LoadingButtonContainer = styled.div`
  ${tw`flex justify-center mx-auto mt-2 mb-16 px-12 py-4 bg-[#E95098] rounded-full cursor-pointer`}
`;

export const LoadingButton = styled.div`
  ${tw`font-bold text-white `};
`;

export const TotalNumContainer = styled.div`
  ${tw`bg-[#fff] px-4 py-2 rounded-full w-[60px] text-[14px] md:text-[16px] text-center font-bold mt-2 md:mt-0`};
  filter: drop-shodow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -webkit-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -moz-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
  -ms-filter: drop-shadow(0px 2px 2px rgba(233, 80, 152, 0.3));
`;
