import { useContext } from 'react';
import { getAllArts, getLikedArtsList } from '../../../api/ArtsService';
import { Art } from '../../../types/domain/Art';
import { AuthContext, AuthContextType } from '../../../Providers/AuthProvider';

interface ArtsDataResponse {
  artsData: Art[];
  error: any;
  reload: () => Promise<any>;
  isLast?: boolean;
  loadMore?: any;
}

export const useArtsList = (
  kind: string,
  artId?: string,
  selectedTag?: string,
  creatorId?: string,
  ownerId?: string,
  userId?: string,
  query?: string,
) => {
  let artsDataResponse: ArtsDataResponse | undefined = undefined;
  const authContext: AuthContextType = useContext(AuthContext);
  switch (kind) {
    case 'contest':
      artsDataResponse = getAllArts(
        'tag=490,491,492,493,494,495,549,516,508,513,496,505' + '&newer=true',
      );
      break;
    case 'new':
      artsDataResponse = getAllArts('newer=true');
      break;
    case 'recommend':
      artsDataResponse = getAllArts('recommend=true');
      break;
    case 'search':
      artsDataResponse = getAllArts(`q=${query}`);
      break;
    case 'tag':
      artsDataResponse = getAllArts(`tag=${selectedTag}`);
      break;
    case 'create':
      artsDataResponse = getAllArts(`createdBy=${creatorId}`);
      break;
    case 'own':
      artsDataResponse = getAllArts(`ownedBy=${ownerId}`);
      break;
    case 'portfolio':
      artsDataResponse = getAllArts(`createdBy=${creatorId}&isPortfolio=true`);
      break;
    case 'nft':
      artsDataResponse = getAllArts(`isNFT=true&newer=true`);
      break;
    case 'display':
      artsDataResponse = getAllArts(`isNft=true&isDisplay=true&newer=true`);
      break;
    case 'myLiked':
      const likedArtsDataResponse = getLikedArtsList(
        userId,
        authContext.authData.token,
      );
      if (
        !likedArtsDataResponse.isLast &&
        likedArtsDataResponse.size * 5 <= likedArtsDataResponse.total
      ) {
        likedArtsDataResponse.loadMore();
      } else {
        artsDataResponse = likedArtsDataResponse;
      }
      break;
  }
  const userArtsDataId = artsDataResponse?.artsData.map((art) => art.id) || [];
  return userArtsDataId;
};
