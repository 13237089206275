import tw from 'twin.macro';
import styled from '@emotion/styled';

export const TermsOfUseWrapper = styled.div`
  ${tw`flex mt-2 mb-4 bg-white max-w-[100vw] w-[100%] md:px-8 mt-8 mx-auto max-w-[1440px]`}
`;

export const TermsOfUseContainer = styled.div`
  ${tw`flex flex-col mb-4 mx-auto w-[90%]`}
`;
