import styled from '@emotion/styled';
import tw from 'twin.macro';

export const StyledContainer = styled.div`
  ${tw`flex-grow`}
`;

export const StyledTextAreaContainer = styled.div`
  width: 50%;
  height: 250px;
`;

export const StyledSmallContainer = styled.div`
  ${tw`col-span-2 flex flex-wrap items-center justify-between p-2`}
`;

export const StyledTextInputContainer = styled.div`
  ${tw`col-span-2 flex flex-wrap items-center justify-between p-2`}
  width: 50%
`;

export const ChangeButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

export const SubContainer = styled.div`
  display: flex;
  width: 40%;
  height: 50px;
  justify-content: center;
  align-items: center;
`;
export const MyPageHeaderContainer = styled.div`
  ${tw`h-96 flex justify-center items-center pt-20 m-2 `}
  position: relative;
  cursor: pointer;
`;

export const MyPageHeaderImg = styled.img`
  ${tw`object-cover w-full rounded-2xl`}
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const HeaderInput = styled.input`
  cursor: pointer;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
`;

export const HeaderInputContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const TopPartContainer = styled.div`
  padding-top: 5vw;
  min-width: 50rem;
`;

export const FullOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  z-index: 65535;
`;

export const HeaderOverlay = styled.div`
  ${tw`flex justify-center items-center rounded-2xl`}
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;
