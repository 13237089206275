import React from 'react';
import {
  PrevNextButtonWrapper,
  PrevButton,
  NextButton,
  PrevButtonDisabled,
  NextButtonDisabled,
} from './style';
import { useArtsList } from './hooks';
import { useNavigate } from 'react-router-dom';

export const PrevNextButton = ({
  kind,
  artId,
  selectedTagId,
  creatorId,
  ownerId,
  resetLikeStatus,
  userId,
  query,
}: {
  kind: string;
  artId: string;
  selectedTagId: string;
  creatorId: string;
  ownerId: string;
  resetLikeStatus: () => void;
  userId: string;
  query: string;
}) => {
  let artsIdList: string[] = [];
  switch (kind) {
    case 'search':
      artsIdList =
        useArtsList(
          kind,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          query,
        ) || [];
      break;
    case 'tag':
      artsIdList = useArtsList(kind, undefined, selectedTagId) || [];
      break;
    case 'create':
      artsIdList =
        useArtsList(kind, undefined, undefined, creatorId, undefined) || [];
      break;
    case 'own':
      artsIdList =
        useArtsList(kind, undefined, undefined, undefined, ownerId) || [];
      break;
    case 'contest':
      artsIdList = useArtsList(kind) || [];
      break;
    case 'new':
      artsIdList = useArtsList(kind) || [];
      break;
    case 'recommend':
      artsIdList = useArtsList(kind) || [];
      break;
    case 'portfolio':
      artsIdList = useArtsList(kind, undefined, undefined, creatorId) || [];
      break;
    case 'myLiked':
      artsIdList =
        useArtsList(kind, undefined, undefined, undefined, undefined, userId) ||
        [];
      break;
    case 'nft':
      artsIdList = useArtsList(kind) || [];
      break;
    case 'display':
      artsIdList = useArtsList(kind) || [];
      break;
    case 'award':
      // artsIdList = [
      //   '2183',
      //   '2074',
      //   '1531',
      //   '2127',
      //   '1875',
      //   '2079',
      //   '2130',
      //   '2111',
      //   '2114',
      //   '2156',
      //   '2044',
      //   '2153',
      //   '2102',
      //   '1997',
      //   '2362',
      //   '2161',
      //   '2147',
      //   '2045',
      //   '1677',
      //   '1977',
      //   '2029',
      // ];
      artsIdList = [
        '54',
        '55',
        '56',
        '57',
        '58',
        '133',
        '134',
        '127',
        '65',
        '66',
        '67',
        '68',
        '69',
        '70',
        '71',
        '72',
        '73',
        '74',
        '75',
        '76',
        '85',
      ];
      break;
  }
  let tmpValue = artId;
  if (kind === 'award') {
    tmpValue = Number(artId).toString();
  }
  const tmpIndex = artsIdList.indexOf(tmpValue);
  const navigate = useNavigate();

  const handlePrevClick = () => {
    if (tmpIndex > 0 && tmpIndex < artsIdList.length) {
      const targetIndex = tmpIndex - 1;
      navigate(`/items/${artsIdList[targetIndex]}`, {
        state: { kind: kind, selectedTagId: selectedTagId, query: query },
      });
      resetLikeStatus();
    }
  };
  const handleNextClick = () => {
    if (tmpIndex != undefined && tmpIndex < artsIdList.length - 1) {
      const targetIndex = tmpIndex + 1;
      navigate(`/items/${artsIdList[targetIndex]}`, {
        state: { kind: kind, selectedTagId: selectedTagId, query: query },
      });
      resetLikeStatus();
    }
  };

  return (
    <PrevNextButtonWrapper>
      {tmpIndex != undefined && tmpIndex > 0 ? (
        <PrevButton onClick={handlePrevClick}>
          <div>←</div>
          <div>前の作品を見る</div>
        </PrevButton>
      ) : (
        <PrevButtonDisabled>
          <div>←</div>
          <div>前の作品を見る</div>
        </PrevButtonDisabled>
      )}
      {tmpIndex != undefined && tmpIndex < artsIdList.length - 1 ? (
        <NextButton onClick={handleNextClick}>
          <div>次の作品を見る</div>
          <div>→</div>
        </NextButton>
      ) : (
        <NextButtonDisabled>
          <div>次の作品を見る</div>
          <div>→</div>
        </NextButtonDisabled>
      )}
    </PrevNextButtonWrapper>
  );
};
