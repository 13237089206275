import React from 'react';
import { Text } from '../../atoms/Text';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';
import { TitleContainer } from './style';

interface Props {
  title?: string;
  subTitle?: string;
}

export const Title: React.FC<Props> = ({ title, subTitle }) => {
  return (
    <TitleContainer>
      <Text fontType={FontType.TITLE2}>{title}</Text>
      <Text color={Color.BRANDMAIN} fontType={FontType.TITLE2s}>
        {subTitle}
      </Text>
    </TitleContainer>
  );
};
