/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiShareBoxLine } from 'react-icons/ri';
import moment from 'moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Box, CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import {
  ArtUserInfoContainer,
  ItemDetailContainer,
  ItemInfoContainer,
  PictureContainer,
  StyledDescription,
  StyledDateDescription,
  DateDiscriptionContainer,
  StyledItemImage,
  TagsContainer,
  UserTagVStack,
  NFTInfoWrapper,
  NFTInfoTextContainer,
  NFTScanPath,
} from './style';
import { useMetamaskConnection } from './connect';

import { ArtPopUp } from '../../ArtPopUp';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { Tag } from '../../atoms/Tag';
import { UserTag } from '../../atoms/UserTag';

import { ButtonType } from '../../../constants/Button';
import { FontType } from '../../../constants/Fonts';
import { Color } from '../../../constants/Color';

import { Art } from '../../../types/domain/Art';
import { User } from '../../../types/domain/User';

import { AuthContext } from '../../../Providers/AuthProvider';
import { useAlertContext } from '../../../Providers/AlertProvider';
import { getAccount } from '../../../Providers/ethers';

import { API_URL, CHAIN_NETWORK } from '../../../config';

interface ItemDetailProps {
  artDetail: Art | undefined;
  currentUser: User | undefined;
  setIsMetamaskSigned: (value: boolean) => void;
  reload: () => Promise<any> | undefined;
}

export const createScanText = () => {
  if (CHAIN_NETWORK === 'PolygonMainnet') {
    return 'PolygonScanで表示';
  } else if (CHAIN_NETWORK === 'GoerliTest') {
    return 'EtherScan(テストネットワーク)で表示';
  }
};

export const DeliveryDetail: React.FC<ItemDetailProps> = ({
  artDetail,
  currentUser,
  setIsMetamaskSigned,
}) => {
  const navigate = useNavigate();
  const [isPicturePopUpDisplayed, setIsPicturePopUpDisplayed] = useState(false);
  const createDate = moment(artDetail?.createdAt).format('YYYY/MM/DD　HH:mm');
  const updateDate = moment(artDetail?.updatedAt).format('YYYY/MM/DD　HH:mm');

  const { authData } = useContext(AuthContext);
  const [isApproved, setIsApproved] = useState(false);
  const [metamaskId, setMetamaskId] = useState('');
  const [isSigning, setIsSigning] = useState(false);
  const useDeliveryDetailOptions = {
    artDetail: artDetail,
    authData: authData,
    setIsApproved: setIsApproved,
    setMetamaskId: setMetamaskId,
    setIsSigning: setIsSigning,
  };

  const { signAndUpdateWallet, checkApproval, handleMetamaskConnection } =
    useMetamaskConnection(useDeliveryDetailOptions);

  const createScanURL = () => {
    if (CHAIN_NETWORK === 'PolygonMainnet') {
      return (
        'https://polygonscan.com/token/' +
        artDetail?.Token?.contractAddr +
        '?a=' +
        artDetail?.Token?.contractTokenId
      );
    } else if (CHAIN_NETWORK === 'Goerli') {
      return (
        'https://goerli.etherscan.io/token/' +
        artDetail?.Token?.contractAddr +
        '?a=' +
        artDetail?.Token?.contractTokenId
      );
    }
  };

  const { addAlert } = useAlertContext();

  useEffect(() => {
    (async () =>
      checkApproval(currentUser).catch((e) => addAlert(e, 'error')))();
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      const metamaskId = await getAccount();
      setMetamaskId(metamaskId);
    })();
  }, []);

  useEffect(() => {
    setIsMetamaskSigned(isApproved);
  }, [isApproved]);

  if (!artDetail) return <></>;
  else {
    return (
      <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <ArtPopUp
          close={() => setIsPicturePopUpDisplayed(false)}
          isDisplayed={isPicturePopUpDisplayed}
          src={artDetail?.entityUrl}
        />
        <ItemDetailContainer>
          <PictureContainer>
            <Box>
              <IconButton
                onClick={() => navigate(-1)}
                color="secondary"
                aria-label="back"
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <StyledItemImage
              src={artDetail?.entityUrl}
              onClick={() => setIsPicturePopUpDisplayed(true)}
            />
          </PictureContainer>
          <ItemInfoContainer>
            <TagsContainer>
              {artDetail.isNFT && (
                <div
                  style={{
                    background:
                      'linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%)',
                    borderRadius: '100px',
                  }}
                >
                  <Tag
                    title="NFT"
                    key="nft-tag"
                    color={Color.WHITETEXT}
                    bgColor={Color.BRANDMAIN}
                  />
                </div>
              )}
              {artDetail.isPortfolio && (
                <div
                  style={{
                    background:
                      'linear-gradient(94.62deg, #E95098 15.12%, #00A1E9 86.57%)',
                    borderRadius: '100px',
                  }}
                >
                  <Tag
                    title="ポートフォリオ"
                    key="nft-tag"
                    color={Color.WHITETEXT}
                    bgColor={Color.BRANDMAIN}
                  />
                </div>
              )}
              {artDetail.tags?.map((tag) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <Tag
                    title={tag.name}
                    key={artDetail.tags?.indexOf(tag)}
                    color={Color.WHITETEXT}
                    bgColor={Color.BRANDMAIN}
                  />
                );
              })}
            </TagsContainer>
            <Text fontType={FontType.TITLE1}>{artDetail?.name}</Text>
            {/* {nftItem()} */}
            <StyledDescription>
              <Text fontType={FontType.SUB}>{artDetail?.about}</Text>
            </StyledDescription>
            <Text fontType={FontType.TITLE2s}>作品の情報</Text>
            <ArtUserInfoContainer>
              <UserTagVStack>
                <Text fontType={FontType.TITLE4}>オーナー</Text>
                {artDetail?.ownedByAddress ? (
                  artDetail?.isSoldByOpensea ? (
                    //OpenSeaで販売されている場合
                    <UserTag
                      userTagImageUri={`${process.env.PUBLIC_URL}/opensea_logo.png`}
                      userName={artDetail?.ownedByAddress}
                      onclick={() =>
                        window.open(
                          `https://opensea.io/accounts/${artDetail?.ownedByAddress}`,
                          '_blank',
                        )
                      }
                    />
                  ) : (
                    //出庫されている場合
                    <UserTag
                      userTagImageUri={`${process.env.PUBLIC_URL}/polygon_logo.png`}
                      userName={artDetail?.ownedByAddress}
                      onclick={() =>
                        window.open(
                          `https://polygonscan.com/address/${artDetail?.ownedByAddress}`,
                          '_blank',
                        )
                      }
                    />
                  )
                ) : (
                  //YoaniMP内に存在する場合
                  <UserTag
                    userTagImageUri={artDetail?.ownerInfo.profileImage}
                    userName={artDetail?.ownerInfo.username}
                    onclick={() =>
                      navigate(`/userpage/${artDetail?.ownerInfo.userId}`)
                    }
                  />
                )}
              </UserTagVStack>
              <UserTagVStack>
                <Text fontType={FontType.TITLE4}>クリエイター</Text>
                <UserTag
                  userTagImageUri={
                    API_URL + `/users/${artDetail?.creatorInfo.userId}/icon`
                  }
                  userName={artDetail?.creatorInfo.username}
                  onclick={() =>
                    navigate(`/userpage/${artDetail?.creatorInfo.userId}`)
                  }
                />
              </UserTagVStack>
            </ArtUserInfoContainer>
            <DateDiscriptionContainer>
              <StyledDateDescription>
                <Text fontType={FontType.TITLE5}>作成日：</Text>
                <Text fontType={FontType.SMALL_NORMAL}>{createDate}</Text>
              </StyledDateDescription>
              {createDate !== updateDate ? (
                <StyledDateDescription>
                  <Text fontType={FontType.TITLE5}>更新日：</Text>
                  <Text fontType={FontType.SMALL_NORMAL}>{updateDate}</Text>
                </StyledDateDescription>
              ) : (
                <></>
              )}
            </DateDiscriptionContainer>
            {artDetail.isNFT &&
              artDetail.Token &&
              artDetail.Token.contractAddr != '' && (
                <NFTInfoWrapper>
                  <NFTInfoTextContainer>
                    <Text fontType={FontType.TITLE3}>Contract Address</Text>
                    <NFTScanPath
                      href={createScanURL()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiShareBoxLine style={{ marginRight: '2%' }} />
                      <p style={{ wordBreak: 'break-all', margin: 0 }}>
                        {artDetail.Token.contractAddr}
                      </p>
                    </NFTScanPath>
                  </NFTInfoTextContainer>
                  <NFTInfoTextContainer>
                    <Text fontType={FontType.TITLE3}>Contract Token ID</Text>
                    {artDetail.Token.contractTokenId}
                  </NFTInfoTextContainer>
                </NFTInfoWrapper>
              )}
            {(() => {
              if (artDetail.ownerInfo.userId === authData.userId) {
                return (
                  <>
                    {!metamaskId ? (
                      <div style={{ marginBottom: '20px' }}>
                        <Button
                          buttonType={ButtonType.ROUNDEDIMPORTANT}
                          onClick={() => handleMetamaskConnection()}
                        >
                          <p style={{ fontWeight: 900 }}>
                            ウォレットに接続する
                          </p>
                        </Button>
                      </div>
                    ) : (
                      <>
                        {!isApproved ? (
                          <div style={{ marginBottom: '20px' }}>
                            <Button
                              onClick={async () => {
                                await signAndUpdateWallet(currentUser);
                              }}
                              buttonType={ButtonType.WIDEROUNDED_OUTLINED}
                            >
                              {!isSigning ? (
                                'ウォレットで署名する'
                              ) : (
                                <CircularProgress
                                  size={16}
                                  color={'secondary'}
                                  style={{ margin: '0px 80px 0px 80px' }}
                                />
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div style={{ marginBottom: '20px' }}>
                            <Text fontType={FontType.TITLE3}>出庫先：</Text>
                            <Text fontType={FontType.SMALL}>
                              {metamaskId.slice(0, 6) +
                                ' ****** ' +
                                metamaskId.slice(-4)}{' '}
                              のウォレットに転送されます。
                            </Text>
                          </div>
                        )}
                      </>
                    )}
                  </>
                );
              }
            })()}
          </ItemInfoContainer>
        </ItemDetailContainer>
      </div>
    );
  }
};
